import { defineMessages } from 'react-intl';

const messages = defineMessages({
  mobileMenuTitle: {
    id: 'footer.mobileMenuTitle',
    defaultMessage: 'More',
  },
  dismissAriaLabel: {
    id: 'footer.dismissAriaLabel',
    defaultMessage: 'Close menu',
  },
});

export default messages;

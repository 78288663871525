import { API, graphqlOperation, GraphQLAuthError } from '@aws-amplify/api';
import { find, propEq, prop } from 'ramda';
import { Hub } from '@aws-amplify/core';

import { timeout } from './promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';
import { verifyEmail } from '../utils/authUtils';
import { AUTH_EVENTS } from '../constants/auth';

// Singleton cache for requests in transit
const requestsCache = {};

const defaultOptions = {
  bypassCache: false,
  timeout: null,
};

const makeRequest = (operation, timeoutMs) => {
  return timeoutMs
    ? timeout(timeoutMs, API.graphql(operation))
    : API.graphql(operation);
};

const isEmailValidationRequired = err => {
  if (err && err.errors) {
    const errors = prop('errors', err);
    const emailValidationRequired = propEq(
      'errorType',
      ERROR_TYPES.EmailValidationRequired
    );
    if (find(emailValidationRequired, errors)) {
      return true;
    }
  }
  return false;
};

const isUserSignedOut = err =>
  err?.message === GraphQLAuthError.NO_CURRENT_USER;

/**
 * Executes the given GraphQL query. If an identical query is in transit the
 * existing promise will be reused.
 *
 * @param {String} query
 * @param {Object} variables
 * @param {Object} [options={}]
 * @param {Boolean} [options.bypassCache=false] Whether to disable promise reuse
 * @param {Number} [options.timeoutMs=null] Request timeout in ms
 * @returns {Promise}
 */
export const graphql = (query, variables, options) => {
  const operation = graphqlOperation(query, variables);
  const { bypassCache, timeoutMs } = Object.assign({}, defaultOptions, options);

  if (bypassCache) {
    return makeRequest(operation, timeoutMs);
  }

  const serialized = JSON.stringify(operation);

  if (requestsCache[serialized]) {
    return requestsCache[serialized];
  }

  const promise = makeRequest(operation, timeoutMs)
    .then(res => {
      delete requestsCache[serialized];
      return res;
    })
    .catch(err => {
      delete requestsCache[serialized];
      if (isEmailValidationRequired(err)) verifyEmail();
      if (isUserSignedOut(err)) {
        Hub.dispatch('auth', { event: AUTH_EVENTS.EH_NoCurrentUser });
        // Return a "successful" response to not trigger error flows when getting signed out.
        return {};
      }
      throw err;
    });
  requestsCache[serialized] = promise;
  return promise;
};

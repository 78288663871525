import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { URL_PATHS } from '../constants/urlPaths';

const getAggregatePath = path => {
  let aggregatePath;
  if (path.startsWith(URL_PATHS.labPage)) {
    aggregatePath = URL_PATHS.labPage;
  } else if (path.startsWith(URL_PATHS.standaloneLabPage)) {
    aggregatePath = URL_PATHS.standaloneLabPage;
  } else {
    aggregatePath = path;
  }
  return aggregatePath;
};

/**
 * @param {() => string)} onViewPage Emits the path when navigating.
 */
const useRecordPageView = onViewPage => {
  const location = useLocation();
  const previousPath = useRef();

  useEffect(() => {
    // Avoid recording duplicate page navigation events for the same path.
    if (previousPath.current === location.pathname) return;
    previousPath.current = location.pathname;

    onViewPage(getAggregatePath(location.pathname));
  }, [location, onViewPage]);
};

export default useRecordPageView;

import React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';

import './SideNavigationOverride.scss';

const SideNavigationOverride = ({ variant, lang, ...props }) => {
  if (variant === 'labToc')
    return (
      <span className="SideNavigationOverride--labToc" lang={lang}>
        <SideNavigation {...props} />
      </span>
    );
  return <SideNavigation {...props} />;
};

export default SideNavigationOverride;

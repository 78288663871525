import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'ongoingLab.header',
    defaultMessage: 'Lab In Progress',
  },
  note: {
    id: 'ongoingLab.note',
    defaultMessage:
      'Note: You can have only one lab in progress at a time. If you want to start another lab, you have to end the current lab.',
  },
  resumeLabButton: {
    id: 'ongoingLab.resumeLabButton',
    defaultMessage: 'Resume Lab',
  },
  existingLabLink: {
    id: 'ongoingLab.existingLabLink',
    defaultMessage: 'Open Lab',
  },
  loadOngoingLabFail: {
    id: 'ongoingLab.loadOngoingLabFail',
    defaultMessage:
      'Uh oh, something went wrong.{br}Please reload the page and try again.',
  },
  reload: {
    id: 'ongoingLab.reload',
    defaultMessage: 'Reload page',
  },
});

export default messages;

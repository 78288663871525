import React, { useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import metrics from '../../utils/metrics';
import messages from './TourTooltip.messages';
import './TourTooltip.css';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const IDS = {
  next: 'nextBtn',
  back: 'backBtn',
  skip: 'skipBtn',
  prefix: 'TourTooltip__',
};

const TourTooltip = ({
  index,
  size,
  step,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  lastFocused,
  setLastFocused,
}) => {
  const { formatMessage } = useIntl();

  const metricsPublisher = useRef(metrics.createPublisher('SiteTour'));

  const publishTourCloseMetrics = useCallback(() => {
    if (index === 0) {
      metricsPublisher.current.publishCounter('Skipped', 1);
    } else {
      metricsPublisher.current.publishCounter('Ended', 1);
      metricsPublisher.current.publishString('currentStep', index + 1);
      metricsPublisher.current.publishString('totalSteps', size);
    }
  }, [index, size]);

  useEffect(() => {
    const handleKeyDown = e => {
      // Polaris AppLayout is stealing focus on initial load
      // which is causing issues with keyboard navigation
      // Need to manually handle tab and shift+tab
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (!lastFocused) setLastFocused(IDS.skip);
          else if (lastFocused === IDS.skip) setLastFocused(IDS.next);
          else if (lastFocused === IDS.back) setLastFocused(IDS.skip);
          else if (lastFocused === IDS.next)
            setLastFocused(index > 0 ? IDS.back : IDS.skip);
        } else {
          if (!lastFocused) setLastFocused(IDS.skip);
          else if (lastFocused === IDS.skip)
            setLastFocused(index > 0 ? IDS.back : IDS.next);
          else if (lastFocused === IDS.back) setLastFocused(IDS.next);
          else if (lastFocused === IDS.next) setLastFocused(IDS.skip);
        }
      } else if (e.key === 'ArrowLeft' && index !== 0) {
        backProps.onClick(e);
      } else if (e.key === 'ArrowRight' && index !== size - 1) {
        primaryProps.onClick(e);
      } else if (e.key === 'Escape' || e.key === 'Esc') {
        publishTourCloseMetrics();
        skipProps.onClick(e);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    index,
    lastFocused,
    setLastFocused,
    skipProps,
    backProps,
    primaryProps,
    size,
    formatMessage,
    publishTourCloseMetrics,
  ]);

  useEffect(() => {
    const target = document.querySelector(`.${IDS.prefix}${lastFocused}`);
    target && target.focus();
  }, [lastFocused, index]);

  return (
    <span
      {...tooltipProps}
      role="dialog"
      data-testid="siteTour-dialog"
      aria-label={step.title}
    >
      <Container
        header={step.title && <Header variant="h2">{step.title}</Header>}
        footer={
          <div className="TourTooltip__footer">
            <div>
              {!isLastStep && (
                <Button
                  {...skipProps}
                  className={`${IDS.prefix}${IDS.skip}`}
                  ariaLabel={formatMessage(messages.end)}
                  data-testid={dataTestId['siteTour-skip']}
                  variant="link"
                  onClick={e => {
                    publishTourCloseMetrics();
                    skipProps.onClick(e);
                  }}
                >
                  {formatMessage(messages.end)}
                </Button>
              )}
            </div>
            <SpaceBetween direction="horizontal" size="s">
              {index > 0 && (
                <Button
                  {...backProps}
                  className={`${IDS.prefix}${IDS.back}`}
                  ariaLabel={formatMessage(messages.back)}
                  data-testid={dataTestId['siteTour-back']}
                  onClick={e => {
                    setLastFocused(IDS.next);
                    backProps.onClick(e);
                  }}
                >
                  {formatMessage(messages.back)}
                </Button>
              )}
              <Button
                {...primaryProps}
                className={`${IDS.prefix}${IDS.next}`}
                ariaLabel={
                  isLastStep
                    ? formatMessage(messages.end)
                    : formatMessage(messages.next)
                }
                data-testid={dataTestId['siteTour-next']}
                variant="primary"
                onClick={e => {
                  if (index === size - 2) {
                    // Publish metrics when user presses next to access final step.
                    // Ensures all completions are captured even if back/escape is pressed.
                    metricsPublisher.current.publishCounter('Completed', 1);
                  }
                  setLastFocused(IDS.next);
                  primaryProps.onClick(e);
                }}
              >
                {isLastStep
                  ? formatMessage(messages.end)
                  : formatMessage(messages.next)}
              </Button>
            </SpaceBetween>
          </div>
        }
      >
        {step.content}
      </Container>
    </span>
  );
};

export default TourTooltip;

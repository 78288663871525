import React from 'react';

import FooterEventLink from './FooterEventLink';
import FooterLayout from './FooterLayout';
import LegalZone from './LegalZone';
import LiveSupport from '../LiveSupport/LiveSupport';

const FooterOutsideRouter = () => {
  const left = (
    <>
      <LegalZone />
      <FooterEventLink />
    </>
  );
  const right = <LiveSupport />;

  return (
    <FooterLayout
      left={left}
      right={right}
      testid={'footer-FooterOutsideRouter'}
    />
  );
};

FooterOutsideRouter.propTypes = {};

export default FooterOutsideRouter;

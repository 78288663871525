import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

import seoMessages from './SEO.messages';
import appMessages from '../i18n/app.messages';

function SEO({ description, meta, title }) {
  const { formatMessage } = useIntl();
  const siteMetadata = {
    title: formatMessage(appMessages.title),
    description: formatMessage(seoMessages.description),
    author: 'AWS Training and Certification', // is not translated
  };

  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      defer={false} // Ensures the header is populated even though the browser tab is not focused.
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;

import { defineMessages } from 'react-intl';

export const appLayoutMessages = defineMessages({
  navigation: {
    id: 'appLayout.navigation',
    defaultMessage: 'Navigation drawer',
  },
  navigationToggle: {
    id: 'appLayout.navigationToggle',
    defaultMessage: 'Open navigation drawer',
  },
  navigationClose: {
    id: 'appLayout.navigationClose',
    defaultMessage: 'Close navigation drawer',
  },
  tools: {
    id: 'appLayout.tools',
    defaultMessage: 'Help panel',
  },
  toolsClose: {
    id: 'appLayout.toolsClose',
    defaultMessage: 'Close help panel',
  },
  toolsToggle: {
    id: 'appLayout.toolsToggle',
    defaultMessage: 'Open help panel',
  },
  notifications: {
    id: 'appLayout.notifications',
    defaultMessage: 'Notifications',
  },
});

export default appLayoutMessages;

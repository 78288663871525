import React from 'react';
import { useIntl } from 'react-intl';
import { Mode } from '@amzn/awsui-global-styles';
import { Icon } from '@amzn/awsui-components-react';
import { path } from 'ramda';

import { ButtonDropdownOverride } from '../PolarisOverrides';
import { handleSignOut } from '../../utils/authUtils';
import { publishButtonClickMetric } from '../../utils/metrics';
import { USER_MENU } from '../../constants/userMenu';
import { TOUR_IDS } from '../../constants/siteTour';
import { useThemeContext } from '../../contexts';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './UserMenu.messages';

const publishMenuMetrics = (menuTitle, itemId) =>
  publishButtonClickMetric(`${menuTitle}:${itemId}`, {
    currentPath: window.location.pathname,
    clickLocation: 'Header',
  });

const getUserMenuItems = ({ currentTheme, formatMessage }) => {
  return [
    {
      text: formatMessage(messages.themeHeader),
      items: [
        {
          text: formatMessage(messages.light),
          id: USER_MENU.lightTheme,
          disabled: currentTheme === Mode.Light,
        },
        {
          text: formatMessage(messages.dark),
          id: USER_MENU.darkTheme,
          disabled: currentTheme === Mode.Dark,
        },
      ],
    },
    {
      text: formatMessage(messages.signOut),
      id: USER_MENU.signOut,
    },
  ];
};

const getUserMenuCallbacks = setCurrentTheme => ({
  [USER_MENU.signOut]: handleSignOut,
  [USER_MENU.lightTheme]: () => setCurrentTheme(Mode.Light),
  [USER_MENU.darkTheme]: () => setCurrentTheme(Mode.Dark),
});

const UserMenu = ({ children, isStandalone }) => {
  const { formatMessage } = useIntl();
  const [currentTheme, setCurrentTheme] = useThemeContext();

  const userMenuCallbacks = getUserMenuCallbacks(setCurrentTheme);

  const menuItems = getUserMenuItems({
    currentTheme,
    formatMessage,
    isStandalone,
  });

  return (
    <>
      <ButtonDropdownOverride
        variant="transparent"
        activeDisabled
        items={menuItems}
        onItemClick={event => {
          const itemId = path(['detail', 'id'], event);
          publishMenuMetrics('UserMenu', itemId);
          const itemCallback = userMenuCallbacks[itemId];
          if (!itemCallback) return;
          itemCallback();
        }}
        data-jsid="header-userMenu"
        data-testid={dataTestId['header-userMenu']}
        data-tourid={TOUR_IDS.userMenu}
      >
        <span role="img" aria-label={formatMessage(messages.userIconAlt)}>
          <Icon name="user-profile" />
        </span>{' '}
        {children}
      </ButtonDropdownOverride>
    </>
  );
};

export default UserMenu;

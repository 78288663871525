import { keys, head, length, pipe, propSatisfies } from 'ramda';

import { EHBlueprint } from '../../models';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import { updateQueryParam } from '../../utils/queryParamUtils';
import { SEARCH_QUERY_PARAM } from '../../constants/catalog';

export const getFirstKey = pipe(keys, head);

export const createSegmentedOptions = (config, locale) => {
  const options = [];
  if (!config || typeof config !== 'object') return options;

  Object.keys(config).forEach(key => {
    const item = getLocaleOrAvailable(locale, config[key]);

    if (item && item.title) {
      const optionConfig = { text: item.title, id: key };
      options.push(optionConfig);
    }
  });

  return options;
};

/**
 * Retrieves catalog items by filtering and mapping blueprints from the selected config.
 *
 * @param {Object} config - The configuration object containing blueprint data for each catalog item.
 * @param {string} selectedId - The ID of the selected catalog item.
 * @returns {EHBlueprint[]} An array of EHBlueprint instances that have valid locales.
 */
export const getCatalogItems = (config, selectedId) => {
  if (!config || !selectedId || !config[selectedId]) return [];
  const hasValidLocales = propSatisfies(length, 'locales');
  const { blueprints } = config[selectedId];
  return blueprints.map(data => new EHBlueprint(data)).filter(hasValidLocales);
};

/**
 * Updates the search query string in the URL by removing or setting the value of the search query parameter.
 *
 * @param {Object} history - The history object from React Router to manage session history.
 */
export const updateSearchQueryString = history => {
  const modQueryString = updateQueryParam(
    history.location.search,
    SEARCH_QUERY_PARAM,
    ''
  );
  history.replace({
    pathname: history.location.pathname,
    search: modQueryString ? `?${modQueryString}` : '',
  });
};

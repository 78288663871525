import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tcLogoAlt: {
    id: 'tncLink.tcLogoAlt',
    defaultMessage: 'AWS Training and Certification',
  },
});

export default messages;

import React, { useRef, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  SpaceBetween,
  Container,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { prop, isNil, isEmpty } from 'ramda';
import { useIntl } from 'react-intl';

import LoadingSkeleton from '../LoadingSkeleton';
import { Carousel } from '../Carousel';
import useUniqueId from '../../hooks/useUniqueId';
import logger from '../../utils/logger';
import metrics from '../../utils/metrics';
import { getLocaleOrAvailable, getLanguageCode } from '../../utils/localeUtils';
import { getUriForCatalogItem } from '../../utils/catalogUtils';
import ERROR_MESSAGES from '../../i18n/errors.messages';
import messages from './Channel.messages';
import useCollection from '../../hooks/useCollection';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const emptyItem = { name: '', arn: '', duration: '', description: '' };

/**
 * Generates a list of ChannelListItem components to be shown in the carousel.
 * Invalid blueprints are removed from the list to avoid a empty card in the
 * UI. If no metadata for the preferred locale exists the first available
 * locale will be picked.
 *
 * @param {Array[Object]} blueprints
 * @param {String} locale
 * @param {String} ongoingBlueprintArn Blueprint arn for ongoing lab
 * @param {String} ongoingLabId Lab id for ongoing lab
 * @returns {Array[React.Node]} List of components
 */
const getChannelViewItems = (
  blueprints,
  locale,
  ongoingBlueprintArn,
  ongoingLabId
) =>
  !isNil(blueprints) &&
  blueprints
    .map(blueprint => {
      if (prop('isLoading', blueprint)) {
        return emptyItem;
      }

      const bpLocale = getLocaleOrAvailable(locale, blueprint);
      if (!bpLocale) {
        logger.debug(
          `No valid locale on blueprint. arn: ${prop('arn', blueprint)}`
        );
        return null;
      }

      return {
        name: bpLocale.title,
        duration: blueprint.durationData,
        arn: getUriForCatalogItem(
          blueprint.arn,
          bpLocale.locale,
          ongoingBlueprintArn,
          ongoingLabId
        ),
        services: blueprint.services,
        certifications: blueprint.certifications,
        level: blueprint.level,
        lang: getLanguageCode(bpLocale.locale),
      };
    })
    .filter(Boolean);

const ChannelLoadError = ({
  header,
  onRefresh,
  loadErrorMsg,
  reloadBtnText,
}) => (
  <Box>
    <Box variant="h3" fontWeight="bold" margin={{ bottom: 'm' }}>
      {header}
    </Box>
    <Container>
      <Box textAlign="center" padding={{ bottom: 's' }}>
        <SpaceBetween size="s">
          <p>{loadErrorMsg}</p>
          <Button onClick={onRefresh} iconName="refresh">
            {reloadBtnText}
          </Button>
        </SpaceBetween>
      </Box>
    </Container>
  </Box>
);

const EmptyChannelView = ({ header, title, message }) => (
  <Box>
    <Box variant="h3" fontWeight="bold" margin={{ bottom: 'm' }}>
      {header}
    </Box>
    <Container>
      <Box textAlign="center" padding={{ bottom: 's' }}>
        <SpaceBetween size="xs">
          <Box variant="p" fontWeight="bold">
            {title}
          </Box>

          <p>{message}</p>
        </SpaceBetween>
      </Box>
    </Container>
  </Box>
);

const Channel = ({
  arn,
  localePref,
  ongoingBlueprintArn,
  ongoingLabId,
  onSettled = () => {},
}) => {
  const { formatMessage } = useIntl();
  const uniqueId = useUniqueId();
  const metricsPublisher = useRef(metrics.createPublisher('Channel'));
  const {
    data: channel,
    isLoading,
    isError,
    error,
  } = useCollection({
    arn,
    metricsPublisher: metricsPublisher.current,
    options: {
      onSettled,
    },
  });

  const Header = () => {
    const headerLocale = getLocaleOrAvailable(localePref, {
      locales: prop('locales', channel),
    });
    const title = prop('title', headerLocale);
    if (title) {
      const lang = getLanguageCode(headerLocale.locale);
      return <span lang={lang}>{title}</span>;
    }
    return (
      <>
        <div className="awsui-util-hide">{formatMessage(messages.loading)}</div>
        <LoadingSkeleton type="darkGrey" width={25} height={22} />
      </>
    );
  };

  const channelItems = useMemo(() => {
    // Populate loading skeleton dummy data when loading.
    if (isLoading || isNil(channel))
      return Array.from({ length: 5 }).map(() => emptyItem);

    return getChannelViewItems(
      channel.blueprints,
      localePref,
      ongoingBlueprintArn,
      ongoingLabId
    );
  }, [channel, localePref, ongoingBlueprintArn, ongoingLabId, isLoading]);

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  if (isError) {
    logger.debug('Failed to load Channel', error);
    return (
      <ChannelLoadError
        header={<Header />}
        onRefresh={handleRefresh}
        loadErrorMsg={formatMessage(ERROR_MESSAGES.loadChannelFail)}
        reloadBtnText={formatMessage(messages.reload)}
      />
    );
  }
  if (isEmpty(channelItems))
    return (
      <EmptyChannelView
        header={<Header />}
        title={formatMessage(messages.emptyViewTitle)}
        message={formatMessage(messages.emptyViewMessage)}
      />
    );

  return (
    <div
      data-testid={dataTestId['channel-container']}
      role="group"
      aria-labelledby={uniqueId}
      // Carousel buttons are `position: absolute`
      // They depend on the relative positioning of a parent
      // If no parent has a relative position, it defaults to the body
      // This was causing issues with positioning when help panel is open
      // Setting the channel position to relative fixes the issue
      style={{ position: 'relative' }}
    >
      <Box>
        <Box variant="h3" margin={{ bottom: 'm' }} id={uniqueId}>
          <Header />
        </Box>
        <Carousel items={channelItems} />
      </Box>
    </div>
  );
};

Channel.propTypes = {
  arn: PropTypes.string.isRequired,
  localePref: PropTypes.string,
  ongoingBlueprintArn: PropTypes.string,
  ongoingLabId: PropTypes.string,
  onSettled: PropTypes.func,
};

export default Channel;

import React from 'react';
import PropTypes from 'prop-types';

import Feedback from '../Feedback/Feedback';
import FooterEventLink from './FooterEventLink';
import HelpButton from './HelpButton';
import FooterLayout from './FooterLayout';
import LegalZone from './LegalZone';
import LiveSupport from '../LiveSupport/LiveSupport';

const FooterStandalone = ({ blueprint, appLayoutRef }) => {
  const left = (
    <>
      <LegalZone />
      <FooterEventLink />
    </>
  );
  const right = (
    <>
      <LiveSupport />
      <Feedback blueprint={blueprint} />
      <HelpButton appLayoutRef={appLayoutRef} />
    </>
  );

  // items always shown in footer on mobile view
  const mobileItems = (
    <>
      <LiveSupport />
      <HelpButton appLayoutRef={appLayoutRef} />
    </>
  );

  // items inside footer mobile menu
  const mobileCollapsed = (
    <>
      <LegalZone />
      <FooterEventLink />
      <Feedback blueprint={blueprint} />
    </>
  );

  return (
    <FooterLayout
      left={left}
      right={right}
      mobileItems={mobileItems}
      mobileCollapsed={mobileCollapsed}
      testid={'footer-FooterStandalone'}
    />
  );
};

FooterStandalone.propTypes = {
  blueprint: PropTypes.object,
  appLayoutRef: PropTypes.object.isRequired,
};

export default FooterStandalone;

import { Amplify, Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { GraphQLAuthError } from '@aws-amplify/api';
import { path, prop } from 'ramda';

import configService from './configService';
import logger from './logger';
import {
  AMPLIFY_APPSYNC_AUTH,
  AUTH_EVENTS,
  EMAIL_VERIFICATION_PARAM,
  FORBIDDEN_FAILED_MESSAGE,
  FORBIDDEN_FAILED_STATUS,
} from '../constants/auth';
import ForbiddenAccess from '../errors/ForbiddenAccess';
import RequireAuthentication from '../errors/RequireAuthentication';
import SignInError from '../errors/SignInError';
import { getEHLocationValue } from './locationUtils';
import { historyReplaceState } from './urlUtils';

// Enable to get more transparency into Amplify Auth when debugging locally:
// window.LOG_LEVEL = 'DEBUG';

/**
 * Checks if the code authorization flow is onging.
 * This check needs to happen before Amplify starts using the code in the URL.
 *
 * @returns {Boolean}
 */
const isCodeFlowOngoing = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return Boolean(urlParams.get('code'));
};

/**
 * @param {URLSearchParams} urlParams
 */
const removeErrorParamsFromUrl = urlParams => {
  try {
    urlParams.delete('error');
    urlParams.delete('error_description');
    urlParams.delete('state');
    historyReplaceState(
      {},
      document.title,
      `${document.location.pathname}?${urlParams}`
    );
  } catch (error) {
    logger.debug('Failed to replace url.', error);
  }
};

/**
 * Checkes the URL for parameters that indicates that sign in failed, that will
 * cause the function to throw.
 *
 * @throws {SignInError} If sign in has failed
 */
const throwIfUrlStateIndicatesSignInFailed = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('error') && urlParams.get('error_description')) {
    const errorMessage = `${urlParams.get('error')}: ${urlParams.get(
      'error_description'
    )}`;

    // Cleanup the URL to ensure the error state does not persist on reload.
    // A page reload in this case will result in a redirect to Gandalf.
    removeErrorParamsFromUrl(urlParams);

    throw new SignInError(errorMessage);
  }
};

/**
 * Need to await Amplify getting configured properly before trying to get the
 * currently signed in user. This flow of expected events will be different
 * depending on if we have a ongoing code authorization flow or not.
 *
 * Events passed in different states:
 *
 * (When authenticated/unauthenticated)
 *  - configured
 *
 * (During signin using code flow)
 *  - configured
 *  - signIn
 *
 * @param {Boolean} isCodeFlow Indicates if the code authorization flow is ongoing.
 * @returns {Promise}
 */
const awaitAmplifyConfigured = isCodeFlow => {
  if (isCodeFlow) logger.debug('codeFlow ongoing, will await singIn event');

  return new Promise((resolve, reject) => {
    let conditions = {
      isAmplifyConfigurationDone: false,
      isCodeFlowDone: !isCodeFlow,
      signInError: undefined,
    };

    // Callback function to handle Amplify Hub events
    const handleAuthEvent = data => {
      handleAuthEventHelper(data, conditions);

      if (conditions.isAmplifyConfigurationDone && conditions.isCodeFlowDone) {
        Hub.remove('auth', handleAuthEvent);
        resolve();
      } else if (conditions.signInError) {
        Hub.remove('auth', handleAuthEvent);
        reject(conditions.signInError);
      }
    };
    Hub.listen('auth', handleAuthEvent);
  });
};

/**
 * Creates a `urlOpener` function to intercept sign in calls and exchange the url.
 * If no signInUIDomain has been defined the given Hosted UI url will be used.
 *
 * @param {String} domain Hosted UI domain
 * @param {String} signInUIDomain SignIn UI domain
 * @param {Boolean} emailValidationRequired Require the user validates their email
 * @returns {Function(String): Promise} urlOpener function
 */
export const createUrlInterceptor =
  (domain, signInUIDomain, emailValidationRequired) => url => {
    const updatedUrl = new URL(url);
    const loginRequestUrl = `https://${domain}/oauth2/authorize`;

    // Check that it's a login URL (in case Amplify uses the interceptor for something else)
    if (url.startsWith(loginRequestUrl)) {
      // When a custom signInUIDomain is provided we'll update to use the custom Gandalf login
      if (signInUIDomain) {
        updatedUrl.hostname = signInUIDomain;
        updatedUrl.pathname = '/login';
      }
      // Add the custom email verification search param when required
      if (emailValidationRequired) {
        updatedUrl.searchParams.append(
          EMAIL_VERIFICATION_PARAM,
          emailValidationRequired
        );
      }
    }

    const windowProxy = window.open(updatedUrl.href, '_self');
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

/**
 * Replaces the URL with the given path, but also ensures to merge any existing
 * search params. If the new path is invalid the replace will not be performed.
 * @param {String} newPath
 */
const replaceUrlPath = newPath => {
  try {
    const url = new URL(window.location.origin + newPath);
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });
    historyReplaceState({}, document.title, url.pathname + url.search);
  } catch (error) {
    logger.debug('Failed to replace url.', error);
  }
};

/**
 * Listens to the Amplify Hub event for when the Oauth state param has been
 * handled. The state param currently contains information about the URL the
 * customer requested originally before being redirected to authentication.
 */
const addOauthStateListener = () => {
  const handleHubEvent = ({ payload }) => {
    const eventType = prop('event', payload);
    const requestedPath = prop('data', payload);

    if (eventType === AUTH_EVENTS.customOAuthState && requestedPath) {
      logger.debug('Received customOAuthState', requestedPath);
      Hub.remove('auth', handleHubEvent);
      replaceUrlPath(requestedPath);
    }
  };

  Hub.listen('auth', handleHubEvent);
};

/**
 * Checking Auth event and mutate the conditions appropriately.
 * @param {Object} data Event information from Amplify Hub.
 * @param {Object} conditions This parameter will get mutated.
 */
export const handleAuthEventHelper = (data, conditions) => {
  const errorMessage = path(['payload', 'data', 'message'], data) || '';
  switch (path(['payload', 'event'], data)) {
    case AUTH_EVENTS.configured:
      logger.debug('configured done.');
      conditions.isAmplifyConfigurationDone = true;
      break;

    case AUTH_EVENTS.signIn:
      logger.debug('signIn done.');
      conditions.isCodeFlowDone = true;
      break;

    case AUTH_EVENTS.signIn_failure:
      logger.debug('signIn_failure', data);

      if (errorMessage === FORBIDDEN_FAILED_MESSAGE) {
        conditions.signInError = new ForbiddenAccess('Access required.');
      } else if (errorMessage.includes(FORBIDDEN_FAILED_STATUS)) {
        conditions.signInError = new ForbiddenAccess('Access required.');
      } else {
        conditions.signInError = new RequireAuthentication(errorMessage);
      }
      break;

    default:
      // noop
      break;
  }
};

/**
 * Configure Amplify.
 *
 * @param {Object} [params]
 * @param {Boolean} [params.emailValidationRequired=false] Used to dynamically re-configure Amplify to require a email when signing in.
 * @returns {Promise}
 */
const configureAmplify = async ({ emailValidationRequired = false } = {}) => {
  addOauthStateListener();
  const isCodeFlow = isCodeFlowOngoing();
  const config = await configService.get();

  // Need to set up the listener before Amplify.configure to not miss events
  const configPromise = awaitAmplifyConfigured(isCodeFlow);

  const amplifyConfig = {
    API: {
      aws_appsync_region: config.region,
      aws_appsync_graphqlEndpoint: config.graphqlEndpoint,
      // AMAZON_COGNITO_USER_POOLS is used as we have configured Auth with Amazon Cognito
      // UserPoolID and WebClientId, not to be confused with AppSync configuration.
      aws_appsync_authenticationType:
        AMPLIFY_APPSYNC_AUTH.AMAZON_COGNITO_USER_POOLS,
      graphql_headers: async () => ({
        Authorization: (
          await Auth.currentSession().catch(err => {
            // Check if the user has been signed out. Ref: https://tiny.amazon.com/ni2guctt
            if (err === GraphQLAuthError.NO_CURRENT_USER)
              Hub.dispatch('auth', { event: AUTH_EVENTS.EH_NoCurrentUser });
            throw err;
          })
        )
          .getIdToken()
          .getJwtToken(),
        'EH-Location': getEHLocationValue(),
      }),
    },
    Auth: {
      region: config.cognitoRegion,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolClientId,
      oauth: {
        ...config.oauth,
        urlOpener: createUrlInterceptor(
          config.oauth.domain,
          config.signInUIDomain,
          emailValidationRequired
        ),
      },
    },
  };

  // Amplify throws an uncatchable Error if there are error params in the URL
  // resulting in an unhandled rejection. This might result in a debug screen
  // if you are developing in localhost, pointing to this line. Ok to ignore.
  Amplify.configure(amplifyConfig);

  // We need to await Amplify configure to be able to retry signing if it has failed.
  throwIfUrlStateIndicatesSignInFailed();

  await configPromise;
};

export default configureAmplify;

import React from 'react';
import { Badge } from '@amzn/awsui-components-react';

import './BadgeOverride.scss';

const BadgeOverride = ({ color, clickable = false, ...props }) => {
  if (color === 'black') {
    return (
      <span
        className={`BadgeOverride--black${
          clickable ? ' BadgeOverride--clickable' : ''
        }`}
      >
        <Badge {...props} />
      </span>
    );
  }
  return <Badge {...props} />;
};

export default BadgeOverride;

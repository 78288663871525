import { prop } from 'ramda';
import { ACTIONS } from 'react-joyride';
import { TOUR_IDS, TOUR_STORAGE_TEXT } from '../constants/siteTour';
import { TOUR_STORAGE_ID } from '../constants/cookies';

import logger from '../utils/logger';
import { localStorageAdapter } from '../utils/cookieManagement';
import { URL_PATHS } from '../constants/urlPaths';

export const actions = {
  START_TOUR: 'START_TOUR',
  CONTINUE_TOUR: 'CONTINUE_TOUR',
  END_TOUR: 'END_TOUR',
};

export const defaultState = {
  isRunning: false,
  isEnded: false,
  stepIndex: 0,
  labPath: '',
  startPath: '',
};

const getResetState = () => defaultState;

/**
 * If a user starts on any page other than a lab,
 * they need to be redirected for the tour to work correctly.
 * First, we need to query the document for a specific lab path.
 * This will be the spotlight lab on the recommendations page,
 * or the first lab in the catalog.
 * We also need to preserve the start path 
 * to redirect the user back once the tour ends.
 
 * @returns {Object} {startPath, labPath}
 */
const getPathsOnTourStart = () => {
  let startPath = window.location.pathname;
  let labPath = '';
  if (
    !startPath.includes(`${URL_PATHS.labPage}/`) &&
    !startPath.includes(`${URL_PATHS.standaloneLabPage}/`)
  ) {
    const $lab = document.querySelector(`[data-tourid="${TOUR_IDS.labPath}"]`);
    labPath = prop('pathname', $lab);
  } else startPath = '';

  return { startPath, labPath };
};

export const tourReducer = (state, action) => {
  switch (action.type) {
    case actions.START_TOUR: {
      localStorageAdapter.removeItem(TOUR_STORAGE_ID);
      const { startPath, labPath } = getPathsOnTourStart();
      return {
        ...state,
        stepIndex: 0,
        isRunning: true,
        isEnded: false,
        startPath,
        labPath,
      };
    }

    case actions.CONTINUE_TOUR:
      return {
        ...state,
        stepIndex:
          action.stepIndex + (action.tourAction === ACTIONS.PREV ? -1 : 1),
      };

    case actions.END_TOUR: {
      localStorageAdapter.setItem(TOUR_STORAGE_ID, TOUR_STORAGE_TEXT);
      return {
        ...getResetState(),
        // Ensure the tour does not autostart if the local storage state is not persisted.
        isEnded: true,
      };
    }

    default:
      logger.debug(`Unhandled action: ${action.type}`);
      return state;
  }
};

export default {
  actions,
  defaultState,
  tourReducer,
};

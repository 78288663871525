/**
 * Parses the duration string and returns an object with hours and minutes.
 * @param {string} duration
 * @returns {Object|undefined}
 */
export function parseDurationData(duration) {
  if (!duration) return undefined;
  // duration is given by Content Service in a predefined format: https://tiny.amazon.com/fqw9h3nm
  // The regex matches: "1h1m1s", "1m1s" or "1s"
  if (!/(^([0-9]+h)?([0-9]+m)?[0-9]+s$)/i.test(duration)) return undefined;

  const hours = duration.substring(0, duration.indexOf('h')) || '0';
  const minutes =
    duration.substring(duration.indexOf('h') + 1, duration.indexOf('m')) || '0';

  return { hours, minutes };
}

/**
 * Converts parsed duration data to minutes.
 * @param {Object|undefined} durationData
 * @returns {number}
 */
export function getDurationInMinutes(durationData) {
  if (!durationData) return 0;
  const { hours, minutes } = durationData;
  return parseInt(hours) * 60 + parseInt(minutes);
}

/**
 * Determines the duration ID (short, medium, long) based on the duration string.
 * @param {string} duration
 * @returns {LabDurationId}
 */
export function getDurationId(duration) {
  const durationData = parseDurationData(duration);
  if (!durationData) return '';
  const durationInMinutes = getDurationInMinutes(durationData);
  if (durationInMinutes < 0) return '';
  if (durationInMinutes <= 60) return 'short';
  if (durationInMinutes < 120) return 'medium';
  return 'long';
}

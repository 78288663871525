import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'forbiddenAccessErrorPage.heading',
    defaultMessage: 'Are you registered for this event?',
  },
  forbiddenError: {
    id: 'forbiddenAccessErrorPage.forbiddenError',
    defaultMessage: 'Please try signing in with your registered event email.',
  },
  // Custom re:Invent copy
  reInventHeading: {
    id: 'forbiddenAccessErrorPage.reInventHeading',
    defaultMessage: 'Are you registered to join us in Las Vegas?',
  },
  reInventContentInfo: {
    id: 'forbiddenAccessErrorPage.reInventContentInfo',
    defaultMessage:
      'Sign in using the same email you used to register for re:Invent.',
  },
  reInventContentRegister: {
    id: 'forbiddenAccessErrorPage.reInventContentRegister',
    defaultMessage:
      'Not registered to attend in-person? <link>Register here</link>',
  },
  reInventContentSkillBuilder: {
    id: 'forbiddenAccessErrorPage.reInventContentSkillBuilder',
    defaultMessage:
      'Watching re:Invent online or unable to attend this year? Learn AWS with self-paced labs on Skill Builder. <link>Learn more</link>',
  },
});

export default messages;

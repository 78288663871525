import React, { useRef } from 'react';
import { Box, Icon, Button, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';

import LoadingSkeleton from '../LoadingSkeleton';
import { TOUR_IDS } from '../../constants/siteTour';
import metrics from '../../utils/metrics';
import { getLanguageCode, getLocaleOrAvailable } from '../../utils/localeUtils';
import { getUriForCatalogItem } from '../../utils/catalogUtils';
import ERROR_MESSAGES from '../../i18n/errors.messages';
import messages from './HeroBanner.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import useGetBlueprint from '../../hooks/useGetBlueprint';

import './HeroBanner.scss';

const HeroBanner = ({ arn, locale, ongoingBlueprintArn, ongoingLabId }) => {
  const { formatMessage } = useIntl();
  const spotlightLink = getUriForCatalogItem(
    arn,
    locale,
    ongoingBlueprintArn,
    ongoingLabId
  );
  const metricsPublisher = useRef(metrics.createPublisher('HeroBanner'));
  const { data: blueprint, isError } = useGetBlueprint({
    blueprintArn: arn,
    metricsPublisher: metricsPublisher.current,
  });

  const onBannerClick = () => {
    metricsPublisher.current.publishCounter('Click', 1);
    metricsPublisher.current.publishString('ARN', arn);
  };

  const Title = () => {
    const titleLocale = getLocaleOrAvailable(locale, blueprint);
    const title = prop('title', titleLocale);
    if (title) {
      const lang = getLanguageCode(titleLocale.locale);
      return <span lang={lang}>{title}</span>;
    }

    return (
      <div className="HeroBanner__loading">
        <div className="awsui-util-hide">{formatMessage(messages.loading)}</div>
        <LoadingSkeleton width={100} height={56} opacity={0.5} />
      </div>
    );
  };

  return isError ? (
    <Box
      padding={{ vertical: 'xxl' }}
      textAlign="center"
      className="HeroBanner"
    >
      <SpaceBetween size="m">
        <p>{formatMessage(ERROR_MESSAGES.loadSpotlightFail, { br: <br /> })}</p>
        <Button onClick={() => window.location.reload()} iconName="refresh">
          {formatMessage(messages.reload)}
        </Button>
      </SpaceBetween>
    </Box>
  ) : (
    <Link
      to={spotlightLink}
      data-testid={dataTestId['spotlight-link']}
      data-tourid={TOUR_IDS.labPath}
      onClick={onBannerClick}
      className="HeroBanner"
    >
      <Box
        margin={{ vertical: 'm' }}
        padding={{ vertical: 'xxl' }}
        className="HeroBanner__content"
        color="inherit"
      >
        <SpaceBetween size="s">
          <Box
            variant="h2"
            fontSize="heading-xl"
            fontWeight="bold"
            color="inherit"
          >
            {formatMessage(messages.tag)}
          </Box>
          <Box
            variant="h3"
            fontWeight="light"
            fontSize="display-l"
            color="inherit"
          >
            <Title />
          </Box>
          <Box
            fontSize="heading-xl"
            fontWeight="bold"
            className="HeroBanner__cta"
            color="inherit"
          >
            {formatMessage(messages.action)}&nbsp;
            <Icon name="angle-right-double" />
          </Box>
        </SpaceBetween>
      </Box>
    </Link>
  );
};

HeroBanner.propTypes = {
  arn: PropTypes.string.isRequired,
  locale: PropTypes.string,
  ongoingBlueprintArn: PropTypes.string,
  ongoingLabId: PropTypes.string,
};

export default HeroBanner;

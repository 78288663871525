import React from 'react';
import { Box, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './LabEvaluation.messages';

/**
 * Shown when the customer hasn't scored their lab yet
 */
const EvaluationNoScoreYet = () => {
  const { formatMessage } = useIntl();
  return (
    <Box textAlign={'center'} margin={{ top: 'm' }}>
      <span role="img" aria-label={formatMessage(messages.noScoreYetIconLabel)}>
        <Icon name="status-info" size="large" variant="subtle" />
      </span>
      <Box variant="p" margin={{ bottom: 's' }} color="text-body-secondary">
        {formatMessage(messages.noScoreYetMessage)}
      </Box>
    </Box>
  );
};

export default EvaluationNoScoreYet;

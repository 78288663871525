class ForbiddenAccess extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'ForbiddenAccess';
    this.date = new Date();

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenAccess);
    }
  }
}

export default ForbiddenAccess;

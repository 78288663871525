import { useLayoutEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const MOBILE_WINDOW_SIZE = 688;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleSize = useCallback(() => {
    const debouncedSetSize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 150);

    debouncedSetSize();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  return {
    windowSize,
    isMobile: windowSize.width <= MOBILE_WINDOW_SIZE,
  };
};

export default useWindowSize;

import React from 'react';
import PropTypes from 'prop-types';

const Main = ({ children, className = '' }) => (
  // Cannot use `<main>` as it is already used by Polaris when using AppLayout.
  <div
    id="js-main-content"
    tabIndex="-1"
    style={{ outline: 'none' }}
    className={className}
  >
    {children}
  </div>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Main;

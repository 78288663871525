import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { propOr, prop } from 'ramda';

import { labReducer, defaultState, actions, defaultLabData } from './labState';
import metrics, { publishFatal } from '../utils/metrics';
import useGetOngoingLabs from '../hooks/useGetOngoingLabs';
import useStartLabMutation from '../hooks/useStartLabMutation';
import useStopLabMutation from '../hooks/useStopLabMutation';
import ERROR_TYPES from '../constants/errorTypes';
import ERROR_MESSAGES from '../i18n/errors.messages';
import PROGRESS_TRACKING_MESSAGES from '../components/LabUI/LabButtons.messages';
import { useNotificationContext } from './NotificationContextProvider';
import { notificationTypes } from './notificationState';
import { handleSignIn } from '../utils/authUtils';
import useSubmitLabEvaluation from '../hooks/useSubmitLabEvaluation';
import usePollGetLabEvaluation from '../hooks/usePollGetLabEvaluation';
import useStartLabEvaluation from '../hooks/useStartLabEvaluation';
import { LAB_FUNCTION_STATUS } from '../constants/labFunctionExecution';

/**
 * @typedef {Object} LabContext
 * @property {import('./labState').LabState} labState
 * @property {Function} resetLabState
 * @property {Function} setHasQueriedOngoing
 * @property {boolean} hasQueriedOngoing
 * @property {(variables: import('../hooks/useStartLabMutation').Variables) => void} startLab
 * @property {boolean} isStartingLab
 * @property {(variables: import('../hooks/useStopLabMutation').Variables) => void} stopLab
 * @property {boolean} isStoppingLab
 * @property {(variables: import('../hooks/useStartLabEvaluation').Variables) => void} startLabEvaluation
 * @property {(variables: import('../hooks/useSubmitLabEvaluation').Variables) => void} submitLabEvaluation
 * @property {() => import('./labState').EvaluationState | undefined} getCurrentLabEvaluation
 * @property {() => import('./labState').LabData} getFirstLabInLabState
 * @property {Function} dispatch
 */

/** @type {LabContext} */
export const LabContext = createContext({
  labState: defaultState,
  getFirstLabInLabState: () => defaultLabData,
});

/**
 * @returns {LabContext}
 */
export const useLabContext = () => useContext(LabContext);

const LabContextProvider = ({ children, isAuthenticated }) => {
  const [labState, dispatch] = useReducer(labReducer, defaultState);

  // This state is used to delay render for lab action buttons before checking for ongoing lab.
  // It is useful when user opens a lab page directly, for example for a standalone event.
  // It should be set to true after querying for ongoing labs and getting the lab.
  const [hasQueriedOngoing, setHasQueriedOngoing] = useState(false);
  const { appendNotification } = useNotificationContext();
  const metricsPublisher = useRef(metrics.createPublisher('LabContext'));

  const resetLabState = useCallback(() => {
    dispatch({ type: actions.RESET_LAB_STATE });
    // Need this for when there is no ongoing lab or when initial getLab call returns an error.
    setHasQueriedOngoing(true);
  }, [dispatch]);

  const { refetch: refetchOngoingLabs, error: getOngoingLabsError } =
    useGetOngoingLabs({
      isAuthenticated,
      metricsPublisher: metricsPublisher.current,
      onSuccess: ongoingLabs => {
        if (ongoingLabs.length === 0) {
          resetLabState();
          return;
        }
        dispatch({
          type: actions.GET_ONGOING_RESPONSE,
          ongoingLabs: ongoingLabs,
        });
      },
      onError: error => {
        publishFatal('GetOngoingLabs', error);
        dispatch({ type: actions.GET_ONGOING_ERROR });
      },
    });

  const { mutate: startLab, isLoading: isStartingLab } = useStartLabMutation({
    metricsPublisher: metricsPublisher.current,
    onMutate: () => {
      dispatch({ type: actions.START_LAB_REQUEST });
    },
    onSuccess: (
      data,
      { acceptTerms, blueprintArn, blueprintLocale, attempt }
    ) => {
      dispatch({
        type: actions.START_LAB_RESPONSE,
        labId: data.labId,
        acceptTerms,
        blueprintArn,
        blueprintLocale,
        attempt,
      });
    },
    onError: (error, variables) => {
      publishFatal('StartLab', error, variables);
      dispatch({ type: actions.START_LAB_ERROR, labId: variables.labId });
      const errors = propOr([], 'errors', error);
      for (const error of errors) {
        switch (prop('errorType', error)) {
          case ERROR_TYPES.NotFound:
            appendNotification({
              contentId: ERROR_MESSAGES.startLab404,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            return;
          case ERROR_TYPES.Conflict: {
            refetchOngoingLabs();
            appendNotification({
              contentId: ERROR_MESSAGES.startLab409,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            return;
          }
          case ERROR_TYPES.Forbidden:
            appendNotification({
              contentId: ERROR_MESSAGES.startLabForbidden,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            return;
          case ERROR_TYPES.Unauthorized:
            handleSignIn();
            return;
        }
      }
      // If no match is found, show default error notification
      appendNotification({
        contentId: ERROR_MESSAGES.startLabDefault,
        type: 'warning',
        notificationType: notificationTypes.LAB,
      });
    },
  });

  const { mutate: stopLab, isLoading: isStoppingLab } = useStopLabMutation({
    metricsPublisher: metricsPublisher.current,
    onMutate: () => {
      dispatch({ type: actions.END_LAB_REQUEST });
    },
    onSuccess: (data, variables) => {
      dispatch({ type: actions.END_LAB_RESPONSE, labId: variables.labId });
    },
    onError: (error, variables) => {
      publishFatal('StopLab', error, variables);
      dispatch({ type: actions.END_LAB_ERROR });
      const errors = propOr([], 'errors', error);
      for (const error of errors) {
        switch (prop('errorType', error)) {
          case ERROR_TYPES.Conflict:
            appendNotification({
              contentId: ERROR_MESSAGES.stopLab409,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            return;
          case ERROR_TYPES.Unauthorized:
            handleSignIn();
            return;
        }
      }
      // If no match is found, show default error notification
      appendNotification({
        contentId: ERROR_MESSAGES.stopLabDefault,
        type: 'warning',
        notificationType: notificationTypes.LAB,
      });
    },
  });

  const getCurrentLabEvaluation = () => {
    if (
      labState.labs.length === 0 ||
      !labState.labs[0].evaluationCurrentId ||
      !labState.labs[0]?.evaluations.length
    )
      return undefined;
    return labState.labs[0].evaluations.find(
      item => item.id === labState.labs[0].evaluationCurrentId
    );
  };

  const { mutate: startLabEvaluation } = useStartLabEvaluation({
    onMutate: () => {
      dispatch({ type: actions.START_LAB_EVALUATION_REQUEST });
    },
    onSuccess: result => {
      dispatch({
        type: actions.START_LAB_EVALUATION_RESPONSE,
        result,
      });
    },
    onError: () => {
      dispatch({ type: actions.START_LAB_EVALUATION_ERROR });
    },
  });

  usePollGetLabEvaluation(
    labState.labs[0] ? labState.labs[0].labId : null,
    getCurrentLabEvaluation()?.result?.executionId,
    {
      onSuccess: result => {
        if (
          result?.status === LAB_FUNCTION_STATUS.ENDED ||
          result?.status === LAB_FUNCTION_STATUS.ERROR
        ) {
          dispatch({ type: actions.GET_LAB_EVALUATION_RESPONSE, result });
        }
      },
      onError: () => {
        dispatch({ type: actions.GET_LAB_EVALUATION_ERROR });
      },
    }
  );

  const { mutate: submitLabEvaluation } = useSubmitLabEvaluation({
    onMutate: () => {
      dispatch({ type: actions.SUBMIT_LAB_EVALUATION_REQUEST });
    },
    onSuccess: (data, variables) => {
      dispatch({ type: actions.SUBMIT_LAB_EVALUATION_RESPONSE });
      const progressTrackingOnSuccess = prop(
        'progressTrackingOnSuccess',
        variables
      );
      if (progressTrackingOnSuccess) {
        progressTrackingOnSuccess();
        dispatch({
          type: actions.RESET_LAB_EVALUATION,
        });
        appendNotification({
          contentId: PROGRESS_TRACKING_MESSAGES.progressTrackingSuccess,
          type: 'success',
          notificationType: notificationTypes.LAB,
        });
      }
    },
    onError: () => {
      dispatch({ type: actions.SUBMIT_LAB_EVALUATION_ERROR });
    },
  });

  /**
   * This is a temporary helper function used to fetch the first lab or default labState details if it isn't present to make the multilab code easier to maintain
   * until the multi lab feature is implemented in all components.
   * https://issues.amazon.com/issues/EventHorizon-3084
   * @returns {import('./labState').LabData}
   */
  const getFirstLabInLabState = () => {
    if (labState.labs.length === 0) {
      return defaultLabData;
    }
    return labState.labs[0];
  };

  return (
    <LabContext.Provider
      value={{
        labState,
        resetLabState,
        setHasQueriedOngoing,
        hasQueriedOngoing,
        startLab,
        isStartingLab,
        stopLab,
        isStoppingLab,
        startLabEvaluation,
        submitLabEvaluation,
        getCurrentLabEvaluation,
        getFirstLabInLabState,
        dispatch,
        getOngoingLabsError,
      }}
    >
      {children}
    </LabContext.Provider>
  );
};

LabContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default LabContextProvider;

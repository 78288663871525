import React, { useState, useRef, useEffect } from 'react';
import {
  AppLayout,
  Box,
  Button,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SEO from '../components/SEO';
import { HeaderWrapper, HeaderEH } from '../components/Header';
import { Footer } from '../components/Footer';
import AppNotifications from '../components/AppNotifications';
import { OngoingLabBanner } from '../components/OngoingLab';
import HeroBanner from '../components/Recommendations/HeroBanner';
import Channel from '../components/Recommendations/Channel';
import { HelpPanel } from '../components/HelpPanel';
import Main from '../components/Main';
import {
  useAuthContext,
  useLabContext,
  useEventContext,
  useHelpPanelContext,
} from '../contexts';
import metrics, { publishButtonClickMetric } from '../utils/metrics';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import { URL_PATHS } from '../constants/urlPaths';
import { IMPRESSIONS } from '../constants/metrics';
import messages from './RecommendationsPage.messages';
import appMessages from '../i18n/app.messages';
import { dataTestId } from '../constants/dataTestIdSelectors';
import { SkillBuilderBanner } from '../components/SkillBuilder';
import { CustomCallouts } from '../components/CustomCallouts';
import { SLOTS } from '../components/CustomCallouts/constants';

import './RecommendationsPage.css';

// Needed to capture a once per app lifetime event.
let hasChannelsLoaded = false;

const RecommendationsPage = ({ localePref }) => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { event } = useEventContext();
  const history = useHistory();
  const location = useLocation();
  const { getFirstLabInLabState } = useLabContext();
  const { showHelpPanel, handlePanelClose } = useHelpPanelContext();
  const labState = getFirstLabInLabState();

  const metricsPublisher = useRef(
    metrics.createPublisher('RecommendationsPage')
  );
  const pageLoadTimeMetric = useRef(
    metrics.createTimerStopWatch('PageLoadTime').withMonitor()
  );
  const [loadedChannelCounter, setLoadedChannelCounter] = useState(0);
  useEffect(() => metricsPublisher.current.publishCounter(IMPRESSIONS, 1), []);
  useEffect(() => {
    if (hasChannelsLoaded || loadedChannelCounter !== event.channels.length)
      return;
    metricsPublisher.current.publishMetric(pageLoadTimeMetric.current);
    hasChannelsLoaded = true;
  }, [loadedChannelCounter, event.channels]);

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH
          isStandalone={event.isStandaloneLabEvent()}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      </HeaderWrapper>
      <AppLayout
        ref={appLayoutRef}
        content={
          <Main>
            <h1 className="awsui-util-hide">
              {formatMessage(appMessages.title)}
            </h1>
            {/* Ensure only one hero type can be shown at any given time, with priority given to the lab spotlight hero. */}
            {event.spotlightArn ? (
              <HeroBanner
                arn={event.spotlightArn}
                locale={localePref}
                ongoingBlueprintArn={labState.blueprintArn}
                ongoingLabId={labState.labId}
              />
            ) : (
              <CustomCallouts slot={SLOTS.homeHero} />
            )}
            <div className="RecommendationsPage__content">
              <SpaceBetween direction="vertical" size="l">
                <SpaceBetween size="s">
                  <CustomCallouts slot={SLOTS.homeTop} />
                </SpaceBetween>
                <OngoingLabBanner />
                <Box>
                  <h2 className="awsui-util-hide">
                    {formatMessage(messages.recommendationsHeader)}
                  </h2>
                  {event.channels.map(arn => (
                    <Channel
                      key={arn}
                      localePref={localePref}
                      arn={arn}
                      ongoingBlueprintArn={labState.blueprintArn}
                      ongoingLabId={labState.labId}
                      onSettled={() => setLoadedChannelCounter(l => l + 1)}
                    />
                  ))}
                </Box>
                <SkillBuilderBanner />
                <SpaceBetween size="s">
                  <CustomCallouts slot={SLOTS.homeBottom} />
                </SpaceBetween>
                <Box padding={{ vertical: 'm' }} textAlign="center">
                  <Button
                    variant="primary"
                    onClick={() => {
                      publishButtonClickMetric('CatalogCTA', {
                        destinationUrl: URL_PATHS.catalogPage,
                        currentPath: location.pathname,
                        clickLocation: 'RecommendationsPage',
                      });
                      history.push(URL_PATHS.catalogPage);
                    }}
                    data-testid={dataTestId['catalog-cta']}
                  >
                    {formatMessage(messages.catalogBtn)}
                  </Button>
                </Box>
              </SpaceBetween>
            </div>
          </Main>
        }
        variant="navless"
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        notifications={<AppNotifications />}
        stickyNotifications={true}
        navigationHide={true}
        toolsHide={!showHelpPanel}
        toolsOpen={showHelpPanel}
        onToolsChange={handlePanelClose}
        disableContentPaddings={true}
        tools={<HelpPanel />}
        ariaLabels={APP_LAYOUT_LABELS}
      />
      <Footer
        isStandalone={event.isStandaloneLabEvent()}
        appLayoutRef={appLayoutRef}
      />
    </>
  );
};

RecommendationsPage.propTypes = {
  localePref: PropTypes.string,
};

export default RecommendationsPage;

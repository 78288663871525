import React from 'react';
import PropTypes from 'prop-types';
import { TextContent } from '@amzn/awsui-components-react';

import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { CALLOUT_TYPES } from './constants';
import EasyMarkdown from '../EasyMarkdown';

const CalloutMarkdown = ({ params }) => {
  useEmitCalloutImpression(CALLOUT_TYPES.markdown);
  const content = params?.content;

  return content ? (
    <TextContent data-testid={`callout-${CALLOUT_TYPES.markdown}`}>
      <EasyMarkdown content={content} />
    </TextContent>
  ) : null;
};

CalloutMarkdown.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }),
};

export default CalloutMarkdown;

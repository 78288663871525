import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'activeLabOnlyErrorPage.heading',
    defaultMessage: "There's no active lab",
  },
  message: {
    id: 'activeLabOnlyErrorPage.message',
    defaultMessage: 'Try closing this browser tab and re-launch the lab.',
  },
});

export default messages;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // Feedback.js
  submitMessage: {
    id: 'feedback.submitMessage',
    defaultMessage: 'Thank you, your feedback has been received.',
  },
  modalHeader: {
    id: 'feedback.modalHeader',
    defaultMessage: 'Rate your experience',
  },
  modalClose: {
    id: 'feedback.modalClose',
    defaultMessage: 'Close',
  },
  closeBtn: {
    id: 'feedback.closeBtn',
    defaultMessage: 'Cancel',
  },
  submitBtn: {
    id: 'feedback.submitBtn',
    defaultMessage: 'Submit',
  },
  feedbackBtn: {
    id: 'feedback.feedbackBtn',
    defaultMessage: 'Feedback',
  },

  // FeedbackForm.js
  formHeader: {
    id: 'feedback.formHeader',
    defaultMessage:
      'Let us know how we’re doing. Your feedback is important to us.',
  },
  metricOverallSatisfaction: {
    id: 'feedback.metricOverallSatisfaction',
    defaultMessage: 'Overall Experience',
  },
  metricLabInstructions: {
    id: 'feedback.metricLabInstructions',
    defaultMessage: 'Lab Instructions',
  },
  metricWebApplication: {
    id: 'feedback.metricWebApplication',
    defaultMessage: 'Web Application',
  },
  metricAWSConsole: {
    id: 'feedback.metricAWSConsole',
    defaultMessage: 'AWS Console',
  },

  // Segmented control options
  rating: {
    id: 'feedback.rating',
    defaultMessage: 'Rate {number} {star}',
  },
  starSingular: {
    id: 'feedback.starSingular',
    defaultMessage: 'star',
  },
  starPlural: {
    id: 'feedback.starPlural',
    defaultMessage: 'stars',
  },

  thumbsUp: {
    id: 'feedback.thumbsUp',
    defaultMessage: 'Thumbs up',
  },
  thumbsDown: {
    id: 'feedback.thumbsDown',
    defaultMessage: 'Thumbs down',
  },
  noInput: {
    id: 'feedback.noInput',
    defaultMessage: 'No opinion',
  },
  clear: {
    id: 'feedback.clear',
    defaultMessage: 'Clear',
  },

  // FeedbackSurveyBox.js
  surveyBoxHeading: {
    id: 'feedback.surveyBoxHeading',
    defaultMessage:
      'Nice work! Help us make Builder Labs better in just 2 minutes.',
  },
  surveyBoxCallToAction: {
    id: 'feedback.surveyBoxCallToAction',
    defaultMessage: 'Launch Survey',
  },
  surveyBoxNewTab: {
    id: 'feedback.surveyBoxNewTab',
    defaultMessage: 'Opens in a new browser tab.',
  },
});

export default messages;

import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
  appBoundary: {
    id: 'errors.appBoundary',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },
  sessionEnded: {
    id: 'errors.sessionEnded',
    defaultMessage:
      'Your session has ended. Please restart the lab, or select a different one.',
  },
  sessionCancelled: {
    id: 'errors.sessionCancelled',
    defaultMessage:
      'Lab session ended after reaching the maximum budget. Please restart the lab or select a different one.',
  },
  missingStatus: {
    id: 'errors.missingStatus',
    defaultMessage: 'No lab status was returned.',
  },
  invalidLocale: {
    id: 'errors.invalidLocale',
    defaultMessage: 'The requested locale does not exist.',
  },
  loadCatalogFail: {
    id: 'errors.loadCatalogFail',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },
  loadSpotlightFail: {
    id: 'errors.loadSpotlightFail',
    defaultMessage:
      'Uh oh, something went wrong.{br}Please reload the page and try again.',
  },
  loadChannelFail: {
    id: 'errors.loadChannelFail',
    defaultMessage:
      'Uh oh, something went wrong while loading the channel. Please reload the page and try again.',
  },
  loadMarkdownFail: {
    id: 'errors.loadMarkdownFail',
    defaultMessage:
      'Failed to get lab instructions. Please refresh the page to try again.',
  },

  // AUTH NOTIFICATIONS
  signInFailure: {
    id: 'errors.signInFailure',
    defaultMessage: 'Sign in was unsuccessful. Please try again.',
  },
  signOutFailure: {
    id: 'errors.signOutFailure',
    defaultMessage: 'Sign out was unsuccessful. Please try again.',
  },
  forbiddenError: {
    id: 'errors.forbiddenError',
    defaultMessage:
      'Uh oh, you do not have access to the event. Please reach out to your event administrator.',
  },
  unauthorizedError: {
    id: 'errors.unauthorizedError',
    defaultMessage:
      'Uh oh, it appears that you have been signed out. Please reload the page and try again.',
  },
  initializationFailure: {
    id: 'errors.initializationFailure',
    defaultMessage:
      "We're having trouble with your account initialization. This can result in access issues, please retry in a few moments.",
  },
  // START LAB NOTIFICATIONS
  startLab409: {
    id: 'errors.startLab409',
    defaultMessage:
      "There's already a lab running. Please finish it before starting another.",
  },
  startLab404: {
    id: 'errors.startLab404',
    defaultMessage:
      'The selected lab is currently unavailable. Please try a different lab.',
  },
  startLabForbidden: {
    id: 'errors.startLabForbidden',
    defaultMessage: 'You do not have access to this lab.',
  },
  startLabDefault: {
    id: 'errors.startLabDefault',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again, or select a different lab.',
  },

  // STOP LAB NOTIFICATIONS
  stopLab409: {
    id: 'errors.stopLab409',
    defaultMessage: 'Lab cannot be stopped in the current state.',
  },
  stopOngoingLab409: {
    id: 'errors.stopOngoingLab409',
    defaultMessage:
      'Lab cannot be stopped in the current state. Please wait a few minutes and try again.',
  },
  stopLabDefault: {
    id: 'errors.stopLabDefault',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },

  // GET LAB NOTIFICATIONS
  getLab404: {
    id: 'errors.getLab404',
    defaultMessage: 'The selected lab is currently unavailable.',
  },
  getLabDefault: {
    id: 'errors.getLabDefault',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },
});

export default errorMessages;

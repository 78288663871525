import React from 'react';
import { Cards } from '@amzn/awsui-components-react';

import './CardsOverride.scss';

const CardsOverride = ({ variant, ...props }) => {
  if (variant === 'carousel')
    return (
      <span className="CardsOverride--carousel">
        <Cards {...props} />
      </span>
    );
  return <Cards {...props} />;
};

export default CardsOverride;

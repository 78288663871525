import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@amzn/awsui-components-react';

const isCurrentPage = to => location.pathname === to;

const HeaderLink = ({ children, to, ...rest }) => (
  <Link
    className={`Header__link ${
      isCurrentPage(to) ? 'Header__link--active' : ''
    }`}
    aria-current={isCurrentPage(to) ? 'page' : null}
    to={to}
    {...rest}
  >
    <Box variant="span" fontWeight="bold" color="inherit">
      {children}
    </Box>
  </Link>
);

HeaderLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default HeaderLink;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import metrics from '../utils/metrics';
import { IMPRESSIONS } from '../constants/metrics';
import FEATURES from '../constants/features';
import { useAuthContext, useEventContext } from '../contexts';

const DeepLinkPage = ({ match, isInitialized, error }) => {
  const { lxpKey, refId } = match.params;
  const metricsPublisher = useRef(metrics.createPublisher('DeepLinkPage'));
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { event } = useEventContext();
  const metadata = event.getMetadata(FEATURES.deepLink);
  const redirectUrl = metadata[lxpKey]?.[refId]?.url;

  useEffect(() => {
    metricsPublisher.current.publishCounter(IMPRESSIONS, 1);
  }, []);

  useEffect(() => {
    if (redirectUrl && isInitialized) {
      window.location.replace(redirectUrl);
    }
  }, [redirectUrl, isInitialized]);

  if (!redirectUrl) return <NotFoundPage />;

  if (error) {
    return (
      <ErrorPage
        errors={prop('errors', error)}
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
      />
    );
  }

  return <LoadingPage />;
};

DeepLinkPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      lxpKey: PropTypes.string,
      refId: PropTypes.string,
    }),
  }),
};

export default DeepLinkPage;

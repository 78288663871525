/**
 * Auth Events.
 * @constant {Object}
 */
export const AUTH_EVENTS = {
  // Amplify Auth events:
  signOut: 'signOut',
  signIn: 'signIn',
  signIn_failure: 'signIn_failure',
  signOut_failure: 'signOut_failure',
  configured: 'configured',
  customOAuthState: 'customOAuthState',
  // EventHorizon Auth events:
  EH_NoCurrentUser: 'EH_NoCurrentUser',
};

/**
 * Amplify AppSync Authentication types
 * @see {@link https://github.com/aws-amplify/amplify-js/blob/%40aws-amplify/api%405.4.4/packages/api-graphql/src/internals/InternalGraphQLAPI.ts#L130}
 * @constant {Object}
 */
export const AMPLIFY_APPSYNC_AUTH = {
  API_KEY: 'API_KEY',
  AWS_IAM: 'AWS_IAM',
  OPENID_CONNECT: 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS: 'AMAZON_COGNITO_USER_POOLS',
  AWS_LAMBDA: 'AWS_LAMBDA',
};

/**
 * OAuth error message when user is not on the approved list. Currently due to a Cognito bug, we grouped
 * approved list related error with internal error. We have a SIM ticket for the Cognito team to fix this:
 * https://t.corp.amazon.com/V219424388.
 * TODO: Keeping this until we can verify that 500s are no longer triggered from Cognito.
 * @constant {string}
 */
export const FORBIDDEN_FAILED_MESSAGE = 'Internal Error';

/**
 * OAuth error status when user is not on the approved list. The message payload contains this status.
 * @constant {string}
 */
export const FORBIDDEN_FAILED_STATUS = '[Forbidden]';

/**
 * A Gandalf URL search param for when a customer's email needs to be verified.
 */
export const EMAIL_VERIFICATION_PARAM = 'require_email_verification';

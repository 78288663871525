import { defineMessages } from 'react-intl';

const messages = defineMessages({
  short: {
    id: 'duration.short',
    defaultMessage: '0-1 hour',
  },
  medium: {
    id: 'duration.medium',
    defaultMessage: '1-2 hours',
  },
  long: {
    id: 'duration.long',
    defaultMessage: '2+ hours',
  },
});

export default messages;

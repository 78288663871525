import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  Header,
  Container,
  AppLayout,
  Grid,
} from '@amzn/awsui-components-react';

import SEO from '../components/SEO';
import Main from '../components/Main';
import messages from './StandaloneSignedOutPage.messages';
import { HeaderEH, HeaderWrapper } from '../components/Header';
import { Footer } from '../components/Footer';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import { publishCounterMetric } from '../utils/metrics';

const StandaloneSignedOutPage = () => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  useEffect(() => {
    publishCounterMetric('StandaloneSignedOut');
  }, []);

  return (
    <>
      <SEO title={formatMessage(messages.pageTitle)} />
      <HeaderWrapper>
        <HeaderEH isStandalone isOutsideRouter />
      </HeaderWrapper>
      <AppLayout
        ref={appLayoutRef}
        content={
          <Main>
            <Grid
              gridDefinition={[
                {
                  colspan: { default: 12, s: 8, m: 6 },
                  offset: { default: 0, s: 2, m: 3 },
                },
              ]}
            >
              <Container
                header={
                  <Header variant="h1">
                    {formatMessage(messages.heading)}
                  </Header>
                }
              >
                {formatMessage(messages.message)}
              </Container>
            </Grid>
          </Main>
        }
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        navigationHide={true}
        toolsHide={true}
        ariaLabels={APP_LAYOUT_LABELS}
      />
      <Footer isStandalone isOutsideRouter appLayoutRef={appLayoutRef} />
    </>
  );
};

StandaloneSignedOutPage.propTypes = {};

export default StandaloneSignedOutPage;

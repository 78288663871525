import React from 'react';
import { Header } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';

import { TOUR_IDS } from '../../../constants/siteTour';
import './LabHeader.scss';

const LabHeader = ({ title, children, lang }) => (
  <Header
    data-tourid={TOUR_IDS.labHeader}
    variant="h2"
    actions={children}
    className="LabHeader"
  >
    <span lang={lang}>{title}</span>
  </Header>
);

LabHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  lang: PropTypes.string,
};

export default LabHeader;

import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Box } from '@amzn/awsui-components-react';

import { labStatuses, useLabContext } from '../../../contexts';
import { loadingStatuses } from '../../../contexts/labState';
import metrics from '../../../utils/metrics';
import { IMPRESSIONS } from '../../../constants/metrics';
import { dataTestId } from '../../../constants/dataTestIdSelectors';
import EvaluateLabAction from './EvaluateLabAction';
import EvaluationSplitPanel from './EvaluationSplitPanel';
import EvaluationContent from './EvaluationContent';
import EvaluationScore from './EvaluationScore';
import EvaluationNoScoreYet from './EvaluationNoScoreYet';
import messages from './LabEvaluation.messages';
import './LabEvaluation.scss';

/**
 * @param {Object} props
 * @param {boolean} props.isOngoingLab
 * @param {boolean} props.isStoppingLab
 * @param {import('../../../models').EHBlueprint} props.blueprint
 */
export const LabEvaluationContainer = ({
  isOngoingLab,
  isStoppingLab,
  blueprint,
}) => {
  const { formatMessage } = useIntl();
  const functionNames = blueprint.labContext?.labEvaluation?.functionNames;
  const metricsPublisherRef = useRef(metrics.createPublisher('LabEvaluation'));
  useEffect(() => metricsPublisherRef.current.publishCounter(IMPRESSIONS), []);
  const hasSubmissionDisabled = blueprint.hasSubmissionDisabled();
  const gradingTemplateArn = blueprint.getGradingTemplateArn();
  const {
    startLabEvaluation,
    submitLabEvaluation,
    getCurrentLabEvaluation,
    getFirstLabInLabState,
  } = useLabContext();

  // Note, only one lab function is used/supported for version 1.0.0
  const [functionName] = functionNames;
  const labState = getFirstLabInLabState();
  const labId = labState.labId;
  const currentEvaluation = getCurrentLabEvaluation();
  const executionId = currentEvaluation?.result?.executionId;

  const handleStartLabEvaluation = useCallback(() => {
    startLabEvaluation({
      labId,
      functionName,
      gradingTemplateArn,
      clientRequestToken: uuid(),
    });
  }, [labId, functionName, gradingTemplateArn, startLabEvaluation]);

  const handleSubmitLabEvaluation = useCallback(() => {
    submitLabEvaluation({ labId, executionId });
  }, [submitLabEvaluation, labId, executionId]);

  const labHasNotStarted =
    !isOngoingLab || labState.status !== labStatuses.ONGOING;

  const evaluateLabMessage = hasSubmissionDisabled
    ? formatMessage(messages.evaluateLabInfoNoSubmission)
    : formatMessage(messages.evaluateLabInfo);

  // Return early if the lab has not started
  if (labHasNotStarted) {
    return (
      <Box margin={{ bottom: 'l' }}>
        <div className="LabEvaluation__section">
          <Box variant="p" margin={{ bottom: 's' }}>
            {evaluateLabMessage}
          </Box>
          <EvaluateLabAction labHasNotStarted={labHasNotStarted} />
        </div>
        <div className="LabEvaluation__section">
          <EvaluationNoScoreYet />
        </div>
      </Box>
    );
  }

  const hasPreviouslySuccessfulResult = labState.evaluations
    ? Boolean(
        labState.evaluations.find(
          item => item?.evaluationStatus === loadingStatuses.DONE
        )
      )
    : false;
  const isPollingEvaluation =
    currentEvaluation?.evaluationStatus === loadingStatuses.LOADING;
  const isSubmitting =
    currentEvaluation?.submissionStatus === loadingStatuses.LOADING;

  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="LabEvaluation__section">
        <Box variant="p" margin={{ bottom: 's' }}>
          {evaluateLabMessage}
        </Box>
        <EvaluateLabAction
          handleStartLabEvaluation={handleStartLabEvaluation}
          isPollingEvaluation={isPollingEvaluation}
          currentEvaluation={currentEvaluation}
          isSubmitting={isSubmitting}
          isStoppingLab={isStoppingLab}
          labId={labId}
        />
      </div>
      <div className="LabEvaluation__section" role="status">
        {!hasPreviouslySuccessfulResult ? (
          <EvaluationNoScoreYet />
        ) : (
          <EvaluationScore
            labId={labId}
            evaluationResult={currentEvaluation?.result}
            isPollingEvaluation={isPollingEvaluation}
            onSubmit={handleSubmitLabEvaluation}
            isSubmitting={isSubmitting}
            isSubmitError={
              currentEvaluation?.submissionStatus === loadingStatuses.ERROR
            }
            isSubmitSuccessfull={
              currentEvaluation?.submissionStatus === loadingStatuses.DONE
            }
            hasSubmissionDisabled={hasSubmissionDisabled}
          />
        )}
      </div>
      {hasPreviouslySuccessfulResult && (
        <div className="LabEvaluation__section">
          <Box
            variant="h3"
            data-testid={dataTestId['labEval-evaluateResultsDetailsHeader']}
          >
            {formatMessage(messages.evaluateResultsDetailsHeader)}
          </Box>
          <EvaluationContent
            locales={currentEvaluation?.result?.locales}
            isLoading={isPollingEvaluation}
          />
        </div>
      )}
    </Box>
  );
};

const WrappedComponent = props => {
  return (
    <EvaluationSplitPanel>
      <LabEvaluationContainer {...props} />
    </EvaluationSplitPanel>
  );
};

export default WrappedComponent;

import { useMutation } from 'react-query';

import { startLab } from '../utils/labsService';
import {
  interceptRequestMetrics,
  publishCounterMetric,
} from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';

const metricsNamespace = 'StartLab';

/**
 * @typedef {import('../utils/labsService').LabRef} LabRef
 */

/**
 * @typedef {Object} Variables
 * @property {string} blueprintArn
 * @property {string} blueprintLocale
 * @property {string} clientRequestToken
 * @property {boolean} acceptTerms
 */

/**
 * @param {Object} props
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {(variables: Variables) => void} [props.onMutate] - (optional) Callback on startLab call
 * @param {(data: LabRef, variables: Variables) => void} [props.onSuccess] - (optional) Callback on startLab success
 * @param {(error: any, variables: Variables) => void} [props.onError] - (optional) Callback on startLab error
 * @returns {import('react-query').UseMutationResult<LabRef, any, Variables, void>}
 */
const useStartLabMutation = ({
  metricsPublisher,
  onMutate,
  onSuccess,
  onError,
} = {}) => {
  return useMutation({
    mutationFn: ({ attempt, blueprintArn, clientRequestToken, acceptTerms }) =>
      interceptRequestMetrics(
        metricsNamespace,
        startLab(attempt + 1, blueprintArn, clientRequestToken, acceptTerms),
        metricsPublisher
      ),
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [
            ERROR_TYPES.BadRequest,
            ERROR_TYPES.NotFound,
            ERROR_TYPES.Conflict,
          ],
        })
      ) {
        return false;
      }
      return failureCount < 2;
    },
    retryDelay: failureCount =>
      calculateRetryDelay(failureCount, { interval: 200 }),
    onMutate: variables => {
      if (onMutate) onMutate(variables);
    },
    onSuccess: (data, variables) => {
      publishCounterMetric(metricsNamespace, {
        additionalMetrics: {
          blueprintArn: variables.blueprintArn,
          labLocale: variables.blueprintLocale,
        },
      });
      if (onSuccess) onSuccess(data, variables);
    },
    onError: (error, variables) => {
      if (onError) onError(error, variables);
    },
  });
};

export default useStartLabMutation;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import metrics from '../utils/metrics';
import { IMPRESSIONS } from '../constants/metrics';

import { useEventContext } from '../contexts';

const RedirectPage = ({ match }) => {
  const { event } = useEventContext();
  const redirectPagePublisher = useRef(metrics.createPublisher('RedirectPage'));
  const { redirectId } = match.params;

  redirectPagePublisher.current.publishCounter(IMPRESSIONS, 1);

  const redirectLocation = event.getRedirectLocation(redirectId);
  if (!redirectLocation) {
    redirectPagePublisher.current.publishCounter('UnknownRedirectId', 1);
    return <Redirect to="/" />;
  }

  redirectPagePublisher.current.publishCounter(`RedirectHit:${redirectId}`, 1);

  return <Redirect to={redirectLocation} />;
};

RedirectPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      redirectId: PropTypes.string,
    }),
  }),
};

export default RedirectPage;

import { path } from 'ramda';

import { graphql } from './graphQLService';

/**
 * @typedef {Object} LabAssessment
 * @property {import('../constants/labAssessment').LabAssessmentStatus} status
 * @property {string} url
 */

/**
 * Returns the lab assessment query key.
 *
 * @param {string} labId
 * @param {string} assessmentId
 * @returns {string[]}
 */
export const labAssessmentQueryKey = (labId, assessmentId) => {
  return ['lab', labId, 'assessment', assessmentId];
};

/**
 * Returns the requested lab assessment.
 *
 * @param {string} labId
 * @param {string} assessmentId
 * @returns {Promise<LabAssessment>}
 */
export const getLabAssessment = (labId, assessmentId) => {
  const fieldName = 'getLabAssessment';
  const query = `
    query GetLabAssessment($labId: ID!, $assessmentId: ID!) {
      ${fieldName}(labId: $labId, assessmentId: $assessmentId) {
        status
        url
      }
    }
  `;
  return graphql(query, { labId, assessmentId }).then(
    path(['data', fieldName])
  );
};

/**
 * Starts a lab assessment.
 *
 * @param {string} labId
 * @param {string} assessmentId
 * @returns {Promise<LabAssessment>}
 */
export const startLabAssessment = (labId, assessmentId) => {
  const fieldName = 'startLabAssessment';
  const query = `
    mutation StartLabAssessment($labId: ID!, $assessmentId: ID!) {
      ${fieldName}(labId: $labId, assessmentId: $assessmentId) {
        status
        url
      }
    }
  `;
  return graphql(query, { labId, assessmentId }).then(
    path(['data', fieldName])
  );
};

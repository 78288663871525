import { useQuery } from 'react-query';

import { getOngoingLabs } from '../utils/labsService';
import { interceptRequestMetrics } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';

// How long to wait before considering refetching in the background.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const GET_ONGOING_LABS_POLL_INTERVAL = 3 * 60 * 1000;

const metricsNamespace = 'GetOngoingLabs';

/**
 * @typedef {import('../utils/labsService').OngoingLab} OngoingLab
 */

/**
 * @param {Object} props
 * @param {boolean} [props.isAuthenticated] - Boolean for authenticated status
 * @param {string} [props.labId] - Ongoing lab id
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {(data: OngoingLab[]) => void} [props.onSuccess] - (optional) Callback on getOngoingLabs success
 * @param {(error: any) => void} [props.onError] - (optional) Callback on getOngoingLabs error
 * @returns {import('react-query').UseQueryResult<OngoingLab[]>}
 */
const useGetOngoingLabs = ({
  isAuthenticated,
  metricsPublisher,
  onSuccess,
  onError,
} = {}) => {
  const enableQuery = isAuthenticated;

  return useQuery({
    queryKey: ['useGetOngoingLabs'],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getOngoingLabs(),
        metricsPublisher
      ),
    enabled: enableQuery,
    staleTime: FIVE_MIN_IN_MS,
    refetchInterval: GET_ONGOING_LABS_POLL_INTERVAL,
    retry: (failureCount, error) => {
      if (isNonRetryableError(error)) return false;
      return failureCount < 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 200 }),
    onSuccess: data => {
      if (onSuccess) onSuccess(data);
    },
    onError: error => {
      if (onError) onError(error);
    },
  });
};

export default useGetOngoingLabs;

/**
 * @constant {Object}
 */
export const EVENT_TYPES = {
  Default: 'event',
  Standalone: 'standalone',
  Offline: 'offline',
};

export default EVENT_TYPES;

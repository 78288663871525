import React, { useEffect, useState } from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { useIntl } from 'react-intl';

import { AppLayoutOverride } from '../components/PolarisOverrides';
import SEO from '../components/SEO';
import Catalog from '../components/Catalog/Catalog';
import FEATURES from '../constants/features';
import CatalogFilterPanel from '../components/Catalog/CatalogFilterPanel';
import { HeaderWrapper } from '../components/Header';
import AppNotifications from '../components/AppNotifications';
import { HelpPanel } from '../components/HelpPanel';
import Main from '../components/Main';
import {
  useAuthContext,
  useEventContext,
  useHelpPanelContext,
} from '../contexts';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import metrics, { publishButtonClickMetric } from '../utils/metrics';
import { catalogMetricsNames } from '../constants/catalog';
import messages from '../components/Catalog/Catalog.messages';
import HeaderEH from '../components/Header/HeaderEH';
import Footer from '../components/Footer/Footer';
import { useRef } from 'react';
import { IMPRESSIONS } from '../constants/metrics';
import useCollection from '../hooks/useCollection';
import { OngoingLabBanner } from '../components/OngoingLab';
import { SkillBuilderBanner } from '../components/SkillBuilder';
import { CustomCallouts } from '../components/CustomCallouts';
import { SLOTS } from '../components/CustomCallouts/constants';

// Needed to capture a once per app lifetime event.
let hasCatalogLoadedOnce = false;

const CatalogPage = props => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { event } = useEventContext();
  const [refinerState, setRefinerState] = useState({
    // Contains fields relevant to both filtering and searching
    matchesFilterQuery: () => true,
    searchText: '',
    searchResult: null,
  });
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const { showHelpPanel, handlePanelClose } = useHelpPanelContext();
  const metricsPublisher = useRef(metrics.createPublisher('CatalogPage'));
  const pageLoadTimeMetric = useRef(
    metrics.createTimerStopWatch('PageLoadTime').withMonitor()
  );
  const {
    data: catalog,
    isLoading,
    isError,
  } = useCollection({
    arn: event.collectionArn,
    metricsPublisher: metricsPublisher.current,
  });
  useEffect(() => {
    metricsPublisher.current.publishCounter(IMPRESSIONS, 1);
  }, []);
  useEffect(() => {
    if (isLoading || hasCatalogLoadedOnce) return;
    metricsPublisher.current.publishMetric(pageLoadTimeMetric.current);
    hasCatalogLoadedOnce = true;
  }, [metricsPublisher, isLoading]);

  const filterPanelToggle = event => {
    const panelOpen = path(['detail', 'open'], event);
    publishButtonClickMetric(
      panelOpen
        ? catalogMetricsNames.filterPanelOpen
        : catalogMetricsNames.filterPanelClose
    );
  };

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          isStandalone={event.isStandaloneLabEvent()}
        />
      </HeaderWrapper>
      <AppLayoutOverride
        forwardRef={appLayoutRef}
        variant="filterNavigation"
        maxContentWidth={1600}
        content={
          <Main>
            <SpaceBetween direction="vertical" size="l">
              <OngoingLabBanner />
              <SpaceBetween size="s">
                <CustomCallouts slot={SLOTS.catalogTop} />
              </SpaceBetween>
              <Catalog
                {...props}
                isLoading={isLoading}
                isError={isError}
                catalogList={catalog.blueprints}
                isAuthenticated={isAuthenticated}
                refinerState={refinerState}
                setRefinerState={setRefinerState}
              />
              <SkillBuilderBanner />
              <SpaceBetween size="s">
                <CustomCallouts slot={SLOTS.catalogBottom} />
              </SpaceBetween>
            </SpaceBetween>
          </Main>
        }
        navigation={
          event.hasFeature(FEATURES.catalogFiltering) && (
            <CatalogFilterPanel
              catalogList={catalog.blueprints}
              setRefinerState={setRefinerState}
            />
          )
        }
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        notifications={<AppNotifications />}
        stickyNotifications={true}
        navigationHide={!event.hasFeature(FEATURES.catalogFiltering)}
        toolsHide={!showHelpPanel}
        toolsOpen={showHelpPanel}
        onToolsChange={handlePanelClose}
        tools={<HelpPanel />}
        ariaLabels={APP_LAYOUT_LABELS}
        onNavigationChange={filterPanelToggle}
      />
      <Footer
        isStandalone={event.isStandaloneLabEvent()}
        appLayoutRef={appLayoutRef}
      />
    </>
  );
};

CatalogPage.propTypes = {
  localePref: PropTypes.string,
};

export default CatalogPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { handleSignIn } from '../../utils/authUtils';
import { ButtonOverride } from '../PolarisOverrides';
import HeaderLayout from './HeaderLayout';
import LocaleMenu from './LocaleMenu';
import ThemeMenu from './ThemeMenu';
import UserMenu from './UserMenu';
import EventLogo from './EventLogo';
import messages from './Header.messages';

/**
 * Used for standalone Event Horizon pages, such as the standalone lab page
 * which stands on it's own and does not link to other parts of EH.
 */
const HeaderStandalone = ({
  isAuthenticated,
  currentUser,
  testid,
  disableSignIn,
}) => {
  const { formatMessage } = useIntl();

  const branding = <EventLogo />;

  const SignInButton = ({ disableSignIn }) => {
    return disableSignIn ? (
      <ThemeMenu />
    ) : (
      <ButtonOverride variant="transparent" onClick={handleSignIn}>
        {formatMessage(messages.signIn)}
      </ButtonOverride>
    );
  };

  const actions = (
    <>
      <LocaleMenu />
      {isAuthenticated ? (
        <UserMenu isStandalone>
          {currentUser || formatMessage(messages.defaultGreeting)}
        </UserMenu>
      ) : (
        <SignInButton disableSignIn={disableSignIn} />
      )}
    </>
  );

  return <HeaderLayout branding={branding} actions={actions} testid={testid} />;
};

HeaderStandalone.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.string,
  testid: PropTypes.string.isRequired,
  disableSignIn: PropTypes.bool,
};

export default HeaderStandalone;

import metrics from './metrics';
import { IMPRESSIONS } from '../constants/metrics';
import {
  CSAT_BINARY,
  CSAT_STAR,
  METRICS_NAMES,
  METRIC_METHOD,
  METRIC_VALUES,
} from '../constants/feedback';

export const feedbackMetricsList = [
  // messageId must be the same as id in Feedback.messages.js
  {
    name: METRICS_NAMES.OverallSatisfaction,
    messageId: 'metricOverallSatisfaction',
    csat: CSAT_STAR,
  },
  {
    name: METRICS_NAMES.LabInstructions,
    messageId: 'metricLabInstructions',
    csat: CSAT_BINARY,
  },
  {
    name: METRICS_NAMES.WebApplication,
    messageId: 'metricWebApplication',
    csat: CSAT_BINARY,
  },
  {
    name: METRICS_NAMES.AWSConsole,
    messageId: 'metricAWSConsole',
    csat: CSAT_BINARY,
  },
];

// The current use of `SegmentedControl` requires pre-selected values, otherwise
// the control is not accessible via the keyboard.
export const initialFeedbackState = {
  [METRICS_NAMES.OverallSatisfaction]: METRIC_VALUES.NA,
  [METRICS_NAMES.LabInstructions]: METRIC_VALUES.NA,
  [METRICS_NAMES.WebApplication]: METRIC_VALUES.NA,
  [METRICS_NAMES.AWSConsole]: METRIC_VALUES.NA,
};

/**
 * Composes the metric name used in PMET.
 * @example
 * // returns 'LabInstructions-1.0:1'
 * getMetricName('LabInstructions-1.0', '1')
 *
 * @param {String} name
 * @param {String} value
 * @returns {String}
 */
export const getMetricName = (name, value) => `${name}:${value}`;

/**
 * @returns {KatalMetricsPublisher}
 */
export const getFeedbackPublisher = () =>
  metrics.createPublisher(METRIC_METHOD);

/**
 * Publishes individual metrics for the given state.
 *
 * @param {KatalMetricsPublisher} metricsPublisher
 * @param {Object} feedbackState
 */
export const publishFeedbackState = (metricsPublisher, feedbackState) => {
  Object.keys(feedbackState).forEach(feedback => {
    metricsPublisher.publishCounter(
      getMetricName(feedback, feedbackState[feedback]),
      1
    );
  });
};

/**
 * Publish zero metrics to avoid them getting dropped during long periods of non-use.
 * https://w.amazon.com/index.php/Monitoring/Metrics/IsPMETRightForMe#Metric_Retention
 *
 * @param {KatalMetricsPublisher} [metricsPublisher] optional
 */
export const publishZeroMetrics = (metricsPublisher = null) => {
  const publisher = metricsPublisher || getFeedbackPublisher();
  const ZERO_METRIC = 0;

  feedbackMetricsList.forEach(feedbackMetric => {
    feedbackMetric.csat.forEach(csat => {
      publisher.publishCounter(
        getMetricName(feedbackMetric.name, csat.value),
        ZERO_METRIC
      );
    });
  });

  publisher.publishCounter(IMPRESSIONS, ZERO_METRIC);
};

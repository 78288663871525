import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'standaloneSignedOutPage.heading',
    defaultMessage: "You're signed out of the lab session",
  },
  pageTitle: {
    id: 'standaloneSignedOutPage.pageTitle',
    defaultMessage: 'Signed out',
  },
  message: {
    id: 'standaloneSignedOutPage.message',
    defaultMessage:
      'Feel free to close this tab. Please note that you may still be signed in to the course where you opened the lab.',
  },
});

export default messages;

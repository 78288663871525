import React, { useEffect, useRef } from 'react';
import { AppLayout, Grid, Header, Box } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Mode } from '@amzn/awsui-global-styles';

import SEO from '../components/SEO';
import { HeaderWrapper, HeaderEH } from '../components/Header';
import { Footer } from '../components/Footer';
import AppNotifications from '../components/AppNotifications';
import Main from '../components/Main';
import { useNotificationContext, useThemeContext } from '../contexts';
import { publishCounterMetric } from '../utils/metrics';
import messages from './OfflinePage.messages';
import darkImage from '../images/offline/tnc-icon-1color-light_tools.svg';
import lightImage from '../images/offline/tnc-icon-1color-dark_tools.svg';

const OfflinePage = ({ notifications }) => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const { appendNotification } = useNotificationContext();
  const [currentTheme] = useThemeContext();

  useEffect(() => {
    publishCounterMetric('Offline', {
      additionalMetrics: {
        PathName: window.location.pathname,
      },
    });
  }, []);

  useEffect(() => {
    if (notifications) {
      for (const notification of notifications) {
        appendNotification(notification);
      }
    }
  }, [notifications, appendNotification]);

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH isStandalone isOutsideRouter />
      </HeaderWrapper>
      <AppLayout
        ref={appLayoutRef}
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        navigationHide={true}
        toolsHide={true}
        stickyNotifications={true}
        notifications={<AppNotifications />}
        content={
          <Main>
            <Grid
              gridDefinition={[
                { colspan: { default: 10, xs: 7, m: 5 }, offset: 1 },
              ]}
            >
              <Box>
                <img
                  src={currentTheme === Mode.Dark ? darkImage : lightImage}
                  alt=""
                />
                <Header variant="h1">{formatMessage(messages.heading)}</Header>
                {formatMessage(messages.message)}
              </Box>
            </Grid>
          </Main>
        }
      />
      <Footer isStandalone isOutsideRouter appLayoutRef={appLayoutRef} />
    </>
  );
};

OfflinePage.propTypes = {
  notifications: PropTypes.array,
};

export default OfflinePage;

import React from 'react';
import PropTypes from 'prop-types';

import './LoadingSkeleton.scss';

/**
 * @param {String} width as a percentage of the container
 * @param {String} height in px
 * @returns {String} CSS style for placeholder
 */
const getPlaceholderStyle = (width, height, opacity) => ({
  borderRadius: '5px',
  width: `${width}%`,
  height: `${height}px`,
  opacity: opacity ? opacity : 1,
});

const getClassName = (type, className) => {
  let classArray = [className];
  if (type === 'darkGrey') classArray.push('LoadingSkeleton--darkGrey');
  else classArray.push('LoadingSkeleton--default');
  return classArray.join(' ');
};

const LoadingSkeleton = ({
  width,
  height,
  opacity,
  className,
  type,
  testId,
}) => (
  <div
    data-testid={testId}
    className={getClassName(type, className)}
    style={getPlaceholderStyle(width, height, opacity)}
  ></div>
);

LoadingSkeleton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  opacity: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,
};

export default LoadingSkeleton;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  seoTitle: {
    id: 'landingPage.seoTitle',
    defaultMessage: 'Welcome',
  },
});

export default messages;

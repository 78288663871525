import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'sharedLxpHomePage.title',
    defaultMessage: 'AWS Interactive Labs',
  },
  introHeader: {
    id: 'sharedLxpHomePage.introHeader',
    defaultMessage: 'Choose a learning experience to browse free content',
  },
});

export default messages;

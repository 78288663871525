import { isDevelopment } from '../utils/envUtil';
import logger from '../utils/logger';

/**
 * In DEV, potentially mock network requests using MSW. See README for more info.
 * When enabled it will dynamically import the browser mocking code.
 */
export const interceptNetworkRequestsInDevMaybe = async () => {
  if (isDevelopment && process.env.VITE_MSW_MOCKS === 'true') {
    const { worker } = await import('./browser');
    worker.start();
    logger.warn(
      '%cEventHorizon MSW network mocks are enabled',
      'color:#0073bb; font-size:30px'
    );
    logger.warn(
      '%cIf this is not intended, please update `VITE_MSW_MOCKS` in your `.env.development.local`',
      'color:#0073bb; font-size:20px'
    );
  }
};

import { defineMessages } from 'react-intl';

export const troubleshooting = defineMessages({
  troubleshooting2Header: {
    id: 'helpPanelContent.troubleshooting2Header',
    defaultMessage: 'My lab is asking for credentials. Where are they?',
  },
  troubleshooting2Content: {
    id: 'helpPanelContent.troubleshooting2Content',
    defaultMessage:
      'When your lab is ready, you\'ll find the credentials you need to complete it in the left-hand panel. If you don\'t see the panel, select the button with the three horizontal bars (aka "hamburger menu") to expand it.',
  },
  troubleshooting3Header: {
    id: 'helpPanelContent.troubleshooting3Header',
    defaultMessage: "I'm stuck on a step in my lab. Where can I find help?",
  },
  troubleshooting3Content: {
    id: 'helpPanelContent.troubleshooting3Content',
    defaultMessage:
      'First, read through the questions on this panel. Next, search within the resources at the bottom of this panel, which include AWS Documentation, AWS Blogs, and AWS Forums.',
  },
  troubleshooting4Header: {
    id: 'helpPanelContent.troubleshooting4Header',
    defaultMessage: 'Why is my lab taking so long to start?',
  },
  troubleshooting4Content: {
    id: 'helpPanelContent.troubleshooting4Content',
    defaultMessage:
      'When you start a lab, we equip your sandbox AWS account with the resources and permissions needed for you to do the lab. This usually takes about 5 minutes, but can take longer, depending on the lab.',
  },
  troubleshooting11Header: {
    id: 'helpPanelContent.troubleshooting11Header',
    defaultMessage: 'Can I restart my lab?',
  },
  troubleshooting11Content: {
    id: 'helpPanelContent.troubleshooting11Content',
    defaultMessage:
      "Yes, you can restart an in-progress lab at any time. Select End Lab (your progress in the lab won't be saved), then select Start Lab and relaunch a new instance of the sandbox AWS Management Console.",
  },
  troubleshooting12Header: {
    id: 'helpPanelContent.troubleshooting12Header',
    defaultMessage:
      "I'm getting notifications in the AWS Management Console that aren't mentioned in the lab's instructions.",
  },
  troubleshooting12Content: {
    id: 'helpPanelContent.troubleshooting12Content',
    defaultMessage:
      "Each AWS service manages its own notifications in the AWS Management Console. We recommend reading the notifications and adjusting to them accordingly. (Note: The sandbox AWS Management Console account has permissions for only the resources needed for that specific lab. You may see extra notifications if you attempt to use a service's resource that wasn't launched as part of the lab.)",
  },
});

export const faq = defineMessages({
  faq1Header: {
    id: 'helpPanelContent.faq1Header',
    defaultMessage: 'What are Self-Paced Labs?',
  },
  faq1Content: {
    id: 'helpPanelContent.faq1Content',
    defaultMessage:
      'Self-Paced Labs are exercises that let you build real AWS architectures and resources by following step-by-step instructions in the safety of a sandbox AWS account.',
  },

  faq3Header: {
    id: 'helpPanelContent.faq3Header',
    defaultMessage: 'What is a lab limit and how does it affect me?',
  },
  faq3Content: {
    id: 'helpPanelContent.faq3Content',
    defaultMessage:
      'We set a limit for the number of labs each user can take during preset periods of time. This makes it possible for everyone to take labs.',
  },

  faq6Header: {
    id: 'helpPanelContent.faq6Header',
    defaultMessage: 'Can I retake a lab I completed?',
  },
  faq6Content: {
    id: 'helpPanelContent.faq6Content',
    defaultMessage:
      'Yes! You can retake completed labs as many times as you want. The goal of labs is to give you opportunities to practice the skills you’re learning!',
  },

  faq8Header: {
    id: 'helpPanelContent.faq8Header',
    defaultMessage: 'How much time will I have to complete a lab?',
  },
  faq8Content: {
    id: 'helpPanelContent.faq8Content',
    defaultMessage:
      'Lab duration varies, but in general a lab is available for at least 8 hours per lab. You can leave your lab running but if your lab expires before you complete it, don’t worry, you can return to the lab and take it again. Remember, the goal of labs is to give you opportunities to practice the skills you’re learning!',
  },

  faq9Header: {
    id: 'helpPanelContent.faq9Header',
    defaultMessage:
      'Can I have more than one AWS Management Console open in the same browser while doing a lab?',
  },
  faq9Content: {
    id: 'helpPanelContent.faq9Content',
    defaultMessage:
      "We don't recommend having more than one AWS Management Console open in the same browser. The AWS Management Console allows only one sign-in at a time per browser.<br/><br/>If you have more than one AWS Management Console open and can't find the correct one, go back to the lab and select Open Console; this will reopen the sandbox AWS account.",
  },

  faq11Header: {
    id: 'helpPanelContent.faq11Header',
    defaultMessage: 'What are the system requirements?',
  },
  faq11Content: {
    id: 'helpPanelContent.faq11Content',
    defaultMessage:
      "You'll need a reliable internet connection and the latest version of Chrome or Firefox browser on a recent MacOS or Windows operating system.",
  },
});

// Classrooms event specific troubleshooting messaging
export const troubleshootingClassrooms = defineMessages({
  troubleshootingClassrooms1Header: {
    id: 'helpPanelContent.troubleshootingClassrooms1Header',
    defaultMessage: 'My lab is asking for credentials. Where are they?',
  },
  troubleshootingClassrooms1Content: {
    id: 'helpPanelContent.troubleshootingClassrooms1Content',
    defaultMessage:
      'When your lab is ready and the AWS services have finished provisioning, you’ll find the credentials you need to complete it in the left-hand panel. If you don’t see the panel, select the button with the three horizontal bars (aka “hamburger menu”) to expand it.',
  },
  troubleshootingClassrooms2Header: {
    id: 'helpPanelContent.troubleshootingClassrooms2Header',
    defaultMessage: 'Why is my lab taking so long to start?',
  },
  troubleshootingClassrooms2Content: {
    id: 'helpPanelContent.troubleshootingClassrooms2Content',
    defaultMessage:
      'When you start a lab, we equip your sandbox AWS account with the resources and permissions needed for you to do the lab. This usually takes about 5 minutes, but may take longer, depending on the lab.',
  },
  troubleshootingClassrooms3Header: {
    id: 'helpPanelContent.troubleshootingClassrooms3Header',
    defaultMessage:
      'I see a banner that says my lab limit has been reached. What does this mean?',
  },
  troubleshootingClassrooms3Content: {
    id: 'helpPanelContent.troubleshootingClassrooms3Content',
    defaultMessage:
      'This means you have hit the concurrent active lab limit, and will need to end one of your previous labs before you can start. Your instructor also has the ability to do this on your behalf.',
  },
  troubleshootingClassrooms4Header: {
    id: 'helpPanelContent.troubleshootingClassrooms4Header',
    defaultMessage:
      'I can’t sign in to the correct AWS Management Console for my lab.',
  },
  troubleshootingClassrooms4Content: {
    id: 'helpPanelContent.troubleshootingClassrooms4Content',
    defaultMessage:
      'AWS Management Console allows only one console sign-in at a time. Sign out of all of your open AWS Management Consoles, wait a few seconds, then select the Open Console button in your lab to reopen the sandbox AWS account.',
  },
  troubleshootingClassrooms5Header: {
    id: 'helpPanelContent.troubleshootingClassrooms5Header',
    defaultMessage: 'Can I restart my lab?',
  },
  troubleshootingClassrooms5Content: {
    id: 'helpPanelContent.troubleshootingClassrooms5Content',
    defaultMessage:
      'Yes, you can restart an in-progress lab at any time. Select End Lab (your progress in the lab won’t be saved), then select Start Lab.',
  },
  troubleshootingClassrooms6Header: {
    id: 'helpPanelContent.troubleshootingClassrooms6Header',
    defaultMessage: 'There’s an error in the lab’s instructions.',
  },
  troubleshootingClassrooms6Content: {
    id: 'helpPanelContent.troubleshootingClassrooms6Content',
    defaultMessage:
      'We continuously release updates and new features to our services, so the occasional error may appear. If your lab’s instructions don’t match what you’re seeing, and you’re unable to complete the lab, please notify your instructor so we can investigate and update the lab as needed. Be sure to include the lab’s title and the instruction step number(s).',
  },
});

// Classrooms event specific faq contents
export const faqClassrooms = defineMessages({
  faqClassrooms1Header: {
    id: 'helpPanelContent.faqClassrooms1Header',
    defaultMessage: 'Can I do more than one lab at a time?',
  },
  faqClassrooms1Content: {
    id: 'helpPanelContent.faqClassrooms1Content',
    defaultMessage:
      'No, you can have only one lab in progress at a time. If you want to start another lab, you have to end the current lab. (Note: You’ll lose your progress when you end the current lab.)',
  },
  faqClassrooms2Header: {
    id: 'helpPanelContent.faqClassrooms2Header',
    defaultMessage: 'Can I retake a lab I completed?',
  },
  faqClassrooms2Content: {
    id: 'helpPanelContent.faqClassrooms2Content',
    defaultMessage:
      'Yes! You can retake completed labs as many times as you want. The goal of labs is to give you opportunities to practice the skills you’re learning!',
  },

  faqClassrooms3Header: {
    id: 'helpPanelContent.faqClassrooms3Header',
    defaultMessage: 'How long will the labs remain available to use?',
  },
  faqClassrooms3Content: {
    id: 'helpPanelContent.faqClassrooms3Content',
    defaultMessage:
      'Labs available via instructor-led training courses are generally available for two weeks following the class. This is to allow you additional time after class to return and practice the labs again, if needed.',
  },
  faqClassrooms4Header: {
    id: 'helpPanelContent.faqClassrooms4Header',
    defaultMessage: 'How do I access the labs after class?',
  },
  faqClassrooms4Content: {
    id: 'helpPanelContent.faqClassrooms4Content',
    defaultMessage:
      'Navigate to the student landing page to see a list of your upcoming and previous classes, once you have selected the specific Builder Labs for classes course, you will see all labs listed out in sequential order. If the ‘Launch’ button has been disabled, this is because the lab availability end-date has passed, or the instructor has made them unavailable to re-use.',
  },
  faqClassrooms5Header: {
    id: 'helpPanelContent.faqClassrooms5Header',
    defaultMessage: 'How do I access my class e-book or other materials?',
  },
  faqClassrooms5Content: {
    id: 'helpPanelContent.faqClassrooms5Content',
    defaultMessage:
      'All course materials, including the e-book or lab guides or listed on the specific Builder Labs for classes course page. Navigate to the student landing page to see a list of your upcoming and previous classes, once you have selected the specific Builder Labs for classes course you will see the ‘lab guide’ and ‘student guide’ buttons on the upper right hand side.',
  },
  faqClassrooms6Header: {
    id: 'helpPanelContent.faqClassrooms6Header',
    defaultMessage: 'How much time will I have to complete a lab?',
  },
  faqClassrooms6Content: {
    id: 'helpPanelContent.faqClassrooms6Content',
    defaultMessage:
      'Lab duration varies, but in general a lab is available for at least 8 hours per lab. If your lab expires before you complete it, don’t worry, you can take it again. Remember, the goal of labs is to give you opportunities to practice the skills you’re learning!',
  },
  faqClassrooms7Header: {
    id: 'helpPanelContent.faqClassrooms7Header',
    defaultMessage: 'What are the system requirements?',
  },
  faqClassrooms7Content: {
    id: 'helpPanelContent.faqClassrooms7Content',
    defaultMessage:
      'You’ll need a reliable internet connection and the latest version of Chrome or Firefox browser on a recent MacOS or Windows operating system.',
  },
});

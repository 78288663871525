import React from 'react';
import { Box, Badge } from '@amzn/awsui-components-react';
import { TOUR_IDS } from '../../constants/siteTour';
import messages from './tourSteps.messages';

const tourSteps = formatMessage => [
  {
    target: 'body',
    placement: 'center',
    title: formatMessage(messages.welcomeTitle),
    content: (
      <>
        <Box variant="p">
          {formatMessage(messages.firstMessage, {
            s: chunk => <Box variant="strong">{chunk}</Box>,
          })}
        </Box>
        {formatMessage(messages.tourContent, {
          ul: chunk => <ul className="TourContent__list">{chunk}</ul>,
          li: chunk => <li>{chunk}</li>,
        })}
        <Box variant="p">
          {formatMessage(messages.tip, {
            badge: chunk => <Badge>{chunk}</Badge>,
          })}{' '}
          {formatMessage(messages.restart, {
            s: chunk => <Box variant="strong">{chunk}</Box>,
          })}
        </Box>
      </>
    ),
  },
  {
    target: 'main',
    title: formatMessage(messages.labContentTitle),
    content: <Box variant="p">{formatMessage(messages.labContent1)}</Box>,
  },
  {
    target: `[data-tourid="${TOUR_IDS.labHeader}"]`,
    title: formatMessage(messages.labHeaderTitle),
    content: (
      <>
        <Box variant="p">{formatMessage(messages.labHeader1)}</Box>
        <Box variant="p">{formatMessage(messages.labHeader2)}</Box>
      </>
    ),
  },
  {
    target: `[data-tourid="${TOUR_IDS.helpPanel}"]`,
    placement: 'left-start',
    title: formatMessage(messages.helpTitle),
    content: (
      <>
        <Box variant="p">{formatMessage(messages.help1)}</Box>
        <Box variant="p">
          {formatMessage(messages.help2, {
            s: chunk => <Box variant="strong">{chunk}</Box>,
          })}
        </Box>
      </>
    ),
  },
  {
    target: `[data-tourid="${TOUR_IDS.liveSupportBtn}"]`,
    title: formatMessage(messages.liveSupportTitle),
    content: formatMessage(messages.liveSupport, {
      s: chunk => <Box variant="strong">{chunk}</Box>,
    }),
  },
  {
    target: `[data-tourid="${TOUR_IDS.localeMenu}"]`,
    title: formatMessage(messages.localeTitle),
    content: formatMessage(messages.locale, {
      s: chunk => <Box variant="strong">{chunk}</Box>,
    }),
  },
  {
    target: `[data-tourid="${TOUR_IDS.userMenu}"]`,
    title: formatMessage(messages.userTitle),
    content: formatMessage(messages.user, {
      s: chunk => <Box variant="strong">{chunk}</Box>,
    }),
  },
  {
    target: `[data-tourid="${TOUR_IDS.feedbackBtn}"]`,
    title: formatMessage(messages.feedbackTitle),
    content: formatMessage(messages.feedback, {
      s: chunk => <Box variant="strong">{chunk}</Box>,
    }),
  },
  {
    target: 'body',
    placement: 'center',
    isLastStep: true,
    title: formatMessage(messages.finalTitle),
    content: <Box variant="p">{formatMessage(messages.final)}</Box>,
  },
];

export default tourSteps;

import React from 'react';
import PropTypes from 'prop-types';

import logger from '../../utils/logger';
import { publishFatal } from '../../utils/metrics';

class CalloutErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo = {}) {
    logger.error('CalloutErrorBoundary', error, errorInfo);
    this.setState({
      hasError: true,
    });
    const stackString = errorInfo.componentStack || 'NA';
    publishFatal('CalloutErrorBoundary', error, {
      ComponentStack: stackString.toString().replace(/\n/g, '').trim(),
    });
  }

  render() {
    if (this.state.hasError) return null;

    return this.props.children;
  }
}

CalloutErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default CalloutErrorBoundary;

import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Header,
  Container,
  Link,
  TextContent,
} from '@amzn/awsui-components-react';

import SEO from '../components/SEO';
import Main from '../components/Main';
import { useAuthContext } from '../contexts';
import { publishCounterMetric } from '../utils/metrics';
import messages from './UnauthorizedPage.messages';
import appMessages from '../i18n/app.messages';

export const skillBuilderUrl = 'labs.skillbuilder.aws';

/**
 * Rendered when a customer does not pass VIAS AuthZ or if they have been
 * blocked through vide/hat ID.
 */
const UnauthorizedPage = () => {
  const { formatMessage } = useIntl();
  const [{ currentUser }] = useAuthContext();

  useEffect(() => {
    publishCounterMetric('Unauthorized', {
      additionalMetrics: { CurrentUser: currentUser },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const normalContent = {
    header: formatMessage(messages.primaryMessage),
    main: <p>{formatMessage(messages.secondaryMessage)}</p>,
  };

  const skillBuilderContent = {
    header: formatMessage(messages.skillBuilderHeading),
    main: (
      <>
        <p>
          {formatMessage(messages.skillBuilderSubscriptionInfo, {
            link: chunks => (
              <Link
                href="https://aws.amazon.com/training/digital/"
                external
                externalIconAriaLabel={formatMessage(
                  appMessages.externalIconAriaLabel
                )}
              >
                {chunks}
              </Link>
            ),
          })}
        </p>
        <p>{formatMessage(messages.skillBuilderESSOInfo)}</p>
        <p>{formatMessage(messages.skillBuilderHelpInfo)}</p>
        <p>{formatMessage(messages.skillBuilderPaymentInfo)}</p>
        <p>{formatMessage(messages.skillBuilderLabUndermaintenance)}</p>
      </>
    ),
  };

  const contentOverrides = {
    [skillBuilderUrl]: skillBuilderContent,
  };

  const content = contentOverrides[location.hostname] || normalContent;

  return (
    <Main>
      <SEO title={formatMessage(messages.primaryMessage)} />
      <Container header={<Header variant="h1">{content.header}</Header>}>
        <TextContent>{content.main}</TextContent>
      </Container>
    </Main>
  );
};

export default UnauthorizedPage;

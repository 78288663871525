import { v4 as uuid } from 'uuid';

import logger from '../utils/logger';
import { awsLabsStatuses } from '../constants/awsLabs';
import { CONSOLE_URL_TTL } from '../constants/lab';

export const LAB_PROVISIONING_ATTEMPTS = 2;

/** @typedef {'INITIAL'|'PROVISIONING'|'ONGOING'|'CLOSED'|'ERROR'} LabStatus */

/** @typedef {'LOADING'|'DONE'|'ERROR'} LoadingStatus */

/** @typedef {string} EvaluationId Internal ID represenatation of a lab evaluation */

/**
 * @typedef {Object} EvaluationState
 * @property {EvaluationId} id
 * @property {?LoadingStatus} evaluationStatus
 * @property {?LoadingStatus} submissionStatus
 * @property {?import('../models').EHLabEvaluation} result
 */

/** @readonly @enum {LabStatus} */
export const labStatuses = {
  /** There is no ongoing lab */
  INITIAL: 'INITIAL',
  /** A new lab is being provisioned */
  PROVISIONING: 'PROVISIONING',
  /** Lab is ongoing */
  ONGOING: 'ONGOING',
  /** Lab is closed due to budget exceeded */
  CLOSED: 'CLOSED',
  /** The lab is in error state */
  ERROR: 'ERROR',
};

/** @readonly @enum {LoadingStatus} */
export const loadingStatuses = {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

/** @readonly @enum {string} */
export const actions = {
  START_LAB_REQUEST: 'START_LAB_REQUEST',
  START_LAB_RESPONSE: 'START_LAB_RESPONSE',
  START_LAB_ERROR: 'START_LAB_ERROR',

  END_LAB_REQUEST: 'END_LAB_REQUEST',
  END_LAB_RESPONSE: 'END_LAB_RESPONSE',
  END_LAB_ERROR: 'END_LAB_ERROR',

  GET_SESSION_REQUEST: 'GET_SESSION_REQUEST',
  GET_SESSION_RESPONSE: 'GET_SESSION_RESPONSE',
  GET_SESSION_ERROR: 'GET_SESSION_ERROR',

  GET_LAB_REQUEST: 'GET_LAB_REQUEST',
  GET_LAB_RESPONSE: 'GET_LAB_RESPONSE',
  GET_LAB_ERROR: 'GET_LAB_ERROR',

  GET_ONGOING_RESPONSE: 'GET_ONGOING_RESPONSE',
  GET_ONGOING_ERROR: 'GET_ONGOING_ERROR',

  START_LAB_EVALUATION_REQUEST: 'START_LAB_EVALUATION_REQUEST',
  START_LAB_EVALUATION_RESPONSE: 'START_LAB_EVALUATION_RESPONSE',
  START_LAB_EVALUATION_ERROR: 'START_LAB_EVALUATION_ERROR',

  GET_LAB_EVALUATION_RESPONSE: 'GET_LAB_EVALUATION_RESPONSE',
  GET_LAB_EVALUATION_ERROR: 'GET_LAB_EVALUATION_ERROR',

  SUBMIT_LAB_EVALUATION_REQUEST: 'SUBMIT_LAB_EVALUATION_REQUEST',
  SUBMIT_LAB_EVALUATION_RESPONSE: 'SUBMIT_LAB_EVALUATION_RESPONSE',
  SUBMIT_LAB_EVALUATION_ERROR: 'SUBMIT_LAB_EVALUATION_ERROR',

  RESET_LAB_EVALUATION: 'RESET_LAB_EVALUATION',
  RESET_LAB_STATE: 'RESET_LAB_STATE',
};

/**
 * @typedef {Object} Secret
 * @property {string} name
 * @property {string} value
 */

/**
 * @typedef {Object} LabData
 * @property {?string} labId
 * @property {?string} blueprintArn
 * @property {?string} blueprintLocale
 * @property {LabStatus} status
 * @property {?Secret[]} secrets
 * @property {?string} consoleUrl
 * @property {?number} consoleUrlTtl
 * @property {?string} createdOn
 * @property {?string} expiresOn
 * @property {?string} estimatedReadyTime
 * @property {number} attempt
 * @property {?EvaluationId} evaluationCurrentId
 * @property {EvaluationState[]} evaluations
 */

/**
 * @typedef {Object} LabState
 * @property {LabData[]} labs

 */

/** @type {LabState} */
export const defaultState = {
  labs: [],
};

/** @type {LabData} */
export const defaultLabData = {
  labId: null,
  acceptTerms: null,
  blueprintArn: null,
  blueprintLocale: null,
  status: labStatuses.INITIAL,
  secrets: null,
  consoleUrl: null,
  consoleUrlTtl: null,
  createdOn: null,
  estimatedReadyTime: null,
  attempt: 0,
  evaluationCurrentId: null,
  evaluations: [],
  expiresOn: null,
};

/**
 * Function that returns the matched lab state based on blueprintArn or labId
 * @param {LabData[]} labState
 * @param {string} blueprintArn
 * @param {string} labId
 * @returns {LabData}
 */
export const getLabStateByField = (
  labState,
  blueprintArn = null,
  labId = null
) => {
  for (const state of labState) {
    if (blueprintArn && state.blueprintArn === blueprintArn) {
      return state;
    }
    if (labId && state.labId === labId) {
      return state;
    }
  }

  return defaultLabData;
};

/**
 * Function that checks whether it is an ongoing lab
 * @param {LabStatus} status
 * @returns {boolean}
 */
export const isOngoing = status => {
  switch (status) {
    case labStatuses.PROVISIONING:
    case labStatuses.ONGOING:
    case labStatuses.CLOSED:
    case labStatuses.ERROR:
      return true;
    default:
      return false;
  }
};

/**
 * Function that maps lab status returned by API to internal lab status
 * @param {string} awsLabsState
 * @returns {LabStatus}
 */
export const mapAwsLabsStatus = awsLabsState => {
  switch (awsLabsState) {
    case awsLabsStatuses.READY:
    case awsLabsStatuses.RUNNING:
      return labStatuses.ONGOING;

    case awsLabsStatuses.INITIALIZING:
    case awsLabsStatuses.PROVISIONING:
      return labStatuses.PROVISIONING;

    case awsLabsStatuses.CLOSED:
      return labStatuses.CLOSED;

    default:
      return labStatuses.INITIAL;
  }
};

/**
 * @param {LabState} state
 * @param {*} action
 * @returns {LabState}
 */
export const labReducer = (state, action) => {
  switch (action.type) {
    // START_LAB ACTIONS
    case actions.START_LAB_REQUEST:
      return state;

    case actions.START_LAB_RESPONSE: {
      return {
        ...state,
        labs: [
          ...state.labs.filter(lab => lab.blueprintArn != action.blueprintArn),
          {
            labId: action.labId,
            acceptTerms: action.acceptTerms,
            blueprintArn: action.blueprintArn,
            blueprintLocale: action.blueprintLocale,
            status: labStatuses.PROVISIONING,
            attempt: action.attempt ? action.attempt + 1 : 1,
          },
        ],
      };
    }

    case actions.START_LAB_ERROR:
      return state;

    // END_LAB ACTIONS
    case actions.END_LAB_REQUEST:
      return state;

    case actions.END_LAB_RESPONSE: {
      return {
        ...state,
        labs: state.labs.filter(lab => lab.labId != action.labId),
      };
    }

    case actions.END_LAB_ERROR:
      return state;

    // GET_SESSION ACTIONS
    case actions.GET_SESSION_REQUEST:
      return state;

    case actions.GET_SESSION_RESPONSE: {
      let consoleUrlTtl = Date.now() + CONSOLE_URL_TTL;
      const sessionEndsOn = Date.parse(action.endsOn);
      if (sessionEndsOn && sessionEndsOn < consoleUrlTtl) {
        consoleUrlTtl = sessionEndsOn;
      }
      return {
        ...state,
        labs: state.labs.map(lab =>
          lab.labId === action.labId
            ? {
                ...lab,
                consoleUrl: action.consoleUrl,
                consoleUrlTtl,
              }
            : lab
        ),
      };
    }

    case actions.GET_SESSION_ERROR: {
      return {
        ...state,
        labs: state.labs.map(lab =>
          lab.labId === action.labId
            ? {
                ...lab,
                status: labStatuses.ERROR,
              }
            : lab
        ),
      };
    }

    // GET_LAB ACTIONS
    // TODO(https://issues.amazon.com/issues/EventHorizon-3087): Add multi lab support for all lab_eval actions
    case actions.GET_LAB_REQUEST:
      return state;

    case actions.GET_LAB_RESPONSE: {
      return {
        ...state,
        labs: state.labs.map(lab =>
          lab.labId === action.labId
            ? {
                ...lab,
                status: mapAwsLabsStatus(action.status),
                secrets: action.secrets,
                createdOn: action.createdOn,
                estimatedReadyTime: action.estimatedReadyTime,
                expiresOn: action.expiresOn,
              }
            : lab
        ),
      };
    }

    case actions.GET_LAB_ERROR: {
      return {
        ...state,
        labs: state.labs.map(lab =>
          lab.labId === action.labId
            ? {
                ...lab,
                status: labStatuses.ERROR,
              }
            : lab
        ),
      };
    }

    // GET_ONGOING ACTIONS
    case actions.GET_ONGOING_RESPONSE: {
      let newState = { labs: [] };

      for (const ongoinglab of action.ongoingLabs) {
        const ongoingLabState = {
          labId: ongoinglab.labId,
          blueprintArn: ongoinglab.blueprintArn,
          status: mapAwsLabsStatus(ongoinglab.status),
          secrets: ongoinglab.secrets,
          createdOn: ongoinglab.createdOn,
          estimatedReadyTime: ongoinglab.estimatedReadyTime,
        };

        if (state.labs.length !== 0 && state.labs[0]?.evaluations) {
          newState.labs.push({
            ...ongoingLabState,
            evaluationCurrentId: state.labs[0]?.evaluationCurrentId,
            evaluations: state.labs[0].evaluations,
          });
        } else {
          newState.labs.push({
            ...ongoingLabState,
          });
        }
      }

      return newState;
    }

    case actions.GET_ONGOING_ERROR:
      return state;

    // START_LAB_EVALUATION ACTIONS
    case actions.START_LAB_EVALUATION_REQUEST: {
      let newState = { labs: [...state.labs] };
      const evaluationCurrentId = uuid();
      newState.labs[0] = {
        ...newState.labs[0],
        evaluationCurrentId,
        evaluations: [
          {
            id: evaluationCurrentId,
            evaluationStatus: loadingStatuses.LOADING,
          },
        ],
      };
      return newState;
    }

    case actions.START_LAB_EVALUATION_RESPONSE: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                result: action.result,
              }
            : item
        ),
      };
      return newState;
    }

    case actions.START_LAB_EVALUATION_ERROR: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                evaluationStatus: loadingStatuses.ERROR,
              }
            : item
        ),
      };
      return newState;
    }

    // GET_LAB_EVALUATION ACTIONS
    case actions.GET_LAB_EVALUATION_RESPONSE: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                evaluationStatus: loadingStatuses.DONE,
                result: action.result,
              }
            : item
        ),
      };
      return newState;
    }

    case actions.GET_LAB_EVALUATION_ERROR: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                evaluationStatus: loadingStatuses.ERROR,
                result: null,
              }
            : item
        ),
      };
      return newState;
    }

    // SUBMIT_LAB_EVALUATION ACTIONS
    case actions.SUBMIT_LAB_EVALUATION_REQUEST: {
      let newState = { labs: [...state.labs] };
      const hasEvaluations =
        newState.labs[0]?.evaluations &&
        newState.labs[0].evaluations.length > 0;

      if (!hasEvaluations) {
        newState.labs[0] = {
          ...newState.labs[0],
          evaluations: [
            {
              submissionStatus: loadingStatuses.LOADING,
            },
          ],
        };
      } else {
        newState.labs[0] = {
          ...newState.labs[0],
          evaluations: newState.labs[0].evaluations.map(item =>
            item.id === newState.labs[0].evaluationCurrentId
              ? {
                  ...item,
                  submissionStatus: loadingStatuses.LOADING,
                }
              : item
          ),
        };
      }

      return newState;
    }

    case actions.SUBMIT_LAB_EVALUATION_RESPONSE: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                submissionStatus: loadingStatuses.DONE,
              }
            : item
        ),
      };
      return newState;
    }

    case actions.SUBMIT_LAB_EVALUATION_ERROR: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: newState.labs[0].evaluations.map(item =>
          item.id === newState.labs[0].evaluationCurrentId
            ? {
                ...item,
                submissionStatus: loadingStatuses.ERROR,
              }
            : item
        ),
      };
      return newState;
    }

    case actions.RESET_LAB_EVALUATION: {
      let newState = { labs: [...state.labs] };
      newState.labs[0] = {
        ...newState.labs[0],
        evaluations: [],
      };
      return newState;
    }

    // Reset state
    case actions.RESET_LAB_STATE:
      return defaultState;

    default:
      logger.debug(`Unhandled action: ${action.type}`);
      return state;
  }
};

export default {
  labStatuses,
  actions,
  labReducer,
  defaultState,
};

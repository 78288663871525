import { any, isEmpty, isNil, prop, propOr, propEq, find } from 'ramda';
import logger from '../utils/logger';

import './typedef';

export class EHEvent {
  constructor(payload) {
    /** @type {string} */
    this.arn = prop('arn', payload);
    /** @type {Announcement[]} */
    this.announcements = prop('announcements', payload);
    /** @type {CapTimeWindow} */
    this.capLabsPerUser = prop('capLabsPerUser', payload);
    /** @type {string[]?} */
    this.channels = prop('channels', payload);
    /** @type {string?} */
    this.collectionArn = prop('collectionArn', payload);
    /** @type {number?} */
    this.concurrentLabsLimit = propOr(1, 'concurrentLabsLimit', payload);
    /** @type {string} */
    this.defaultLocale = prop('defaultLocale', payload);
    /** @type {string} */
    this.defaultTheme = prop('defaultTheme', payload);
    /** @type {string} */
    this.description = prop('description', payload);
    /** @type {EventLink?} */
    this.eventLink = prop('eventLink', payload);
    /** @type {EventFeature[]} */
    this.features = prop('features', payload);
    /** @type {string} */
    this.logoAltText = prop('logoAltText', payload);
    /** @type {string?} */
    this.name = prop('name', payload);
    /** @type {Redirect[]} */
    this.redirects = prop('redirects', payload);
    /** @type {string?} */
    this.spotlightArn = prop('spotlightArn', payload);
    /** @type {string?} */
    this.supportLinkUrl = prop('supportLinkUrl', payload);
  }

  /**
   * Returns a valid value for the concurrentLabsLimit.
   * @returns {number}
   */
  getConcurrentLabsLimit() {
    if (this.concurrentLabsLimit < 1) return 1;
    return this.concurrentLabsLimit;
  }

  /**
   * Checks is the event is configured to only serve labs, without any lab discovery function
   * such as the catalog or recommendation page.
   * @returns {boolean}
   */
  isStandaloneLabEvent() {
    return !this.collectionArn;
  }

  /**
   * Checks if the feature flag is enabled in the event config.
   * @param {string} feature
   * @returns {boolean}
   */
  hasFeature(feature) {
    if (isNil(this.features) || any(isNil, this.features)) return false;
    return !!prop('enabled', find(propEq('name', feature))(this.features));
  }

  /**
   * Gets feature metadata for a specific flag if available.
   * @param {string} feature
   * @returns {Object | {}} Deserialized JSON.
   */
  getMetadata(feature) {
    if (!this.hasFeature(feature)) return {};
    const metadata = prop(
      'metadata',
      find(propEq('name', feature))(this.features)
    );

    if (isNil(metadata) || isEmpty(metadata)) return {};

    try {
      return JSON.parse(metadata);
    } catch (error) {
      logger.debug('Failed to parse feature metadata', error);
      return {};
    }
  }

  /**
   * Returns a location given a redirect name.
   * @param {string} name
   * @returns {string} location
   */
  getRedirectLocation(name) {
    if (isNil(this.redirects)) return null;
    return prop('location', find(propEq('name', name))(this.redirects));
  }
}

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flashbar } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import messages from './LabStateNotification.messages';
import LabProgressBar from './LabProgressBar';
import { ClosedStateAction, ClosedStateContent } from './ClosedState';
import { labStatuses, useEventContext } from '../../../contexts';
import { flashbarI18nStrings } from '../../../i18n/flashbarI18nStrings.messages';
import { dataTestId } from '../../../constants/dataTestIdSelectors';
import FEATURES from '../../../constants/features';

const LabStateNotification = ({
  attempt,
  createdOn,
  expirationData,
  estimatedReadyTime,
  isGettingLabSession,
  isOngoingLab,
  status,
}) => {
  const [notification, setNotification] = useState(null);
  const { formatMessage } = useIntl();
  const { event } = useEventContext();
  const isLabExpiryTimeFeatureEnabled = event.hasFeature(
    FEATURES.labExpiryTime
  );
  const [readyNotificationDismissed, setReadyNotificationDismissed] =
    useState(false);

  const i18nStrings = {
    errorIconAriaLabel: formatMessage(flashbarI18nStrings.error),
    inProgressIconAriaLabel: formatMessage(flashbarI18nStrings.inProgress),
    infoIconAriaLabel: formatMessage(flashbarI18nStrings.info),
    successIconAriaLabel: formatMessage(flashbarI18nStrings.success),
    warningIconAriaLabel: formatMessage(flashbarI18nStrings.warning),
  };

  const handleDismiss = useCallback(() => {
    setNotification(null);
    setReadyNotificationDismissed(true);
  }, []);

  const getNotificationContent = useCallback(() => {
    if (!isOngoingLab) return null;

    if (status === labStatuses.PROVISIONING || isGettingLabSession) {
      return {
        header: formatMessage(messages.loading),
        content: (
          <LabProgressBar
            attempt={attempt}
            createdOn={createdOn}
            estimatedReadyTime={estimatedReadyTime}
          />
        ),
        type: 'info',
        loading: true,
      };
    }

    if (status === labStatuses.ONGOING && !readyNotificationDismissed) {
      return {
        header: formatMessage(messages.ready),
        content: (
          <>
            <Box
              variant="p"
              color="inherit"
              data-testid={dataTestId['lab-readyNotification']}
            >
              {!isLabExpiryTimeFeatureEnabled || !expirationData
                ? formatMessage(messages.regionNote)
                : formatMessage(messages.regionNoteWithExpiryTime, {
                    expiryDate: expirationData.expiryDate,
                    expiryTime: expirationData.expiryTime,
                  })}
            </Box>
            <Box variant="p" color="inherit">
              {formatMessage(messages.consoleTip)}
            </Box>
          </>
        ),
        type: 'success',
        dismissible: true,
        onDismiss: handleDismiss,
        dismissLabel: formatMessage(messages.dismiss),
      };
    }

    if (status === labStatuses.CLOSED) {
      return {
        header: formatMessage(messages.closed),
        content: <ClosedStateContent />,
        type: 'error',
        action: <ClosedStateAction />,
      };
    }

    return null;
  }, [
    attempt,
    createdOn,
    estimatedReadyTime,
    expirationData,
    isLabExpiryTimeFeatureEnabled,
    status,
    formatMessage,
    isGettingLabSession,
    isOngoingLab,
    handleDismiss,
    readyNotificationDismissed,
  ]);

  useEffect(() => {
    const content = getNotificationContent();
    setNotification(content);
  }, [getNotificationContent]);

  return (
    <>
      <div role="status">
        {notification && notification.type !== 'error' && (
          <Flashbar items={[notification]} i18nStrings={i18nStrings} />
        )}
      </div>
      <div role="alert">
        {notification && notification.type === 'error' && (
          <Flashbar items={[notification]} i18nStrings={i18nStrings} />
        )}
      </div>
    </>
  );
};

LabStateNotification.propTypes = {
  attempt: PropTypes.number,
  createdOn: PropTypes.string,
  expirationData: PropTypes.object,
  estimatedReadyTime: PropTypes.string,
  isGettingLabSession: PropTypes.bool,
  isOngoingLab: PropTypes.bool,
  status: PropTypes.string,
};

export default LabStateNotification;

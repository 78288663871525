import { useState, useLayoutEffect } from 'react';
import { prop, or, pathOr } from 'ramda';

// exported for use in Carousel.test
export const cardSelector = 'li[class^="awsui_card_"]';

export const cardSharedSelector = '.ContainerOverride--borderless';

/**
 * Custom hook that that listens to the window resize event
 *
 * @returns {Number} Current window width
 */
export const useWindowWidth = () => {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return width;
};

/**
 * @param {Object} $carousel Carousel element ref
 * @returns {Object} Current scroll data
 */
export const getScrollData = $carousel => {
  if (!$carousel) return;

  // scrollLeftMax is only available in Firefox, but works best when available
  const { scrollLeft, scrollLeftMax, scrollWidth, clientWidth } = $carousel;
  // get max scroll for all other browsers
  const maxScroll = scrollWidth - clientWidth;

  return {
    scrollLocation: scrollLeft,
    maxScroll: or(scrollLeftMax, maxScroll),
  };
};

/**
 * Scrolls to the next lab that should be visible in the carousel.
 *
 * @param {Object} $carousel Carousel element ref
 * @param {String} direction Direction of the scroll
 * @param {Boolean} shared Uses .ContainerOverride--borderless as selector when true
 *
 * @note This function is dependent on Polaris cards using li components.
 * If this ever changes, the function will need to be updated.
 */
export const setCarouselScroll = ($carousel, direction, shared = false) => {
  if (!$carousel) return;

  const selector = shared ? cardSharedSelector : cardSelector;
  const $carouselItem = $carousel.querySelector(selector);

  const carouselWidth = prop('clientWidth', $carousel);
  const itemWidth = prop('clientWidth', $carouselItem);

  // the number of items that can be displayed inside carousel
  // dependent on the width of the carousel, defaults to 1
  const multiplier = or(Math.floor(carouselWidth / itemWidth), 1);
  // padding of partial items to equally display on right and left of carouselItems
  const padding =
    (carouselWidth - itemWidth * multiplier) / 2 +
    (getCardPadding(itemWidth) * 3) / 2;

  const currCard = Math.ceil($carousel.scrollLeft / itemWidth);
  const scrollTo =
    direction === 'right' ? currCard + multiplier : currCard - multiplier;

  // can't scroll to an item number larger than carousel size
  // if negative number is returned, it auto scrolls to beginning
  const carouselSize = $carousel.querySelectorAll(selector).length;
  const scrollToNum = scrollTo + 1 > carouselSize ? carouselSize : scrollTo + 1;

  const $carouselItemN = $carousel.querySelector(
    `${selector}:nth-of-type(${scrollToNum})`
  );
  const carouselItemNOffset = prop('offsetLeft', $carouselItemN);
  $carousel.scrollLeft = carouselItemNOffset - padding;
};

/**
 * Gets the width of the card minus padding/margin
 * @param {String} $cardWidth
 * @note The 1 is to account for the 1px padding for the border
 */
export const getCardPadding = (cardWidth, shared = false) => {
  const selector = shared ? cardSharedSelector : cardSelector;
  return (
    cardWidth -
    1 -
    pathOr(
      cardWidth - 1,
      ['firstChild', 'clientWidth'],
      document.querySelector(selector)
    )
  );
};

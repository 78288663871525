import React from 'react';
import { useIntl } from 'react-intl';

import { ButtonOverride } from '../PolarisOverrides';
import { publishButtonClickMetric } from '../../utils/metrics';
import { customizeCookies } from '../../utils/cookieManagement';
import { COOKIE_PREFS_ID } from '../../constants/cookies';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './LegalZone.messages';

const handleCookiePrefsClick = () => {
  customizeCookies();
  publishButtonClickMetric('CookiePreferences', {
    currentPath: window.location.pathname,
  });
};

const CookiePrefsButton = () => {
  const { formatMessage } = useIntl();

  return (
    <ButtonOverride
      variant="transparent"
      id={COOKIE_PREFS_ID}
      data-testid={dataTestId['footer-cookies']}
      onClick={handleCookiePrefsClick}
    >
      {formatMessage(messages.cookiePref)}
    </ButtonOverride>
  );
};

CookiePrefsButton.propTypes = {};

export default CookiePrefsButton;

import { Box } from '@amzn/awsui-components-react';
import React from 'react';
import { useIntl } from 'react-intl';

import metrics from '../../utils/metrics';
import messages from './SkipNavigation.messages';
import './SkipNavigation.scss';

const MAIN_SELECTOR = 'js-main-content';

const SkipNavigation = () => {
  const { formatMessage } = useIntl();

  const handleClick = event => {
    event.preventDefault();

    const publisher = metrics.createPublisher('SkipNavigation');

    const $main = document.getElementById(MAIN_SELECTOR);

    if ($main) {
      $main.focus();
      $main.scrollIntoView();
      publisher.publishCounter('Click', 1);
    } else {
      publisher.publishCounter('MissingMain', 1);
    }
  };

  return (
    <a
      className="SkipNavigation"
      href={`#${MAIN_SELECTOR}`}
      onClick={handleClick}
    >
      <Box variant="span" color="inherit">
        {formatMessage(messages.link)}
      </Box>
    </a>
  );
};

export default SkipNavigation;

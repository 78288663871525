import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { usePageVisibility } from 'react-page-visibility';

import { connectChatReducer, actions, defaultState } from './connectChatState';
import ChatBox from '../components/LiveSupport/ChatBox';

export const ConnectChatContext = createContext();

export const useConnectChatContext = () => useContext(ConnectChatContext);

const ConnectChatContextProvider = ({ children }) => {
  const isPageVisible = usePageVisibility();
  const [chatState, chatDispatch] = useReducer(
    connectChatReducer,
    defaultState
  );

  const startChat = useCallback(
    () => chatDispatch({ type: actions.CHAT_STARTING }),
    []
  );

  const dispatchChatFailed = useCallback(
    () => chatDispatch({ type: actions.CHAT_FAILURE }),
    []
  );

  const setChatSession = useCallback(
    chatSession =>
      chatDispatch({ type: actions.SET_CHAT_SESSION, chatSession }),
    []
  );

  const dispatchChatSucceeded = useCallback(
    () => chatDispatch({ type: actions.CHAT_SUCCESS }),
    []
  );

  const toggleChatWindow = useCallback(
    () => chatDispatch({ type: actions.TOGGLE_CHAT_WINDOW }),
    []
  );

  const toggleChatLoading = useCallback(
    () => chatDispatch({ type: actions.TOGGLE_LOADING }),
    []
  );

  const handleIncomingChat = useCallback(
    chatData => {
      if (chatData.ParticipantRole !== 'AGENT') return;

      if (!isPageVisible) {
        chatDispatch({ type: actions.INCREASE_UNREAD_COUNT });
      }
    },
    [isPageVisible]
  );

  useEffect(() => {
    if (isEmpty(chatState.chatSession)) return;

    chatState.chatSession._eventHandlers['incoming-message'] = [
      handleIncomingChat,
    ];
    chatState.chatSession.incomingItemDecorator = item => {
      if (item.displayName && item.displayName.indexOf('SYSTEM_MESSAGE') >= 0) {
        item.displayName = 'Amazon Connect';
      }
      return item;
    };
    chatState.chatSession.onChatDisconnected(() =>
      chatDispatch({ type: actions.CHAT_DISCONNECT })
    );
    chatState.chatSession.onChatClose(() =>
      chatDispatch({ type: actions.CHAT_CLOSE })
    );
  }, [chatState.chatSession, handleIncomingChat]);

  // Reset unread message count when page is viewed
  useEffect(() => {
    if (isPageVisible) chatDispatch({ type: actions.RESET_UNREAD_COUNT });
  }, [isPageVisible]);

  // Open chat window automatically when there is a new message
  useEffect(() => {
    if (chatState.chatWindowOpen) return;
    if (chatState.unreadMessages === 0) return;
    toggleChatWindow();
  }, [chatState.unreadMessages, chatState.chatWindowOpen, toggleChatWindow]);

  // Append number of unread messages to title
  useEffect(() => {
    const unreadRegex = /^(\([0-9]+\) )?/;
    if (chatState.unreadMessages === 0) {
      document.title = document.title.replace(unreadRegex, '');
      return;
    }
    document.title = document.title.replace(
      unreadRegex,
      `(${chatState.unreadMessages}) `
    );
  }, [chatState.unreadMessages]);

  return (
    <ConnectChatContext.Provider
      value={{
        chatState,
        startChat,
        dispatchChatFailed,
        dispatchChatSucceeded,
        toggleChatLoading,
        toggleChatWindow,
        setChatSession,
      }}
    >
      {children}
      <ChatBox chatWindowOpen={chatState.chatWindowOpen} />
    </ConnectChatContext.Provider>
  );
};

ConnectChatContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConnectChatContextProvider;

import React from 'react';

import { publishButtonClickMetric } from '../../utils/metrics';
import EventLogo from './EventLogo';
import HeaderLayout from './HeaderLayout';

/**
 * The template used while the application is spinning up.
 */
const HeaderLoading = () => {
  return (
    <HeaderLayout
      branding={
        <a
          href={document.location.href}
          onClick={() => publishButtonClickMetric('EventLogo')}
        >
          <EventLogo />
        </a>
      }
      testid={'header-Loading'}
    />
  );
};

HeaderLoading.propTypes = {};

export default HeaderLoading;

import React from 'react';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { SideNavigationOverride } from '../PolarisOverrides';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import LoadingSkeleton from '../LoadingSkeleton';
import title from './LabNavigation.messages';

/**
 * Maps a given navigation item to an object necessary for the SideNavigation component.
 * Also strips any HTML from the text content before adding it to the object.
 *    Backlog SIM ticket for this -> https://sim.amazon.com/issues/EventHorizon-247
 *
 * @param {Object} navItem The single nav item to be mapped
 */
const navigationLinksMapper = navItem => ({
  type: 'link',
  text: navItem.content.replace(/<[^>]*>?/gm, ''),
  href: `#${navItem.anchor}`,
});

const LabNavigation = ({ content = [], lang }) => {
  const { formatMessage } = useIntl();
  const navItems = content
    .filter(navItem => navItem.level === 2)
    .map(navigationLinksMapper);

  return (
    <div data-testid={dataTestId['lab-toc']}>
      <Box margin={{ bottom: 's' }} variant="h3">
        {formatMessage(title)}
      </Box>
      {!navItems.length ? (
        <SpaceBetween direction="vertical" size="s">
          <LoadingSkeleton width={70} height={22} />
          <LoadingSkeleton width={70} height={22} />
          <LoadingSkeleton width={70} height={22} />
          <LoadingSkeleton width={70} height={22} />
        </SpaceBetween>
      ) : (
        <SideNavigationOverride
          variant="labToc"
          className="LabNavigation__content"
          items={navItems}
          lang={lang}
        />
      )}
    </div>
  );
};

LabNavigation.propTypes = {
  content: PropTypes.array,
  lang: PropTypes.string,
};

export default LabNavigation;

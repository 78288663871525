import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

export const ConfigContext = createContext();
export const useConfigContext = () => useContext(ConfigContext);

const ConfigContextProvider = ({ config, children }) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

export default ConfigContextProvider;

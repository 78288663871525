import React from 'react';
import { Button, Box } from '@amzn/awsui-components-react';
import { Mode } from '@amzn/awsui-global-styles';
import { prop } from 'ramda';
import { useIntl } from 'react-intl';

import {
  useEventContext,
  useAuthContext,
  useLabContext,
  useThemeContext,
  useLocaleContext,
} from '../../contexts';
import FEATURES from '../../constants/features';
import {
  generateExternalSurveyUrl,
  createFeedbackURLParams,
} from './feedbackUtils';
import messages from './Feedback.messages';
import { ContainerOverride } from '../PolarisOverrides';
import { publishCounterMetric } from '../../utils/metrics';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import appMessages from '../../i18n/app.messages';
import darkImage from '../../images/customer-engagement-darkMode.svg';
import lightImage from '../../images/customer-engagement-lightMode.svg';

import './FeedbackSurveyBox.scss';

const FeedbackSurveyBox = ({ blueprint }) => {
  const { formatMessage } = useIntl();
  const { event } = useEventContext();
  const [{ vibeId }] = useAuthContext();
  const { getFirstLabInLabState } = useLabContext();
  const [theme] = useThemeContext();
  const [locale] = useLocaleContext();
  const labState = getFirstLabInLabState();
  const hasFeature = event.hasFeature(FEATURES.feedbackOpensExternalLink);
  const featureMetadata = event.getMetadata(FEATURES.feedbackOpensExternalLink);
  const labSurveyBox = prop('baseUrl', featureMetadata);

  const externalURL = generateExternalSurveyUrl(
    featureMetadata,
    createFeedbackURLParams(
      vibeId,
      event,
      blueprint,
      labState ? labState.labId : '',
      locale
    )
  );

  if (!hasFeature || !externalURL || !labSurveyBox) {
    return null;
  }

  return (
    <ContainerOverride
      variant={'flashy'}
      className="FeedbackSurveyBox__container"
    >
      <Box variant="h2" margin={{ bottom: 's' }}>
        {formatMessage(messages.surveyBoxHeading)}
      </Box>
      <Button
        variant="normal"
        href={externalURL}
        target="_blank"
        iconName="external"
        iconAlign="right"
        data-testid={dataTestId['feedback-surveyCta']}
        onClick={() => {
          publishCounterMetric('SurveyBox', {
            counterName: 'CTAClicked',
          });
        }}
        ariaLabel={`${formatMessage(
          messages.surveyBoxCallToAction
        )} ${formatMessage(appMessages.externalIconAriaLabel)}`}
      >
        <>{formatMessage(messages.surveyBoxCallToAction)}</>
      </Button>
      <img
        className="FeedbackSurveyBox__image"
        style={{
          width: '100px',
          height: 'auto',
        }}
        src={theme === Mode.Dark ? darkImage : lightImage}
        alt=""
      />
    </ContainerOverride>
  );
};

export default FeedbackSurveyBox;

import React from 'react';
import { useEventContext } from '../../contexts';

export const LOGO_ALT_TEXT = 'AWS Training and Certification';

const EventLogo = () => {
  const { event } = useEventContext();
  return (
    <img alt={event.logoAltText || LOGO_ALT_TEXT} src="/resources/logo.png" />
  );
};

export default EventLogo;

import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

import { notificationReducer, actions } from './notificationState';

/**
 * @typedef {import('./notificationState').Notification} Notification
 */

/**
 * @typedef {Object} NotificationContext
 * @property {Notification[]} notifications
 * @property {(value: Notification) => void} appendNotification
 * @property {(id: string) => void} removeNotification
 * @property {(searchData: {searchKey: string, searchValue: string}) => void} searchAndRemoveNotification
 * @property {() => void} clearAllNotifications
 * @property {() => void} clearLabNotifications
 */

/** @type {NotificationContext} */
export const NotificationContext = createContext();

/** @returns {NotificationContext} */
export const useNotificationContext = () => useContext(NotificationContext);

const NotificationContextProvider = ({ children }) => {
  const [notifications, updateNotifications] = useReducer(
    notificationReducer,
    []
  );

  const appendNotification = useCallback(
    value =>
      updateNotifications({
        type: actions.APPEND_NOTIFICATION,
        value,
      }),
    []
  );

  const removeNotification = useCallback(
    id =>
      updateNotifications({
        type: actions.REMOVE_NOTIFICATION,
        id,
      }),
    []
  );

  const searchAndRemoveNotification = useCallback(
    searchData =>
      updateNotifications({
        type: actions.SEARCH_AND_REMOVE_NOTIFICATION,
        searchData,
      }),
    []
  );

  const clearAllNotifications = useCallback(
    () => updateNotifications({ type: actions.CLEAR_NOTIFICATIONS }),
    []
  );

  const clearLabNotifications = useCallback(
    () => updateNotifications({ type: actions.CLEAR_ALL_LAB_NOTIFICATIONS }),
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        appendNotification,
        removeNotification,
        searchAndRemoveNotification,
        clearAllNotifications,
        clearLabNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationContextProvider;

import { useQuery } from 'react-query';
import axios from 'axios';
import { calculateRetryDelay } from '../utils/promiseUtils';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';

const cacheBuster = new Date().getTime();
const CONFIG_URI = `/resources/catalog.json?v=${cacheBuster}`;

const CATALOG_DATA_CACHE_MS = 1000 * 60 * 60 * 12;
const CATALOG_DATA_STALE_MS = 1000 * 60 * 60 * 2;
const metricsNamespace = 'GetUnauthenticatedCatalog';

const fetchUnauthenticatedCatalog = async () => {
  const { data } = await axios.get(CONFIG_URI, {
    headers: {
      Accept: 'application/json',
    },
  });

  return data;
};

const useGetUnauthenticatedCatalog = ({
  metricsPublisher,
  onSuccess,
  onError,
} = {}) => {
  return useQuery({
    queryKey: ['getUnauthenticatedCatalog'],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        fetchUnauthenticatedCatalog(),
        metricsPublisher
      ),
    staleTime: CATALOG_DATA_STALE_MS,
    cacheTime: CATALOG_DATA_CACHE_MS,
    refetchOnWindowFocus: false,
    retry: failureCount => {
      return failureCount < 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 300 }),
    onSuccess: data => {
      if (onSuccess) onSuccess(data);
    },
    onError: error => {
      publishFatal('GetUnauthenticatedCatalog', error);
      if (onError) onError(error);
    },
  });
};

export default useGetUnauthenticatedCatalog;

import React from 'react';
import {
  Box,
  Button,
  Popover,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';

import messages from './ClosedState.messages';
import { useEventContext, useLocaleContext } from '../../../contexts';
import FEATURES from '../../../constants/features';
import { getLocaleOrAvailable } from '../../../utils/localeUtils';

export const ClosedStateContent = () => {
  const { formatMessage } = useIntl();
  const { event } = useEventContext();
  const [locale] = useLocaleContext();

  const metadata = event.getMetadata(FEATURES.budgetControl);
  const messageOverride = getLocaleOrAvailable(locale, metadata);

  return (
    <>
      <Box variant="p" color="inherit">
        {prop(messages.content.id, messageOverride) ||
          formatMessage(messages.content)}
      </Box>
      {!metadata.hideCopyUrlButton && (
        <Popover
          renderWithPortal
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={
            <StatusIndicator type="success">
              {formatMessage(messages.copiedUrl)}
            </StatusIndicator>
          }
        >
          <Button
            iconName="copy"
            onClick={() => navigator.clipboard.writeText(window.location.href)}
          >
            {formatMessage(messages.copyUrl)}
          </Button>
        </Popover>
      )}
    </>
  );
};

export const ClosedStateAction = () => {
  const { formatMessage } = useIntl();
  const { event } = useEventContext();

  const metadata = event.getMetadata(FEATURES.budgetControl);

  if (metadata.hideContactSupportButton || !event.supportLinkUrl) return null;

  return (
    <Button
      href={event.supportLinkUrl}
      iconAlign="right"
      iconName="external"
      target="_blank"
    >
      {formatMessage(messages.contactSupport)}
    </Button>
  );
};

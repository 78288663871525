import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dismissLabel: {
    id: 'appNotifications.dismissLabel',
    defaultMessage: 'Dismiss notification',
  },
});

export default messages;

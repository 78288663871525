import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reload: {
    id: 'channel.reload',
    defaultMessage: 'Reload page',
  },
  loading: {
    id: 'channel.loading',
    defaultMessage: 'Loading channel ...',
  },
  emptyViewTitle: {
    id: 'channel.emptyViewTitle',
    defaultMessage: 'No Labs',
  },
  emptyViewMessage: {
    id: 'channel.emptyViewMessage',
    defaultMessage: 'Channel has no labs to display.',
  },
});

export default messages;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'signInError.heading',
    defaultMessage: 'Sign-in failed',
  },
  message: {
    id: 'signInError.message',
    defaultMessage: 'Your login session has timed out. Please sign in again.',
  },
});

export default messages;

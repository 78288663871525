import React, { useRef, useEffect } from 'react';
import {
  Alert,
  Box,
  RadioGroup,
  FormField,
  Button,
} from '@amzn/awsui-components-react';
import useGetBlueprints from '../../hooks/useGetBlueprints';

import messages from './MultipleOngoingLabs.messages';
import { EndLabButton } from '../LabUI/LabButtons';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import metrics from '../../utils/metrics';
import { getLabStateByField } from '../../contexts/labState';

import {
  useLocaleContext,
  useLabContext,
  useEventContext,
} from '../../contexts';
import { useIntl } from 'react-intl';

import appMessages from '../../i18n/app.messages';

const MultipleOngoingLabs = () => {
  const [radioGroupLabels, setRadioGroupLabels] = React.useState([]);
  const [selectedLabId, setSelectedLabId] = React.useState(null);
  const [userLocale] = useLocaleContext();
  const { labState, stopLab, isStoppingLab } = useLabContext();
  const { event } = useEventContext();
  const { formatMessage } = useIntl();

  const concurrentLabsLimit = event.getConcurrentLabsLimit();
  const multipleLabsPagePublisher = useRef(
    metrics.createPublisher('MultipleOngoingLabs')
  );

  const handleStopLab = () => {
    setRadioGroupLabels(prevState =>
      prevState.map(label => ({ ...label, disabled: true }))
    );
    stopLab({ labId: selectedLabId });
  };

  const labelsAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((obj, i) => obj.label === arr2[i].label);
  };

  const blueprintArns = labState.labs
    .map(lab => lab.blueprintArn)
    .sort((a, b) => {
      if (a.blueprintArn < b.blueprintArn) return -1;
      if (a.blueprintArn > b.blueprintArn) return 1;
      return 0;
    });

  const blueprints = useGetBlueprints({
    blueprintArns,
    enableQuery: radioGroupLabels.length !== concurrentLabsLimit,
    metricsPublisher: multipleLabsPagePublisher.current,
  });
  const hasError = blueprints.some(blueprint => blueprint.isError);

  useEffect(() => {
    let labels = [];
    blueprints.forEach(blueprint => {
      if (blueprint.isSuccess) {
        const { title } =
          getLocaleOrAvailable(userLocale, blueprint.data) || {};
        const selectedLab = getLabStateByField(
          labState.labs,
          blueprint.data.arn
        );
        labels.push({
          value: selectedLab.labId,
          label: title,
        });
      } else if (blueprint.isLoading) {
        labels.push({
          value: labels.length,
          label: formatMessage(messages.loading),
          disabled: true,
        });
      }
    });
    if (
      labels.length > 0 &&
      !labelsAreEqual(labels, radioGroupLabels) &&
      !hasError
    ) {
      setRadioGroupLabels(labels);
    }
  }, [
    blueprints,
    userLocale,
    labState.labs,
    radioGroupLabels,
    isStoppingLab,
    formatMessage,
    hasError,
  ]);

  if (hasError) {
    return (
      <Alert
        dismissible={false}
        header={
          <Box variant="h2" fontSize="heading-s" color="inherit">
            {formatMessage(messages.multipleOngoingLabsHeader)}
          </Box>
        }
      >
        <Box>
          <p>
            {formatMessage(messages.loadMultipleOngoingLabsFail, {
              br: <br />,
            })}
          </p>
          <Button onClick={() => window.location.reload()} iconName="refresh">
            {formatMessage(messages.reload)}
          </Button>
        </Box>
      </Alert>
    );
  }

  return (
    <Alert
      statusIconAriaLabel={formatMessage(appMessages.info)}
      header={
        <Box variant="h2" fontSize="heading-s" color="inherit">
          {formatMessage(messages.multipleOngoingLabsHeader)}
        </Box>
      }
    >
      <Box margin={{ bottom: 'xs' }} color="inherit">
        <FormField label={formatMessage(messages.multipleOngoingLabsContent)}>
          <RadioGroup
            onChange={({ detail }) => {
              setSelectedLabId(detail.value);
            }}
            value={selectedLabId}
            items={radioGroupLabels}
          />
        </FormField>
      </Box>
      <EndLabButton
        onClick={handleStopLab}
        variant={'normal'}
        loading={isStoppingLab}
        disabled={!selectedLabId}
      />
    </Alert>
  );
};

export default MultipleOngoingLabs;

import React from 'react';
import { Box, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import messages from './ExpireTimeItem.messages';

const ExpireTimeItem = ({ expirationData, isOngoingLab }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {isOngoingLab && !isNil(expirationData) ? (
        <Box
          data-testid="expirationTime-message"
          color="text-body-secondary"
          margin={{ top: 's' }}
        >
          <Box variant="span" margin={{ right: 'xxs' }} color="inherit">
            <span role="img" aria-label={formatMessage(messages.inProgressAlt)}>
              <Icon
                data-testid="ExpiryTime-available-icon"
                name="status-in-progress"
              />
            </span>
          </Box>
          {formatMessage(messages.expiryTime, {
            expiryDate: expirationData.expiryDate,
            expiryTime: expirationData.expiryTime,
          })}
        </Box>
      ) : (
        <Box
          data-testid="no-expirationTime-message"
          color="text-body-secondary"
          margin={{ top: 's' }}
        >
          <Box variant="span" margin={{ right: 'xxs' }} color="inherit">
            <span role="img" aria-label={formatMessage(messages.notStartedAlt)}>
              <Icon
                data-testid="ExpiryTime-unavailable-icon"
                name="status-stopped"
              />
            </span>
          </Box>
          {formatMessage(messages.expiryTimeDefaultString)}
        </Box>
      )}
    </>
  );
};

export default ExpireTimeItem;

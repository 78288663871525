import React, { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import { isEmpty } from 'ramda';
import { Mode } from '@amzn/awsui-global-styles';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

import {
  useHelpPanelContext,
  useThemeContext,
  useTourContext,
} from '../../contexts';
import TourTooltip from './TourTooltip';
import tourSteps from './tourSteps';

import './Tour.scss';

const Tour = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { tourState, continueTour, endTour } = useTourContext();
  const [lastFocused, setLastFocused] = useState();
  const [currentTheme] = useThemeContext();
  const { showHelpPanel, setShowHelpPanel } = useHelpPanelContext();

  const handleTourCallback = ({ action, index, type, status }) => {
    const { labPath, startPath } = tourState;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (!isEmpty(labPath) && index === 0) history.replace(tourState.labPath);
      // show help panel for next step if not already showing
      if (index === 2 && !showHelpPanel) setShowHelpPanel(true);

      continueTour({ stepIndex: index, tourAction: action });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      endTour();
      setLastFocused();
      setShowHelpPanel(false);
      if (!isEmpty(startPath)) history.replace(startPath);
    }
  };

  return (
    <Joyride
      run={tourState.isRunning}
      floaterProps={{
        disableAnimation: true,
      }}
      styles={{
        options: {
          arrowColor: awsui.colorBackgroundContainerContent,
          overlayColor:
            currentTheme === Mode.Dark
              ? 'rgba(255,255,255, 0.8)'
              : 'rgba(0,0,0, 0.8)',
          zIndex: 5000, // Polaris likes to use big numbers, le sigh
        },
        // Fixes scroll issue related to height
        // github.com/gilbarbara/react-joyride/issues/603
        overlay: { height: '100vh' },
      }}
      disableOverlayClose={true}
      disableScrolling={true}
      disableCloseOnEsc={true}
      continuous={true}
      callback={handleTourCallback}
      steps={tourSteps(formatMessage)}
      stepIndex={tourState.stepIndex}
      beaconComponent={forwardRef(() => null)} // Beacon is never used but was messing with page scroll
      tooltipComponent={props => (
        <TourTooltip
          {...props}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
        />
      )}
    />
  );
};

Tour.propTypes = {};

export default Tour;

import { Box } from '@amzn/awsui-components-react';
import React from 'react';
import { useIntl } from 'react-intl';

import messages from './TnCLink.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import logoImage from '../../images/tc_logo.png';

const TnCLink = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <Box margin={{ right: 'l' }}>
      <a href="//aws.amazon.com/training">
        <img
          alt={formatMessage(messages.tcLogoAlt)}
          src={logoImage}
          data-testid={dataTestId['footer-tncLogo']}
        />
        {children}
      </a>
    </Box>
  );
};

export default TnCLink;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  welcomeTitle: {
    id: 'tourSteps.welcomeTitle',
    defaultMessage: 'Let us help you get started!',
  },
  firstMessage: {
    id: 'tourSteps.firstMessage',
    defaultMessage: 'Take this quick guided tour to learn:',
  },
  tourContent: {
    id: 'tourSteps.tourContent',
    defaultMessage:
      '<ul><li>How to launch and complete a lab</li><li>Where to find help</li><li>How to change your user preferences</li></ul>',
  },
  restart: {
    id: 'tourSteps.restart',
    defaultMessage:
      'You can access this guided tour whenever you need it from the help panel, which can be opened by selecting the <s>Help</s> button in the footer.',
  },
  finalTitle: {
    id: 'tourSteps.finalTitle',
    defaultMessage: "You're ready to start learning!",
  },
  final: {
    id: 'tourSteps.final',
    defaultMessage: 'We hope you enjoy your Self-Paced Labs experience!',
  },
  tip: {
    id: 'tourSteps.tip',
    defaultMessage: '<badge>Tip</badge>',
  },

  // Lab Content
  labContentTitle: {
    id: 'tourSteps.labContentTitle',
    defaultMessage: 'Lab instructions',
  },
  labContent1: {
    id: 'tourSteps.labContent1',
    defaultMessage:
      'The lab instructions will guide you through the lab step-by-step.',
  },

  // Lab Actions
  labHeaderTitle: {
    id: 'tourSteps.labHeaderTitle',
    defaultMessage: 'Lab header',
  },
  labHeader1: {
    id: 'tourSteps.labHeader1',
    defaultMessage:
      'The lab header is where you’ll find the buttons to start the lab, open the AWS console after your lab launches, and end the lab.',
  },
  labHeader2: {
    id: 'tourSteps.labHeader2',
    defaultMessage:
      'You’ll do your lab in a sandbox AWS account after the lab launches (no charges to your personal or business accounts).',
  },

  // Help panel
  helpTitle: {
    id: 'tourSteps.helpTitle',
    defaultMessage: 'Help panel',
  },
  help1: {
    id: 'tourSteps.help1',
    defaultMessage:
      'Find extensive documentation and training resources in the help panel.',
  },
  help2: {
    id: 'tourSteps.help2',
    defaultMessage:
      'This panel can be opened by selecting the <s>Help</s> button in the footer.',
  },

  // UI Buttons
  liveSupportTitle: {
    id: 'tourSteps.liveSupportTitle',
    defaultMessage: 'Live support',
  },
  liveSupport: {
    id: 'tourSteps.liveSupport',
    defaultMessage:
      'Select <s>Live support</s> to get real-time help from a support agent.',
  },
  localeTitle: {
    id: 'tourSteps.localeTitle',
    defaultMessage: 'Language menu',
  },
  locale: {
    id: 'tourSteps.locale',
    defaultMessage:
      "Select your preferred language for the site's content via the language dropdown menu.",
  },
  userTitle: {
    id: 'tourSteps.userTitle',
    defaultMessage: 'User menu',
  },
  user: {
    id: 'tourSteps.user',
    defaultMessage:
      'Select light or dark theme and set other preferences in the user menu.',
  },
  feedbackTitle: {
    id: 'tourSteps.feedbackTitle',
    defaultMessage: 'Site feedback',
  },
  feedback: {
    id: 'tourSteps.feedback',
    defaultMessage: "Select <s>Feedback</s> to let us know how we're doing.",
  },
});

export default messages;

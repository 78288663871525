import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  HelpPanel,
  Button,
  Checkbox,
  ExpandableSection,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  both,
  isNil,
  complement,
  includes,
  isEmpty,
  map,
  mapObjIndexed,
  propSatisfies,
  uniq,
} from 'ramda';
import { useHistory } from 'react-router-dom';

import LoadingSkeleton from '../LoadingSkeleton';
import { getQueryParam, updateQueryParam } from '../../utils/queryParamUtils';
import {
  publishButtonClickMetric,
  publishCounterMetric,
} from '../../utils/metrics';
import {
  filterDefinitions,
  filterJoinType,
  catalogMetricsNames,
} from '../../constants/catalog';

import messages from './CatalogFilterPanel.messages';

const LoadingPlaceholderBox = ({ width, height, testId }) => (
  <Box margin={{ vertical: 'xs' }}>
    <LoadingSkeleton width={width} height={height} testId={testId} />
  </Box>
);

const LoadingPlaceholder = (
  <>
    <LoadingPlaceholderBox
      width={90}
      height={20}
      testId="catalogFilter-loadingSkeleton"
    />
    <LoadingPlaceholderBox width={50} height={15} />
    <LoadingPlaceholderBox width={50} height={15} />
    <LoadingPlaceholderBox width={50} height={15} />
    <LoadingPlaceholderBox width={90} height={20} />
    <LoadingPlaceholderBox width={50} height={15} />
    <LoadingPlaceholderBox width={50} height={15} />
    <LoadingPlaceholderBox width={50} height={15} />
  </>
);

const filterKeys = Object.keys(filterDefinitions);

/**
 * Creates an object mapping { FILTER_TYPE: [{id: filter value, label: filter display name}] }
 * @param {array} blueprints
 * @param {function} formatMessage
 */
const getFilters = (blueprints, formatMessage) => {
  const filterValues = blueprints.reduce((filterValuesAcc, blueprint) => {
    /* 
      For each possible filter field:
        1)  Initialize an empty array if it doesn't exist already
        2)  If the current blueprint is not empty add the value(s)
            to the filter field array
    */
    for (const curFilter of filterKeys) {
      if (!filterValuesAcc[curFilter]) filterValuesAcc[curFilter] = [];
      filterValuesAcc[curFilter] = filterDefinitions[
        curFilter
      ].appendFilterData(filterValuesAcc[curFilter], blueprint);
    }
    return filterValuesAcc;
  }, {});
  // Dedupe the values for each filter field
  const uniqFilterValues = map(filter => uniq(filter), filterValues);
  // Map each filter field value to an object with an id and a formatted label.
  // Then sort that array based on the sort method provided for that filter field. We
  // need to map first because some fields are sorted by their display names.
  const sortedFilterValues = mapObjIndexed(
    (filter, key) =>
      filter
        .map(val => ({
          id: val,
          label: formatMessage(filterDefinitions[key].getLabel(val)),
        }))
        .sort(filterDefinitions[key].sortCompare),
    uniqFilterValues
  );
  return sortedFilterValues;
};
/**
 * Prepopulate the activeFilters list with values from the query string, if there are any.
 * @return {object} { FILTER_NAME: [string] }
 */
const getInitialActiveFilters = history =>
  filterKeys.reduce((filters, curFilter) => {
    filters[curFilter] = getQueryParam(history.location.search, curFilter)
      .split(',')
      .filter(elem => elem !== '');
    return filters;
  }, {});
/**
 * Expand any sections that are open by default or that contain selected checkboxes
 * @param {object} activeFilters
 * @returns {object} { FILTER_NAME: boolean }
 */
const getInitialSectionExpanded = activeFilters =>
  filterKeys.reduce((expanded, curFilter) => {
    expanded[curFilter] =
      filterDefinitions[curFilter].expandedByDefault ||
      activeFilters[curFilter].length > 0;
    return expanded;
  }, {});
/**
 * Creates a logic function that when passed a blueprint will return either true
 * or false, depending on if the blueprint should be shown.
 * @param {object} activeFilters
 * @returns {function}
 */
export const getMatchesFilterQuery = activeFilters =>
  filterJoinType(
    filterKeys.reduce((internalLogic, filter) => {
      if (!isEmpty(activeFilters[filter])) {
        return [
          ...internalLogic,
          filterDefinitions[filter].joinType(
            activeFilters[filter].map(value =>
              propSatisfies(both(complement(isNil), includes(value)), filter)
            )
          ),
        ];
      }
      return internalLogic;
    }, [])
  );
/**
 * Updates the query search parameters with the active filters
 * @param {object} activeFilters
 * @param {object} history
 */
const updateFilterQueryParams = (activeFilters, history) => {
  const urlParamObj = new URLSearchParams(history.location.search);
  for (const filter of filterKeys) {
    if (!isEmpty(activeFilters[filter]))
      updateQueryParam(
        null,
        filter,
        activeFilters[filter].join(','),
        urlParamObj
      );
    else urlParamObj.delete(filter);
  }
  history.replace({
    pathname: history.location.pathname,
    search: urlParamObj.toString(),
  });
};

const CatalogFilterPanel = ({ catalogList, setRefinerState }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [activeFilters, setActiveFilters] = useState(
    getInitialActiveFilters(history)
  );
  const [sectionExpanded, setSectionExpanded] = useState(
    getInitialSectionExpanded(activeFilters)
  );

  const filters = useMemo(
    () => getFilters(catalogList, formatMessage),
    [catalogList, formatMessage]
  );

  const clearFilters = () => {
    publishButtonClickMetric(catalogMetricsNames.filterPanelReset);
    setActiveFilters(
      filterKeys.reduce((filters, curFilter) => {
        filters[curFilter] = [];
        return filters;
      }, {})
    );
  };
  const toggleSection = filter => {
    publishCounterMetric(catalogMetricsNames.filterPanelSectionToggle, {
      counterName: filter,
    });
    setSectionExpanded(prevState => ({
      ...prevState,
      [filter]: !prevState[filter],
    }));
  };
  const toggleCheckbox = (filter, value) => {
    publishCounterMetric(catalogMetricsNames.filterPanelCheckboxToggle, {
      counterName: value,
    });

    if (activeFilters[filter].includes(value)) {
      setActiveFilters(prevState => ({
        ...prevState,
        [filter]: prevState[filter].filter(curVal => curVal !== value),
      }));
    } else {
      setActiveFilters(prevState => ({
        ...prevState,
        [filter]: [...prevState[filter], value],
      }));
    }
  };
  useEffect(() => {
    setRefinerState(prevState => ({
      ...prevState,
      matchesFilterQuery: getMatchesFilterQuery(activeFilters),
    }));
    updateFilterQueryParams(activeFilters, history);
  }, [activeFilters, history, setRefinerState]);

  return (
    <HelpPanel header={<h2>{formatMessage(messages.header)}</h2>}>
      <Button variant="link" onClick={clearFilters}>
        {formatMessage(messages.resetFiltersButton)}
      </Button>
      {catalogList.length === 0
        ? LoadingPlaceholder
        : Object.keys(filters).map(filter => {
            const filterDef = filterDefinitions[filter];
            return filters[filter].length > 0 ? (
              <ExpandableSection
                key={formatMessage(filterDef.title)}
                headerText={formatMessage(filterDef.title)}
                headingTagOverride="h3"
                expanded={sectionExpanded[filter]}
                onChange={() => toggleSection(filter)}
              >
                {filters[filter].map(value => {
                  return (
                    <Checkbox
                      key={value.id}
                      checked={activeFilters[filter].includes(value.id)}
                      onChange={() => toggleCheckbox(filter, value.id)}
                    >
                      {value.label}
                    </Checkbox>
                  );
                })}
              </ExpandableSection>
            ) : null;
          })}
    </HelpPanel>
  );
};

CatalogFilterPanel.propTypes = {
  catalogList: PropTypes.array.isRequired,
  setRefinerState: PropTypes.func.isRequired,
};

export default CatalogFilterPanel;

import React from 'react';
import { useIntl } from 'react-intl';
import { path } from 'ramda';

import { ButtonDropdownOverride } from '../PolarisOverrides';
import {
  publishButtonClickMetric,
  publishCounterMetric,
} from '../../utils/metrics';
import { TOUR_IDS } from '../../constants/siteTour';
import { LOCALES } from '../../constants/locale';
import { useLocaleContext } from '../../contexts';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './LocaleMenu.messages';
import { getLabArnFromPath } from '../../utils/urlUtils';

const publishMenuMetrics = (menuTitle, itemId) =>
  publishButtonClickMetric(`${menuTitle}:${itemId}`, {
    currentPath: window.location.pathname,
    clickLocation: 'Header',
  });

const publishLocaleMetrics = (locale, selectedLocale) => {
  const additionalMetrics = {
    fromLocale: locale,
    locale: selectedLocale,
  };
  const labArn = getLabArnFromPath(window.location.pathname);
  if (labArn) {
    additionalMetrics.blueprintArn = labArn;
  }
  publishCounterMetric(`LocaleSelected:${selectedLocale}`, {
    additionalMetrics,
  });
};

const getLocaleItems = locale => {
  const items = Object.values(LOCALES).map(({ id, label }) => ({
    text: label,
    id: id,
    lang: id,
  }));

  return items.map(item => ({ ...item, disabled: locale === item.id }));
};

const LocaleMenu = () => {
  const { formatMessage } = useIntl();
  const [locale, setLocale] = useLocaleContext();

  return (
    <ButtonDropdownOverride
      variant="transparent"
      activeDisabled
      items={getLocaleItems(locale)}
      onItemClick={event => {
        const selectedLocale = path(['detail', 'id'], event);
        publishMenuMetrics('LocaleMenu', selectedLocale);
        publishLocaleMetrics(locale, selectedLocale);
        setLocale(selectedLocale);
      }}
      data-testid={dataTestId['header-localeMenu']}
      data-tourid={TOUR_IDS.localeMenu}
    >
      <i aria-hidden="true" className="fas fa-globe" />{' '}
      <span className="awsui-util-hide">
        {formatMessage(messages.selectLanguage)}{' '}
        {formatMessage(messages.currentSelection)}
      </span>
      {LOCALES[locale].label}
    </ButtonDropdownOverride>
  );
};

LocaleMenu.propTypes = {};

export default LocaleMenu;

/**
 * @constant {Object}
 */
export const ERROR_TYPES = {
  BadRequest: '400',
  NotFound: '404',
  Conflict: '409',
  Unauthorized: 'Unauthorized',
  Forbidden: 'Forbidden',
  NotAuthorizedInACL: 'NotAuthorizedInACL',
  EmailValidationRequired: 'EmailValidationRequired',
  ClosedEvent: 'ClosedEvent',
  ProvisioningError: 'ProvisioningError',
  MissingLabStatus: 'MissingLabStatus',
  UnknownLabStatus: 'UnknownLabStatus',
  LabEnded: 'LabEnded',
  LabExpired: 'LabExpired',
  LabCancelled: 'LabCancelled',
  NoActiveLab: 'NoActiveLab',
  SessionAlreadyExistsError: 'SessionAlreadyExistsError',
};

export default ERROR_TYPES;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  expiryTimeDefaultString: {
    id: 'expireTimeItem.expiryTimeDefaultString',
    defaultMessage: 'Not started',
  },
  expiryTime: {
    id: 'expireTimeItem.expiryTime',
    defaultMessage: 'Expires {expiryDate} at {expiryTime}',
  },
  inProgressAlt: {
    id: 'expireTimeItem.inProgressAlt',
    defaultMessage: 'In Progress',
  },
  notStartedAlt: {
    id: 'expireTimeItem.notStartedAlt',
    defaultMessage: 'Not Started',
  },
});

export default messages;

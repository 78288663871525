import { defineMessages } from 'react-intl';

const messages = defineMessages({
  100: {
    id: 'levels.fundamental',
    defaultMessage: 'Fundamental',
  },
  200: {
    id: 'levels.intermediate',
    defaultMessage: 'Intermediate',
  },
  300: {
    id: 'levels.advanced',
    defaultMessage: 'Advanced',
  },
});

export default messages;

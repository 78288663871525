import { defineMessages } from 'react-intl';

const messages = defineMessages({
  content: {
    id: 'closedState.content',
    defaultMessage:
      'Contact support to request additional budget. Include the lab URL in your request.',
  },
  copiedUrl: {
    id: 'closedState.copiedUrl',
    defaultMessage: 'Copied URL',
  },
  copyUrl: {
    id: 'closedState.copyUrl',
    defaultMessage: 'Copy URL',
  },
  contactSupport: {
    id: 'closedState.contactSupport',
    defaultMessage: 'Contact Support',
  },
});

export default messages;

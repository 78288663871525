import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reload: {
    id: 'heroBanner.reload',
    defaultMessage: 'Reload page',
  },
  loading: {
    id: 'heroBanner.loading',
    defaultMessage: 'Loading lab title ...',
  },

  tag: {
    id: 'heroBanner.tag',
    defaultMessage: 'Featured content',
  },
  action: {
    id: 'heroBanner.action',
    defaultMessage: 'Get Started',
  },
});

export default messages;

import React, { useState } from 'react';
import { Box } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './Feedback.messages';
import './FeedbackStarRating.scss';

const defaultIconClasses = Array(5).fill('far');

const FeedbackStarRating = ({ name, csat, onChange }) => {
  const { formatMessage } = useIntl();
  const [iconClasses, setIconClasses] = useState(defaultIconClasses);
  const [selectedRating, setSelectedRating] = useState();

  const handleOnClick = rating => {
    setSelectedRating(rating);
    setIconClasses([...defaultIconClasses].fill('fas', 0, rating));
    onChange({
      name,
      value: csat[rating],
    });
  };

  return (
    <Box margin={{ top: 'xxs' }} className="FeedbackStarRating__Wrapper">
      {Array.from(Array(5).keys()).map(idx => {
        const rating = idx + 1;
        return (
          <button
            className="FeedbackStarRating"
            key={rating}
            aria-label={formatMessage(messages.rating, {
              number: rating,
              star:
                rating > 1
                  ? formatMessage(messages.starPlural)
                  : formatMessage(messages.starSingular),
            })}
            aria-pressed={rating === selectedRating}
            onClick={() => handleOnClick(rating)}
          >
            <i className={`${iconClasses[idx]} fa-star fa-2x`} />
          </button>
        );
      })}

      {selectedRating && (
        <button
          className="FeedbackStarRating__link"
          onClick={() => {
            setSelectedRating(null);
            setIconClasses(defaultIconClasses);
            onChange({
              name,
              value: csat[0],
            });
          }}
        >
          {formatMessage(messages.clear)}
        </button>
      )}
    </Box>
  );
};

FeedbackStarRating.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  csat: PropTypes.array.isRequired,
};

export default FeedbackStarRating;

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { path } from 'ramda';

import { EHCollection } from '../models';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import { retry } from '../utils/promiseUtils';
import { graphql } from '../utils/graphQLService';
import { isOnline } from '../utils/envUtil';

const metricsNamespace = 'GetCollection';

const getCollection = arn => {
  const fieldName = 'getCollection';
  const collectionQuery = `
  query GetCollection($arn: ID!) {
    ${fieldName}(arn: $arn) {
      arn
      availability
      blueprints {
        blueprints {
          blueprint {
            arn
            availability
            certifications
            duration
            type
            level
            locales {
              title
              description
              locale
            }
            services
          }
        }
      }
      createdAt
      locales {
        title
        description
        locale
      }
    }
  }
`;
  return retry(() => graphql(collectionQuery, { arn }, { timeoutMs: 10000 }), {
    retries: 3,
    interval: 100,
  }).then(path(['data', fieldName]));
};

// Cached for a long time since the catalog points to blueprint default versions.
// There is no data in a collection we expect to change for the same version, ex
// adding/removing a lab will result in a new collection version.
// When expiring the data will be garbage collected if not currently used.
const COLLECTION_DATA_CACHE_MS = 1000 * 60 * 60 * 12;
// How long to wait before considering refetching in the background.
const COLLECTION_DATA_STALE_MS = 1000 * 60 * 60 * 2;

const useCollection = ({ arn, metricsPublisher = null, options = {} }) => {
  const { onError, ...restOptions } = options;
  const { data: rawData, ...rest } = useQuery({
    queryKey: ['getCollection', arn],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getCollection(arn),
        metricsPublisher
      ),
    enabled: isOnline(),
    retry: false,
    staleTime: COLLECTION_DATA_STALE_MS,
    cacheTime: COLLECTION_DATA_CACHE_MS,
    onError: error => {
      publishFatal(metricsNamespace, error, { arn });
      if (onError) onError(error);
    },
    ...restOptions,
  });
  const data = useMemo(() => new EHCollection(rawData), [rawData]);

  return { data, ...rest };
};

export default useCollection;

import { useQuery } from 'react-query';

import {
  getLabAssessment,
  labAssessmentQueryKey,
} from '../utils/assessmentsService';
import { interceptRequestMetrics } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';

// How long to wait before considering refetching in the background.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const metricsNamespace = 'GetLabAssessment';

/**
 * @typedef {import('../utils/assessmentsService').LabAssessment} LabAssessment
 */

/**
 * @param {Object} props
 * @param {string} [props.labId] - Ongoing lab id
 * @param {string} [props.assessmentId] - Ongoing lab assessment id
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @returns {import('react-query').UseQueryResult<LabAssessment>}
 */
const useGetLabAssessment = ({
  labId,
  assessmentId,
  metricsPublisher,
} = {}) => {
  return useQuery({
    enabled: !!labId && !!assessmentId,
    queryKey: labAssessmentQueryKey(labId, assessmentId),
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getLabAssessment(labId, assessmentId),
        metricsPublisher
      ),
    staleTime: FIVE_MIN_IN_MS,
    refetchOnWindowFocus: 'always',
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [
            ERROR_TYPES.BadRequest,
            ERROR_TYPES.NotFound,
            ERROR_TYPES.Conflict,
          ],
        })
      ) {
        return false;
      }
      return failureCount < 3;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 500 }),
  });
};

export default useGetLabAssessment;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'catalogFilterPanel.header',
    defaultMessage: 'Refine your search',
  },
  resetFiltersButton: {
    id: 'catalogFilterPanel.resetFiltersButton',
    defaultMessage: 'Clear all filters',
  },
  certifications: {
    id: 'catalogFilterPanel.certifications',
    defaultMessage: 'Certification prep',
  },
  duration: {
    id: 'catalogFilterPanel.duration',
    defaultMessage: 'Duration',
  },
  levels: {
    id: 'catalogFilterPanel.levels',
    defaultMessage: 'Level',
  },
  services: {
    id: 'catalogFilterPanel.services',
    defaultMessage: 'AWS product',
  },
});

export default messages;

import { defineMessages } from 'react-intl';

export const flashbarI18nStrings = defineMessages({
  error: {
    id: 'flashbarI18nStrings.error',
    defaultMessage: 'Error',
  },
  inProgress: {
    id: 'flashbarI18nStrings.inProgress',
    defaultMessage: 'In progress',
  },
  info: {
    id: 'flashbarI18nStrings.info',
    defaultMessage: 'Info',
  },
  success: {
    id: 'flashbarI18nStrings.success',
    defaultMessage: 'Success',
  },
  warning: {
    id: 'flashbarI18nStrings.warning',
    defaultMessage: 'Warning',
  },
});

import React, { forwardRef } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import './ButtonOverride.scss';

const ButtonDropdownOverride = ({ variant, activeDisabled, ...props }, ref) => {
  if (variant === 'transparent')
    return (
      <span
        className={`ButtonDropdownOverride--transparent${
          activeDisabled ? ' ButtonDropdownOverride--activeDisabled' : '' // active prop only used for transparent variant
        }`}
      >
        <ButtonDropdown ref={ref} {...props} />
      </span>
    );

  return <ButtonDropdown variant={variant} ref={ref} {...props} />;
};

export default forwardRef(ButtonDropdownOverride);

import { useIntl } from 'react-intl';

import APP_LAYOUT_LABELS from '../i18n/appLayout.messages';

const useAppLayoutLabels = () => {
  const { formatMessage } = useIntl();
  let formattedLabels = {};
  for (const label in APP_LAYOUT_LABELS) {
    formattedLabels[label] = formatMessage(APP_LAYOUT_LABELS[label]);
  }
  return formattedLabels;
};

export default useAppLayoutLabels;

import { path } from 'ramda';
import { graphql } from './graphQLService';

/**
 * Triggers any initialization tasks for EH stack
 *
 * @returns {Promise<string>}
 *
 */
export const initialize = () => {
  const fieldName = 'initialize';
  const initializeGql = `
    mutation Initialize {
      ${fieldName}
    }
  `;
  return graphql(initializeGql).then(path(['data', fieldName]));
};

import React from 'react';
import { AppLayout } from '@amzn/awsui-components-react';

import { HeaderWrapper, HeaderLoading } from '../components/Header';
import Loader from '../components/Loader';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import AppNotifications from '../components/AppNotifications';
import Main from '../components/Main';

const LoadingPage = () => {
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  return (
    <>
      <HeaderWrapper>
        <HeaderLoading />
      </HeaderWrapper>
      <AppLayout
        content={
          <Main>
            <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
              <Loader loading />
            </div>
          </Main>
        }
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        stickyNotifications={true}
        notifications={<AppNotifications />}
        navigationHide={true}
        toolsHide={true}
        ariaLabels={APP_LAYOUT_LABELS}
      />
    </>
  );
};

LoadingPage.propTypes = {};

export default LoadingPage;

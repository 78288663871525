/**
 * @param {string} buttonType Type of button to match. 'startLab', 'openConsole', 'endLab'
 * @returns {string} styles to match T&C inline markdown styles for button types
 */
export const matchPatternType = buttonType => {
  const types = {
    startLab: `1d976c`,
    openConsole: `16191f`,
    endLab: `df3312`,
  };

  return `background-color:#${types[buttonType]}; font-family:Amazon Ember; font-weight:400; font-size:14px; color:#ffffff; padding: 5px 15px`;
};

import React from 'react';
import { Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import LoadingSkeleton from '../LoadingSkeleton';
import appMessages from '../../i18n/app.messages';

const LabMarkdownLoading = () => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <span className="awsui-util-hide">
        {formatMessage(appMessages.loading)}
      </span>
      <SpaceBetween direction="vertical" size="l">
        <LoadingSkeleton width={60} height={40} />
        <LoadingSkeleton width={10} height={20} />
        <LoadingSkeleton width={90} height={20} />
        <LoadingSkeleton width={80} height={20} />
        <LoadingSkeleton width={40} height={30} />
        <LoadingSkeleton width={80} height={400} />
        <LoadingSkeleton width={40} height={30} />
        <LoadingSkeleton width={80} height={400} />
      </SpaceBetween>
    </Container>
  );
};

export default LabMarkdownLoading;

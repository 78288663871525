import React from 'react';
import { useIntl } from 'react-intl';

import { ButtonOverride } from '../PolarisOverrides';
import FooterEventLink from './FooterEventLink';
import FooterLayout from './FooterLayout';
import messages from './LegalZone.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import { EXTERNAL_LINKS } from '../../constants/externalLinks';
import appMessages from '../../i18n/app.messages';
import CookiePrefsButton from './CookiePrefsButton';
import LiveSupport from '../LiveSupport/LiveSupport';

const PrivacyBtn = () => {
  const { formatMessage } = useIntl();
  return (
    <ButtonOverride
      variant="transparent"
      href={EXTERNAL_LINKS.awsPrivacyLink}
      target="_blank"
      data-testid={dataTestId['footer-privacy']}
      ariaLabel={`${formatMessage(messages.privacy)} ${formatMessage(
        appMessages.externalIconAriaLabel
      )}`}
    >
      {formatMessage(messages.privacy)}
    </ButtonOverride>
  );
};

const SiteTermsBtn = () => {
  const { formatMessage } = useIntl();
  return (
    <ButtonOverride
      variant="transparent"
      href={EXTERNAL_LINKS.awsSiteTermsLink}
      target="_blank"
      data-testid={dataTestId['footer-siteTerms']}
      ariaLabel={`${formatMessage(messages.siteTerms)} ${formatMessage(
        appMessages.externalIconAriaLabel
      )}`}
    >
      {formatMessage(messages.siteTerms)}
    </ButtonOverride>
  );
};

const FooterSharedLandingPage = () => {
  const left = (
    <>
      <LiveSupport />
      <CookiePrefsButton />
    </>
  );

  const right = (
    <>
      <PrivacyBtn />
      <SiteTermsBtn />
      <FooterEventLink />
    </>
  );

  // items always shown in footer on mobile view
  const mobileItems = (
    <>
      <LiveSupport />
    </>
  );

  // items inside footer mobile menu
  const mobileCollapsed = (
    <>
      <CookiePrefsButton />
      <PrivacyBtn />
      <SiteTermsBtn />
      <FooterEventLink />
    </>
  );

  return (
    <FooterLayout
      left={left}
      right={right}
      mobileItems={mobileItems}
      mobileCollapsed={mobileCollapsed}
      isSticky={false}
      testid={'footer-FooterFull'}
    />
  );
};

FooterSharedLandingPage.propTypes = {};

export default FooterSharedLandingPage;

import { useQuery } from 'react-query';
import { path } from 'ramda';

import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import { graphql } from '../utils/graphQLService';
import {
  LAB_FUNCTION_POLL_INTERVAL,
  LAB_FUNCTION_STATUS,
} from '../constants/labFunctionExecution';
import { setRefetchInterval } from '../utils/reactQueryUtils';
import { EHLabEvaluation } from '../models';
import metrics, {
  interceptRequestMetrics,
  publishCounterMetric,
  publishFatal,
} from '../utils/metrics';
import ERROR_TYPES from '../constants/errorTypes';

const metricsNamespace = 'GetLabEvaluation';

const publishResultMetric = (counterName, result) => {
  publishCounterMetric(metricsNamespace, {
    counterName,
    additionalMetrics: {
      labId: result?.labId || 'NA',
      executionId: result?.executionId || 'NA',
      functionName: result?.functionName || 'NA',
    },
  });
};

/**
 * Emits metrics for unexpected results in successful getLabEvaluation responses
 */
const emitResultMetrics = result => {
  const { status, locales, percentageScore } = result;
  if (status === LAB_FUNCTION_STATUS.ENDED) {
    if (!locales?.length > 0 || !locales[0]?.content) {
      publishResultMetric('MissingMarkdown', result);
    }
    if (percentageScore === null) {
      publishResultMetric('MissingScore', result);
    }
  }

  if (status === LAB_FUNCTION_STATUS.ERROR) {
    publishResultMetric('ErrorStatus', result);
    metrics.recordRumError(
      'LabEvaluationStatusERROR',
      new Error(
        `functionName:${result?.functionName},executionId:${result?.executionId},labId:${result?.labId}`
      )
    );
  }
};

const getLabEvaluationQuery = (labId, executionId) => {
  const fieldName = 'getLabEvaluation';
  const gql = `
    query GetLabEvaluation($labId: String!, $executionId: String!) {
      ${fieldName}(labId: $labId, executionId: $executionId) {
        arn
        endedOn
        executionId
        functionName
        locales {
          content
          locale
        }
        score {
          comment
          scoreGiven
          scoreMaximum
          timestamp
        }
        startedOn
        status
      }
    }`;

  return graphql(gql, { labId, executionId }).then(
    payload => new EHLabEvaluation(path(['data', fieldName], payload))
  );
};

/**
 * Gets the status of a lab function evaluation. The status will poll until
 * finished or until it fails.
 *
 * @param {string} labId
 * @param {string} executionId
 * @param {import('react-query').UseQueryOptions} [options]
 * @returns {import('react-query').UseQueryResult<EHLabEvaluation>}
 */
const usePollGetLabEvaluation = (labId, executionId, options = {}) => {
  const { onSuccess, onError, ...restOptions } = options;
  const queryKey = ['getLabEvaluation', executionId];
  const isEnabled = Boolean(executionId);

  const { data, ...rest } = useQuery({
    queryKey,
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getLabEvaluationQuery(labId, executionId)
      ),
    enabled: isEnabled,
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [ERROR_TYPES.BadRequest, ERROR_TYPES.NotFound],
        })
      ) {
        return false;
      }
      return failureCount <= 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 500 }),
    refetchInterval: data => {
      if (!data?.status) return false;
      if (data.status === LAB_FUNCTION_STATUS.RUNNING)
        return setRefetchInterval(LAB_FUNCTION_POLL_INTERVAL);
      return false;
    },
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    // The data should never be considered stale for the same cache key, it's immutable
    staleTime: Infinity,
    // Cache the result for as long as the app is running, we'll manually clean up if needed
    cacheTime: Infinity,
    onSuccess: result => {
      emitResultMetrics(result);
      if (onSuccess) onSuccess(result);
    },
    onError: error => {
      publishFatal(metricsNamespace, error, { labId, executionId });
      if (onError) onError(error);
    },
    ...restOptions,
  });

  return {
    ...rest,
    data,
  };
};

export default usePollGetLabEvaluation;

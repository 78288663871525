import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { labStatuses } from '../../../contexts';
import {
  StartLabButton,
  EndLabButton,
  OpenConsoleButton,
  OpenLabEvaluationPanelButton,
  LabProgressTrackingButton,
} from '../LabButtons';
import messages from './LabActions.messages';

const LabActions = ({
  isAuthenticated,
  status,
  onStartLab,
  onStopLab,
  onOpenConsole,
  isOngoingLab,
  hasQueriedOngoing,
  hasNoCredits,
  isLabUnderMaintenance,
  isGettingLabSession,
  isStartingLab,
  isStoppingLab,
  isUnderConcurrentLabsLimit,
  hasLabEvaluation,
  hasCheckedLabEvaluationScore,
  hasSubmittedLabEvaluationScore,
  hasSubmissionDisabled,
  isLabEvaluationPanelShown,
  onOpenLabEvaluationPanel,
  currentEvalSubmissionStatus,
}) => {
  const { formatMessage } = useIntl();

  // Disable actions until ongoing lab is checked.
  if (!hasQueriedOngoing) {
    return (
      <>
        <OpenLabEvaluationPanelButton
          onClick={onOpenLabEvaluationPanel}
          hasLabEvaluation={hasLabEvaluation}
          isLabEvaluationPanelShown={isLabEvaluationPanelShown}
        />
        <StartLabButton disabled={true} />
      </>
    );
  }

  // Return actions for a lab that has not started yet
  if (!isOngoingLab) {
    if (!isUnderConcurrentLabsLimit || hasNoCredits || isLabUnderMaintenance)
      return (
        <>
          <OpenLabEvaluationPanelButton
            onClick={onOpenLabEvaluationPanel}
            hasLabEvaluation={hasLabEvaluation}
            isLabEvaluationPanelShown={isLabEvaluationPanelShown}
          />
          <Popover
            position="left"
            dismissButton={false}
            triggerType="custom"
            content={
              !isUnderConcurrentLabsLimit
                ? formatMessage(messages.disabledTooltipOngoing)
                : isLabUnderMaintenance
                  ? formatMessage(messages.labUnderMaintenanceTooltip)
                  : formatMessage(messages.disabledTooltipCredits)
            }
          >
            <StartLabButton disabled={true} />
          </Popover>
        </>
      );

    return (
      <>
        <OpenLabEvaluationPanelButton
          onClick={onOpenLabEvaluationPanel}
          hasLabEvaluation={hasLabEvaluation}
          isLabEvaluationPanelShown={isLabEvaluationPanelShown}
        />
        <StartLabButton
          isAuthenticated={isAuthenticated}
          onClick={onStartLab}
          disabled={isStartingLab}
          loading={isStartingLab}
        />
      </>
    );
  }

  // User is on a active Lab page, show actions.
  switch (status) {
    case labStatuses.PROVISIONING:
      return (
        <>
          <OpenLabEvaluationPanelButton
            onClick={onOpenLabEvaluationPanel}
            hasLabEvaluation={hasLabEvaluation}
            isLabEvaluationPanelShown={isLabEvaluationPanelShown}
          />
          <EndLabButton
            onClick={onStopLab}
            hasLabEvaluation={hasLabEvaluation}
            hasCheckedLabEvaluationScore={hasCheckedLabEvaluationScore}
            hasSubmittedLabEvaluationScore={hasSubmittedLabEvaluationScore}
            hasSubmissionDisabled={hasSubmissionDisabled}
            loading={isStoppingLab}
            disabled={isStoppingLab}
          />
          <OpenConsoleButton
            disabled={true}
            loading={!isStoppingLab}
            isProvisioning={true}
          />
        </>
      );
    case labStatuses.ONGOING:
      return (
        <>
          <OpenLabEvaluationPanelButton
            onClick={onOpenLabEvaluationPanel}
            hasLabEvaluation={hasLabEvaluation}
            isLabEvaluationPanelShown={isLabEvaluationPanelShown}
          />
          <LabProgressTrackingButton
            hasLabEvaluation={hasLabEvaluation}
            currentEvalSubmissionStatus={currentEvalSubmissionStatus}
            disabled={isStoppingLab}
          />
          <EndLabButton
            onClick={onStopLab}
            hasLabEvaluation={hasLabEvaluation}
            hasCheckedLabEvaluationScore={hasCheckedLabEvaluationScore}
            hasSubmittedLabEvaluationScore={hasSubmittedLabEvaluationScore}
            hasSubmissionDisabled={hasSubmissionDisabled}
            loading={isStoppingLab}
            disabled={isStoppingLab}
          />
          <OpenConsoleButton
            onClick={onOpenConsole}
            disabled={isGettingLabSession || isStoppingLab}
            loading={isGettingLabSession && !isStoppingLab}
            isProvisioning={isGettingLabSession}
          />
        </>
      );
    case labStatuses.CLOSED:
      return (
        <>
          <OpenLabEvaluationPanelButton
            onClick={onOpenLabEvaluationPanel}
            hasLabEvaluation={hasLabEvaluation}
            isLabEvaluationPanelShown={isLabEvaluationPanelShown}
          />
          <EndLabButton
            onClick={onStopLab}
            hasLabEvaluation={hasLabEvaluation}
            hasCheckedLabEvaluationScore={hasCheckedLabEvaluationScore}
            hasSubmittedLabEvaluationScore={hasSubmittedLabEvaluationScore}
            hasSubmissionDisabled={hasSubmissionDisabled}
            loading={isStoppingLab}
            disabled={isStoppingLab}
          />
          <OpenConsoleButton disabled={true} />
        </>
      );
    case labStatuses.ERROR:
      return (
        <>
          <OpenLabEvaluationPanelButton
            onClick={onOpenLabEvaluationPanel}
            hasLabEvaluation={hasLabEvaluation}
            isLabEvaluationPanelShown={isLabEvaluationPanelShown}
          />
          <EndLabButton disabled={true} />
          <OpenConsoleButton disabled={true} />
        </>
      );
    default:
      return null;
  }
};

LabActions.propTypes = {
  status: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onStartLab: PropTypes.func.isRequired,
  onStopLab: PropTypes.func.isRequired,
  onOpenConsole: PropTypes.func.isRequired,
  isOngoingLab: PropTypes.bool.isRequired,
  hasQueriedOngoing: PropTypes.bool,
  hasNoCredits: PropTypes.bool,
  isLabUnderMaintenance: PropTypes.bool,
  isGettingLabSession: PropTypes.bool,
  isStartingLab: PropTypes.bool,
  isStoppingLab: PropTypes.bool,
  hasLabEvaluation: PropTypes.bool,
  hasCheckedLabEvaluationScore: PropTypes.bool,
  hasSubmittedLabEvaluationScore: PropTypes.bool,
  hasSubmissionDisabled: PropTypes.bool,
  isLabEvaluationPanelShown: PropTypes.bool,
  onOpenLabEvaluationPanel: PropTypes.func,
  currentEvalSubmissionStatus: PropTypes.string,
};

export default LabActions;

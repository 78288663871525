import { defineMessages } from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'catalogListItem.loading',
    defaultMessage: 'Loading lab information ...',
  },
  inProgress: {
    id: 'catalogListItem.inProgress',
    defaultMessage: 'In Progress',
  },
});

export default messages;

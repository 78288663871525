import React from 'react';
import { SplitPanel, TextContent } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './LabEvaluation.messages';

/**
 * The split panel container, used in AppLayout
 */
const EvaluationSplitPanel = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <SplitPanel
      header={<>{formatMessage(messages.splitPanelHeader)}</>}
      hidePreferencesButton={true}
      i18nStrings={{
        preferencesTitle: formatMessage(messages.preferencesTitle),
        preferencesPositionLabel: formatMessage(
          messages.preferencesPositionLabel
        ),
        preferencesPositionDescription: formatMessage(
          messages.preferencesPositionDescription
        ),
        preferencesPositionSide: formatMessage(
          messages.preferencesPositionSide
        ),
        preferencesPositionBottom: formatMessage(
          messages.preferencesPositionBottom
        ),
        preferencesConfirm: formatMessage(messages.preferencesConfirm),
        preferencesCancel: formatMessage(messages.preferencesCancel),
        closeButtonAriaLabel: formatMessage(messages.closeButtonAriaLabel),
        openButtonAriaLabel: formatMessage(messages.openButtonAriaLabel),
        resizeHandleAriaLabel: formatMessage(messages.resizeHandleAriaLabel),
      }}
    >
      <TextContent>{children}</TextContent>
    </SplitPanel>
  );
};
export default EvaluationSplitPanel;

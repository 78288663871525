import { useQuery } from 'react-query';

import { getSession } from '../utils/labsService';
import { interceptRequestMetrics } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';
import { labStatuses } from '../contexts';
import { CONSOLE_URL_TTL } from '../constants/lab';

// How long to wait before considering refetching in the background.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const metricsNamespace = 'GetSession';

/**
 * @typedef {import('../utils/labsService').Session} Session
 */

/**
 * @param {Object} props
 * @param {string} [props.labId] - Ongoing lab id
 * @param {string} [props.labStatus] - Ongoing lab status
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {(data: Session) => void} [props.onSuccess] - (optional) Callback on getSession success
 * @param {(error: any) => void} [props.onError] - (optional) Callback on getSession error
 * @returns {import('react-query').UseQueryResult<Session>}
 */
const useGetLabSession = ({
  labId,
  labStatus,
  metricsPublisher,
  onSuccess,
  onError,
} = {}) => {
  const enableQuery = !!labId && labStatus === labStatuses.ONGOING;

  return useQuery({
    queryKey: ['useGetLabSession', labId],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getSession(labId),
        metricsPublisher
      ),
    enabled: enableQuery,
    staleTime: FIVE_MIN_IN_MS,
    refetchInterval: data => {
      // Return default ttl if endsOn is missing for some reason.
      if (!data?.endsOn) return CONSOLE_URL_TTL;
      // If session ends before the default ttl, return that interval.
      const now = Date.now();
      const sessionEndsOn = Date.parse(data.endsOn);
      if (sessionEndsOn && sessionEndsOn < now + CONSOLE_URL_TTL) {
        return sessionEndsOn - now;
      }
      return CONSOLE_URL_TTL;
    },
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [
            ERROR_TYPES.BadRequest,
            ERROR_TYPES.NotFound,
            ERROR_TYPES.Conflict,
          ],
        })
      ) {
        return false;
      }
      return failureCount <= 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 500 }),
    onSuccess: data => {
      if (onSuccess) onSuccess(data);
    },
    onError: error => {
      if (onError) onError(error);
    },
  });
};

export default useGetLabSession;

import React, { useCallback, useRef } from 'react';
import {
  Box,
  HelpPanel as PolarisHelpPanel,
  Link,
  Button,
  ExpandableSection,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { path } from 'ramda';
import { BORKMarkdown } from '@amzn/bork-markdown';
import { Mode } from '@amzn/awsui-global-styles';

import {
  useHelpPanelContext,
  useEventContext,
  useLocaleContext,
  useThemeContext,
  useTourContext,
} from '../../contexts';
import { GUIDED_TOUR_ID } from '../../constants/helpPanel';
import { RESOURCES } from '../../constants/support';
import { TOUR_IDS } from '../../constants/siteTour';
import { publishButtonClickMetric } from '../../utils/metrics';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import { messages } from '.';
import appMessages from '../../i18n/app.messages';

import './HelpPanel.scss';

const scrollSectionIntoView = ref => {
  if (ref.current) {
    ref.current.scrollIntoView();
    ref.current.focus();
  }
};

const handleResourceClick = resourceId => {
  const resourceLinkUrl = path([resourceId, 'url'], RESOURCES);
  const resourceMetric = path([resourceId, 'metricName'], RESOURCES);

  publishButtonClickMetric(resourceMetric, {
    destinationUrl: resourceLinkUrl,
    currentPath: window.location.pathname,
    clickLocation: 'HelpPanel',
  });
};

const handleQuestionExpand = (tag, index, expanded, setQuestions) => {
  setQuestions(prevState => {
    const data = prevState[tag].slice();
    data[index].expanded = !expanded;
    return { ...prevState, [tag]: data };
  });
};

const HelpPanel = ({ showTourLink = true }) => {
  const { formatMessage } = useIntl();
  const { startTour } = useTourContext();
  const { questions, setQuestions } = useHelpPanelContext();
  const [locale] = useLocaleContext();
  const [theme] = useThemeContext();
  const { event } = useEventContext();

  const mapToQuestionElement = useCallback(
    (question, index) => {
      const { headerId, headerMessage, contentMessage, tag, expanded } =
        question;

      // header and content are both required
      if (!headerMessage || !contentMessage) return;

      return (
        <ExpandableSection
          data-testid={`helpPanel-${tag}QuestionItem`}
          key={headerId}
          headerText={formatMessage(headerMessage)}
          headingTagOverride="h4"
          expanded={expanded}
          onChange={() =>
            handleQuestionExpand(tag, index, expanded, setQuestions)
          }
        >
          <BORKMarkdown
            locale={locale}
            markdown={formatMessage(contentMessage)}
            padding={false}
            theme={theme === Mode.Dark ? 'dark' : 'light'}
          />
        </ExpandableSection>
      );
    },
    [setQuestions, formatMessage, locale, theme]
  );

  const panelQuestions = {
    troubleshooting: questions.troubleshooting.map((q, idx) =>
      mapToQuestionElement(q, idx)
    ),
    faq: questions.faq.map((q, idx) => mapToQuestionElement(q, idx)),
  };

  const troubleshootingRef = useRef(null);
  const faqRef = useRef(null);
  const resourcesRef = useRef(null);

  const tableOfContents = [
    { messageId: 'troubleshooting', ref: troubleshootingRef },
    { messageId: 'faq', ref: faqRef },
    { messageId: 'resources', ref: resourcesRef },
  ];

  return (
    <PolarisHelpPanel
      className="HelpPanel"
      data-testid="helpPanel"
      data-tourid={TOUR_IDS.helpPanel}
      header={<h2>{formatMessage(messages.title)}</h2>}
      footer={
        <div>
          <div
            ref={resourcesRef}
            className="HelpPanel__footerSection"
            tabIndex={-1}
          >
            <h3>{formatMessage(messages.resources)}</h3>
            <ul>
              {Object.keys(RESOURCES).map(resourceId => (
                <li key={resourceId}>
                  <Link
                    onClick={() => handleResourceClick(resourceId)}
                    href={RESOURCES[resourceId].url}
                    external
                    externalIconAriaLabel={formatMessage(
                      appMessages.externalIconAriaLabel
                    )}
                  >
                    {formatMessage(messages[resourceId])}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <hr />
          {!event.supportLinkUrl ? null : (
            <Link
              href={event.supportLinkUrl}
              external={true}
              externalIconAriaLabel={formatMessage(
                appMessages.externalIconAriaLabel
              )}
              data-testid={dataTestId['helpPanel-customerSupport']}
              onFollow={() => {
                publishButtonClickMetric('CustomerSupportLink', {
                  currentPath: window.location.pathname,
                });
              }}
            >
              {formatMessage(messages.report)}
            </Link>
          )}
        </div>
      }
    >
      <>
        <ul className="HelpPanel__tableOfContents">
          {tableOfContents.map(({ messageId, ref }) => (
            <li key={messageId}>
              <button
                data-testid={`helpPanel-${messageId}Link`}
                className="awsui-button-link"
                onClick={() => scrollSectionIntoView(ref)}
              >
                {formatMessage(messages[messageId])}
              </button>
            </li>
          ))}
        </ul>

        {showTourLink && (
          <Box margin={{ top: 'l' }}>
            <Button
              variant="normal"
              id={GUIDED_TOUR_ID}
              data-testid={dataTestId['helpPanel-tourLaunch']}
              onClick={() => {
                publishButtonClickMetric('LaunchGuidedTour', {
                  currentPath: window.location.pathname,
                  clickLocation: 'HelpPanel',
                });
                startTour();
              }}
            >
              {formatMessage(messages.tour)}
            </Button>
          </Box>
        )}

        <hr />

        <div
          ref={troubleshootingRef}
          tabIndex={-1}
          className="HelpPanel__contentSection"
        >
          <h3>{formatMessage(messages.troubleshooting)}</h3>
          {panelQuestions.troubleshooting}
        </div>

        <div ref={faqRef} tabIndex={-1} className="HelpPanel__contentSection">
          <h3>{formatMessage(messages.faq)}</h3>
          {panelQuestions.faq}
        </div>
      </>
    </PolarisHelpPanel>
  );
};

HelpPanel.propTypes = {
  showTourLink: PropTypes.bool,
};

export default HelpPanel;

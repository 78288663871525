export const BLUEPRINT_STATUS = {
  CREATING: 'CREATING',
  VALIDATED: 'VALIDATED',
  FAILED: 'FAILED',
};

export const BLUEPRINT_AVAILABILITY = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

/**
 * The version let's us control how the labContext evolves over time.
 */
export const LAB_CONTEXT_VERSION = '1.0.0';

import { path } from 'ramda';

import { graphql } from './graphQLService';
import { EHBlueprint } from '../models';

// TODO: We will eventually want to replace all references to getBlueprint in blueprintUtls.js to this file

/**
 * Returns the requested blueprint.
 *
 * @param {string} arn
 * @returns {Promise<EHBlueprint>}
 */
export const getBlueprint = arn => {
  const fieldName = 'getBlueprint';
  const getBlueprintGql = `
    query GetBlueprint($arn: ID!) {
      ${fieldName}(arn: $arn) {
        arn
        duration
        type
        status
        availability
        certifications
        level
        services
        locales {
          title
          description
          locale
          resources {
            type
            arn
          }
        }
        labContext {
          version
          labEvaluation {
            functionNames
            disableSubmission
            gradingTemplate
          }
        }
      }
    }
  `;
  return graphql(getBlueprintGql, { arn }, { timeoutMs: 10000 }).then(data => {
    const payload = path(['data', fieldName], data);
    return new EHBlueprint(payload);
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { ButtonOverride } from '../PolarisOverrides';
import { signOutAndRedirectBack } from '../../utils/authUtils';
import { publishButtonClickMetric } from '../../utils/metrics';
import { URL_PATHS } from '../../constants/urlPaths';
import LocaleMenu from './LocaleMenu';
import UserMenu from './UserMenu';
import EventLogo from './EventLogo';
import HeaderLayout from './HeaderLayout';
import messages from './Header.messages';

/**
 * HeaderOutsideRouter is only used when component is rendered outside a react router.
 */
const HeaderOutsideRouter = ({
  isAuthenticated,
  isStandalone,
  currentUser,
  testid,
}) => {
  const { formatMessage } = useIntl();
  const currentPath = window.location.pathname;

  const branding = isStandalone ? (
    <EventLogo />
  ) : (
    <a
      href={URL_PATHS.homePage}
      onClick={() => publishButtonClickMetric('EventLogo', { currentPath })}
    >
      <EventLogo />
    </a>
  );

  const actions = (
    <>
      <LocaleMenu />
      {isAuthenticated ? (
        <UserMenu>
          {currentUser || formatMessage(messages.defaultGreeting)}
        </UserMenu>
      ) : isStandalone ? null : (
        <ButtonOverride variant="transparent" onClick={signOutAndRedirectBack}>
          {formatMessage(messages.signIn)}
        </ButtonOverride>
      )}
    </>
  );

  return <HeaderLayout branding={branding} actions={actions} testid={testid} />;
};

HeaderOutsideRouter.propTypes = {
  isAuthenticated: PropTypes.bool,
  isStandalone: PropTypes.bool,
  currentUser: PropTypes.string,
  testid: PropTypes.string.isRequired,
};

export default HeaderOutsideRouter;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'labAssessment.description',
    defaultMessage:
      'This knowledge check is designed to gauge your understanding of the material covered.',
  },
  why: {
    id: 'labAssessment.why',
    defaultMessage:
      '<strong>Why this matters:</strong> Completing this knowledge check will help reinforce your grasp of the content and highlight any areas that may need further review.',
  },
  launch: {
    id: 'labAssessment.launch',
    defaultMessage: 'Launch knowledge check',
  },
  resume: {
    id: 'labAssessment.resume',
    defaultMessage: 'Resume knowledge check',
  },
  retake: {
    id: 'labAssessment.retake',
    defaultMessage: 'Retake knowledge check',
  },
  passed: {
    id: 'labAssessment.passed',
    defaultMessage: 'Passed',
  },
  failed: {
    id: 'labAssessment.failed',
    defaultMessage: 'Failed',
  },
  errorDefault: {
    id: 'labAssessment.errorDefault',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },
  errorConflict: {
    id: 'labAssessment.errorConflict',
    defaultMessage: 'Knowledge check cannot be started in the current state.',
  },
  reload: {
    id: 'labAssessment.reload',
    defaultMessage: 'Reload page',
  },
});

export default messages;

import React from 'react';
import { HelpPanel, Box } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';

import LabLanguages from './LabInformation/LabLanguages';
import DurationItem from './LabInformation/DurationItem';
import LoadingSkeleton from '../LoadingSkeleton';
import Feedback from '../Feedback/Feedback';
import ExpireTimeItem from './LabInformation/ExpireTimeItem';
import messages from './LabSidebar.messages';
import { useEventContext } from '../../contexts';
import FEATURES from '../../constants/features';
import { EHBlueprint } from '../../models/index';

const LabSidebar = ({ children, blueprint, expirationData, isOngoingLab }) => {
  const { formatMessage } = useIntl();
  const { durationData, locales } = blueprint || {};
  const { event } = useEventContext();

  return (
    <HelpPanel
      header={
        <>
          <h2>{formatMessage(messages.title)}</h2>
          {event.hasFeature(FEATURES.labExpiryTime) ? (
            isOngoingLab && !expirationData ? (
              <Box margin={{ vertical: 's' }}>
                <LoadingSkeleton width={80} height={22} />
              </Box>
            ) : (
              <Box color="text-body-secondary" margin={{ top: 's' }}>
                <ExpireTimeItem
                  expirationData={expirationData}
                  isOngoingLab={isOngoingLab}
                />
              </Box>
            )
          ) : null}
          {isNil(durationData) ? (
            <Box margin={{ vertical: 's' }}>
              <LoadingSkeleton width={40} height={22} />
            </Box>
          ) : (
            <Box color="text-body-secondary" margin={{ top: 's' }}>
              <DurationItem showIcon={true} duration={durationData} />
            </Box>
          )}
          {isNil(locales) ? (
            <Box margin={{ vertical: 's' }}>
              <LoadingSkeleton width={80} height={22} />
            </Box>
          ) : (
            <Box color="text-body-secondary" margin={{ top: 's' }}>
              <LabLanguages blueprintLocales={locales} />
            </Box>
          )}
          <Box margin={{ top: 'xs' }}>
            <Feedback blueprint={blueprint} isInSidebar={true} />
          </Box>
        </>
      }
    >
      {children}
    </HelpPanel>
  );
};

LabSidebar.propTypes = {
  children: PropTypes.node,
  blueprint: PropTypes.instanceOf(EHBlueprint),
  expirationData: PropTypes.shape({
    expiryDate: PropTypes.string,
    expiryTime: PropTypes.string,
  }),
  isOngoingLab: PropTypes.bool,
};

export default LabSidebar;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Icon from '@amzn/awsui-components-react/polaris/icon';

import useWindowSize from '../../hooks/useWindowSize';
import messages from './Header.messages';

import SkipNavigation from './SkipNavigation';
import './HeaderLayout.scss';

const HeaderLayout = ({ branding, actions, children, testid }) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useWindowSize();

  const HeaderActions = ({ actions }) => {
    if (!isMobile) {
      return <div className="Header__actions">{actions}</div>;
    }

    return (
      <div className="Header__mobile-container">
        <Popover
          fixedWidth
          size="large"
          content={<SpaceBetween size="l">{actions}</SpaceBetween>}
          position="bottom"
          dismissAriaLabel={formatMessage(messages.dismissAriaLabel)}
        >
          <div className="Header__mobile-btn">
            {formatMessage(messages.mobileMenuTitle)}
            <Icon
              name="caret-down-filled"
              className="Header__mobile-btn-icon"
            />
          </div>
        </Popover>
      </div>
    );
  };
  return (
    <header className="Header" data-testid={testid}>
      <nav>
        <SkipNavigation />
        <div className="Header__branding">{branding}</div>
        <HeaderActions actions={actions} />
      </nav>
      {children}
    </header>
  );
};

HeaderLayout.propTypes = {
  branding: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node,
  testid: PropTypes.string.isRequired,
};

export default HeaderLayout;

/**
 * @typedef {'INITIALIZING'|'PROVISIONING'|'READY'|'RUNNING'|'ERROR'|'EXPIRED'|'ENDED'|'CLOSED'|'CANCELLED'} AwsLabStatus
 */

/**
 * Expected Lab statuses from AWS Labs API
 * @readonly
 * @enum {AwsLabStatus}
 */
export const awsLabsStatuses = {
  READY: 'READY',
  RUNNING: 'RUNNING',
  ERROR: 'ERROR',
  ENDED: 'ENDED',
  EXPIRED: 'EXPIRED',
  INITIALIZING: 'INITIALIZING',
  PROVISIONING: 'PROVISIONING',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
};

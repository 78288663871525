import React from 'react';
import PropTypes from 'prop-types';
import { HeaderFull, HeaderOutsideRouter, HeaderStandalone } from '.';

const HeaderEH = props => {
  if (props.isOutsideRouter) {
    return (
      <HeaderOutsideRouter testid={'header-HeaderOutsideRouter'} {...props} />
    );
  }

  return props.isStandalone ? (
    <HeaderStandalone testid={'header-HeaderStandalone'} {...props} />
  ) : (
    <HeaderFull testid={'header-HeaderFull'} {...props} />
  );
};

HeaderEH.propTypes = {
  isStandalone: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isOutsideRouter: PropTypes.bool,
  currentUser: PropTypes.string,
};

export default HeaderEH;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  notFound: {
    id: 'notFoundPage.notFound',
    defaultMessage: 'Oh no, the page cannot be found.',
  },
  goBack: {
    id: 'notFoundPage.goBack',
    defaultMessage: 'Go back to the <link>Home Page</link>.',
  },
  dogsOfAmazon: {
    id: 'notFoundPage.dogsOfAmazon',
    defaultMessage: 'Meet the dogs of Amazon.',
  },
});

export default messages;

import { defineMessages } from 'react-intl';

const supportInfoMessages = defineMessages({
  supportInfoExpand: {
    id: 'SupportInfo.supportInfoExpand',
    defaultMessage: 'Support info',
  },
  supportInfoCopyBtn: {
    id: 'SupportInfo.supportInfoCopyBtn',
    defaultMessage: 'Copy to clipboard',
  },
  supportInfoCopied: {
    id: 'SupportInfo.supportInfoCopied',
    defaultMessage: 'Support info copied',
  },
  supportInfoInputLabel: {
    id: 'SupportInfo.supportInfoInputLabel',
    defaultMessage: 'Detailed support information',
  },
});

export default supportInfoMessages;

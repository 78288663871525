import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultTitle: {
    id: 'header.defaultTitle',
    defaultMessage: 'Event',
  },
  recommendationsBtn: {
    id: 'header.recommendationsBtn',
    defaultMessage: 'Home',
  },
  catalogBtn: {
    id: 'header.catalogBtn',
    defaultMessage: 'Catalog',
  },
  signIn: {
    id: 'header.signIn',
    defaultMessage: 'Sign in',
  },
  defaultGreeting: {
    id: 'header.defaultGreeting',
    defaultMessage: 'Hello, User',
  },
  mobileMenuTitle: {
    id: 'header.mobileMenuTitle',
    defaultMessage: 'Menu',
  },
  dismissAriaLabel: {
    id: 'header.dismissAriaLabel',
    defaultMessage: 'Close menu',
  },
});

export default messages;

import React from 'react';

import {
  EventContextProvider,
  ThemeContextProvider,
  AuthContextProvider,
  LocaleContextProvider,
  NotificationContextProvider,
  HelpPanelContextProvider,
  QueryClientProvider,
  ConnectChatContextProvider,
  ConfigContextProvider,
} from '.';

const RootProviders = ({
  children,
  isAuthenticated = false,
  user = {},
  config = {},
}) => (
  <QueryClientProvider>
    <NotificationContextProvider>
      <ConfigContextProvider config={config}>
        <AuthContextProvider initialState={{ isAuthenticated, user }}>
          <EventContextProvider>
            <LocaleContextProvider>
              <ThemeContextProvider>
                <HelpPanelContextProvider>
                  <ConnectChatContextProvider>
                    {children}
                  </ConnectChatContextProvider>
                </HelpPanelContextProvider>
              </ThemeContextProvider>
            </LocaleContextProvider>
          </EventContextProvider>
        </AuthContextProvider>
      </ConfigContextProvider>
    </NotificationContextProvider>
  </QueryClientProvider>
);

export default RootProviders;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ProgressBar, Box } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import {
  calcEstimatedTimeRemaining,
  calcMinutes,
  calcPercentRemaining,
  estimatedReadyTimeAvailable,
  isWaitingForEstimatedReadyTime,
  MAXIMUM_PERCENTAGE_REMAINING,
} from './labProgressUtils';
import messages from './LabProgressBar.messages';

const REFRESH_PROGRESS_INTERVAL_MILLISECONDS = 200;
const REFRESH_IS_WAITING_FOR_ESTIMATE_INTERVAL_MILLISECONDS = 1000;

const LabProgressBar = ({ attempt, createdOn, estimatedReadyTime }) => {
  const { formatMessage } = useIntl();

  // If attempt is not defined or passes in less than 1 for some reason default to 1.
  const safeAttempt = !attempt || attempt < 1 ? 1 : attempt;

  const [percentageRemaining, setPercentageRemaining] = useState(
    MAXIMUM_PERCENTAGE_REMAINING
  );
  const [minutesRemaining, setMinutesRemaining] = useState(
    calcMinutes(calcEstimatedTimeRemaining(estimatedReadyTime))
  );

  useEffect(() => {
    const cancelToken = setInterval(() => {
      setPercentageRemaining(
        calcPercentRemaining(createdOn, estimatedReadyTime, safeAttempt)
      );
      setMinutesRemaining(
        calcMinutes(calcEstimatedTimeRemaining(estimatedReadyTime))
      );
    }, REFRESH_PROGRESS_INTERVAL_MILLISECONDS);

    return () => {
      clearInterval(cancelToken);
    };
  }, [safeAttempt, createdOn, estimatedReadyTime, setPercentageRemaining]);

  const [waitingForEstimatedReadyTime, setWaitingForEstimatedReadyTime] =
    useState(true);
  useEffect(() => {
    const checkIfWaitingForEstimate = setInterval(() => {
      setWaitingForEstimatedReadyTime(
        isWaitingForEstimatedReadyTime(createdOn)
      );
    }, REFRESH_IS_WAITING_FOR_ESTIMATE_INTERVAL_MILLISECONDS);

    return () => {
      clearInterval(checkIfWaitingForEstimate);
    };
  }, [createdOn, setWaitingForEstimatedReadyTime]);

  if (estimatedReadyTimeAvailable(estimatedReadyTime)) {
    return (
      <ProgressBar
        value={100 - percentageRemaining}
        variant="flash"
        description={formatMessage(messages.minutesRemaining, {
          minutesRemaining,
        })}
      />
    );
  }

  if (waitingForEstimatedReadyTime) {
    return (
      <Box variant="small" color="inherit">
        {safeAttempt <= 1
          ? formatMessage(messages.estimatingTime)
          : formatMessage(messages.estimatingTimeRetry)}
      </Box>
    );
  }

  return (
    <Box variant="small" color="inherit">
      {formatMessage(messages.noEstimateAvailable)}
    </Box>
  );
};

LabProgressBar.propTypes = {
  attempt: PropTypes.number,
  createdOn: PropTypes.string,
  estimatedReadyTime: PropTypes.string,
};

export default LabProgressBar;

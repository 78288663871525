import { useMutation } from 'react-query';
import { path } from 'ramda';

import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import { graphql } from '../utils/graphQLService';
import { EHLabEvaluation } from '../models';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import ERROR_TYPES from '../constants/errorTypes';

const startLabEvaluationQuery = (
  labId,
  functionName,
  gradingTemplateArn,
  clientRequestToken
) => {
  const fieldName = 'startLabEvaluation';
  const gql = `
    mutation StartLabEvaluation($labId: String!, $functionName: String!, $gradingTemplateArn: String!, $clientRequestToken: String!) {
      ${fieldName}(labId: $labId, functionName: $functionName, gradingTemplateArn: $gradingTemplateArn, clientRequestToken: $clientRequestToken) {
        arn
        executionId
        functionName
        startedOn
        status
      }
    }`;

  return graphql(gql, {
    labId,
    functionName,
    gradingTemplateArn,
    clientRequestToken,
  }).then(payload => new EHLabEvaluation(path(['data', fieldName], payload)));
};

const metricsNamespace = 'StartLabEvaluation';

/**
 * @typedef {Object} Variables
 * @property {string} labId
 * @property {string} functionName
 * @property {string} clientRequestToken
 */

/**
 * @param {Object} [props]
 * @param {(variables: Variables) => void} [props.onMutate]
 * @param {(data: EHLabEvaluation, variables: Variables) => void} [props.onSuccess]
 * @param {(error: any, variables: Variables) => void} [props.onError]
 * @returns {import('react-query').UseMutationResult<EHLabEvaluation, any, Variables, void>}
 */
const useStartLabEvaluation = ({ onMutate, onSuccess, onError } = {}) => {
  const { mutate, data, ...rest } = useMutation({
    mutationFn: ({
      labId,
      functionName,
      gradingTemplateArn,
      clientRequestToken,
    }) =>
      interceptRequestMetrics(
        metricsNamespace,
        startLabEvaluationQuery(
          labId,
          functionName,
          gradingTemplateArn,
          clientRequestToken
        )
      ),
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [ERROR_TYPES.BadRequest, ERROR_TYPES.NotFound],
        })
      ) {
        return false;
      }
      return failureCount < 3;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 100 }),
    onMutate: variables => {
      if (onMutate) onMutate(variables);
    },
    onSuccess: (data, variables) => {
      if (onSuccess) onSuccess(data, variables);
    },
    onError: (error, variables) => {
      publishFatal(metricsNamespace, error, { ...variables });
      if (onError) onError(error, variables);
    },
  });

  return {
    mutate,
    data: data || {},
    ...rest,
  };
};

export default useStartLabEvaluation;

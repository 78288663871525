import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const QueryClientProviderWrapper = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        cacheTime: FIVE_MIN_IN_MS,
      },
      mutations: {
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientProviderWrapper;

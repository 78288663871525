import { useQuery } from 'react-query';

import { getBlueprint } from '../utils/contentService';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import { EHBlueprint } from '../models';

// As the blueprint ARN points to a default version we want to make sure to
// capture if the blueprint has been updated.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;
// No need to garbage collect the data too quickly, is results in a visual reload.
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;

const metricsNamespace = 'GetBlueprint';

/**
 * @param {Object} props
 * @param {string} props.blueprintArn - Blueprint arn to get
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @returns {import('react-query').UseQueryResult<EHBlueprint>}
 */
const useGetBlueprint = ({ blueprintArn, metricsPublisher } = {}) => {
  const enableQuery = !!blueprintArn;

  return useQuery({
    queryKey: ['useGetBlueprint', blueprintArn],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getBlueprint(blueprintArn),
        metricsPublisher
      ),
    enabled: enableQuery,
    staleTime: FIVE_MIN_IN_MS,
    cacheTime: TWO_HOURS_IN_MS,
    refetchOnWindowFocus: false,
    initialData: new EHBlueprint(),
    initialDataUpdatedAt: 0, // Allows immediate refetch
    retry: (failureCount, error) => {
      if (isNonRetryableError(error)) return false;
      return failureCount < 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 100 }),
    onError: error => {
      publishFatal(metricsNamespace, error, { blueprintArn });
    },
  });
};

export default useGetBlueprint;

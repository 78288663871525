import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ExpandableSection,
  SpaceBetween,
  Link,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { publishCounterMetric } from '../../utils/metrics';
import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { CALLOUT_TYPES } from './constants';
import appMessages from '../../i18n/app.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import { calloutTypes } from '../../models/EHCustomCallouts';

const TopicLink = ({ url, copy, type }) => {
  const { formatMessage } = useIntl();
  return (
    <Link
      href={url}
      ariaLabel={formatMessage(appMessages.externalIconAriaLabel)}
      external
      data-testid={dataTestId[`calloutTopic-${type}`]}
      onFollow={() => {
        publishCounterMetric('CalloutTopic', {
          counterName: `Click:${type}`,
          additionalMetrics: { copy },
        });
      }}
    >
      {copy}
    </Link>
  );
};

/**
 * The CalloutTopic is used for encouraging experiential learning with Labs and Jams.
 */
const CalloutTopic = ({ params }) => {
  const { formatMessage } = useIntl();
  useEmitCalloutImpression(CALLOUT_TYPES.topic);
  const {
    title,
    description,
    expanded,
    labs,
    jams,
    labsDescription,
    jamsDescription,
  } = params.topic || {};

  if (!title || !labs?.length || !jams?.length) return null;

  return (
    <ExpandableSection
      data-testid={`callout-${CALLOUT_TYPES.topic}`}
      variant="container"
      headerText={title}
      headingTagOverride="h3"
      defaultExpanded={expanded}
      onChange={event => {
        publishCounterMetric('CalloutTopic', {
          counterName: event.detail?.expanded ? 'Expanded' : 'Collapsed',
          additionalMetrics: { title },
        });
      }}
    >
      {description ? (
        <Box variant="p" margin={{ bottom: 's' }}>
          {description}
        </Box>
      ) : null}
      <SpaceBetween direction="horizontal" size="xxl">
        <TextContent>
          <Box variant="h4">{formatMessage(appMessages.builderLabsTitle)}</Box>
          {labsDescription ? (
            <Box variant="small">{labsDescription}</Box>
          ) : null}
          <ul>
            {labs.map(({ url, copy }, i) => (
              <li key={i}>
                <TopicLink url={url} copy={copy} type="lab" />
              </li>
            ))}
          </ul>
        </TextContent>
        <TextContent>
          <Box variant="h4">{formatMessage(appMessages.awsJamTitle)}</Box>
          {jamsDescription ? (
            <Box variant="small">{jamsDescription}</Box>
          ) : null}
          <ul>
            {jams.map(({ url, copy }, i) => (
              <li key={i}>
                <TopicLink url={url} copy={copy} type="jam" />
              </li>
            ))}
          </ul>
        </TextContent>
      </SpaceBetween>
    </ExpandableSection>
  );
};

CalloutTopic.propTypes = {
  params: PropTypes.shape({
    topic: PropTypes.instanceOf(calloutTypes.Topic),
  }),
};

export default CalloutTopic;

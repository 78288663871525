import React from 'react';
import PropTypes from 'prop-types';
import { FooterFull, FooterOutsideRouter, FooterStandalone } from '.';

const Footer = ({
  isStandalone,
  isOutsideRouter = false,
  blueprint,
  appLayoutRef,
}) => {
  if (isOutsideRouter) return <FooterOutsideRouter />;

  return isStandalone ? (
    <FooterStandalone blueprint={blueprint} appLayoutRef={appLayoutRef} />
  ) : (
    <FooterFull blueprint={blueprint} appLayoutRef={appLayoutRef} />
  );
};

Footer.propTypes = {
  isStandalone: PropTypes.bool,
  isOutsideRouter: PropTypes.bool,
  blueprint: PropTypes.object,
  appLayoutRef: PropTypes.object.isRequired,
};

export default Footer;

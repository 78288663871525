import React from 'react';
import PropTypes from 'prop-types';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';

import useEmitCalloutImpression from './useCalloutImpressionCounter';
import EasyMarkdown from '../EasyMarkdown';
import { CALLOUT_TYPES } from './constants';
import './CalloutHeroBanner.scss';
import { calloutTypes } from '../../models/EHCustomCallouts';

/**
 * Custom callout hero banner. Uses the HeroBanner graphics by default.
 */
const CalloutHeroBanner = ({ params }) => {
  useEmitCalloutImpression(CALLOUT_TYPES.hero);
  const { title, secondaryTitle, content } = params.hero || {};
  return (
    <div
      className="CalloutHeroBanner"
      data-testid={`callout-${CALLOUT_TYPES.hero}`}
    >
      <Box
        margin={{ vertical: 'm' }}
        padding={{ vertical: 'xxl' }}
        color="inherit"
      >
        <div className="CalloutHeroBanner__content">
          <SpaceBetween size="s">
            <Box
              variant="h2"
              fontWeight="bold"
              fontSize="display-l"
              color="inherit"
            >
              {title}
            </Box>
            {secondaryTitle ? (
              <Box fontSize="heading-l" variant="p" color="inherit">
                {secondaryTitle}
              </Box>
            ) : null}
            {content ? (
              <div className="CalloutHeroBanner__markdown">
                <EasyMarkdown content={content} />
              </div>
            ) : null}
          </SpaceBetween>
        </div>
      </Box>
    </div>
  );
};

CalloutHeroBanner.propTypes = {
  params: PropTypes.shape({
    hero: PropTypes.instanceOf(calloutTypes.Hero).isRequired,
  }),
};

export default CalloutHeroBanner;

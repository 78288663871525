export const HELP_BTN_ID = 'awsui-help-button';

export const HELP_BTN_SELECTOR = `#${HELP_BTN_ID}`;

export const GUIDED_TOUR_ID = `awsui-guided-tour-button`;

export const GUIDED_TOUR_SELECTOR = `#${GUIDED_TOUR_ID}`;

export const CLASSROOMS_EVENT_NAME = `classrooms`;

export const CLASSROOMS_GAMMA_EVENT_NAME = `classrooms-gamma`;

export const TAGS = {
  troubleshooting: 'troubleshooting',
  faq: 'faq',
};

import React, { useEffect, useRef } from 'react';
import {
  AppLayout,
  Header,
  Container,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SEO from '../components/SEO';
import { HeaderWrapper } from '../components/Header';
import HeaderEH from '../components/Header/HeaderEH';
import { Footer } from '../components/Footer';
import AppNotifications from '../components/AppNotifications';
import { OngoingLabBanner } from '../components/OngoingLab';
import { HelpPanel } from '../components/HelpPanel';
import Main from '../components/Main';
import {
  useAuthContext,
  useHelpPanelContext,
  useEventContext,
} from '../contexts';
import { publishCounterMetric } from '../utils/metrics';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import { URL_PATHS } from '../constants/urlPaths';
import { DOGS_OF_AMAZON_LINK } from '../constants/notFound';
import messages from './NotFoundPage.messages';

const dogImage = new URL(
  `../images/404/${Math.floor(Math.random() * 82 + 1)}.jpg`,
  import.meta.url
).href;

const NotFoundPage = ({ contentOnly = false }) => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { showHelpPanel, handlePanelClose } = useHelpPanelContext();
  const { event } = useEventContext();

  useEffect(() => {
    publishCounterMetric('NotFound', {
      additionalMetrics: {
        PathName: location.pathname,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Main>
      <SpaceBetween direction="vertical" size="l">
        <OngoingLabBanner />
        <Container
          header={
            <Header variant="h1">{formatMessage(messages.notFound)}</Header>
          }
        >
          <TextContent>
            <SpaceBetween size="s">
              {event.isStandaloneLabEvent() ? null : (
                <p>
                  {formatMessage(messages.goBack, {
                    link: chunks => (
                      <Link data-testid="notFound-home" to={URL_PATHS.homePage}>
                        {chunks}
                      </Link>
                    ),
                  })}
                </p>
              )}
              <a
                href={DOGS_OF_AMAZON_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={formatMessage(messages.dogsOfAmazon)}
                  src={dogImage}
                />
              </a>
            </SpaceBetween>
          </TextContent>
        </Container>
      </SpaceBetween>
    </Main>
  );

  if (contentOnly) return content;

  return (
    <>
      <SEO title={'404'} />
      <HeaderWrapper>
        <HeaderEH
          isStandalone={event.isStandaloneLabEvent()}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      </HeaderWrapper>
      <AppLayout
        ref={appLayoutRef}
        content={content}
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        notifications={<AppNotifications />}
        stickyNotifications={true}
        navigationHide={true}
        toolsHide={!showHelpPanel}
        toolsOpen={showHelpPanel}
        onToolsChange={handlePanelClose}
        tools={<HelpPanel showTourLink={false} />}
        ariaLabels={APP_LAYOUT_LABELS}
      />
      <Footer
        isStandalone={event.isStandaloneLabEvent()}
        appLayoutRef={appLayoutRef}
      />
    </>
  );
};

NotFoundPage.propTypes = {
  contentOnly: PropTypes.bool,
};

export default NotFoundPage;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alertMessage: {
    id: 'signInContainer.alert',
    defaultMessage: 'Use the same email you used to register for the event',
  },
  headerMessage: {
    id: 'signInContainer.header',
    defaultMessage: 'Choose a sign in method',
  },
  orMessage: {
    id: 'signInContainer.or',
    defaultMessage: 'or',
  },
  getHelpLinkMessage: {
    id: 'signInContainer.getHelpLink',
    defaultMessage: 'Get help signing in',
  },
  unauthenticatedCatalogPageCTA: {
    id: 'signInContainer.unauthenticatedCatalogPageCTA',
    defaultMessage: 'Browse all content',
  },
});

export default messages;

import logger from './logger';

/**
 * A function that resolves hourCycle (12/24hr) based on default locale of the system
 */
export const getUserHourCycle = () => {
  try {
    return Intl.DateTimeFormat(undefined, { hour: 'numeric' }).resolvedOptions()
      .hourCycle;
  } catch (error) {
    logger.error("Failed to resolve user's hourCycle preference", error);
    return 'h24';
  }
};

export const getUserTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    logger.error("Failed to resolve user's timezone", error);
    return 'UTC';
  }
};

/**
 * A function that parses dateTimeString with provided formatOptions and returns formatted date and time information
 *
 * @param dateString - date string to be parsed example: 2024-05-15T19:40:21Z
 * @param formatOptions - example: { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' }
 * @param locale - example: en-US
 * @returns returns formatted date/time information
 */
export const parseDateTime = (dateString, formatOptions, locale) => {
  const date = new Date(dateString);
  return Intl.DateTimeFormat(locale, formatOptions).format(date);
};

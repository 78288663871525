import { is, prop, propOr, length } from 'ramda';
import { LAB_CONTEXT_VERSION } from '../constants/blueprint';
import './typedef';
import {
  parseDurationData,
  getDurationInMinutes,
  getDurationId,
} from '../utils/blueprintDurationUtils';

export class EHBlueprint {
  constructor(payload) {
    /** @type {string} */
    this.arn = prop('arn', payload);
    /** @type {string} */
    this.type = prop('type', payload);
    /** @type {string} */
    this.availability = prop('availability', payload);
    /** @type {string} */
    this.connectivity = prop('connectivity', payload);
    /** @type {string} */
    this.createdAt = prop('createdAt', payload);
    /** @type {string} */
    this.duration = prop('duration', payload);
    /** @type {Locale[]} */
    this.locales = prop('locales', payload);
    /** @type {string[]]} */
    this.regions = prop('regions', payload);
    /** @type {string} */
    this.level = prop('level', payload);
    /** @type {string[]]} */
    this.services = propOr([], 'services', payload);
    /** @type {string[]]} */
    this.certifications = propOr([], 'certifications', payload);
    /** @type {string} */
    this.status = prop('status', payload);
    /** @type {LabContext} */
    this.labContext = prop('labContext', payload);
    /** @type {string} */
    this.externalUrl = prop('externalUrl', payload);
  }

  /**
   * The `dur` property is used for filtering.
   * @type {LabDurationId}
   */
  get dur() {
    return getDurationId(this.duration);
  }

  /**
   * Parsed duration object with hour and minutes.
   * @type {Object|undefined}
   */
  get durationData() {
    return parseDurationData(this.duration);
  }

  /**
   * Parses duration string and returns it in minutes
   * @returns {number} duration in minutes
   */
  getDurationInMinutes() {
    return getDurationInMinutes(this.durationData);
  }

  /**
   * Checks if the blueprint is configured to run lab evaluations.
   * @returns {boolean}
   */
  hasLabEvaluation() {
    return (
      // Validating the expected version
      this.labContext?.version === LAB_CONTEXT_VERSION &&
      // Validating that there is (at least) one lab function for evaluation
      Boolean(this.labContext?.labEvaluation?.functionNames?.[0])
    );
  }

  /**
   * Checks if the blueprint has disabled lab eval submissions
   * @returns {boolean}
   */
  hasSubmissionDisabled() {
    const disableSubmission = this.labContext?.labEvaluation?.disableSubmission;
    return is(Boolean, disableSubmission) ? disableSubmission : false;
  }

  /**
   * Checks if the blueprint has a grading template and returns string grading template arn if found or empty string
   * @returns {string}
   */
  getGradingTemplateArn() {
    const gradingTemplate = this.labContext?.labEvaluation?.gradingTemplate;
    return is(String, gradingTemplate) && length(gradingTemplate)
      ? gradingTemplate
      : '';
  }
}

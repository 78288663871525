import { useQueries } from 'react-query';
import { propOr, propEq } from 'ramda';

import ERROR_TYPES from '../constants/errorTypes';
import { getBlueprint } from '../utils/contentService';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import { calculateRetryDelay } from '../utils/promiseUtils';
import { EHBlueprint } from '../models';

// As the blueprint ARN points to a default version we want to make sure to
// capture if the blueprint has been updated.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;
// No need to garbage collect the data too quickly, is results in a visual reload.
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;

const metricsNamespace = 'GetBlueprints';

/**
 * @param {Object} props
 * @param {string[]} props.blueprintArns - List of blueprint arns
 * @param {(data: LabRef) => void} [props.onSuccess] - (optional) Callback on startLab success
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {boolean} enableQuery - List of blueprint arns
 * @returns {import('react-query').UseQueryResult<EHBlueprint>}
 */
const useGetBlueprints = ({ blueprintArns, enableQuery, metricsPublisher }) => {
  enableQuery = enableQuery && blueprintArns.length > 0;
  return useQueries(
    blueprintArns.map(blueprint => ({
      queryKey: ['useGetBlueprint', blueprint],
      queryFn: () =>
        interceptRequestMetrics(
          metricsNamespace,
          getBlueprint(blueprint),
          metricsPublisher
        ),
      enabled: enableQuery,
      staleTime: FIVE_MIN_IN_MS,
      cacheTime: TWO_HOURS_IN_MS,
      initialData: new EHBlueprint(),
      initialDataUpdatedAt: 0, // Allows immediate refetch
      retry: (failureCount, error) => {
        const errors = propOr([], 'errors', error);
        if (
          errors.find(propEq('errorType', ERROR_TYPES.Forbidden)) ||
          errors.find(propEq('errorType', ERROR_TYPES.Unauthorized))
        ) {
          return false;
        }
        return failureCount < 2;
      },
      retryDelay: attemptIndex =>
        calculateRetryDelay(attemptIndex, { interval: 100 }),
      onError: error => {
        publishFatal(metricsNamespace, error, blueprint);
      },
    }))
  );
};

export default useGetBlueprints;

import React from 'react';
import { Box, Header, Popover } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';

import LiveSupportButton from './LiveSupportButton';
import { useEventContext, useLocaleContext } from '../../contexts';
import FEATURES from '../../constants/features';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import messages from './LiveSupport.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const InPersonSupport = ({ contentOnly = false }) => {
  const { formatMessage } = useIntl();
  const { event } = useEventContext();
  const supportMetadata = event.getMetadata(FEATURES.inPersonSupport);
  const [locale] = useLocaleContext();

  const supportText = prop(
    'text',
    getLocaleOrAvailable(locale, supportMetadata)
  );

  const content = (
    <>
      <Header
        variant="h3"
        headingTagOverride="h2"
        data-testid={dataTestId['liveSupport-inPersonHeader']}
      >
        {formatMessage(messages.inPersonHeader)}
      </Header>
      <Box variant="p">{supportText}</Box>
    </>
  );

  return contentOnly ? (
    content
  ) : (
    <Popover
      position="top"
      triggerType="custom"
      size="medium"
      content={content}
      dismissAriaLabel={formatMessage(messages.closeLabel)}
    >
      <LiveSupportButton />
    </Popover>
  );
};

export default InPersonSupport;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { publishButtonClickMetric } from '../../utils/metrics';
import { ButtonOverride } from '../PolarisOverrides';
import { HELP_BTN_ID } from '../../constants/helpPanel';
import { useHelpPanelContext } from '../../contexts';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './HelpButton.messages';

const HelpButton = ({ appLayoutRef }) => {
  const { formatMessage } = useIntl();
  const { showHelpPanel, setShowHelpPanel } = useHelpPanelContext();

  const toggleHelpPanel = () => {
    publishButtonClickMetric('OpenHelpPanel');
    if (showHelpPanel) {
      setShowHelpPanel(false);
    } else {
      setShowHelpPanel(true);
      appLayoutRef?.current?.focusToolsClose?.();
    }
  };

  return (
    <ButtonOverride
      variant="transparent"
      id={HELP_BTN_ID}
      data-testid={dataTestId['helpPanel-open']}
      onClick={toggleHelpPanel}
    >
      {formatMessage(messages.help)}
    </ButtonOverride>
  );
};

HelpButton.propTypes = {
  appLayoutRef: PropTypes.object.isRequired,
};

export default HelpButton;

import { curry, find, propEq, pipe, propOr, or } from 'ramda';
import { DEFAULT_LOCALE, LOCALE_CODES } from '../constants/locale';
import { localStorageAdapter } from './cookieManagement';
import { LOCALE_STORAGE_ID } from '../constants/cookies';

export const getLanguageCode = locale => {
  if (!locale) return;
  return or(locale.substr(0, locale.indexOf('-')), locale);
};

export const getLocale = curry((locale, item) =>
  pipe(
    // Provide default empty array to enable chaining
    propOr([], 'locales'),
    find(propEq('locale', locale))
  )(item)
);

export const getLocaleOrAvailable = curry((locale, item) => {
  const preferred = getLocale(locale, item);
  if (preferred) return preferred;

  // If no preferred locale was found, get what is available.
  // Check for English first, then get whatever.
  const englishLocale = getLocale(LOCALE_CODES['en-US'], item);
  if (englishLocale) return englishLocale;
  return pipe(propOr([], 'locales'), find(Boolean))(item);
});

export const getSupportedBrowserLang = () => {
  let browserLang;
  navigator.languages.some(lang => {
    const langCode = getLanguageCode(lang);
    const contains = LOCALE_CODES[lang] || LOCALE_CODES[langCode];
    if (contains) browserLang = contains;
    return !!contains;
  });
  return browserLang;
};

export const getPreferredLocale = (defaultLocale = DEFAULT_LOCALE) => {
  const userLocalePref = localStorageAdapter.getItem(LOCALE_STORAGE_ID);
  const browserLang = getSupportedBrowserLang();

  return userLocalePref || browserLang || defaultLocale;
};

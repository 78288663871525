import React, { useRef } from 'react';
import {
  Box,
  Button,
  ExpandableSection,
  Input,
  Popover,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './ExpandableSupportInformation.messages';
import { dataTestId } from '../constants/dataTestIdSelectors';

const ExpandableSupportInformation = ({ copyableInfo, expandedInfo }) => {
  const { formatMessage } = useIntl();
  const ref = useRef();
  return (
    <ExpandableSection headerText={formatMessage(messages.supportInfoExpand)}>
      {expandedInfo}
      {copyableInfo ? (
        <Box margin={{ top: 's' }}>
          <Input
            value={copyableInfo}
            onFocus={() => ref.current.select()}
            ref={ref}
            ariaLabel={formatMessage(messages.supportInfoInputLabel)}
            readOnly
          />
          <Popover
            dismissButton={false}
            position="top"
            size="small"
            triggerType="custom"
            content={
              <StatusIndicator type="success">
                {formatMessage(messages.supportInfoCopied)}
              </StatusIndicator>
            }
          >
            <Button
              iconName="copy"
              onClick={() => navigator.clipboard.writeText(copyableInfo)}
              data-testid={dataTestId['supportInfo-copySupportInfo']}
            >
              {formatMessage(messages.supportInfoCopyBtn)}
            </Button>
          </Popover>
        </Box>
      ) : null}
    </ExpandableSection>
  );
};

export default ExpandableSupportInformation;

import { defineMessages } from 'react-intl';

const labButtonMessages = defineMessages({
  closeBtn: {
    id: 'labButtons.closeBtn',
    defaultMessage: 'Cancel',
  },
  closeLabel: {
    id: 'labButtons.closeLabel',
    defaultMessage: 'Close',
  },
  startLabBtn: {
    id: 'labButtons.startLabBtn',
    defaultMessage: 'Start Lab',
  },
  endLabBtn: {
    id: 'labButtons.endLabBtn',
    defaultMessage: 'End Lab',
  },
  acceptTermsModalHeader: {
    id: 'labButtons.acceptTermsHeader',
    defaultMessage: 'Accept Terms',
  },
  acceptTermsModalMessage: {
    id: 'labButtons.acceptTermsModalMessage',
    defaultMessage:
      'By participating in AWS Training, I agree to be bound by the <aa>AWS Learner Terms and Conditions</aa>. The information you provide in connection with AWS Training will be handled in accordance with the <ab>AWS Privacy Notice</ab>.',
  },
  endModalHeader: {
    id: 'labButtons.endModalHeader',
    defaultMessage: 'Confirm end lab',
  },
  endModalMessage: {
    id: 'labButtons.endModalMessage',
    defaultMessage:
      'All unfinished work will be lost if you end this lab. Are you sure you want to end?',
  },
  openConsoleBtn: {
    id: 'labButtons.openConsoleBtn',
    defaultMessage: 'Open Console',
  },
  disabledOpenConsoleProvisioning: {
    id: 'labButtons.disabledToodisabledOpenConsoleProvisioning',
    defaultMessage: 'Provisioning AWS services ...',
  },
  openLabEvaluationPanelButton: {
    id: 'labButtons.openLabEvaluationPanelButton',
    defaultMessage: 'Show Lab Validation',
  },
  progressTrackingModalHeader: {
    id: 'labButtons.progressTrackingModalHeader',
    defaultMessage: 'Mark Lab Complete',
  },
  progressTrackingBtn: {
    id: 'labButtons.progressTrackingBtn',
    defaultMessage: 'Mark Lab Complete',
  },
  progressTrackingMessage: {
    id: 'labButtons.progressTrackingMessage',
    defaultMessage:
      'Marking a lab complete means you have successfully completed all of the tasks.',
  },
  progressTrackingFailure: {
    id: 'labButtons.progressTrackingFailure',
    defaultMessage:
      'We were unable to mark the lab complete. Please try again.',
  },
  progressTrackingSuccess: {
    id: 'labButtons.progressTrackingSuccess',
    defaultMessage:
      'Successfully marked lab complete. Proceed to end lab when ready.',
  },
});

export default labButtonMessages;

import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import useResizeObserver from 'use-resize-observer';

/**
 * @param {Number} wait number of milliseconds for which the calls are to be delayed
 * @returns {Object} {ref, width, height}
 */
const useDebouncedResizeObserver = wait => {
  const [size, setSize] = useState({});
  const onResize = useMemo(
    () => debounce(setSize, wait, { leading: true }),
    [wait]
  );
  const { ref } = useResizeObserver({ onResize, round: Math.floor });

  return { ref, ...size };
};

export default useDebouncedResizeObserver;

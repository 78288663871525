import React from 'react';
import { Box, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { trim } from 'ramda';
import messages from './DurationItem.messages';

const DurationItem = ({
  showLabel = false,
  showIcon = false,
  duration: { hours, minutes } = {},
}) => {
  const { formatMessage } = useIntl();

  let hrStr = '';
  let minStr = '';
  if (hours > 0) {
    hrStr += `${hours} `;
    hrStr +=
      hours > 1 ? formatMessage(messages.hours) : formatMessage(messages.hour);
  }
  if (minutes > 0) {
    minStr += `${minutes} `;
    minStr +=
      minutes > 1
        ? formatMessage(messages.minutes)
        : formatMessage(messages.minute);
  }

  return (
    <>
      {showIcon && (
        <Box variant="span" margin={{ right: 'xxs' }} color="inherit">
          <span role="img" aria-label={formatMessage(messages.label)}>
            <Icon data-testid="DurationItem-icon" name="status-pending" />
          </span>
        </Box>
      )}
      {showLabel && (
        <Box
          variant="span"
          margin={{ right: 'xxs' }}
          fontWeight="bold"
          data-testid="DurationItem-visible-label"
        >
          {formatMessage(messages.label)}
        </Box>
      )}
      {!hours && !minutes
        ? formatMessage(messages.unknown)
        : trim(`${hrStr} ${minStr}`)}
    </>
  );
};

export default DurationItem;

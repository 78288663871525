import { isNil } from 'ramda';

const MINIMUM_PERCENTAGE_REMAINING = 1;
export const MAXIMUM_PERCENTAGE_REMAINING = 100;
const MINIMUM_TIME_REMAINING_MILLISECONDS = 1000; // 1 second
const MAXIMUM_WAIT_FOR_ESTIMATED_READY_TIME = 60000; // 60 seconds

/**
 * Check if the current time is past the estimated ready time.
 * @param {string} estimatedReadyTime
 * @returns {boolean}
 */
export const pastEstimatedReadyTime = estimatedReadyTime => {
  const now = new Date().getTime();
  const estimatedReadyTimeMilliseconds = new Date(estimatedReadyTime).getTime();
  return now >= estimatedReadyTimeMilliseconds;
};

/**
 * Check if we are still waiting for estimatedReadyTime.
 * Will return true if we are still waiting for an estimated ready time.
 * Will return false if we should assume there is no estimated ready time available.
 * Will default to true if we are unsure of the createdOn time.
 * @param {string} createdOn
 * @returns {boolean}
 */
export const isWaitingForEstimatedReadyTime = createdOn => {
  if (isNil(createdOn)) {
    return true;
  }
  const now = new Date().getTime();
  const createdOnMilliseconds = new Date(createdOn).getTime();
  const timePassedSinceCreatedOn = now - createdOnMilliseconds;
  return MAXIMUM_WAIT_FOR_ESTIMATED_READY_TIME > timePassedSinceCreatedOn;
};

/**
 * Calculate the minutes from the time remaining in milliseconds
 * while rounding up to the nearest minute.
 *
 * @param {number} estimatedTimeRemaining
 * @returns {number} e.x. 1
 */
export const calcMinutes = estimatedTimeRemaining => {
  return Math.ceil(estimatedTimeRemaining / 60000);
};

/**
 * Determine if the estimated ready time is available yet.
 *
 * @param {String} estimatedReadyTime
 * @returns {boolean}
 */
export const estimatedReadyTimeAvailable = estimatedReadyTime => {
  return !isNil(estimatedReadyTime);
};

/**
 * Calculate the delta between now and the estimated ready time.
 * If the estimated ready time is not available or past then defaults to a minimum.
 *
 * @param {string} estimatedReadyTime
 * @returns {number} the time remaining in milliseconds.
 */
export const calcEstimatedTimeRemaining = estimatedReadyTime => {
  if (!estimatedReadyTimeAvailable(estimatedReadyTime)) {
    return MINIMUM_TIME_REMAINING_MILLISECONDS;
  } else if (pastEstimatedReadyTime(estimatedReadyTime)) {
    // We are past the estimated ready time, let's return a minimum buffer remaining.
    return MINIMUM_TIME_REMAINING_MILLISECONDS;
  } else {
    const estimatedReadyTimeMilliseconds = new Date(
      estimatedReadyTime
    ).getTime();
    const now = new Date().getTime();
    // Calculate the remaining time in milliseconds with a minimum buffer.
    return Math.max(
      estimatedReadyTimeMilliseconds - now,
      MINIMUM_TIME_REMAINING_MILLISECONDS
    );
  }
};

/**
 * Calculate the delta of the time remaining as a percentage of the total estimated time.
 * If the estimated ready time is not available or past then defaults to a minimum.
 *
 * @param {number} attempt
 * @param {string} createdOn
 * @param {string} estimatedReadyTime
 * @returns {number}
 */
export const calcPercentRemaining = (
  createdOn,
  estimatedReadyTime,
  attempt
) => {
  if (!estimatedReadyTimeAvailable(estimatedReadyTime)) {
    // The estimated ready time is not available yet, let's return the maximum remaining percentage.
    return MAXIMUM_PERCENTAGE_REMAINING;
  } else if (pastEstimatedReadyTime(estimatedReadyTime)) {
    // We are past the estimated ready time, let's return a minimum buffer remaining.
    return MINIMUM_PERCENTAGE_REMAINING;
  }

  // If attempt is not defined or passes in less than 1 for some reason default to 1.
  const safeAttempt = !attempt || attempt < 1 ? 1 : attempt;

  // Calculate the remaining percentage.
  const createdOnMilliseconds = new Date(createdOn).getTime();
  const estimatedReadyTimeMilliseconds = new Date(estimatedReadyTime).getTime();
  const estimatedProvisioningTime =
    safeAttempt * (estimatedReadyTimeMilliseconds - createdOnMilliseconds);
  const estimatedProvisioningTimeRemaining =
    calcEstimatedTimeRemaining(estimatedReadyTime);

  // Limit the result to between 1 and 100.
  return Math.min(
    Math.max(
      (
        (estimatedProvisioningTimeRemaining / estimatedProvisioningTime) *
        100
      ).toFixed(0),
      MINIMUM_PERCENTAGE_REMAINING
    ),
    MAXIMUM_PERCENTAGE_REMAINING
  );
};

import { prop } from 'ramda';

import './typedef';

export class EHLandingPageConfig {
  constructor(payload) {
    /** @type {LandingPageVariants} */
    this.variant = prop('variant', payload);
    /** @type {boolean} */
    this.usingEmailAcl = prop('usingEmailAcl', payload);
    /** @type {string[]} */
    this.idps = prop('idps', payload);
  }
}

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, TextContent } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { useIntl } from 'react-intl';

import {
  BadgeOverride,
  ButtonOverride,
  CardsOverride,
} from '../PolarisOverrides';
import {
  useWindowWidth,
  getScrollData,
  setCarouselScroll,
  getCardPadding,
} from './utils/carouselUtils';
import DurationItem from '../LabUI/LabInformation/DurationItem';
import LoadingSkeleton from '../LoadingSkeleton';
import { useEventContext, useHelpPanelContext } from '../../contexts';
import FEATURES from '../../constants/features';
import messages from './Carousel.messages';
import levelMessages from '../Catalog/levels.messages';
import serviceMessages from '../Catalog/services.short.messages';
import certificationMessages from '../Catalog/certifications.messages';
import { publishCounterMetric } from '../../utils/metrics';
import { dataTestId } from '../../constants/dataTestIdSelectors';

import './Carousel.scss';

export const CarouselButton = ({
  carouselRef,
  scroll,
  direction,
  className,
  shared,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [isDisabled, setIsDisabled] = useState(false);

  const heightSelector = shared
    ? `--carousel-shared-card-height`
    : `--carousel-card-height`;

  const buttonHeight = parseInt(
    getComputedStyle(document.body).getPropertyValue(heightSelector)
  );

  const classList = [
    className,
    'Carousel__button',
    `Carousel__button--${direction}`,
  ]
    .filter(Boolean)
    .join(' ');

  useEffect(() => {
    if (!scroll) return;
    const { scrollLocation, maxScroll } = scroll;
    if (scrollLocation == null || maxScroll == null) return;

    if (direction === 'right') {
      return scrollLocation === maxScroll
        ? setIsDisabled(true)
        : setIsDisabled(false);
    }
    return scrollLocation === 0 ? setIsDisabled(true) : setIsDisabled(false);
  }, [scroll, direction, setIsDisabled]);

  return (
    <ButtonOverride
      {...props}
      variant="carousel"
      className={classList}
      style={{ height: `${buttonHeight}px` }}
      ariaLabel={formatMessage(messages.slideCarouselLabel, {
        direction: formatMessage(messages[direction]),
      })}
      disabled={isDisabled}
      onClick={() => {
        setCarouselScroll(carouselRef, direction, shared);
        publishCounterMetric('CarouselButton', {
          counterName: 'Click',
          additionalMetrics: {
            Direction: direction,
          },
        });
      }}
    >
      <Icon size="big" name={`angle-${direction}`}></Icon>
    </ButtonOverride>
  );
};

const Carousel = ({ items, renderCustomContent, children }) => {
  const { event } = useEventContext();
  const { formatMessage } = useIntl();
  const { showHelpPanel } = useHelpPanelContext();

  const CardHeader = ({ arn, name, lang }) =>
    isEmpty(name) ? (
      <>
        <div className="awsui-util-hide">{formatMessage(messages.loading)}</div>
        <LoadingSkeleton width={100} height={60} />
      </>
    ) : (
      <div className="CarouselCard__header">
        <TextContent>
          <Box
            variant="h4"
            fontWeight="normal"
            fontSize="heading-m"
            color="inherit"
          >
            <span lang={lang}>
              <Link
                to={arn}
                data-testid={dataTestId['carousel-link']}
                onClick={() => {
                  publishCounterMetric('CarouselCardHeaderLink', {
                    counterName: 'Count',
                    additionalMetrics: {
                      Arn: arn,
                    },
                  });
                }}
              >
                {name}
              </Link>
            </span>
          </Box>
        </TextContent>
      </div>
    );

  const CardDuration = ({ duration }) =>
    isEmpty(duration) ? (
      <LoadingSkeleton width={40} height={22} />
    ) : (
      <Box variant="span" color="text-label">
        <DurationItem showIcon={true} duration={duration} />
      </Box>
    );

  const MetadataPill = ({ text, type, value, onClick }) => {
    if (event.hasFeature(FEATURES.catalogFiltering)) {
      return (
        <li className="CarouselCard__metadataItem">
          <Link to={`catalog?${type}=${value}`} onClick={onClick}>
            <BadgeOverride color="black" clickable={true}>
              {text}
            </BadgeOverride>
          </Link>
        </li>
      );
    }

    return (
      <li className="CarouselCard__metadataItem">
        <BadgeOverride color="black">{text}</BadgeOverride>
      </li>
    );
  };

  const MetadataPills = ({ level, certifications, services }) => {
    const onMetadataPillsClick = (description, typeOfDescription) => {
      publishCounterMetric('MetadataPills', {
        additionalMetrics: {
          Description: description.toString(),
          TypeOfDescription: typeOfDescription,
        },
        counterName: 'Click',
      });
    };
    return isNil(level) ? (
      <LoadingSkeleton width={35} height={20} />
    ) : (
      <ul
        className="CarouselCard__metadata"
        aria-label={formatMessage(messages.labTags)}
      >
        {levelMessages[level] && (
          <MetadataPill
            key={level}
            type="level"
            value={level}
            text={formatMessage(levelMessages[level])}
            onClick={() => onMetadataPillsClick(level, 'Level')}
          />
        )}
        {certifications &&
          certifications.length > 0 &&
          certifications
            .filter(certification => certificationMessages[certification])
            .slice(0, 1)
            .map(certification => (
              <MetadataPill
                key={certification}
                type="certifications"
                value={certification}
                text={formatMessage(certificationMessages[certification])}
                onClick={() =>
                  onMetadataPillsClick(certification, 'Certification')
                }
              />
            ))}
        {services &&
          services.length > 0 &&
          services
            .filter(service => serviceMessages[service])
            .slice(0, 3)
            .map(service => (
              <MetadataPill
                key={service}
                type="services"
                value={service}
                text={formatMessage(serviceMessages[service])}
                onClick={() => onMetadataPillsClick(service, 'Service')}
              />
            ))}
      </ul>
    );
  };

  const cardDefinition = {
    header: CardHeader,
    sections: [{ content: CardDuration }, { content: MetadataPills }],
  };

  const cardWidth = parseInt(
    getComputedStyle(document.body).getPropertyValue('--carousel-card-width')
  );
  const cardPadding = getCardPadding(cardWidth);

  const carouselRef = useRef();
  const isScrolling = useRef();
  const [scroll, setScroll] = useState();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const { scrollLocation, maxScroll } = getScrollData(carouselRef.current);
    setScroll({
      scrollLocation: scrollLocation,
      maxScroll: maxScroll,
    });
  }, [items, carouselRef, windowWidth]);

  return (
    <div
      className="Carousel"
      ref={carouselRef}
      onScroll={() => {
        clearTimeout(isScrolling.current);
        isScrolling.current = setTimeout(() => {
          const { scrollLocation, maxScroll } = getScrollData(
            carouselRef.current
          );
          setScroll({
            scrollLocation: scrollLocation,
            maxScroll: maxScroll,
          });
        }, 100);
      }}
    >
      <CarouselButton
        carouselRef={carouselRef.current}
        scroll={scroll}
        direction="left"
        data-testid="carousel-left"
        shared={renderCustomContent}
      />

      <div className="Carousel__itemWrapper">
        <div
          className="Carousel__items"
          style={{
            width: items.length * cardWidth - cardPadding,
          }}
        >
          {renderCustomContent ? (
            children
          ) : (
            <CardsOverride
              variant="carousel"
              cardDefinition={cardDefinition}
              items={items}
            />
          )}
        </div>
      </div>

      <CarouselButton
        className={showHelpPanel && 'Carousel__button--toolsOpen'}
        carouselRef={carouselRef.current}
        scroll={scroll}
        direction="right"
        data-testid={dataTestId['carousel-right']}
        shared={renderCustomContent}
      />
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  renderCustomContent: PropTypes.bool,
  children: PropTypes.node,
};

export default Carousel;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Button,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { CALLOUT_TYPES } from './constants';
import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { ContainerOverride } from '../PolarisOverrides';
import { Carousel } from '../Carousel';
import levelMessages from '../Catalog/levels.messages';
import appMessages from '../../i18n/app.messages';
import { calloutTypes } from '../../models/EHCustomCallouts';
import DurationItem from '../LabUI/LabInformation/DurationItem';
import { publishCounterMetric } from '../../utils/metrics';

import './CalloutCarousel.scss';

const CalloutCarousel = ({ params }) => {
  const { headerDescription, headerTitle, viewAllUrl, items, id } =
    params?.carousel || {};
  useEmitCalloutImpression(CALLOUT_TYPES.carousel);
  const { formatMessage } = useIntl();

  if (!items || items.length === 0) return null;

  const LevelItem = ({ level }) => {
    if (!level || !levelMessages[level]) return null;
    return (
      <div
        className="CalloutCarousel__level"
        data-testid="eh-callout-carousel-level"
        display="inline"
      >
        {formatMessage(levelMessages[level])}
      </div>
    );
  };

  const CarouselElement = () => (
    <div
      style={{ position: 'relative' }}
      data-testid={`callout-${CALLOUT_TYPES.carousel}`}
    >
      <Carousel items={items} renderCustomContent>
        {items.map((item, idx) => {
          const carouselItem = new calloutTypes.CarouselItem(item);
          return (
            <ContainerOverride
              variant="borderless"
              key={idx}
              fitHeight
              header={
                <Box
                  variant="h3"
                  color="inherit"
                  fontWeight="normal"
                  display="inline"
                >
                  <Link
                    href={item.url}
                    external
                    variant="primary"
                    fontSize="inherit"
                    className="Container__truncate-content"
                    onFollow={() => {
                      publishCounterMetric('CalloutCarouselItemLink', {
                        counterName: `Click:${id}`,
                        additionalMetrics: {
                          ItemUrl: item.url,
                          ItemTitle: item.title,
                        },
                      });
                    }}
                  >
                    {item.title}
                  </Link>
                </Box>
              }
              footer={
                <SpaceBetween direction="horizontal" size="xs">
                  <DurationItem duration={carouselItem.durationData} showIcon />
                  <LevelItem level={item.level} />
                </SpaceBetween>
              }
            >
              <div className="Container__truncate-content">
                {item.description}
              </div>
            </ContainerOverride>
          );
        })}
      </Carousel>
    </div>
  );

  return headerTitle ? (
    <Box>
      {id && (
        <span
          id={`eh-callout-carousel-${id}`}
          className="SharedLxpHomePage__jumpTo"
        />
      )}
      <Box margin={{ bottom: 'l' }}>
        <Header
          description={headerDescription}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel={formatMessage(appMessages.externalIconAriaLabel)}
                href={viewAllUrl}
                iconAlign="right"
                iconName="external"
                target="_blank"
                onFollow={() => {
                  publishCounterMetric('CalloutCarouselViewAllBtn', {
                    counterName: `Click:${id}`,
                  });
                }}
              >
                {formatMessage(appMessages.viewAllCTA)}
              </Button>
            </SpaceBetween>
          }
        >
          {headerTitle}
        </Header>
      </Box>
      <CarouselElement />
    </Box>
  ) : (
    <CarouselElement />
  );
};

CalloutCarousel.propTypes = {
  params: PropTypes.shape({
    carousel: PropTypes.instanceOf(calloutTypes.Carousel).isRequired,
  }),
};

export default CalloutCarousel;

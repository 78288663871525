export const actions = {
  GET_MARKDOWN_REQUEST: 'GET_MARKDOWN_REQUEST',
  GET_MARKDOWN_RESPONSE: 'GET_MARKDOWN_RESPONSE',
  GET_MARKDOWN_ERROR: 'GET_MARKDOWN_ERROR',
  NO_MARKDOWN_FOUND: 'NO_MARKDOWN_FOUND',
};

export const defaultState = {
  markdown: null,
  loading: false,
  noMarkdownFound: false,
};

export const blueprintReducer = (state, action) => {
  switch (action.type) {
    case actions.GET_MARKDOWN_REQUEST:
      return { ...state, loading: true, noMarkdownFound: false };
    case actions.GET_MARKDOWN_RESPONSE:
      return {
        ...state,
        loading: false,
        markdown: action.value,
        noMarkdownFound: false,
      };
    case actions.GET_MARKDOWN_ERROR:
      return { ...state, loading: false, noMarkdownFound: false };
    case actions.NO_MARKDOWN_FOUND:
      return { ...state, loading: false, noMarkdownFound: true };
    default:
      return state;
  }
};

export default {
  actions,
  blueprintReducer,
  defaultState,
};

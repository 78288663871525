export const PROD_DOMAINS = [
  '.events.training.aws.a2z.com',
  '.aws.training',
  '.skillbuilder.aws',
  '.labs.awsevents.com',
];

export const METRICS_METHOD = 'CookieBanner';
export const COOKIE_PREFS_ID = 'awsui-cookie-prefs-button';
export const COOKIE_PREFS_SELECTOR = `#${COOKIE_PREFS_ID}`;
export const SHORTBREAD_PREFS_SELECTOR = '[data-id="awsccc-cb-btn-customize"]';

// Cookies
export const TOUR_STORAGE_ID = 'site-tour-status'; // LocalStorage
export const THEME_STORAGE_ID = 'user-theme-selection'; // LocalStorage
export const LOCALE_STORAGE_ID = 'user-locale-selection'; // LocalStorage
export const RUM_CREDENTIALS = 'cwr_c'; // LocalStorage (managed by RUM)
export const RUM_USER = 'cwr_u'; // Cookie
export const RUM_SESSION = 'cwr_s'; // Cookie
export const EH_LOCATION = 'hol_rid'; // SessionStorage

/** @typedef {'essential' | 'performance' | 'functional' | 'advertising'} CookieCategory */

/**
 * The Cookie registry for Event Horizon.
 *
 * Adding new registry items? Refer to the runbook: https://tiny.amazon.com/om1i6fu4/wamazbinviewAWS_DeveOpExEven
 *
 * @type {Object.<string, {category: CookieCategory}>}
 */
export const COOKIE_REGISTRY = {
  [LOCALE_STORAGE_ID]: { category: 'essential' },
  [THEME_STORAGE_ID]: { category: 'essential' },
  [TOUR_STORAGE_ID]: { category: 'essential' },
  [RUM_CREDENTIALS]: { category: 'essential' },
  [RUM_USER]: { category: 'functional' },
  [RUM_SESSION]: { category: 'functional' },
  [EH_LOCATION]: { category: 'functional' },
};

import jaJP from './ja-JP';
import deDE from './de-DE';
import esES from './es-ES';
import esLA from './es-LA';
import frFR from './fr-FR';
import itIT from './it-IT';
import ptBR from './pt-BR';
import koKR from './ko-KR';
import zhCN from './zh-CN';
import zhTW from './zh-TW';
import idID from './id-ID';
import plPL from './pl-PL';
import thTH from './th-TH';
import ukUA from './uk-UA';
import viVN from './vi-VN';

export default {
  'ja-JP': jaJP,
  'de-DE': deDE,
  'es-ES': esES,
  'es-LA': esLA,
  'fr-FR': frFR,
  'it-IT': itIT,
  'pt-BR': ptBR,
  'ko-KR': koKR,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
  'id-ID': idID,
  'pl-PL': plPL,
  'th-TH': thTH,
  'uk-UA': ukUA,
  'vi-VN': viVN,
};

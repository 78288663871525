export const IMPRESSIONS = 'Impressions';

export const EH_FATAL_COUNTER = 'EventHorizonFatal';

const rumSessionAttributeNs = key => `eh:${key}`;

/**
 * RUM allows 10 custom attributes per event so we need to be considerate on how many we're adding.
 * All EH session attribute keys should use the `eh:...` namespace, ex `eh:vibeId`
 * @see {@link https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#metadataattributes}
 */
export const RUM_SESSION_ATTRIBUTES = {
  VIBE_ID: rumSessionAttributeNs('vibeId'),
};

import { path, pathOr, propEq, propOr } from 'ramda';

import { graphql } from './graphQLService';
import ERROR_TYPES from '../constants/errorTypes';

/**
 * @typedef {Object} LabRef
 * @property {string} labId
 */

/**
 * @typedef {Object} OngoingLab
 * @property {string} labId
 * @property {string} blueprintArn
 */

/**
 * @typedef {Object} Lab
 * @property {string} labId
 * @property {string} blueprintArn
 * @property {string} status
 * @property {Object[]} secrets
 * @property {string} secrets[].name
 * @property {string} secrets[].value
 * @property {string} createdOn
 * @property {string} estimatedReadyTime
 */

/**
 * @typedef {Object} Session
 * @property {string} consoleUrl
 * @property {string} endsOn
 */

/**
 * @typedef {Object} LabCreditsRemaining
 * @property {boolean} hasEnoughCredits
 * @property {Object} refreshDuration
 * @property {number} refreshDuration.hours
 * @property {number} refreshDuration.minutes
 */

export const GET_LAB_POLL_INTERVAL = 15000;

/**
 * Returns the requested lab.
 *
 * @param {string} labId
 * @param {boolean} [checkOngoing=false]
 * @returns {Promise<Lab>}
 */
export const getLab = (labId, checkOngoing = false) => {
  const fieldName = 'getLab';
  const getLabGql = `
    query GetLab($labId: ID!, $checkOngoing: Boolean) {
      ${fieldName}(labId: $labId, checkOngoing: $checkOngoing) {
        labId
        blueprintArn
        status
        secrets {
          name
          value
        }
        createdOn
        estimatedReadyTime
        expiresOn
      }
    }
  `;
  return graphql(getLabGql, { labId, checkOngoing }).then(
    path(['data', fieldName])
  );
};

/**
 * Returns a lab session.
 *
 * @param {string} labId
 * @returns {Promise<Session>}
 */
export const getSession = labId => {
  const fieldName = 'getSession';
  const getSessionGql = `
    query GetSession($labId: ID!) {
      ${fieldName}(labId: $labId) {
        consoleUrl
        endsOn
      }
    }
  `;
  return graphql(getSessionGql, { labId }).then(path(['data', fieldName]));
};

/**
 * Returns a list of ongoing labs.
 * Upstream labs service will only return active labs.
 * @see {@link https://code.amazon.com/packages/BunsenLabsModel/blobs/452eb9984fb56024cc024d463dab375d9facf382/--/model/documentation.xml#L270}
 *
 * @returns {Promise<OngoingLab[]>}
 */
export const getOngoingLabs = () => {
  const fieldName = 'listLabs';
  const listLabsGql = `
    query ListLabs {
      ${fieldName} {
        results {
          labId
          blueprintArn
          status
          secrets {
            name
            value
          }
          createdOn
          estimatedReadyTime
        }
      }
    }
  `;
  return graphql(listLabsGql).then(pathOr([], ['data', fieldName, 'results']));
};

/**
 * Stops the running lab. Missing labs will get treated as already stopped.
 * The lab might have been stopped in f.ex. another tab.
 *
 * Possible error types:
 * - 400 [BadRequest] - Invalid request
 * - 404 [NotFound] - Lab id not found
 * - 409 [Conflict] - Cannot stop lab in current state
 * - 500 [InternalServerError] - The service cannot complete the request
 * - Unauthorized - User is not authenticated (Added by AppSync).
 *
 * @param {string} labId
 * @returns {Promise}
 */
export const stopLab = labId => {
  const stopLabGql = `
    mutation StopLab($labId: ID!) {
      stopLab(labId: $labId)
    }
  `;
  return graphql(stopLabGql, { labId }).catch(error => {
    const errors = propOr([], 'errors', error);
    //  Treat missing lab as successful since the user is trying to stop a lab that can't be found.
    if (errors.find(propEq('errorType', ERROR_TYPES.NotFound))) {
      return {};
    } else {
      throw error;
    }
  });
};

/**
 * Starts a lab.
 *
 * Possible error types:
 * - 400 [BadRequest] - Invalid request
 * - 404 [NotFound] - Blueprint arn not found
 * - 409 [Conflict] - Already have an active lab
 * - 500 [InternalServerError] - The service cannot complete the request
 * - Unauthorized - User is not authenticated (Added by AppSync)
 *
 * @param {string} blueprintArn
 * @param {string} clientRequestToken
 * @param {boolean} acceptTerms
 * @returns {Promise<LabRef>}
 */
export const startLab = (
  attempt,
  blueprintArn,
  clientRequestToken,
  acceptTerms
) => {
  const fieldName = 'startLab';
  const startLabGql = `
    mutation StartLab($attempt: Int, $blueprintArn: ID!, $clientRequestToken: String, $acceptTerms: Boolean!) {
      ${fieldName}(attempt: $attempt, blueprintArn: $blueprintArn, clientRequestToken: $clientRequestToken, acceptTerms: $acceptTerms) {
        labId
      }
    }
  `;
  return graphql(startLabGql, {
    attempt,
    blueprintArn,
    clientRequestToken,
    acceptTerms,
  }).then(path(['data', fieldName]));
};

/**
 * Returns available lab credits for a user.
 *
 * @returns {Promise<LabCreditsRemaining>}
 */
export const getLabCreditsRemaining = () => {
  const fieldName = 'getLabCreditsRemaining';
  const getLabCreditsRemainingGql = `
    query GetLabCreditsRemaining {
      ${fieldName} {
        creditsRemaining
        refreshDuration
      }
    }
  `;
  return graphql(getLabCreditsRemainingGql).then(data => {
    const { creditsRemaining, refreshDuration } = pathOr(
      {},
      ['data', fieldName],
      data
    );
    const [hours, minutes] = refreshDuration.split(':');
    return {
      hasEnoughCredits: creditsRemaining > 0,
      refreshDuration: {
        hours: parseInt(hours, 10),
        minutes: parseInt(minutes, 10),
      },
    };
  });
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultTitle: {
    id: 'landingPage.defaultTitle',
    defaultMessage: 'Welcome to <b>AWS\u00a0Builder\u00a0Labs</b>', // \u00a0 is the unicode representation of html entity - nbsp
  },
  defaultMessage: {
    id: 'landingPage.defaultMessage',
    defaultMessage: 'Sign in to build real skills in a live lab environment',
  },
  // EH and Jam copy (used at re:Inforce 2024)
  ehAndJam_title: {
    id: 'landingPage.ehAndJam_title',
    defaultMessage: 'Take your skills to the next level',
  },
  ehAndJam_message: {
    id: 'landingPage.ehAndJam_message',
    defaultMessage:
      'Practice your skills (and learn new ones) with AWS Builder Labs and AWS Jam challenges.',
  },
  ehAndJam_labsTitle: {
    id: 'landingPage.ehAndJam_labsTitle',
    defaultMessage: 'AWS Builder Labs',
  },
  ehAndJam_labsDescription: {
    id: 'landingPage.ehAndJam_labsDescription',
    defaultMessage:
      'Practice using AWS services in a live sandbox environment. Choose a lab and follow step-by-step scenarios on specific topics. Allow 40-120 min per lab.',
  },
  ehAndJam_jamTitle: {
    id: 'landingPage.ehAndJam_jamTitle',
    defaultMessage: 'AWS Jam',
  },
  ehAndJam_jamDescription: {
    id: 'landingPage.ehAndJam_jamDescription',
    defaultMessage:
      'Play, Learn, Win! Choose from a menu of gamified real-life security challenges featuring AWS and its Partners. Compete on your own or with a team to secure your spot at the top of the leader board.',
  },
  // reInvent copy (used at re:Invent 2024)
  reInvent_title: {
    id: 'landingPage.reInvent_title',
    defaultMessage: 'Self-paced Training',
  },
  reInvent_message: {
    id: 'landingPage.reInvent_message',
    defaultMessage:
      'Take your AWS Cloud expertise to the next level with three self-paced, interactive learning options. With over 200 offerings, there’s something for you whether you’re a senior technical professional or an organizational decision maker.',
  },
  reInvent_labsTitle: {
    id: 'landingPage.reInvent_labsTitle',
    defaultMessage: 'AWS Builder Labs',
  },
  reInvent_labsDescription: {
    id: 'landingPage.reInvent_labsDescription',
    defaultMessage:
      'Self-paced labs help you learn cloud skills, hands-on with practice in the AWS Management Console. Learn at your own pace with interactive exercises with step-by-step instructions to help you learn cloud skills.',
  },
  reInvent_simulearnTitle: {
    id: 'landingPage.reInvent_simulearnTitle',
    defaultMessage: 'AWS SimuLearn',
  },
  reInvent_simulearnDescription: {
    id: 'landingPage.reInvent_simulearnDescription',
    defaultMessage:
      'Simulated scenarios powered by Amazon Bedrock generative AI provide a realistic business narrative and allow you to engage with virtual customers through an interactive customer conversation. Within your simulation you’ll gather requirements that will inform your architecture proposal, developing the problem-solving skills necessary to identify and build the best solutions.',
  },
  reInvent_jamTitle: {
    id: 'landingPage.reInvent_jamTitle',
    defaultMessage: 'AWS Jam',
  },
  reInvent_jamDescription: {
    id: 'landingPage.reInvent_jamDescription',
    defaultMessage:
      'Engage in a gamified learning environment that helps you develop your AWS Cloud skills through simulated use cases in an AWS Management Console sandbox. Select challenges by technical domain or role and by difficulty level. Clues are available to help navigate the challenges if needed.',
  },
});

export default messages;

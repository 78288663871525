import React, { useEffect } from 'react';
import {
  Container,
  Header,
  Link,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { publishCounterMetric } from '../utils/metrics';
import messages from './ForbiddenAccessErrorPage.messages';
import appMessages from '../i18n/app.messages';
import Main from '../components/Main';

export const reInventUrl = 'reinvent.labs.awsevents.com';

/**
 * Rendered when a customer is not on the EH or Gandalf ACL.
 *
 * The EH ACL will fail in an AuthZ check in AppSync while the Gandalf ACL will
 * fail when the customer attempt to signs in.
 */
const ForbiddenAccessErrorPage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    publishCounterMetric('ForbiddenAccess');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const normalContent = {
    header: formatMessage(messages.heading),
    main: <p>{formatMessage(messages.forbiddenError)}</p>,
  };

  const reInventContent = {
    header: formatMessage(messages.reInventHeading),
    main: (
      <>
        <p>{formatMessage(messages.reInventContentInfo)}</p>
        <p>
          {formatMessage(messages.reInventContentRegister, {
            link: chunks => (
              <Link
                href="https://reinvent.awsevents.com/register/?trk=direct"
                external
                externalIconAriaLabel={formatMessage(
                  appMessages.externalIconAriaLabel
                )}
              >
                {chunks}
              </Link>
            ),
          })}
        </p>
        <p>
          {formatMessage(messages.reInventContentSkillBuilder, {
            link: chunks => (
              <Link
                href="https://skillbuilder.aws/"
                external
                externalIconAriaLabel={formatMessage(
                  appMessages.externalIconAriaLabel
                )}
              >
                {chunks}
              </Link>
            ),
          })}
        </p>
      </>
    ),
  };

  const contentOverrides = {
    [reInventUrl]: reInventContent,
  };

  const content = contentOverrides[location.hostname] || normalContent;

  return (
    <Main>
      <Container header={<Header variant="h1">{content.header}</Header>}>
        <TextContent>{content.main}</TextContent>
      </Container>
    </Main>
  );
};

export default ForbiddenAccessErrorPage;

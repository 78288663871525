import { Auth } from '@aws-amplify/auth';
import logger from '../utils/logger';
import { retry } from '../utils/promiseUtils';
import { publishCounterMetric } from '../utils/metrics';
import RequireAuthentication from '../errors/RequireAuthentication';
import configService from './configService';
import { getPreferredLocale } from './localeUtils';
import configureAmplify from './configureAmplify';

/**
 * Returns the currently signed in user or throws if the user is not signed in.
 * @returns {Promise<Object>} User
 */
export const initiateAuth = async () => {
  try {
    const userData = await getCurrentAuthenticatedUser();
    return {
      isAuthenticated: true,
      user: userData,
    };
  } catch (e) {
    logger.debug('User is not authenticated:', e);
    return {
      isAuthenticated: false,
      user: null,
    };
  }
};

/**
 * Gets identities from current authenticated user, and retrieves the providerName.
 * If identities is undefined because current authenticated user is missing identities,
 * providerName will be COGNITO by default.
 * Once providerName is set, this reconifugres amplify to pass in
 * emailValidationRequired as true.
 * Finally, it calls handleSignIn() to reauthenitcate the user with email verification flow
 */
export const verifyEmail = async () => {
  const userData = await getCurrentAuthenticatedUser();
  const { public_provider_name } =
    userData?.signInUserSession?.idToken?.payload || {};
  const providerName = public_provider_name || 'COGNITO';

  publishCounterMetric('EmailValidationRequired', {
    counterName: 'EmailValidationRequired',
    additionalMetrics: { ProviderName: providerName },
  });

  await configureAmplify({ emailValidationRequired: true });
  handleSignIn(providerName);
};
/**
 * Starts the authentication flow if the error indicates it is needed.
 * @param {Error} err
 * @throws {Error} Rethrows the error when not a authentication error.
 */
export const catchRequiredAuthError = err => {
  if (err instanceof RequireAuthentication) {
    publishCounterMetric('Index', {
      additionalMetrics: { RedirectToAuthError: err.message },
      counterName: 'RedirectToAuth',
    });

    return handleSignIn();
  }
  throw err;
};

export const getCurrentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const handleSignOut = () => {
  return retry(() => Auth.signOut(), { retries: 2 });
};

/**
 * Has mechanism for test automation tools to preselect a provider through URL params, ex
 * using `?provider=AmazonFederate` will pre-select AmazonFederate as sign in provider.
 * @returns {Promise}
 */
export const handleSignIn = (pickedProvider = '') => {
  publishCounterMetric('NewSession', {
    additionalMetrics: {
      locale: getPreferredLocale(),
    },
  });
  // remove code and state
  const requestedPath = window.location.pathname + window.location.search;
  const urlParams = new URLSearchParams(window.location.search);
  const urlProvider = urlParams.get('provider');
  const provider = pickedProvider || urlProvider;
  return provider
    ? Auth.federatedSignIn({ customState: requestedPath, provider })
    : Auth.federatedSignIn({ customState: requestedPath });
};

/**
 * Sign the user out by going directly to the Cognito logout endpoint. Amplify Auth.signOut
 * will not do this if it thinks it is already signed out by checking the client state;
 * we need to clear Cognitos state however.
 *
 * This will result in navigating to Cognito then back to EH which will trigger a SignIn flow.
 *
 * See docs: https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
 */
export const signOutAndRedirectBack = async () => {
  const config = await configService.get();
  const logoutUrl = `https://${config.oauth.domain}/logout?client_id=${config.userPoolClientId}&logout_uri=${config.oauth.redirectSignOut}`;
  window.location.href = logoutUrl;
};

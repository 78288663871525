import { useQuery } from 'react-query';
import { isNil } from 'ramda';

import { getLab, GET_LAB_POLL_INTERVAL } from '../utils/labsService';
import { awsLabsStatuses } from '../constants/awsLabs';
import {
  interceptRequestMetrics,
  publishCounterMetric,
} from '../utils/metrics';
import ERROR_TYPES from '../constants/errorTypes';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';

// How long to wait before considering refetching in the background.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;

// Creates GraphQL error object from error type
const createError = errorType => ({ errors: [{ errorType }] });
const metricsNamespace = 'GetLabPoll';

/**
 * @typedef {import('../utils/labsService').Lab} Lab
 */

/**
 * @param {Object} props
 * @param {string} [props.labId] - Ongoing lab id
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {(data: Lab) => void} [props.onSuccess] - (optional) Callback on getLab success
 * @param {(error: any) => void} [props.onError] - (optional) Callback on getLab error
 * @returns {import('react-query').UseQueryResult<Lab>}
 */
const usePollGetLab = ({
  labId,
  metricsPublisher,
  onSuccess,
  onError,
} = {}) => {
  const enableQuery = !!labId;

  return useQuery({
    queryKey: ['usePollGetLab', labId],
    queryFn: () =>
      interceptRequestMetrics(
        metricsNamespace,
        getLab(labId),
        metricsPublisher
      ),
    enabled: enableQuery,
    staleTime: FIVE_MIN_IN_MS,
    refetchInterval: data => {
      if (!data?.status) return false;
      switch (data.status) {
        case awsLabsStatuses.INITIALIZING:
        case awsLabsStatuses.PROVISIONING:
          return GET_LAB_POLL_INTERVAL;
        default:
          return false;
      }
    },
    refetchIntervalInBackground: true,
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [ERROR_TYPES.BadRequest, ERROR_TYPES.NotFound],
        })
      ) {
        return false;
      }
      return failureCount <= 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 500 }),
    onError: error => {
      if (onError) onError(error);
    },
    onSuccess: data => {
      if (!data?.status) {
        if (onError) onError(createError(ERROR_TYPES.MissingLabStatus));
        return;
      }

      switch (data.status) {
        case awsLabsStatuses.READY:
        case awsLabsStatuses.RUNNING:
        case awsLabsStatuses.INITIALIZING:
        case awsLabsStatuses.PROVISIONING:
        case awsLabsStatuses.CLOSED:
          if (onSuccess) onSuccess(data);
          break;
        case awsLabsStatuses.ERROR:
          if (onError) onError(createError(ERROR_TYPES.ProvisioningError));
          return;
        case awsLabsStatuses.ENDED:
          if (onError) onError(createError(ERROR_TYPES.LabEnded));
          return;
        case awsLabsStatuses.EXPIRED:
          if (onError) onError(createError(ERROR_TYPES.LabExpired));
          return;
        case awsLabsStatuses.CANCELLED:
          if (onError) onError(createError(ERROR_TYPES.LabCancelled));
          return;
        default:
          if (onError) onError(createError(ERROR_TYPES.UnknownLabStatus));
          return;
      }

      // If lab completes provisioning and no estimated ready time was returned, log metric
      if (
        data.status === awsLabsStatuses.READY &&
        isNil(data.estimatedReadyTime)
      ) {
        publishCounterMetric('NoEstimatedReadyTimeShown', {
          additionalMetrics: {
            blueprintArn: data.blueprintArn,
          },
        });
      }
    },
  });
};

export default usePollGetLab;

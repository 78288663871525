import React, { useEffect } from 'react';
import { AppLayout } from '@amzn/awsui-components-react';
import debounce from 'lodash/debounce';

import './AppLayoutOverride.scss';

// exporting for test only
export const navPanelIconSelector =
  '[class^="awsui_navigation-toggle_"] svg path';
export const navPanelIconPathOverride = 'M15 2H1l5 6v7h4V8l5-6z';

// Override the hamburger icon that Polaris uses by default in the navigation panel.
const overrideNavPanelIcon = () => {
  const $elem = document.querySelector(navPanelIconSelector);
  $elem && $elem.setAttribute('d', navPanelIconPathOverride);
};
const debouncedOverrideNavPanelIcon = debounce(overrideNavPanelIcon, 100);

const AppLayoutOverride = ({ variant, forwardRef, ...props }) => {
  useEffect(() => {
    // Override the Polaris navigation toggle icon
    if (variant === 'filterNavigation') {
      overrideNavPanelIcon(); // initial change to icon
      // subsequent updates when the browser is resized
      window.addEventListener('resize', debouncedOverrideNavPanelIcon);
    }

    return () => {
      window.removeEventListener('resize', debouncedOverrideNavPanelIcon);
    };
  }, [variant]);

  return <AppLayout ref={forwardRef} {...props} />;
};

export default AppLayoutOverride;

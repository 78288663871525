import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TextContent,
  Box,
  Link,
  Alert,
  Header,
  SpaceBetween,
  Container,
  Button,
} from '@amzn/awsui-components-react';

import { useConfigContext } from '../../contexts';
import appMessages from '../../i18n/app.messages';
import ProviderButton from './ProviderButton';
import messages from './SignInContainer.messages';
import { handleSignIn } from '../../utils/authUtils';
import headerMessages from '../Header/Header.messages';
import {
  publishCounterMetric,
  publishButtonClickMetric,
} from '../../utils/metrics';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import { URL_PATHS } from '../../constants/urlPaths';

const SignInContainer = ({ usingEmailAcl, landingPageIDPs }) => {
  const history = useHistory();
  const location = useLocation();
  const config = useConfigContext();
  const { formatMessage } = useIntl();

  const [primarySignInOption, ...secondarySignInOptions] = landingPageIDPs;
  const enableUnauthenticatedCatalogPage =
    prop('enableUnauthenticatedCatalogPage', config) === 'true';

  // In case no IDPs are given, ex due to a misconfiguration, renders a failover button
  // that signs in through the Gandlalf UI.
  const useFailover = !primarySignInOption;

  return (
    <div style={{ height: '100%' }}>
      <Box padding="xxxl">
        <SpaceBetween direction="vertical" size="xs">
          <Header variant="h1" headingTagOverride="h2">
            {formatMessage(messages.headerMessage)}
          </Header>
          <Container>
            <SpaceBetween direction="vertical" size="s">
              {usingEmailAcl ? (
                <Alert statusIconAriaLabel="Info">
                  {formatMessage(messages.alertMessage)}
                </Alert>
              ) : null}

              {useFailover ? (
                <Button
                  variant="primary"
                  data-testid={dataTestId['landingPage-failoverBtn']}
                  onClick={() => {
                    publishCounterMetric('LandingPageFailoverClick');
                    handleSignIn();
                  }}
                >
                  {formatMessage(headerMessages.signIn)}
                </Button>
              ) : (
                <ProviderButton
                  idp={primarySignInOption}
                  usingEmailAcl={usingEmailAcl}
                />
              )}

              {secondarySignInOptions && secondarySignInOptions.length ? (
                <>
                  <TextContent>
                    <Box textAlign="center">
                      {formatMessage(messages.orMessage)}
                    </Box>
                  </TextContent>
                  {secondarySignInOptions.map(option => {
                    return (
                      <ProviderButton
                        key={option}
                        idp={option}
                        usingEmailAcl={usingEmailAcl}
                      />
                    );
                  })}
                </>
              ) : null}
            </SpaceBetween>
          </Container>
          <Box float="right">
            <Link
              href={`https://${config.signInUIDomain}/help`}
              data-testid="help-link"
              external
              externalIconAriaLabel={formatMessage(
                appMessages.externalIconAriaLabel
              )}
            >
              {formatMessage(messages.getHelpLinkMessage)}
            </Link>
          </Box>
          {enableUnauthenticatedCatalogPage && (
            <Box
              padding={{ vertical: 'm' }}
              margin={{ top: 'xl' }}
              textAlign="center"
            >
              <Button
                onClick={() => {
                  publishButtonClickMetric(
                    'LandingPageUnauthenticatedCatalogCTA',
                    {
                      destinationUrl: URL_PATHS.catalogPage,
                      currentPath: location.pathname,
                      clickLocation: 'UnauthenticatedCatalogPage',
                    }
                  );
                  history.push(URL_PATHS.unauthenticatedCatalogPage);
                }}
              >
                {formatMessage(messages.unauthenticatedCatalogPageCTA)}
              </Button>
            </Box>
          )}
        </SpaceBetween>
      </Box>
    </div>
  );
};

SignInContainer.propTypes = {
  usingEmailAcl: PropTypes.bool.isRequired,
  landingPageIDPs: PropTypes.arrayOf(PropTypes.string),
};

export default SignInContainer;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'labLanguages.description',
    defaultMessage: 'This lab is available in:',
  },
  popoverText: {
    id: 'labLanguages.popoverText',
    defaultMessage: 'Available languages',
  },
});

export default messages;

import { useRef } from 'react';
import useIsFirstRender from './useIsFirstRender';

let globalId = 0;

/**
 * Returns a unique ID that does not update when re-rendered. Useful for
 * when tying togetner elements using HTML IDs
 * @returns {String}
 */
const useUniqueId = () => {
  const idRef = useRef('');
  const isFirstRender = useIsFirstRender();

  if (isFirstRender) {
    idRef.current = `globalUniqueId${globalId++}`;
  }

  return idRef.current;
};

export default useUniqueId;

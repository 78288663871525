import { defineMessages } from 'react-intl';

const messages = defineMessages({
  estimatingTime: {
    id: 'labProgressBar.estimatingTime',
    defaultMessage:
      'Estimating the time remaining. Most labs take about 5 minutes, but it could take longer.',
  },
  estimatingTimeRetry: {
    id: 'labPage.estimatingTimeRetry',
    defaultMessage:
      "It's taking longer than expected, we're checking for a new estimate.",
  },
  noEstimateAvailable: {
    id: 'labPage.noEstimateAvailable',
    defaultMessage:
      "No estimated time available. Most labs take about 5 minutes, but it could take longer. We'll let you know when it's ready.",
  },
  minutesRemaining: {
    id: 'labProgressBar.minutesRemaining',
    defaultMessage:
      'About {minutesRemaining} {minutesRemaining, plural, one {minute} other {minutes}} remaining',
  },
});

export default messages;

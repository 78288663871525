import React from 'react';
import { Table } from '@amzn/awsui-components-react';

import './TableOverride.css';

const TableOverride = ({ columnHeaders = true, ...props }) => {
  if (!columnHeaders)
    return (
      <span className="TableOverride--headerless">
        <Table {...props} />
      </span>
    );
  return <Table {...props} />;
};

export default TableOverride;

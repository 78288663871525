import { defineMessages } from 'react-intl';

// Shortbread translations for these items can be found in this Wiki: https://w.amazon.com/bin/view/Shortbread/documentation/ux/#HLocalizedcontent
const messages = defineMessages({
  privacy: {
    id: 'legalZone.privacy',
    defaultMessage: 'Privacy',
  },
  siteTerms: {
    id: 'legalZone.siteTerms',
    defaultMessage: 'Site terms',
  },
  cookiePref: {
    id: 'legalZone.cookiePref',
    defaultMessage: 'Cookie preferences',
  },
});

export default messages;

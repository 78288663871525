import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Header, Container } from '@amzn/awsui-components-react';

import SEO from '../components/SEO';
import Main from '../components/Main';
import { publishCounterMetric } from '../utils/metrics';
import messages from './SignInErrorPage.messages';

const SignInErrorPage = ({ errorMessage }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    publishCounterMetric('SignInErrorPage', {
      additionalMetrics: {
        ErrorMessage: errorMessage || 'NA',
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main>
      <SEO title={formatMessage(messages.heading)} />
      <Container
        header={<Header variant="h1">{formatMessage(messages.heading)}</Header>}
      >
        {formatMessage(messages.message)}
      </Container>
    </Main>
  );
};

SignInErrorPage.propTypes = {
  errorMessage: PropTypes.string,
};

export default SignInErrorPage;

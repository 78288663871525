import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Mode } from '@amzn/awsui-global-styles';
import {
  TextContent,
  Icon,
  Header,
  Link,
  Button,
  Container,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import awsLogo from '../../images/providerAssets/AWS_logo_light_mode.svg';
import awsLogoDark from '../../images/providerAssets/AWS_logo_dark_mode.svg';
import lwaIcon from '../../images/providerAssets/lwa.svg';
import appMessages from '../../i18n/app.messages';
import { IdPs } from '../../constants/idPs';
import { handleSignIn } from '../../utils/authUtils';
import { useThemeContext } from '../../contexts';
import './ProviderButton.css';
import {
  messages,
  providerDescription,
  providerMessages,
  signoutFromIdp,
} from './ProviderButton.messages';
import { publishCounterMetric } from '../../utils/metrics';

const ProviderButton = ({ idp, usingEmailAcl }) => {
  const { formatMessage } = useIntl();
  const [theme] = useThemeContext();
  let iconUrl = '';

  switch (idp) {
    case IdPs.AWSBuilderID:
      if (theme === Mode.Dark) {
        iconUrl = awsLogoDark;
      } else {
        iconUrl = awsLogo;
      }
      break;
    case IdPs.LoginWithAmazon:
      iconUrl = lwaIcon;
      break;
  }

  return (
    <Container>
      <div className={'ProviderButton__rowElements'}>
        {iconUrl && (
          <div className="ProviderButton__icon" data-testid="signInIcon">
            <Icon size="large" url={iconUrl} alt="AWS" />
          </div>
        )}
        <div className={'ProviderButton__idpHeader'}>
          <Header
            data-testid="signin-providerLabel"
            variant="h2"
            headingTagOverride="h3"
            description={formatMessage(providerDescription[idp])}
          >
            {formatMessage(providerMessages[idp], {
              i: str => {
                <i>{str}</i>;
              },
            })}
          </Header>
          {idp == IdPs.AWSBuilderID ? (
            <TextContent>
              <SpaceBetween direction="horizontal" size="xxl">
                <Link
                  href="https://docs.aws.amazon.com/signin/latest/userguide/sign-in-aws_builder_id.html?icmpid=docs_builder_id_console"
                  external
                  externalIconAriaLabel={formatMessage(
                    appMessages.externalIconAriaLabel
                  )}
                >
                  {formatMessage(messages.learnMoreLabel)}
                </Link>
                {usingEmailAcl ? (
                  <Link
                    href="https://profile.aws.amazon.com/"
                    external
                    externalIconAriaLabel={formatMessage(
                      appMessages.externalIconAriaLabel
                    )}
                    onFollow={() => {
                      publishCounterMetric('LandingPageIdpLogoutClick', {
                        counterName: idp,
                      });
                    }}
                  >
                    {formatMessage(signoutFromIdp[idp])}
                  </Link>
                ) : null}
              </SpaceBetween>
            </TextContent>
          ) : null}
        </div>
        <div className="ProviderButton__signInButton">
          <Button
            wrapText={false}
            data-testid={`${idp}-provider-button`}
            onClick={() => {
              publishCounterMetric('LandingPageLoginClick', {
                counterName: idp,
              });
              handleSignIn(idp);
            }}
            ariaLabel={`${formatMessage(messages.signInButton)} ${formatMessage(providerMessages[idp])}`}
          >
            {formatMessage(messages.signInButton)}
          </Button>
        </div>
      </div>
    </Container>
  );
};

ProviderButton.propTypes = {
  idp: PropTypes.string.isRequired,
  usingEmailAcl: PropTypes.bool.isRequired,
};

export default ProviderButton;

import { useRef } from 'react';

/**
 * Will return true for it's first render and false for all subsequent re-renders.
 * @returns {Boolean}
 */
const useIsFirstRender = () => {
  const isFirstRender = useRef(true);

  // This will only be tru for the frst render
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return true;
  }
  return false;
};

export default useIsFirstRender;

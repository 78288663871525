import { defineMessages } from 'react-intl';

export const appMessages = defineMessages({
  title: {
    id: 'app.title',
    defaultMessage: 'Self-Paced Labs',
  },
  loading: {
    id: 'app.loading',
    defaultMessage: 'Loading...',
  },
  info: {
    id: 'app.info',
    defaultMessage: 'Info',
  },
  externalIconAriaLabel: {
    id: 'app.externalIconAriaLabel',
    defaultMessage: '(Opens in a new browser tab)',
  },
  builderLabsTitle: {
    id: 'app.builderLabsTitle',
    defaultMessage: 'AWS Builder Labs',
  },
  awsJamTitle: {
    id: 'app.awsJamTitle',
    defaultMessage: 'AWS Jam',
  },
  viewAllCTA: {
    id: 'app.viewAllCTA',
    defaultMessage: 'View All',
  },
});

export default appMessages;

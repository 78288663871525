import { LOCALE_CODES } from '../constants/locale';

const matchLeadingSlashesRegex = /^\/*/;

/**
 * Uses `window.history.replaceState` but ensures the URL is cleaned up from
 * any bad state.
 *
 * @example
 * historyReplaceState({}, '', '/location?param1=abc&param2=123')
 *
 * @param {Object} data
 * @param {String} title
 * @param {String} url
 */
export const historyReplaceState = (data, title, url) => {
  const [pathname = '', searchParams = ''] = url.split('?');
  // Replace any subsequent leading slashes which will result in a security exception.
  // As the pathname is taken from the current URL we cannot fully control it.
  const cleanedPathname = pathname.startsWith('/')
    ? pathname.replace(matchLeadingSlashesRegex, '/')
    : pathname;
  window.history.replaceState(
    data,
    title,
    `${cleanedPathname}${searchParams ? `?${searchParams}` : ''}`
  );
};

/**
 * Retrieve the lab Arn from the given path.
 * @param path the path of the URI.
 * @returns {string|undefined} Returns the lab arn if the input path contains one, otherwise undefined.
 */
export const getLabArnFromPath = path => {
  const decodedPath = decodeURIComponent(path);
  const labURIRegExp = new RegExp(
    `/lab/(.*)/${Object.keys(LOCALE_CODES).join('|')}`
  );
  const isLabPage = labURIRegExp.test(decodedPath);
  const labURIPathMatches = labURIRegExp.exec(decodedPath);

  if (isLabPage && labURIPathMatches.length >= 2) {
    return labURIPathMatches[1];
  } else {
    return undefined;
  }
};

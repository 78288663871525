import { defineMessages } from 'react-intl';

const messages = defineMessages({
  primaryMessage: {
    id: 'closedEventPage.primaryMessage',
    defaultMessage: 'Event is closed',
  },
  secondaryMessage: {
    id: 'closedEventPage.secondaryMessage',
    defaultMessage: 'We hope to see you at the <a>next event</a>!',
  },
});

export default messages;

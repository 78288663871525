import { defineMessages } from 'react-intl';

const messages = defineMessages({
  waitingHeader: {
    id: 'labCreditBanner.waitingHeader',
    defaultMessage: 'Lab limit reached',
  },
  waitingContent: {
    id: 'labCreditBanner.waitingContent',
    defaultMessage:
      'We’re glad you’re enjoying the labs! To ensure that everyone has time for labs, we’ve set a lab limit for each user. {br} You’ve reached that limit. Come back in {duration} to continue learning. {br} In the meantime, get a head start by reading the instructions for the lab!',
  },
  refreshingHeader: {
    id: 'labCreditBanner.refreshingHeader',
    defaultMessage: 'Lab limit refreshing',
  },
  refreshedHeader: {
    id: 'labCreditBanner.refreshedHeader',
    defaultMessage: 'Lab limit refreshed',
  },
  refreshedContent: {
    id: 'labCreditBanner.refreshedContent',
    defaultMessage:
      'You can now continue learning! Please reload the page or select another lab to get started.',
  },
  error: {
    id: 'labCreditBanner.error',
    defaultMessage:
      'Uh oh, something went wrong while refreshing lab credits. Please reload the page and try again.',
  },
});

export default messages;

import React, { useEffect } from 'react';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import SEO from '../components/SEO';
import Main from '../components/Main';
import { publishCounterMetric } from '../utils/metrics';
import messages from './ActiveLabOnlyErrorPage.messages';
import { dataTestId } from '../constants/dataTestIdSelectors';

const ActiveLabOnlyErrorPage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    publishCounterMetric('ActiveLabOnlyErrorPage');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main>
      <SEO title={formatMessage(messages.heading)} />
      <Container
        header={<Header variant="h1">{formatMessage(messages.heading)}</Header>}
        data-testid={dataTestId['active-lab-only-error']}
      >
        <SpaceBetween size="s">
          <p>{formatMessage(messages.message)}</p>
        </SpaceBetween>
      </Container>
    </Main>
  );
};

export default ActiveLabOnlyErrorPage;

import React from 'react';
import { Box, Popover, Icon } from '@amzn/awsui-components-react';
import { pluck } from 'ramda';
import { useIntl } from 'react-intl';

import messages from './LabLanguages.messages';
import { LOCALES } from '../../../constants/locale';

import './LabLanguages.css';
import { flashbarI18nStrings } from '../../../i18n/flashbarI18nStrings.messages';

const LabLanguages = ({ blueprintLocales }) => {
  const { formatMessage } = useIntl();

  const getLocaleList = locales =>
    pluck('locale', locales)
      // Blueprints might contain locales which are not supported
      .filter(locale => LOCALES[locale])
      .map(locale => ({
        langAttr: locale,
        text: LOCALES[locale].label,
      }));

  return (
    <Popover
      dismissButton={false}
      position="right"
      triggerType="text"
      content={
        <>
          {formatMessage(messages.description)}
          <ul className="LabLangauges__localeList">
            {getLocaleList(blueprintLocales).map(({ langAttr, text }) => (
              <li key={langAttr} lang={langAttr}>
                {text}
              </li>
            ))}
          </ul>
        </>
      }
    >
      <Box variant="span" margin={{ right: 'xxs' }} color="inherit">
        <span role="img" aria-label={formatMessage(flashbarI18nStrings.info)}>
          <Icon name="status-info" />
        </span>
      </Box>
      {formatMessage(messages.popoverText)}
    </Popover>
  );
};

export default LabLanguages;

import React from 'react';
import { useEventContext, useLabContext } from '../../contexts';
import MultipleOngoingLabs from './MultipleOngoingLabs';
import SingleOngoingLab from './SingleOngoingLab';

const OngoingLabBanner = ({ isStandalone }) => {
  const { event } = useEventContext();
  const { labState, getFirstLabInLabState } = useLabContext();
  const concurrentLabsLimit = event.getConcurrentLabsLimit();

  return concurrentLabsLimit > 1 &&
    isStandalone &&
    labState.labs.length === concurrentLabsLimit ? (
    <MultipleOngoingLabs />
  ) : concurrentLabsLimit === 1 ? (
    <SingleOngoingLab
      isStandalone={isStandalone}
      labState={getFirstLabInLabState()}
    />
  ) : null;
};

export default OngoingLabBanner;

import { useEffect } from 'react';

import { publishCounterMetric } from '../../utils/metrics';

/**
 * Emits a impression metric when a custom callout is being used.
 *
 * @param {string} customCalloutType
 */
const useEmitCalloutImpression = customCalloutType => {
  useEffect(() => {
    publishCounterMetric('CustomCallouts', {
      additionalMetrics: {
        CalloutType: customCalloutType,
      },
    });
  }, [customCalloutType]);
};

export default useEmitCalloutImpression;

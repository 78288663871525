import React from 'react';
import { useIntl } from 'react-intl';

import { ButtonOverride } from '../PolarisOverrides';
import { useConnectChatContext } from '../../contexts';
import { TOUR_IDS } from '../../constants/siteTour';
import { SUPPORT_BTN_ID } from '../../constants/support';
import { publishButtonClickMetric } from '../../utils/metrics';
import messages from './LiveSupport.messages';

import chatIcon from './assets/chat.svg';
import activeChatIcon from './assets/chat-active.svg';
import { path } from 'ramda';

const LiveSupportButton = ({ onClick, ...props }) => {
  const { formatMessage } = useIntl();
  const chatContext = useConnectChatContext();
  const chatSessionActive = path(
    ['chatState', 'chatSessionActive'],
    chatContext
  );

  return (
    <ButtonOverride
      {...props}
      id={SUPPORT_BTN_ID}
      variant="transparent"
      iconUrl={chatSessionActive ? activeChatIcon : chatIcon}
      iconAlt=""
      data-tourid={TOUR_IDS.liveSupportBtn}
      onClick={() => {
        if (!chatSessionActive)
          publishButtonClickMetric('LiveSupportButton', {
            currentPath: window.location.pathname,
          });
        if (onClick) onClick();
      }}
    >
      {formatMessage(messages.liveSupportBtn)}
    </ButtonOverride>
  );
};

export default LiveSupportButton;

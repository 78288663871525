import React, { useEffect, useRef } from 'react';
import { CHATBOX_ID, SUPPORT_BTN_SELECTOR } from '../../constants/support';
import './ChatBox.css';

const ChatBox = ({ chatWindowOpen }) => {
  const chatBoxRef = useRef();

  useEffect(() => {
    chatWindowOpen
      ? chatBoxRef.current.querySelector('[role="textbox"]')?.focus()
      : document.querySelector(SUPPORT_BTN_SELECTOR)?.focus();
  }, [chatWindowOpen, chatBoxRef]);

  return (
    <div
      ref={chatBoxRef}
      id={CHATBOX_ID}
      className="ConnectChat"
      style={chatWindowOpen ? { display: 'inline-block' } : { display: 'none' }}
    ></div>
  );
};

export default ChatBox;

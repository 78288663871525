import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'unauthenticatedCatalogPage.heading',
    defaultMessage: 'Self-paced training catalog',
  },
  description: {
    id: 'unauthenticatedCatalogPage.description',
    defaultMessage:
      'Explore available content from different learning experience providers.',
  },
  cta: {
    id: 'unauthenticatedCatalogPage.cta',
    defaultMessage: 'Access Interactive Labs',
  },
});

export default messages;

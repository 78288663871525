import React from 'react';
import {
  Alert,
  Box,
  Button,
  Popover,
  Spinner,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './LabEvaluation.messages';
import { dataTestId } from '../../../constants/dataTestIdSelectors';
import ExpandableSupportInformation from '../../../components/ExpandableSupportInformation';

/**
 * The score of the evaluation result
 */
const EvaluationScore = ({
  onSubmit,
  isPollingEvaluation,
  isSubmitting,
  isSubmitError,
  isSubmitSuccessfull,
  evaluationResult,
  labId,
  hasSubmissionDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { percentageScore } = evaluationResult || {};
  const supportInfo = `Error: ScoreSubmission \nLabId: ${labId} \nExecutionID: ${evaluationResult?.executionId} \nLabURL: ${document.location.href} \n`;
  return (
    <>
      <Box
        variant="h3"
        data-testid={dataTestId['labEval-evaluateScoreHeading']}
      >
        {formatMessage(messages.evaluateScoreHeading)}{' '}
        {!hasSubmissionDisabled && (
          <Popover
            position="top"
            dismissButton={false}
            content={formatMessage(messages.submitScorePopoverInfo)}
          >
            <StatusIndicator colorOverride="grey" type="info"></StatusIndicator>
          </Popover>
        )}
      </Box>
      {isSubmitSuccessfull && (
        <Box margin={{ top: 'xs', bottom: 'xs' }}>
          <Alert type="success">
            {formatMessage(messages.submitScoreSuccessfull)}
          </Alert>
        </Box>
      )}
      {isSubmitError && (
        <Box margin={{ top: 'xs', bottom: 'xs' }}>
          <Alert type="warning">
            {formatMessage(messages.submitScoreFailedMessage)}
            <ExpandableSupportInformation
              expandedInfo={formatMessage(messages.supportInfoMessage)}
              copyableInfo={supportInfo}
            />
          </Alert>
        </Box>
      )}
      <div className="LabEvaluation__scoreBox">
        <Box
          fontSize="display-l"
          color="text-status-info"
          data-testid={dataTestId['labEval-evaluationPercentageScore']}
        >
          {isPollingEvaluation ? (
            <Spinner size="large" />
          ) : (
            <>{isNaN(percentageScore) ? 'N/A' : `${percentageScore}%`}</>
          )}
        </Box>
        {!hasSubmissionDisabled && (
          <Button
            variant="normal"
            disabled={isPollingEvaluation}
            loading={isSubmitting}
            onClick={onSubmit}
            data-testid={dataTestId['labEval-submit']}
          >
            {formatMessage(messages.submitScoreButton)}
          </Button>
        )}
      </div>
    </>
  );
};

export default EvaluationScore;

import React from 'react';
import { Popover, ColumnLayout } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { has } from 'ramda';

import ConnectChat from './ConnectChat';
import InPersonSupport from './InPersonSupport';
import LiveSupportButton from './LiveSupportButton';
import { useConnectChatContext, useEventContext } from '../../contexts';
import FEATURES from '../../constants/features';
import messages from './LiveSupport.messages';
import { path } from 'ramda';

const LiveSupportPopover = () => {
  const { formatMessage } = useIntl();
  const chatContext = useConnectChatContext();
  const { toggleChatWindow } = chatContext;
  const chatSessionActive = path(
    ['chatState', 'chatSessionActive'],
    chatContext
  );
  return chatSessionActive ? (
    <LiveSupportButton
      ariaLabel={chatSessionActive ? formatMessage(messages.toggleLabel) : null}
      onClick={toggleChatWindow}
    />
  ) : (
    <Popover
      position="top"
      triggerType="custom"
      size="large"
      content={
        <ColumnLayout columns={2} variant="text-grid">
          <InPersonSupport contentOnly />
          <ConnectChat contentOnly />
        </ColumnLayout>
      }
      dismissAriaLabel={formatMessage(messages.closeLabel)}
    >
      <LiveSupportButton />
    </Popover>
  );
};

const hasLocales = has('locales');

const LiveSupport = () => {
  const { event } = useEventContext();
  const connectEnabled = event.hasFeature(FEATURES.connectChat);

  const inPersonMetadata = event.getMetadata(FEATURES.inPersonSupport);
  const inPersonSupportEnabled =
    event.hasFeature(FEATURES.inPersonSupport) &&
    hasLocales(inPersonMetadata) &&
    inPersonMetadata.locales.length > 0; // has a least one locale present

  if (connectEnabled && inPersonSupportEnabled) return <LiveSupportPopover />;
  if (connectEnabled) return <ConnectChat />;
  if (inPersonSupportEnabled) return <InPersonSupport />;

  return null;
};

export default LiveSupport;

export const TOUR_STORAGE_TEXT = 'shown';

export const TOUR_IDS = {
  localeMenu: 'localeMenu',
  userMenu: 'userMenu',
  helpPanel: 'helpPanel',
  liveSupportBtn: 'liveSupportBtn',
  feedbackBtn: 'feedbackBtn',
  labHeader: 'labHeader',
  launchBtn: 'launchBtn',
  labPath: 'labPath',
};

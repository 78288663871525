import { defineMessages } from 'react-intl';

const messages = defineMessages({
  link: {
    id: 'skipNavigation.link',
    defaultMessage: 'Skip to main content',
  },
});

export default messages;

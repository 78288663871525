import React from 'react';
import { Spinner, Alert } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './LabCreditBanner.messages';
import DurationItem from './LabInformation/DurationItem';

const AlertItem = ({ hasEnoughCredits, duration, error }) => {
  const { formatMessage } = useIntl();

  // Error state when query fails
  if (error)
    return (
      <Alert visible={true} dismissible={false}>
        {formatMessage(messages.error)}
      </Alert>
    );

  // Success state for a refreshed lab limit
  if (hasEnoughCredits)
    return (
      <Alert
        visible={true}
        dismissible={false}
        type="success"
        header={formatMessage(messages.refreshedHeader)}
      >
        {formatMessage(messages.refreshedContent)}
      </Alert>
    );

  // Intermediate state while verifying the lab limit has refreshed
  if (duration.hours === 0 && duration.minutes === 0)
    return (
      <Alert
        visible={true}
        dismissible={false}
        header={
          <>
            {formatMessage(messages.refreshingHeader)} <Spinner />
          </>
        }
      ></Alert>
    );

  // Waiting state while duration is counting down
  return (
    <Alert
      visible={true}
      dismissible={false}
      header={formatMessage(messages.waitingHeader)}
    >
      {formatMessage(messages.waitingContent, {
        duration: <DurationItem duration={duration} />,
        br: <br />,
      })}
    </Alert>
  );
};

const LabCreditBanner = ({ hasEnoughCredits, refreshDuration, error }) => {
  if (!refreshDuration) return null;

  return (
    <AlertItem
      hasEnoughCredits={hasEnoughCredits}
      duration={refreshDuration}
      error={error}
    />
  );
};

LabCreditBanner.propTypes = {
  hasEnoughCredits: PropTypes.bool,
  refreshDuration: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  error: PropTypes.object,
};

export default LabCreditBanner;

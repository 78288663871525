import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@amzn/awsui-components-react';

import './CalloutBanner.scss';
import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { CALLOUT_TYPES } from './constants';
import EasyMarkdown from '../EasyMarkdown';
import { calloutTypes } from '../../models/EHCustomCallouts';

const CalloutBanner = ({ params }) => {
  useEmitCalloutImpression(CALLOUT_TYPES.banner);
  // maxWidth restricts banner image size so it doesn't take up the whole lab content area
  const { img, alt = '', maxWidth = '1200px' } = params.banner || {};
  const content = params.content;

  return (
    <Container
      disableContentPaddings
      data-testid={`callout-${CALLOUT_TYPES.banner}`}
    >
      <div className="CustomCallouts__banner">
        <img
          className="CustomCallouts__banner-img"
          src={img}
          alt={alt}
          style={{ maxWidth }}
        />
      </div>
      {content && <EasyMarkdown content={content} padding />}
    </Container>
  );
};

CalloutBanner.propTypes = {
  params: PropTypes.shape({
    banner: PropTypes.instanceOf(calloutTypes.Banner).isRequired,
    content: PropTypes.string,
  }),
};

export default CalloutBanner;

import { or, prop } from 'ramda';

/**
 * Copies supplied text into clipboard.
 * @param {String} textToCopy
 * @param {Object} $elementRef Optional input ref to place focus to after copy
 * @param {{ document: Document }} optionalGlobals For unit testing. Leave unspecified or pass 'window'.
 */
const copyToClipboard = (
  textToCopy,
  $elementRef,
  { ...optionalGlobals } = {}
) => {
  if (!textToCopy) return;

  textToCopy = textToCopy.trim();
  if (textToCopy.indexOf('\n') >= 0) {
    // Append newline to multiline text as an application contention.
    // In lab sessions, multiline text often represents shell commands
    // that are intended to be executed in a terminal, where the trailing
    // newline executes the last one.
    textToCopy += '\n';
  }

  const globals = or(prop('globals', optionalGlobals), window);
  const textArea = globals.document.createElement('textarea');
  textArea.style = 'position: absolute; left: -1000px; top: -1000px';
  textArea.value = textToCopy;
  globals.document.body.appendChild(textArea);
  textArea.select();
  globals.document.execCommand('copy');
  globals.document.body.removeChild(textArea);

  // Return focus to supplied element
  if ($elementRef.current) $elementRef.current.focus();
};

export default copyToClipboard;

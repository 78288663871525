import { isEmpty, isNil, path } from 'ramda';

import logger from '../../../utils/logger';
import { retry } from '../../../utils/promiseUtils';
import { graphql } from '../../../utils/graphQLService';
import { interceptRequestMetrics, publishFatal } from '../../../utils/metrics';

export const startConnectChat = (
  contactFlowId,
  displayName,
  instanceId,
  userAttributes
) => {
  const startConnectChatGql = `
    mutation StartConnectChat($input: ConnectChatInput!) {
      startConnectChat(connectChatInput: $input) {
        contactId
        participantId
        participantToken
      }
    }
    `;
  const input = {
    input: {
      contactFlowId,
      displayName,
      instanceId,
      userAttributes,
    },
  };
  const metricsNamespace = 'StartConnectChat';
  return interceptRequestMetrics(
    metricsNamespace,
    retry(() => graphql(startConnectChatGql, input))
  )
    .then(path(['data', 'startConnectChat']))
    .catch(error => {
      logger.debug(error);
      publishFatal(metricsNamespace, error, { ...input });
      // Return null to trigger connection failure state in ConnectChat component.
      return null;
    });
};

/**
 * Get the number of available agents in a queue
 * @param {string} instanceId
 * @param {string} queueId
 */
export const getAvailableAgents = (instanceId = '', queueId = '') => {
  const getAvailableAgentsGql = `
    query GetAvilableAgents($instanceId: String, $queueId: String){
      getAvailableAgents(instanceId: $instanceId, queueId: $queueId){
        availableAgents
      }
    }
  `;
  const input = {
    instanceId: instanceId,
    queueId: queueId,
  };
  const metricsNamespace = 'GetAvailableAgents';
  return interceptRequestMetrics(
    metricsNamespace,
    retry(() => graphql(getAvailableAgentsGql, input))
  )
    .then(path(['data', 'getAvailableAgents', 'availableAgents']))
    .catch(error => {
      logger.debug(error);
      publishFatal(metricsNamespace, error, { ...input });
      // Return 0 to trigger no support agent availability state in ConnectChat component.
      return 0;
    });
};

/**
 * Initialize the Amazon Connect chat connection.
 * @param {id} id - id of the element in which the chat interface will mount.
 * @param {Object} options Options for chat connection
 * @param {string} options.displayName
 * @param {string} options.userAttributes Fields to be made available to support agent
 * @param {string} options.region Region of the Connect instance
 * @param {string} options.contactFlowId Default used if blank
 * @param {string} options.instanceId Default used if blank
 * @param {function} successHandler
 * @param {function} failureHandler
 */
export const initChatConnection = async ({
  id,
  options,
  setChatSession,
  successHandler,
  failureHandler,
}) => {
  const results = await Promise.all([
    startConnectChat(
      options.contactFlowId,
      options.displayName,
      options.instanceId,
      options.userAttributes
    ),
    loadConnectDeps(),
  ]);
  const chatDetails = results[0];

  if (
    !chatDetails ||
    !chatDetails.contactId ||
    !chatDetails.participantId ||
    !chatDetails.participantToken
  ) {
    failureHandler();
    return;
  }
  initChatInterface(id);
  return window.connect.ChatInterface.initiateChat(
    {
      name: options.displayName,
      region: options.region,
      contactId: chatDetails.contactId,
      participantId: chatDetails.participantId,
      participantToken: chatDetails.participantToken,
    },
    chatSession => {
      setChatSession(chatSession);
      successHandler();
    },
    failureHandler
  );
};

/**
 * Initializes the chat interface. This needs to be called before the chat
 * connection initiailization.
 * @param {string} id - id of the element in which the chat interface will
 * mount.
 */
export const initChatInterface = id => {
  if (!document.getElementsByClassName('connect-customer-interface').length) {
    window.connect.ChatInterface.init({
      containerId: id,
    });
  }
};

/**
 * Loads the Amazon Connect chat interface onto the page.
 * @returns {Promise}
 */
export const loadConnectDeps = async () => {
  if (window.connect) {
    return;
  }
  await import('./amazon-connect-chat-interface.terserignore.js');
};

export const hasChatHours = metadata =>
  !isEmpty(metadata) && !isEmpty(metadata.chatOpenBlocks);

/**
 * Check if it is currently within one of the chat availability time blocks.
 * @param {object} metadata feature flag metadata for connect chat
 * @param {array} metadata.chatOpenBlocks array of data for when chat is open
 *      [{
 *          startTime: UTC time, // e.g. 13:00Z
 *          endTime: UTC time, // e.g. 15:00Z
 *          daysOfWeek: UTC days // e.g. 0123456, where 0 is Sunday
 *      }]
 * @param {object} currentDate can be set for testing, otherwise defaults to current date
 */
export const currentlyInOpenHours = (metadata, currentDate = new Date()) => {
  if (!hasChatHours(metadata)) return false;
  const currentDayOfWeek = currentDate.getUTCDay();
  const curYearDayStr = `${currentDate.toISOString().split('T')[0]}T`;

  return metadata.chatOpenBlocks
    .map(timeBlock => {
      if (
        isNil(timeBlock.startTime) ||
        isNil(timeBlock.endTime) ||
        isNil(timeBlock.daysOfWeek) ||
        !timeBlock.daysOfWeek.includes(currentDayOfWeek)
      )
        return false;
      const timeBlockStart = new Date(curYearDayStr + timeBlock.startTime);
      const timeBlockEnd = new Date(curYearDayStr + timeBlock.endTime);
      return currentDate >= timeBlockStart && currentDate <= timeBlockEnd;
    })
    .some(Boolean);
};

import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { includes } from 'ramda';

import { useEventContext } from './EventContextProvider';
import translations from '../i18n/translations';
import {
  changeShortbreadLanguage,
  localStorageAdapter,
} from '../utils/cookieManagement';
import { getLanguageCode } from '../utils/localeUtils';
import {
  LOCALE_CODES,
  LOCALE_SHORT_CODES,
  DEFAULT_LOCALE,
} from '../constants/locale';
import { LOCALE_STORAGE_ID } from '../constants/cookies';

export const LocaleContext = createContext();
export const useLocaleContext = () => useContext(LocaleContext);

const getSupportedBrowserLang = () => {
  let browserLang;
  navigator.languages.some(lang => {
    browserLang =
      LOCALE_CODES[lang] || LOCALE_SHORT_CODES[getLanguageCode(lang)];
    return Boolean(browserLang);
  });
  return browserLang;
};

const useGetLocale = () => {
  const userLocalePref = localStorageAdapter.getItem(LOCALE_STORAGE_ID);
  const browserLang = getSupportedBrowserLang();
  const {
    event: { defaultLocale },
  } = useEventContext();

  const localeParam = new URLSearchParams(window.location.search).get('locale');
  let deepLinkLocale;
  if (includes(localeParam, Object.values(LOCALE_CODES))) {
    deepLinkLocale = localeParam;
  }

  /**
   * Order of locale priority:
   *    1. Get lab in search param locale (e.g.: ?locale=xx-XX)
   *    2. Get lab in user selected locale (stored in local storage)
   *    3. Get lab in browser language
   *    4. Get lab in default event locale (pulled via EMS data)
   *    5. Get lab in whatever locale is available
   *
   * Related SIMs:
   *    - https://sim.amazon.com/issues/EventHorizon-1372
   *    - https://sim.amazon.com/issues/EventHorizon-1340
   */
  const [locale, setLocale] = useState(
    deepLinkLocale || userLocalePref || browserLang || DEFAULT_LOCALE
  );

  // Update locale if necessary
  useEffect(() => {
    if (deepLinkLocale) return;
    if (userLocalePref) return;
    if (browserLang) return;
    if (!defaultLocale) return;

    if (includes(defaultLocale, Object.values(LOCALE_CODES))) {
      setLocale(defaultLocale);
    }
  }, [defaultLocale, userLocalePref, browserLang, deepLinkLocale]);

  return [locale, setLocale];
};

const LocaleContextProvider = ({ children }) => {
  const [locale, setLocale] = useGetLocale();

  const setUserPrefLocale = useCallback(
    locale => {
      localStorageAdapter.setItem(LOCALE_STORAGE_ID, locale);
      setLocale(locale);
    },
    [setLocale]
  );

  useEffect(() => {
    // Update the document lang tag with current locale
    // for better accessibility and to assist browers
    document.documentElement.lang = getLanguageCode(locale);
  }, [locale]);

  useEffect(() => {
    // Shortbread does not currently support quick locale changes.
    // The following snippet needs to be wrapped in a setTimeout
    // as the shortbread DOM elements may not have been appended yet.
    // https://w.amazon.com/bin/view/Shortbread/usage#HHELP21
    setTimeout(() => {
      const existingShortbreadEl = document.getElementById('awsccc-sb-ux-c');
      existingShortbreadEl && existingShortbreadEl.remove();
      changeShortbreadLanguage(locale);
    });
  }, [locale]);

  return (
    <LocaleContext.Provider value={[locale, setUserPrefLocale]}>
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        locale={locale}
        messages={translations[locale]}
        wrapRichTextChunksInFragment={true} //https://github.com/formatjs/formatjs/issues/1549
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleContextProvider;

import React from 'react';
import { useIntl } from 'react-intl';
import { Mode } from '@amzn/awsui-global-styles';
import { path } from 'ramda';

import { ButtonDropdownOverride } from '../PolarisOverrides';
import { publishButtonClickMetric } from '../../utils/metrics';
import { USER_MENU } from '../../constants/userMenu';
import { TOUR_IDS } from '../../constants/siteTour';
import { useThemeContext } from '../../contexts';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './UserMenu.messages';

const publishMenuMetrics = (menuTitle, itemId) =>
  publishButtonClickMetric(`${menuTitle}:${itemId}`, {
    currentPath: window.location.pathname,
    clickLocation: 'Header',
  });

const getThemeMenuItems = ({ currentTheme, formatMessage }) => {
  return [
    {
      text: formatMessage(messages.themeHeader),
      items: [
        {
          text: formatMessage(messages.light),
          id: USER_MENU.lightTheme,
          disabled: currentTheme === Mode.Light,
        },
        {
          text: formatMessage(messages.dark),
          id: USER_MENU.darkTheme,
          disabled: currentTheme === Mode.Dark,
        },
      ],
    },
  ];
};

const getThemeMenuCallbacks = setCurrentTheme => ({
  [USER_MENU.lightTheme]: () => setCurrentTheme(Mode.Light),
  [USER_MENU.darkTheme]: () => setCurrentTheme(Mode.Dark),
});

const ThemeMenu = ({ isStandalone }) => {
  const { formatMessage } = useIntl();
  const [currentTheme, setCurrentTheme] = useThemeContext();

  const themeMenuCallbacks = getThemeMenuCallbacks(setCurrentTheme);

  const menuItems = getThemeMenuItems({
    currentTheme,
    formatMessage,
    isStandalone,
  });

  return (
    <>
      <ButtonDropdownOverride
        variant="transparent"
        activeDisabled
        items={menuItems}
        onItemClick={event => {
          const itemId = path(['detail', 'id'], event);
          publishMenuMetrics('ThemeMenu', itemId);
          const itemCallback = themeMenuCallbacks[itemId];
          if (!itemCallback) return;
          itemCallback();
        }}
        ariaLabel={`${formatMessage(messages.themeHeader)} ${formatMessage(messages[currentTheme])}`}
        data-jsid="header-themeMenu"
        data-testid={dataTestId['header-themeMenu']}
        data-tourid={TOUR_IDS.userMenu}
      >
        {formatMessage(messages[currentTheme])}
      </ButtonDropdownOverride>
    </>
  );
};

export default ThemeMenu;

import React from 'react';
import PropTypes from 'prop-types';

import ErrorPage from '../pages/ErrorPage';
import logger from '../utils/logger';
import { publishFatal } from '../utils/metrics';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo = {}) {
    logger.error('ErrorBoundary', error, errorInfo);
    this.setState({
      hasError: true,
    });
    const stackString = errorInfo.componentStack || 'NA';
    publishFatal('ErrorBoundary', error, {
      ComponentStack: stackString.toString().replace(/\n/g, '').trim(),
    });
  }

  render() {
    if (this.state.hasError)
      return (
        <ErrorPage
          isOutsideRouter={true}
          isAuthenticated={Boolean(this.props.isAuthenticated)}
        />
      );

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
};

export default ErrorBoundary;

import React, { useEffect, useMemo, useState } from 'react';
import { TextFilter } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';

import { getQueryParam, updateQueryParam } from '../../utils/queryParamUtils';
import { SEARCH_KEYS, SEARCH_QUERY_PARAM } from '../../constants/catalog';
import messages from './Catalog.messages';
import { EHBlueprint } from '../../models';

const CatalogSearch = ({ catalogList, setRefinerState }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const [searchText, setSearchText] = useState(
    getQueryParam(history.location.search, SEARCH_QUERY_PARAM)
  );

  const fuse = useMemo(
    () =>
      new Fuse(catalogList, {
        threshold: 0.4,
        ignoreLocation: true,
        useExtendedSearch: true,
        includeScore: true,
        keys: SEARCH_KEYS,
      }),
    [catalogList]
  );

  useEffect(() => {
    const searchText = getQueryParam(location.search, SEARCH_QUERY_PARAM);
    if (!searchText) {
      setRefinerState(prevState => ({
        ...prevState,
        searchText: '',
        searchResult: null,
      }));
      setSearchText('');
      return;
    }
    const searchResult = fuse.search(searchText);
    setRefinerState(prevState => ({
      ...prevState,
      searchText,
      searchResult,
    }));
  }, [location.search, fuse, setRefinerState]);

  const onSearchInput = event =>
    setSearchText(path(['detail', 'filteringText'], event));

  const onDelayedSearchInput = event => {
    const searchText = path(['detail', 'filteringText'], event);
    const modQueryString = updateQueryParam(
      history.location.search,
      SEARCH_QUERY_PARAM,
      searchText
    );
    history.replace({
      pathname: history.location.pathname,
      search: modQueryString ? `?${modQueryString}` : '',
    });
  };

  return (
    <TextFilter
      filteringText={searchText}
      filteringAriaLabel={formatMessage(messages.filteringLabel)}
      filteringPlaceholder={formatMessage(messages.filteringPlaceholder)}
      onChange={onSearchInput}
      onDelayedChange={onDelayedSearchInput}
      filteringClearAriaLabel={formatMessage(messages.filteringClearAriaLabel)}
    />
  );
};

CatalogSearch.propTypes = {
  catalogList: PropTypes.arrayOf(PropTypes.instanceOf(EHBlueprint)),
  setRefinerState: PropTypes.func.isRequired,
};

export default CatalogSearch;

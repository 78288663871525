/**
 * Returns the specified parameter from the query string
 * @param {String} queryString
 * @param {String} parameter
 */
export const getQueryParam = (queryString, parameter, urlObj = null) => {
  const queryParams = urlObj ? urlObj : new URLSearchParams(queryString);
  return queryParams.get(parameter) || '';
};

/**
 * Sets the parameter in the query string to the specified value if it is not
 * empty and returns a query string
 * @param {String} queryString
 * @param {String} parameter
 * @param {String} value
 * @param {Object} urlObj a URLSearchParams object to be used instead of creating
 * new one.
 */
export const updateQueryParam = (
  queryString,
  parameter,
  value,
  urlObj = null
) => {
  const queryParams = urlObj ? urlObj : new URLSearchParams(queryString);
  if (value) {
    queryParams.set(parameter, value);
  } else {
    queryParams.delete(parameter);
  }
  return queryParams.toString();
};

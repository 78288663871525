import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { applyMode, Mode } from '@amzn/awsui-global-styles';

import { useEventContext } from './EventContextProvider';
import { THEME_STORAGE_ID } from '../constants/cookies';
import { localStorageAdapter } from '../utils/cookieManagement';

export const ThemeContext = createContext();
export const useThemeContext = () => useContext(ThemeContext);

const LIGHT_THEME_QUERY = '(prefers-color-scheme: light)';
const DARK_THEME_QUERY = '(prefers-color-scheme: dark)';

const getCurrentTheme = () =>
  window.matchMedia(LIGHT_THEME_QUERY).matches
    ? Mode.Light
    : window.matchMedia(DARK_THEME_QUERY).matches
      ? Mode.Dark
      : undefined;

const ThemeContextProvider = ({ children }) => {
  const userThemePref = localStorageAdapter.getItem(THEME_STORAGE_ID);
  const [currentTheme, setCurrentTheme] = useState(
    userThemePref || getCurrentTheme() || Mode.Dark
  );
  const [hasUsedDefault, setHasUsedDefault] = useState(false);
  const {
    event: { defaultTheme },
  } = useEventContext();

  const setUserPrefTheme = useCallback(
    selectedTheme => {
      const theme = selectedTheme.toLowerCase();
      localStorageAdapter.setItem(THEME_STORAGE_ID, theme);
      setCurrentTheme(theme);
    },
    [setCurrentTheme]
  );

  useEffect(() => {
    applyMode(currentTheme || Mode.Dark);
  }, [currentTheme]);

  useEffect(() => {
    // Use user preference if available, and do not update theme if the event default changes async.
    if (userThemePref || getCurrentTheme() || hasUsedDefault) return;
    if (!defaultTheme) return;
    setCurrentTheme(defaultTheme.toLowerCase());
    setHasUsedDefault(true);
  }, [defaultTheme, userThemePref, hasUsedDefault]);

  useEffect(() => {
    const lightThemelistener = e => {
      if (e.matches) {
        setCurrentTheme(Mode.Light);
      }
    };
    const lightThemeQuery = window.matchMedia(LIGHT_THEME_QUERY);
    lightThemeQuery.addEventListener('change', lightThemelistener);
    const darkThemeListener = e => {
      if (e.matches) {
        setCurrentTheme(Mode.Dark);
      }
    };
    const darkThemeQuery = window.matchMedia(DARK_THEME_QUERY);
    darkThemeQuery.addEventListener('change', darkThemeListener);
    return () => {
      lightThemeQuery.removeEventListener('change', lightThemelistener);
      darkThemeQuery.removeEventListener('change', darkThemeListener);
    };
  }, []);

  return (
    <ThemeContext.Provider value={[currentTheme, setUserPrefTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContextProvider;

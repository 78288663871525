import { useQuery } from 'react-query';

import { getLabCreditsRemaining } from '../utils/labsService';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';

// How long to wait before considering refetching in the background.
const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const ONE_HOUR_IN_MS = 60 * 60 * 1000;
const ONE_MIN_IN_MS = 60 * 1000;

const getRefreshInterval = ({ hours, minutes }) => {
  const interval = hours * ONE_HOUR_IN_MS + minutes * ONE_MIN_IN_MS;
  // Refresh interval should be at least one minute
  return interval > ONE_MIN_IN_MS ? interval : ONE_MIN_IN_MS;
};

const metricsNamespace = 'GetLabCreditsRemaining';

/**
 * @typedef {import('../utils/labsService').LabCreditsRemaining} LabCreditsRemaining
 */

/**
 * @returns {import('react-query').UseQueryResult<LabCreditsRemaining>}
 */
const useGetLabCreditsRemaining = () => {
  return useQuery({
    queryKey: ['useGetLabCreditsRemaining'],
    queryFn: () =>
      interceptRequestMetrics(metricsNamespace, getLabCreditsRemaining()),
    staleTime: FIVE_MIN_IN_MS,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    refetchInterval: data => {
      if (!data?.refreshDuration || data.hasEnoughCredits) {
        return false;
      }
      return getRefreshInterval(data.refreshDuration);
    },
    retry: (failureCount, error) => {
      if (isNonRetryableError(error)) return false;
      return failureCount < 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 100 }),
    onError: error => {
      publishFatal(metricsNamespace, error);
    },
  });
};

export default useGetLabCreditsRemaining;

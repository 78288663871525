import React, { useCallback } from 'react';
import { Box, SpaceBetween, Button, Alert } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  isOngoing,
  useAuthContext,
  useLabContext,
  useLocaleContext,
} from '../../contexts';
import { publishButtonClickMetric } from '../../utils/metrics';
import messages from './SingleOngoingLab.messages';
import { EndLabButton } from '../LabUI/LabButtons';
import { URL_PATHS } from '../../constants/urlPaths';
import { getLanguageCode, getLocaleOrAvailable } from '../../utils/localeUtils';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import useGetBlueprint from '../../hooks/useGetBlueprint';
import LoadingSkeleton from '../LoadingSkeleton';
import appMessages from '../../i18n/app.messages';

const OngoingLab = ({ isStandalone, labState }) => {
  const { formatMessage } = useIntl();
  const [{ isAuthenticated }] = useAuthContext();
  const history = useHistory();
  const { stopLab, isStoppingLab } = useLabContext();
  const [userLocale] = useLocaleContext();

  const { data: blueprint, isError } = useGetBlueprint({
    blueprintArn: labState ? labState.blueprintArn : null,
  });

  const handleStopLab = useCallback(() => {
    stopLab(labState);
  }, [stopLab, labState]);

  const { locale, title } = getLocaleOrAvailable(userLocale, blueprint) || {};
  if (isError) {
    return (
      <Alert
        dismissible={false}
        header={
          <Box
            variant="h2"
            fontSize="heading-s"
            color="inherit"
            padding={{ vertical: 'n' }}
          >
            {formatMessage(messages.header)}
          </Box>
        }
      >
        <Box padding={{ vertical: 'xs' }} textAlign="left">
          <SpaceBetween size="xs">
            <p>
              {formatMessage(messages.loadOngoingLabFail, {
                br: <br />,
              })}
            </p>
            <Button onClick={() => window.location.reload()} iconName="refresh">
              {formatMessage(messages.reload)}
            </Button>
          </SpaceBetween>
        </Box>
      </Alert>
    );
  }

  if (!isAuthenticated || !labState || !isOngoing(labState.status)) return null;

  const labUrl = `${
    isStandalone ? URL_PATHS.standaloneLabPage : URL_PATHS.labPage
  }/${encodeURIComponent(labState.blueprintArn)}/${locale}/${labState.labId}`;

  return (
    <Alert
      dismissible={false}
      header={
        <Box
          variant="h2"
          fontSize="heading-s"
          color="inherit"
          padding={{ vertical: 'n' }}
        >
          {formatMessage(messages.header)}
        </Box>
      }
    >
      <Box margin={{ top: 'xs' }} fontSize="heading-m" color="inherit">
        {!blueprint.arn ? (
          <LoadingSkeleton type="darkGrey" width={40} height={22} />
        ) : (
          <span lang={getLanguageCode(locale)}>{title}</span>
        )}
      </Box>
      <Box variant="p" margin={{ bottom: 'xs' }} color="inherit">
        {formatMessage(messages.note)}
      </Box>
      <SpaceBetween direction="horizontal">
        <EndLabButton
          onClick={handleStopLab}
          variant={isStandalone ? 'primary' : 'normal'}
          loading={isStoppingLab}
          data-testid={dataTestId['ongoing-endLabBtn']}
        />
        {isStandalone ? (
          <Button
            variant="link"
            data-testid={dataTestId['ongoing-existingLabLink']}
            href={labUrl}
            target="_blank"
            iconName="external"
            iconAlign="right"
            onClick={() => publishButtonClickMetric('ExistingLabLink')}
            ariaLabel={`${formatMessage(
              messages.existingLabLink
            )} ${formatMessage(appMessages.externalIconAriaLabel)}`}
          >
            {formatMessage(messages.existingLabLink)}
          </Button>
        ) : (
          <Button
            variant="primary"
            data-testid={dataTestId['ongoing-resumeLabBtn']}
            onClick={() => {
              history.push(labUrl);
              publishButtonClickMetric('ResumeLabButton');
            }}
          >
            {formatMessage(messages.resumeLabButton)}
          </Button>
        )}
      </SpaceBetween>
    </Alert>
  );
};

OngoingLab.propTypes = {
  isStandalone: PropTypes.bool,
};

export default OngoingLab;

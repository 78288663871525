import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'CLF-C01': {
    id: 'certifications.cloudPractitioner',
    defaultMessage: 'Cloud Practitioner',
  },
  'SAA-C02': {
    id: 'certifications.solutionsArchitectAssociate',
    defaultMessage: 'Solutions Architect - Associate',
  },
  'SOA-C01': {
    id: 'certifications.sysOpsAdministratorAssociate',
    defaultMessage: 'SysOps Administrator - Associate',
  },
  'DVA-C01': {
    id: 'certifications.developerAssociate',
    defaultMessage: 'Developer - Associate',
  },
  'SAP-C01': {
    id: 'certifications.solutionsArchitectProfessional',
    defaultMessage: 'Solutions Architect - Professional',
  },
  'DOP-C01': {
    id: 'certifications.devOpsEngineerProfessional',
    defaultMessage: 'DevOps Engineer - Professional',
  },
  'ANS-C00': {
    id: 'certifications.advancedNetworkingSpecialty',
    defaultMessage: 'Advanced Networking - Specialty',
  },
  'AXS-C01': {
    id: 'certifications.alexaSkillBuilderSpecialty',
    defaultMessage: 'Alexa Skill Builder - Specialty',
  },
  'DAS-C01': {
    id: 'certifications.dataAnalyticsSpecialty',
    defaultMessage: 'Data Analytics - Specialty',
  },
  'DBS-C01': {
    id: 'certifications.databaseSpecialty',
    defaultMessage: 'Database - Specialty',
  },
  'MLS-C01': {
    id: 'certifications.machineLearningSpeciality',
    defaultMessage: 'Machine Learning - Specialty',
  },
  'SCS-C01': {
    id: 'certifications.securitySpeciality',
    defaultMessage: 'Security - Specialty',
  },
});

export default messages;

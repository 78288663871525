export const actions = {
  CHAT_STARTING: 'CHAT_STARTING',
  CHAT_SUCCESS: 'CHAT_SUCCESS',
  CHAT_FAILURE: 'CHAT_FAILURE',
  CHAT_DISCONNECT: 'CHAT_DISCONNECT',
  CHAT_CLOSE: 'CHAT_CLOSE',
  SET_CHAT_SESSION: 'SET_CHAT_SESSION',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  TOGGLE_CHAT_WINDOW: 'TOGGLE_CHAT_WINDOW',
  INCREASE_UNREAD_COUNT: 'INCREASE_UNREAD_COUNT',
  RESET_UNREAD_COUNT: 'RESET_UNREAD_COUNT',
};

export const defaultState = {
  chatButtonDisabled: false,
  chatSessionActive: false,
  chatWindowOpen: false,
  chatLoading: false,
  chatStarting: false,
  chatSession: {},
  unreadMessages: 0,
};

export const connectChatReducer = (state, action) => {
  switch (action.type) {
    case actions.CHAT_STARTING:
      return {
        ...state,
        chatStarting: true,
      };
    case actions.CHAT_SUCCESS:
      return {
        ...state,
        chatButtonDisabled: false,
        chatLoading: false,
        chatWindowOpen: true,
        chatSessionActive: true,
        chatStarting: false,
      };
    case actions.CHAT_FAILURE:
      return {
        ...state,
        chatButtonDisabled: !state.chatButtonDisabled,
        chatLoading: false,
        chatStarting: false,
      };
    case actions.CHAT_DISCONNECT:
      return {
        ...state,
        chatSessionActive: false,
        chatStarting: false,
      };
    case actions.CHAT_CLOSE:
      return {
        ...state,
        chatWindowOpen: false,
      };
    case actions.SET_CHAT_SESSION:
      return {
        ...state,
        chatSession: action.chatSession,
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        chatButtonDisabled: !state.chatButtonDisabled,
        chatLoading: !state.chatLoading,
      };
    case actions.TOGGLE_CHAT_WINDOW:
      return {
        ...state,
        chatWindowOpen: !state.chatWindowOpen,
      };
    case actions.INCREASE_UNREAD_COUNT:
      return {
        ...state,
        unreadMessages: ++state.unreadMessages,
      };
    case actions.RESET_UNREAD_COUNT:
      return {
        ...state,
        unreadMessages: 0,
      };

    default:
      return state;
  }
};

export default {
  actions,
  connectChatReducer,
  defaultState,
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  closed: {
    id: 'labStateNotification.closed',
    defaultMessage: 'Lab session has exceeded the budget and is inactive.',
  },
  consoleTip: {
    id: 'labPage.consoleTip',
    defaultMessage:
      'Tip: open the console in a new window to see it side-by-side with these instructions.',
  },
  dismiss: {
    id: 'labPage.dismiss',
    defaultMessage: 'Dismiss notification',
  },
  loading: {
    id: 'labPage.loading',
    defaultMessage: 'Provisioning AWS services ...',
  },
  ready: {
    id: 'labPage.ready',
    defaultMessage: 'Lab is ready.',
  },
  regionNote: {
    id: 'labPage.regionNote',
    defaultMessage: 'Open the console to begin. Keep the default region.',
  },
  regionNoteWithExpiryTime: {
    id: 'labPage.regionNoteWithExpiryTime',
    defaultMessage:
      'Open the console to begin. Keep the default region. Your lab will be active until {expiryDate} at {expiryTime}.',
  },
});

export default messages;

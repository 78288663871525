import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Box, Container } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { prop, path, map } from 'ramda';
import axios from 'axios';
import { useIntl } from 'react-intl';

import MarkdownRenderer from '../MarkdownRenderer';
import {
  blueprintReducer,
  defaultState,
  actions as blueprintActions,
} from './blueprintState';
import { notificationTypes, useNotificationContext } from '../../contexts';
import metrics, {
  interceptRequestMetrics,
  publishFatal,
} from '../../utils/metrics';
import { getMarkdownResources } from '../../utils/blueprintUtils';
import { graphql } from '../../utils/graphQLService';
import { retry } from '../../utils/promiseUtils';
import logger from '../../utils/logger';
import { MD_WRAPPER_CLASSNAME } from '../../constants/lab';
import ERROR_MESSAGES from '../../i18n/errors.messages';
import LabMarkdownLoading from './LabMarkdownLoading';
import { LabAssessmentPortal } from './LabAssessment';
import './LabMarkdown.scss';

const getResource = `
  query GetResource($arn: ID!) {
    getResource(arn: $arn) {
      arn
      url
    }
  }
`;

/**
 * @param {String} arn
 * @returns {Promise<Array[String]>} Resource
 */
const goGetResource = arn => {
  return graphql(getResource, { arn }).then(path(['data', 'getResource']));
};

/**
 * @param {Array[String]} arns List of resource ARNs
 * @returns {Promise<Array[String]>} List of Resource
 */
const getResources = arns => {
  return new Promise((resolve, reject) => {
    const promises = arns.map(goGetResource);
    Promise.all(promises).then(resolve).catch(reject);
  });
};

/**
 * @param {String} url
 * @returns {Promise<String>} Content
 */
const getUrlContent = url => axios.get(url).then(prop('data'));

/**
 * @param {Array[String]} urls
 * @returns {Promise<Array[String]>} List of resource contents
 */
const getResourceFiles = urls => {
  return new Promise((resolve, reject) => {
    const promises = urls.map(getUrlContent);
    Promise.all(promises).then(resolve).catch(reject);
  });
};

/**
 * Resources might contain a list of markdown files, normally just one.
 * When there is a list with more than one the current business rule is to
 * combine the result to a single file.
 * Markdown resources are expected to be in the correct order.
 *
 * @param {Array[String]} markdownContents
 * @returns {String}
 */
const combineMarkdown = markdownContents => markdownContents.join('');

/**
 * @param {Array[String]} resourceArns
 * @returns {Promise<String>} Markdown content
 */
const getMarkdown = resourceArns => {
  return Promise.resolve(resourceArns)
    .then(getResources)
    .then(map(prop('url')))
    .then(getResourceFiles)
    .then(combineMarkdown);
};

/**
 * Hook to enable listening to text selection events in Lab Markdown to
 * better understand how often it is used.
 * Note that it does not support capturing events when using the keyboard
 * to select text.
 *
 * @param {String} markdown Lab instructions.
 * @param {Function} onSelection Callback function when lab instruction text has been selected.
 */
const useLabTextSelectionListener = (markdown, onSelection) => {
  useEffect(() => {
    const captureLabTextSelectionEvent = event => {
      // Disregard events that are originating from code blocks, we are only
      // interested in text selection in the lab instructions.
      const codeBlockSelectors = [
        'code',
        'pre',
        'input',
        '.InlineCode',
        '.CodeBlock',
      ];
      if (
        !document.getSelection ||
        event.target.closest(codeBlockSelectors.join(', '))
      ) {
        return;
      }

      // Only trigger callback when there is a selected range of text.
      const selection = document.getSelection();
      if (selection.type !== 'Range') return;
      onSelection();
    };

    const $mdContent = document.querySelector(`.${MD_WRAPPER_CLASSNAME}`);
    if (!$mdContent) return;

    $mdContent.addEventListener('mouseup', captureLabTextSelectionEvent);
    return () => {
      if (!$mdContent) return;
      $mdContent.removeEventListener('mouseup', captureLabTextSelectionEvent);
    };
  }, [markdown, onSelection]);
};

const LabMarkdown = ({
  blueprint,
  setToc,
  blueprintLocale,
  lang,
  ongoingLabId,
  isLabAssessmentEnabled,
}) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useReducer(blueprintReducer, defaultState);
  const { appendNotification } = useNotificationContext();
  const [parentNode, setParentNode] = useState(null);

  const markdownPublisher = useRef(metrics.createPublisher('Markdown'));
  useLabTextSelectionListener(state.markdown, () => {
    markdownPublisher.current.publishCounter('LabTextSelection', 1);
  });

  useEffect(() => {
    let didUnmount = false;
    const markdownResources = getMarkdownResources(blueprintLocale, blueprint);
    if (!markdownResources || markdownResources.length === 0) {
      markdownPublisher.current.publishCounter('NoMarkdownFound', 1);
      dispatch({
        type: blueprintActions.NO_MARKDOWN_FOUND,
      });
      return;
    }

    dispatch({ type: blueprintActions.GET_MARKDOWN_REQUEST });
    const metricsNamespace = 'GetMarkdown';
    interceptRequestMetrics(
      metricsNamespace,
      retry(() => getMarkdown(markdownResources.map(prop('arn'))), {
        retries: 3,
        interval: 200,
      }),
      markdownPublisher.current
    )
      .then(markdown => {
        if (didUnmount) return;
        dispatch({
          type: blueprintActions.GET_MARKDOWN_RESPONSE,
          value: markdown,
        });
      })
      .catch(error => {
        logger.debug(error);
        publishFatal(metricsNamespace, error);

        if (didUnmount) return;

        /* Possible error types:
         * 400 [Bad Request] - ARN is empty or invalid
         * 404 [Not Found] - Collection/Blueprint/Resource doesn't exist or can’t be accessed
         * 409 [Conflict] - ARN didn't specify a Version ID, there is no default,
         *                  and there is no active/valid version to choose
         * 500 [Internal Server Error] - Can't call CSDS or unhandled exception
         */
        dispatch({ type: blueprintActions.GET_MARKDOWN_ERROR });
        appendNotification({
          contentId: ERROR_MESSAGES.loadMarkdownFail,
          type: 'warning',
          notificationType: notificationTypes.LAB,
        });
      });
    return () => {
      didUnmount = true;
    };
  }, [blueprint, blueprintLocale, appendNotification]);

  if (state.noMarkdownFound) {
    return <Box variant="h3">{formatMessage(ERROR_MESSAGES.getLab404)}</Box>;
  }

  if (state.loading) {
    return <LabMarkdownLoading />;
  }

  return (
    <Container disableContentPaddings>
      <div
        className={`${MD_WRAPPER_CLASSNAME} LabMarkdown`}
        lang={lang}
        ref={setParentNode}
      >
        <MarkdownRenderer
          locale={blueprintLocale}
          markdown={state.markdown}
          setToc={setToc}
        />
        {isLabAssessmentEnabled && (
          <LabAssessmentPortal labId={ongoingLabId} parentNode={parentNode} />
        )}
      </div>
    </Container>
  );
};

LabMarkdown.propTypes = {
  blueprint: PropTypes.object,
  setToc: PropTypes.func.isRequired,
  blueprintLocale: PropTypes.string.isRequired,
  lang: PropTypes.string,
  ongoingLabId: PropTypes.string,
  isLabAssessmentEnabled: PropTypes.bool,
};

export default LabMarkdown;

import { equals, omit } from 'ramda';
import { v4 as uuid } from 'uuid';

import logger from '../utils/logger';

export const actions = {
  APPEND_NOTIFICATION: 'APPEND_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SEARCH_AND_REMOVE_NOTIFICATION: 'SEARCH_AND_REMOVE_NOTIFICATION',
  CLEAR_ALL_LAB_NOTIFICATIONS: 'CLEAR_ALL_LAB_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
};

/** @typedef {'global'|'lab'} NotificationType */

/** @readonly @enum {NotificationType} */
export const notificationTypes = {
  GLOBAL: 'global', // visible across the entire app
  LAB: 'lab', // visible only on labs
};

/**
 * @typedef {Object} Notification
 * @property {string} type
 * @property {string} id
 * @property {?boolean} dismissible
 * @property {notificationTypes} notificationType
 * @property {?string} headerId
 * @property {?string} headerText
 * @property {?string} contentId
 * @property {?string} contentText
 * @property {?string} lang
 * @property {Object} messageValues
 */

/**
 * @param {Notification[]} notificationArray Current notification array
 * @param {Notification} newNotification New notification to be displayed
 * @returns {boolean} True if equal, false otherwise
 */
const isNotificationAlreadyShowing = (notificationArray, newNotification) => {
  return notificationArray.some(notification =>
    equals(omit(['id'], notification), newNotification)
  );
};

/**
 * @param {Notification[]} notifications
 * @param {*} action
 * @returns {Notification[]}
 */
export const notificationReducer = (notifications, action) => {
  switch (action.type) {
    case actions.APPEND_NOTIFICATION: {
      if (isNotificationAlreadyShowing(notifications, action.value))
        return notifications;

      return [...notifications, { ...action.value, id: uuid() }];
    }

    case actions.REMOVE_NOTIFICATION: {
      return notifications.filter(
        notification => notification.id !== action.id
      );
    }

    case actions.SEARCH_AND_REMOVE_NOTIFICATION: {
      const { searchKey, searchValue } = action.searchData;
      return notifications.filter(
        notification => notification[searchKey] !== searchValue
      );
    }

    case actions.CLEAR_ALL_LAB_NOTIFICATIONS:
      return notifications.filter(
        notification => notification.notificationType !== notificationTypes.LAB
      );

    case actions.CLEAR_NOTIFICATIONS:
      return [];

    default:
      logger.debug(`Unhandled action: ${action.type}`);
      return notifications;
  }
};

export default {
  actions,
  notificationReducer,
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  a4b: {
    id: 'services.a4b',
    defaultMessage: 'Alexa for Business',
  },
  apigateway: {
    id: 'services.apigateway',
    defaultMessage: 'Amazon API Gateway',
  },
  cloudformation: {
    id: 'services.cloudformation',
    defaultMessage: 'AWS CloudFormation',
  },
  cloudfront: {
    id: 'services.cloudfront',
    defaultMessage: 'Amazon CloudFront',
  },
  cloudtrail: {
    id: 'services.cloudtrail',
    defaultMessage: 'AWS CloudTrail',
  },
  cloudwatch: {
    id: 'services.cloudwatch',
    defaultMessage: 'Amazon CloudWatch',
  },
  codecommit: {
    id: 'services.codecommit',
    defaultMessage: 'AWS CodeCommit',
  },
  codedeploy: {
    id: 'services.codedeploy',
    defaultMessage: 'AWS CodeDeploy',
  },
  config: {
    id: 'services.config',
    defaultMessage: 'AWS Config',
  },
  devicefarm: {
    id: 'services.devicefarm',
    defaultMessage: 'AWS Device Farm',
  },
  dms: {
    id: 'services.dms',
    defaultMessage: 'AWS Database Migration Service (AWS DMS)',
  },
  dynamodb: {
    id: 'services.dynamodb',
    defaultMessage: 'Amazon DynamoDB',
  },
  ebs: {
    id: 'services.ebs',
    defaultMessage: 'Amazon Elastic Block Store (Amazon EBS)',
  },
  ec2: {
    id: 'services.ec2',
    defaultMessage: 'Amazon Elastic Compute Cloud (Amazon EC2)',
  },
  ecr: {
    id: 'services.ecr',
    defaultMessage: 'Amazon Elastic Container Registry (Amazon ECR)',
  },
  ecs: {
    id: 'services.ecs',
    defaultMessage: 'Amazon Elastic Container Service (Amazon ECS)',
  },
  elasticache: {
    id: 'services.elasticache',
    defaultMessage: 'Amazon ElastiCache',
  },
  elasticbeanstalk: {
    id: 'services.elasticbeanstalk',
    defaultMessage: 'AWS Elastic Beanstalk',
  },
  elasticfilesystem: {
    id: 'services.elasticfilesystem',
    defaultMessage: 'Amazon Elastic File System (Amazon EFS)',
  },
  elasticloadbalancing: {
    id: 'services.elasticloadbalancing',
    defaultMessage: 'Elastic Load Balancing',
  },
  elasticmapreduce: {
    id: 'services.elasticmapreduce',
    defaultMessage: 'Amazon EMR',
  },
  elastictranscoder: {
    id: 'services.elastictranscoder',
    defaultMessage: 'Amazon Elastic Transcoder',
  },
  es: {
    id: 'services.es',
    defaultMessage: 'Amazon Elasticsearch Service (Amazon ES)',
  },
  iam: {
    id: 'services.iam',
    defaultMessage: 'AWS Identity and Access Management (IAM)',
  },
  inspector: {
    id: 'services.inspector',
    defaultMessage: 'Amazon Inspector',
  },
  iot: {
    id: 'services.iot',
    defaultMessage: 'AWS IoT',
  },
  kinesis: {
    id: 'services.kinesis',
    defaultMessage: 'Amazon Kinesis',
  },
  kms: {
    id: 'services.kms',
    defaultMessage: 'AWS Key Management Service (AWS KMS)',
  },
  lambda: {
    id: 'services.lambda',
    defaultMessage: 'AWS Lambda',
  },
  lex: {
    id: 'services.lex',
    defaultMessage: 'Amazon Lex',
  },
  lightsail: {
    id: 'services.lightsail',
    defaultMessage: 'Amazon Lightsail',
  },
  mediaconvert: {
    id: 'services.mediaconvert',
    defaultMessage: 'AWS Elemental MediaConvert',
  },
  medialive: {
    id: 'services.medialive',
    defaultMessage: 'AWS Elemental MediaLive',
  },
  mediapackage: {
    id: 'services.mediapackage',
    defaultMessage: 'AWS Elemental MediaPackage',
  },
  mediastore: {
    id: 'services.mediastore',
    defaultMessage: 'AWS Elemental MediaStore',
  },
  mediatailor: {
    id: 'services.mediatailor',
    defaultMessage: 'AWS Elemental MediaTailor',
  },
  opsworks: {
    id: 'services.opsworks',
    defaultMessage: 'AWS OpsWorks',
  },
  polly: {
    id: 'services.polly',
    defaultMessage: 'Amazon Polly',
  },
  rds: {
    id: 'services.rds',
    defaultMessage: 'Amazon Relational Database Service (Amazon RDS)',
  },
  redshift: {
    id: 'services.redshift',
    defaultMessage: 'Amazon Redshift',
  },
  rekognition: {
    id: 'services.rekognition',
    defaultMessage: 'Amazon Rekognition',
  },
  route53: {
    id: 'services.route53',
    defaultMessage: 'Amazon Route 53',
  },
  s3: {
    id: 'services.s3',
    defaultMessage: 'Amazon Simple Storage Service (Amazon S3)',
  },
  sagemaker: {
    id: 'services.sagemaker',
    defaultMessage: 'Amazon SageMaker',
  },
  sns: {
    id: 'services.sns',
    defaultMessage: 'Amazon Simple Notification Service (Amazon SNS)',
  },
  sqs: {
    id: 'services.sqs',
    defaultMessage: 'Amazon Simple Queue Service (Amazon SQS)',
  },
  storagegateway: {
    id: 'services.storagegateway',
    defaultMessage: 'AWS Storage Gateway',
  },
  sts: {
    id: 'services.sts',
    defaultMessage: 'AWS Security Token Service (AWS STS)',
  },
  transcribe: {
    id: 'services.transcribe',
    defaultMessage: 'Amazon Transcribe',
  },
  translate: {
    id: 'services.translate',
    defaultMessage: 'Amazon Translate',
  },
  trustedadvisor: {
    id: 'services.trustedadvisor',
    defaultMessage: 'AWS Trusted Advisor',
  },
  vpc: {
    id: 'services.vpc',
    defaultMessage: 'Amazon Virtual Private Cloud (Amazon VPC)',
  },
  'access-analyzer': {
    id: 'services.access-analyzer',
    defaultMessage: 'AWS Identity and Access Management Access Analyzer',
  },
  acm: {
    id: 'services.acm',
    defaultMessage: 'AWS Certificate Manager (ACM)',
  },
  amplify: {
    id: 'services.amplify',
    defaultMessage: 'AWS Amplify',
  },
  'application-autoscaling': {
    id: 'services.application-autoscaling',
    defaultMessage: 'AWS Application Auto Scaling',
  },
  appmesh: {
    id: 'services.appmesh',
    defaultMessage: 'AWS App Mesh',
  },
  appsync: {
    id: 'services.appsync',
    defaultMessage: 'AWS AppSync',
  },
  arsenal: {
    id: 'services.arsenal',
    defaultMessage: 'Application Discovery Arsenal',
  },
  athena: {
    id: 'services.athena',
    defaultMessage: 'Amazon Athena',
  },
  autoscaling: {
    id: 'services.autoscaling',
    defaultMessage: 'Amazon EC2 Auto Scaling',
  },
  'autoscaling-plans': {
    id: 'services.autoscaling-plans',
    defaultMessage: 'AWS Auto Scaling',
  },
  'aws-marketplace': {
    id: 'services.aws-marketplace',
    defaultMessage: 'AWS Marketplace',
  },
  backup: {
    id: 'services.backup',
    defaultMessage: 'AWS Backup',
  },
  'backup-storage': {
    id: 'services.backup-storage',
    defaultMessage: 'AWS Backup storage',
  },
  batch: {
    id: 'services.batch',
    defaultMessage: 'AWS Batch',
  },
  braket: {
    id: 'services.braket',
    defaultMessage: 'Amazon Braket',
  },
  cloud9: {
    id: 'services.cloud9',
    defaultMessage: 'AWS Cloud9',
  },
  codeartifact: {
    id: 'services.codeartifact',
    defaultMessage: 'AWS CodeArtifact',
  },
  codebuild: {
    id: 'services.codebuild',
    defaultMessage: 'AWS CodeBuild',
  },
  'codeguru-reviewer': {
    id: 'services.codeguru-reviewer',
    defaultMessage: 'Amazon CodeGuru Reviewer',
  },
  codepipeline: {
    id: 'services.codepipeline',
    defaultMessage: 'AWS CodePipeline',
  },
  codestar: {
    id: 'services.codestar',
    defaultMessage: 'AWS CodeStar',
  },
  'codestar-connections': {
    id: 'services.codestar-connections',
    defaultMessage: 'AWS CodeStar Connections',
  },
  'cognito-identity': {
    id: 'services.cognito-identity',
    defaultMessage: 'Amazon Cognito Identity',
  },
  'cognito-idp': {
    id: 'services.cognito-idp',
    defaultMessage: 'Amazon Cognito User Pools',
  },
  'cognito-sync': {
    id: 'services.cognito-sync',
    defaultMessage: 'Amazon Cognito Sync',
  },
  comprehend: {
    id: 'services.comprehend',
    defaultMessage: 'Amazon Comprehend',
  },
  comprehendmedical: {
    id: 'services.comprehendmedical',
    defaultMessage: 'Amazon Comprehend Medical',
  },
  'compute-optimizer': {
    id: 'services.compute-optimizer',
    defaultMessage: 'AWS Compute Optimizer',
  },
  datasync: {
    id: 'services.datasync',
    defaultMessage: 'AWS DataSync',
  },
  dax: {
    id: 'services.dax',
    defaultMessage: 'Amazon DynamoDB Accelerator (DAX)',
  },
  deepracer: {
    id: 'services.deepracer',
    defaultMessage: 'AWS DeepRacer',
  },
  discovery: {
    id: 'services.discovery',
    defaultMessage: 'AWS Application Discovery Service',
  },
  ds: {
    id: 'services.ds',
    defaultMessage: 'AWS Directory Service',
  },
  'ec2-instance-connect': {
    id: 'services.ec2-instance-connect',
    defaultMessage: 'Amazon EC2 Instance Connect',
  },
  ec2messages: {
    id: 'services.ec2messages',
    defaultMessage: 'Amazon Message Delivery Service',
  },
  eks: {
    id: 'services.eks',
    defaultMessage: 'Amazon Elastic Kubernetes Service (Amazon EKS)',
  },
  'emr-containers': {
    id: 'services.emr-containers',
    defaultMessage: 'Amazon EMR',
  },
  events: {
    id: 'services.events',
    defaultMessage: 'Amazon EventBridge',
  },
  'execute-api': {
    id: 'services.execute-api',
    defaultMessage: 'Amazon API Gateway',
  },
  firehose: {
    id: 'services.firehose',
    defaultMessage: 'Amazon Kinesis Data Firehose',
  },
  fis: {
    id: 'services.fis',
    defaultMessage: 'AWS Fault Injection Simulator',
  },
  glacier: {
    id: 'services.glacier',
    defaultMessage: 'Amazon S3 Glacier',
  },
  glue: {
    id: 'services.glue',
    defaultMessage: 'AWS Glue',
  },
  greengrass: {
    id: 'services.greengrass',
    defaultMessage: 'AWS IoT Greengrass V2',
  },
  groundtruthlabeling: {
    id: 'services.groundtruthlabeling',
    defaultMessage: 'Amazon GroundTruth Labeling',
  },
  health: {
    id: 'services.health',
    defaultMessage: 'AWS Health',
  },
  healthlake: {
    id: 'services.healthlake',
    defaultMessage: 'Amazon HealthLake',
  },
  inspector2: {
    id: 'services.inspector2',
    defaultMessage: 'Amazon Inspector2',
  },
  kafka: {
    id: 'services.kafka',
    defaultMessage: 'Amazon Managed Streaming for Apache Kafka (Amazon MSK)',
  },
  'kafka-cluster': {
    id: 'services.kafka-cluster',
    defaultMessage: 'Apache Kafka APIs for Amazon MSK clusters',
  },
  kafkaconnect: {
    id: 'services.kafkaconnect',
    defaultMessage: 'Amazon Managed Streaming for Kafka Connect',
  },
  kinesisanalytics: {
    id: 'services.kinesisanalytics',
    defaultMessage: 'Amazon Kinesis Analytics V2',
  },
  kinesisvideo: {
    id: 'services.kinesisvideo',
    defaultMessage: 'Amazon Kinesis Video Streams',
  },
  lakeformation: {
    id: 'services.lakeformation',
    defaultMessage: 'AWS Lake Formation',
  },
  'license-manager': {
    id: 'services.license-manager',
    defaultMessage: 'AWS License Manager',
  },
  logs: {
    id: 'services.logs',
    defaultMessage: 'Amazon CloudWatch Logs',
  },
  m2: {
    id: 'services.m2',
    defaultMessage: 'AWS Mainframe Modernization Service',
  },
  machinelearning: {
    id: 'services.machinelearning',
    defaultMessage: 'Amazon Machine Learning (Amazon ML)',
  },
  macie2: {
    id: 'services.macie2',
    defaultMessage: 'Amazon Macie',
  },
  managedblockchain: {
    id: 'services.managedblockchain',
    defaultMessage: 'Amazon Managed Blockchain',
  },
  mgh: {
    id: 'services.mgh',
    defaultMessage: 'AWS Migration Hub',
  },
  mgn: {
    id: 'services.mgn',
    defaultMessage: 'AWS Application Migration Service',
  },
  mobiletargeting: {
    id: 'services.mobiletargeting',
    defaultMessage: 'Amazon Pinpoint',
  },
  'network-firewall': {
    id: 'services.network-firewall',
    defaultMessage: 'AWS Network Firewall',
  },
  networkmanager: {
    id: 'services.networkmanager',
    defaultMessage: 'AWS Network Manager',
  },
  pi: {
    id: 'services.pi',
    defaultMessage: 'AWS Performance Insights',
  },
  pricing: {
    id: 'services.pricing',
    defaultMessage: 'AWS Price List',
  },
  quicksight: {
    id: 'services.quicksight',
    defaultMessage: 'Amazon QuickSight',
  },
  ram: {
    id: 'services.ram',
    defaultMessage: 'AWS Resource Access Manager (AWS RAM)',
  },
  'rds-data': {
    id: 'services.rds-data',
    defaultMessage: 'Amazon RDS Data API',
  },
  'rds-db': {
    id: 'services.rds-db',
    defaultMessage: 'Amazon RDS IAM Authentication',
  },
  'redshift-data': {
    id: 'services.redshift-data',
    defaultMessage: 'Amazon Redshift Data',
  },
  'redshift-serverless': {
    id: 'services.redshift-serverless',
    defaultMessage: 'Amazon Redshift Serverless',
  },
  'refactor-spaces': {
    id: 'services.refactor-spaces',
    defaultMessage: 'AWS Migration Hub Refactor Spaces',
  },
  'resource-explorer': {
    id: 'services.resource-explorer',
    defaultMessage: 'AWS Tag Editor',
  },
  'resource-groups': {
    id: 'services.resource-groups',
    defaultMessage: 'AWS Resource Groups',
  },
  'route53-recovery-control-config': {
    id: 'services.route53-recovery-control-config',
    defaultMessage: 'Amazon Route 53 Recovery Controls',
  },
  'route53-recovery-readiness': {
    id: 'services.route53-recovery-readiness',
    defaultMessage: 'Amazon Route 53 Recovery Readiness',
  },
  route53domains: {
    id: 'services.route53domains',
    defaultMessage: 'Amazon Route 53 Domains',
  },
  route53resolver: {
    id: 'services.route53resolver',
    defaultMessage: 'Amazon Route 53 Resolver',
  },
  's3-object-lambda': {
    id: 'services.s3-object-lambda',
    defaultMessage: 'Amazon S3 Object Lambda',
  },
  schemas: {
    id: 'services.schemas',
    defaultMessage: 'Amazon EventBridge Schemas',
  },
  sdb: {
    id: 'services.sdb',
    defaultMessage: 'Amazon SimpleDB',
  },
  secretsmanager: {
    id: 'services.secretsmanager',
    defaultMessage: 'AWS Secrets Manager',
  },
  servicecatalog: {
    id: 'services.servicecatalog',
    defaultMessage: 'AWS Service Catalog',
  },
  servicediscovery: {
    id: 'services.servicediscovery',
    defaultMessage: 'AWS Cloud Map',
  },
  servicequotas: {
    id: 'services.servicequotas',
    defaultMessage: 'Service Quotas',
  },
  ses: {
    id: 'services.ses',
    defaultMessage: 'Amazon Pinpoint Email Service',
  },
  shield: {
    id: 'services.shield',
    defaultMessage: 'AWS Shield',
  },
  'sms-voice': {
    id: 'services.sms-voice',
    defaultMessage: 'Amazon Pinpoint SMS Voice V2',
  },
  sqlworkbench: {
    id: 'services.sqlworkbench',
    defaultMessage: 'AWS SQL Workbench',
  },
  ssm: {
    id: 'services.ssm',
    defaultMessage: 'AWS Systems Manager',
  },
  'ssm-guiconnect': {
    id: 'services.ssm-guiconnect',
    defaultMessage: 'AWS Systems Manager GUI Connect',
  },
  ssmmessages: {
    id: 'services.ssmmessages',
    defaultMessage: 'Amazon Session Manager Message Gateway Service',
  },
  states: {
    id: 'services.states',
    defaultMessage: 'AWS Step Functions',
  },
  support: {
    id: 'services.support',
    defaultMessage: 'AWS Support',
  },
  synthetics: {
    id: 'services.synthetics',
    defaultMessage: 'Amazon CloudWatch Synthetics',
  },
  tag: {
    id: 'services.tag',
    defaultMessage: 'Amazon Resource Group Tagging API',
  },
  textract: {
    id: 'services.textract',
    defaultMessage: 'Amazon Textract',
  },
  tiros: {
    id: 'services.tiros',
    defaultMessage: 'AWS Tiros',
  },
  waf: {
    id: 'services.waf',
    defaultMessage: 'AWS WAF',
  },
  'waf-regional': {
    id: 'services.waf-regional',
    defaultMessage: 'AWS WAF Regional',
  },
  wafv2: {
    id: 'services.wafv2',
    defaultMessage: 'AWS WAF V2',
  },
  xray: {
    id: 'services.xray',
    defaultMessage: 'AWS X-Ray',
  },
  wellarchitected: {
    id: 'services.wellarchitected',
    defaultMessage: 'AWS Well-Architected',
  },
  serverlessrepo: {
    id: 'services.serverlessrepo',
    defaultMessage: 'AWS Serverless Application Repository',
  },
  personalize: {
    id: 'services.personalize',
    defaultMessage: 'Amazon Personalize',
  },
  memorydb: {
    id: 'services.memorydb',
    defaultMessage: 'Amazon MemoryDB for Redis',
  },
  gamelift: {
    id: 'services.gamelift',
    defaultMessage: 'Amazon GameLift',
  },
  docdbelastic: {
    id: 'services.docdbelastic',
    defaultMessage: 'Amazon DocumentDB',
  },
  acmpca: {
    id: 'services.acmpca',
    defaultMessage: 'AWS Private Certificate Authority',
  },
  apprunner: {
    id: 'services.apprunner',
    defaultMessage: 'AWS App Runner',
  },
  fsx: {
    id: 'services.fsx',
    defaultMessage: 'Amazon FSx',
  },
  rolesanywhere: {
    id: 'services.rolesanywhere',
    defaultMessage: 'AWS Identity and Access Management Roles Anywhere',
  },
  securityhub: {
    id: 'services.securityhub',
    defaultMessage: 'AWS Security Hub',
  },
  codegurusecurity: {
    id: 'services.codegurusecurity',
    defaultMessage: 'Amazon CodeGuru Security',
  },
  codewhisperer: {
    id: 'services.codewhisperer',
    defaultMessage: 'Amazon CodeWhisperer',
  },
  drs: {
    id: 'services.drs',
    defaultMessage: 'Elastic Disaster Recovery',
  },
  evidently: {
    id: 'services.evidently',
    defaultMessage: 'Amazon CloudWatch Evidently',
  },
  geo: {
    id: 'services.geo',
    defaultMessage: 'Amazon Location Service',
  },
  globalaccelerator: {
    id: 'services.globalaccelerator',
    defaultMessage: 'AWS Global Accelerator',
  },
  grafana: {
    id: 'services.grafana',
    defaultMessage: 'Amazon Managed Grafana',
  },
  internetmonitor: {
    id: 'services.internetmonitor',
    defaultMessage: 'Amazon CloudWatch Internet Monitor',
  },
  launchwizard: {
    id: 'services.launchwizard',
    defaultMessage: 'AWS Launch Wizard',
  },
  omics: {
    id: 'services.omics',
    defaultMessage: 'Amazon Omics',
  },
  appstream: {
    id: 'services.appstream',
    defaultMessage: 'Amazon AppStream',
  },
  mediaconnect: {
    id: 'services.mediaconnect',
    defaultMessage: 'AWS Elemental MediaConnect',
  },
  timestream: {
    id: 'services.timestream',
    defaultMessage: 'Amazon Timestream',
  },
  // ===============================================================
  // Added 2023-11-10
  // https://w.amazon.com/bin/viewrev/Hulkydew/ACS/Service_Support/WebHome?rev=84.1
  iotfleetwise: {
    id: 'services.iotfleetwise',
    defaultMessage: 'AWS IoT FleetWise',
  },
  iotsitewise: {
    id: 'services.iotsitewise',
    defaultMessage: 'AWS IoT SiteWise',
  },
  kendra: {
    id: 'services.kendra',
    defaultMessage: 'Amazon Kendra',
  },
  lookoutequipment: {
    id: 'services.lookoutequipment',
    defaultMessage: 'Amazon Lookout for Equipment',
  },
  lookoutvision: {
    id: 'services.lookoutvision',
    defaultMessage: 'Amazon Lookout for Vision',
  },
  pipes: {
    id: 'services.pipes',
    defaultMessage: 'Amazon EventBridge Pipes',
  },
  rum: {
    id: 'services.rum',
    defaultMessage: 'Amazon CloudWatch RUM',
  },
  'ssm-incidents': {
    id: 'services.ssm-incidents',
    defaultMessage: 'AWS Systems Manager Incident Manager',
  },
  'vpc-lattice': {
    id: 'services.vpc-lattice',
    defaultMessage: 'Amazon VPC Lattice',
  },
  // ===============================================================
  // Added 2023-02-13
  // https://t.corp.amazon.com/D118934309
  q: {
    id: 'services.q',
    defaultMessage: 'Amazon Q',
  },
  'application-transformation': {
    id: 'services.application-transformation',
    defaultMessage: 'AWS Application Transformation Service',
  },
  iottwinmaker: {
    id: 'services.iottwinmaker',
    defaultMessage: 'AWS IoT TwinMaker',
  },
  serviceextract: {
    id: 'services.serviceextract',
    defaultMessage: 'AWS Microservice Extractor for .NET',
  },
  // ===============================================================

  // ===============================================================
  // Added on 2024-06-24
  bedrock: {
    id: 'services.bedrock',
    defaultMessage: 'Amazon Bedrock',
  },
  iotevents: {
    id: 'services.iotevents',
    defaultMessage: 'AWS IoT Events',
  },
  // ===============================================================

  // ===============================================================
  // Added on 2024-09-26
  aoss: {
    id: 'services.aoss',
    defaultMessage: 'Amazon OpenSearch Serverless',
  },
  // ===============================================================
});

export default messages;

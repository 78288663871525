import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'labSidebar.title',
    defaultMessage: 'Lab Information',
  },
});

export default messages;

import { historyReplaceState } from './urlUtils';
import { localStorageAdapter } from './cookieManagement';
import { EH_LOCATION } from '../constants/cookies';

const EH_LOCATION_SEARCH_PARAM = 'eh_location';

/**
 * Get the the Lab Room Location from storage, if available.
 * Note: We need cookie consent to get the value.
 *
 * @returns {string|null}
 */
export const getEHLocationValue = () => {
  try {
    const ehLocationToken = JSON.parse(
      localStorageAdapter.getItem(EH_LOCATION)
    );
    if (Date.parse(ehLocationToken.expiry) <= Date.now()) {
      localStorageAdapter.removeItem(EH_LOCATION);
      return null;
    }
    return ehLocationToken.locationValue;
  } catch (SyntaxError) {
    localStorageAdapter.removeItem(EH_LOCATION);
    return null;
  }
};

const removeEHLocationValue = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(EH_LOCATION_SEARCH_PARAM);
  historyReplaceState(
    {},
    document.title,
    `${document.location.pathname}?${searchParams}`
  );
};

/**
 * Gets the Lab Room Location from the window location search parameters, if available.
 * Saves the value in storage.
 *
 * @returns {void}
 */
export const setEHLocationValue = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const ehLocationHref = searchParams.get(EH_LOCATION_SEARCH_PARAM);
  if (!ehLocationHref?.match(/^[a-zA-Z0-9_]*$/)) {
    removeEHLocationValue();
    return;
  }
  const oneDayinMS = 24 * 60 * 60 * 1000;
  const expiryTime = new Date(Date.now() + oneDayinMS);
  localStorageAdapter.setItemAsync(
    EH_LOCATION,
    JSON.stringify({
      locationValue: ehLocationHref,
      expiry: expiryTime.toISOString(),
    }),
    () => removeEHLocationValue()
  );
};

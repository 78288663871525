import React from 'react';
import { Button, Box, Alert } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { dataTestId } from '../../../constants/dataTestIdSelectors';
import { LAB_FUNCTION_STATUS } from '../../../constants/labFunctionExecution';
import ExpandableSupportInformation from '../../../components/ExpandableSupportInformation';
import TimestampAgo from '../../../components/TimestampAgo';
import messages from './LabEvaluation.messages';

const EvaluateLabAction = ({
  isSubmitting,
  isStoppingLab,
  isPollingEvaluation,
  labHasNotStarted,
  handleStartLabEvaluation,
  currentEvaluation,
  labId,
}) => {
  const { formatMessage } = useIntl();
  const isRequestError = currentEvaluation?.evaluationStatus === 'ERROR';
  const isScriptError =
    currentEvaluation?.result?.status === LAB_FUNCTION_STATUS.ERROR;
  const evaluateFailSupportInfo = `Error: Evaluation${
    isScriptError ? 'ScriptFailed' : 'RequestFailed'
  } \nLabId: ${labId} \nExecutionID: ${
    currentEvaluation?.result?.executionId
  } \nLabURL: ${document.location.href} \n`;
  const isError = isRequestError || isScriptError;
  return (
    <>
      {isError && (
        <Box margin={{ bottom: 's' }}>
          <Alert type="warning">
            {formatMessage(messages.evaluationFailedMessage)}
            <ExpandableSupportInformation
              expandedInfo={formatMessage(messages.supportInfoMessage)}
              copyableInfo={evaluateFailSupportInfo}
            />
          </Alert>
        </Box>
      )}
      <Button
        onClick={handleStartLabEvaluation}
        loading={isPollingEvaluation}
        disabled={isSubmitting || isStoppingLab || labHasNotStarted}
        data-testid={dataTestId['labEval-evaluate']}
      >
        {formatMessage(messages.evaluateLabButton)}
      </Button>
      {!isPollingEvaluation && currentEvaluation?.result?.endedOn && (
        <Box
          fontSize="body-s"
          color="text-label"
          variant="span"
          margin={{ left: 's' }}
        >
          {formatMessage(messages.lastEvaluationTimestamp, {
            timestamp: (
              <TimestampAgo timestamp={currentEvaluation?.result?.endedOn} />
            ),
          })}
        </Box>
      )}
    </>
  );
};

export default EvaluateLabAction;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jamsTitle: {
    id: 'lxpCatalogPage.jamsTitle',
    defaultMessage: 'AWS Jam Journeys',
  },
  simulearnTitle: {
    id: 'lxpCatalogPage.simulearnTitle',
    defaultMessage: 'AWS SimuLearn Assignments',
  },
});

export default messages;

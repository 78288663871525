export const CHATBOX_ID = 'connectChatRoot';
export const CHATBOX_SELECTOR = `#${CHATBOX_ID}`;

export const SUPPORT_BTN_ID = 'awsui-live-support-button';
export const SUPPORT_BTN_SELECTOR = `#${SUPPORT_BTN_ID}`;

/**
 * The following constants are items from the 
 * resources section of the help panel 
 * and the live chat button in the footer.
 
 * @note The metric name will affect the metrics dashboard. Make
 * sure to consider this before altering it.
 */

export const SUPPORT = {
  awsConnectLiveChat: {
    metricNameNoAgents: 'SupportAWSConnectLiveChatNoAgents',
    metricNameSuccess: 'SupportAWSConnectLiveChatSuccess',
    metricNameFailure: 'SupportAWSConnectLiveChatFailure',
    connectRegion: 'us-east-1',
  },
};

export const RESOURCES = {
  awsBlogs: {
    id: 'awsBlogs',
    metricName: 'SupportAWSBlogs',
    url: 'https://aws.amazon.com/blogs/',
  },
  awsDocs: {
    id: 'awsDocs',
    metricName: 'SupportAWSDocs',
    url: 'https://docs.aws.amazon.com/',
  },
  awsForums: {
    id: 'awsForums',
    metricName: 'SupportAWSForums',
    url: 'https://forums.aws.amazon.com/',
  },
  awsTCResources: {
    id: 'awsTCResources',
    metricName: 'SupportAWSTCResources',
    url: 'https://aws.amazon.com/training/',
  },
};

export const DEFAULT_SUPPORT_LINK_URL =
  'https://support.aws.amazon.com/#/contacts/aws-training';

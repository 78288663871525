import { defineMessages } from 'react-intl';

const messages = defineMessages({
  selectLanguage: {
    id: 'localeMenu.selectLanguage',
    defaultMessage: 'Select your preferred language.',
  },
  currentSelection: {
    id: 'localeMenu.currentSelection',
    defaultMessage: 'Current selection:',
  },
});

export default messages;

import React from 'react';
import useDebouncedResizeObserver from '../../hooks/useDebouncedResizeObserver';

import './HeaderWrapper.css';

const HeaderWrapper = ({ children }) => {
  const { ref, height } = useDebouncedResizeObserver(50);

  // height set on HeaderWrapper div to fix flashbar flickering bug in Firefox
  return (
    <div className="HeaderWrapper" style={{ height: `${height}px` }}>
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default HeaderWrapper;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';
import { AppLayout } from '@amzn/awsui-components-react';

import SEO from '../components/SEO';
import NotFoundPage from './NotFoundPage';
import UnauthorizedPage from './UnauthorizedPage';
import ClosedEventPage from './ClosedEventPage';
import AppNotifications from '../components/AppNotifications';
import Main from '../components/Main';
import { HeaderWrapper } from '../components/Header';
import { Footer } from '../components/Footer';
import { HelpPanel } from '../components/HelpPanel';
import HeaderEH from '../components/Header/HeaderEH';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import { useHelpPanelContext } from '../contexts';
import { getCurrentAuthenticatedUser } from '../utils/authUtils';
import DefaultErrorPage from './DefaultErrorPage';
import ForbiddenAccessErrorPage from './ForbiddenAccessErrorPage';
import ActiveLabOnlyErrorPage from './ActiveLabOnlyErrorPage';
import ERROR_TYPES from '../constants/errorTypes';

const getErrorContent = ({ customContent, errors }) => {
  // redirect to 404 page if a supplied error type is 'not found'
  if (find(propEq('errorType', ERROR_TYPES.NotFound), errors))
    return <NotFoundPage contentOnly={true} />;

  // Handle if the customer is blocked or fails the VIAS AuthZ
  if (
    find(propEq('errorType', ERROR_TYPES.Unauthorized), errors) || // Blocked
    find(propEq('errorType', ERROR_TYPES.Forbidden), errors) || // VIAS AuthZ
    find(propEq('errorType', ERROR_TYPES.EmailValidationRequired), errors)
  )
    return <UnauthorizedPage />;

  // Handle if the EH ACL fails
  if (find(propEq('errorType', ERROR_TYPES.NotAuthorizedInACL), errors))
    return <ForbiddenAccessErrorPage />;

  // Handle if the event is closed
  if (find(propEq('errorType', ERROR_TYPES.ClosedEvent), errors))
    return <ClosedEventPage />;

  // Handle if event is active lab only mode and user has no active lab
  if (find(propEq('errorType', ERROR_TYPES.NoActiveLab), errors))
    return <ActiveLabOnlyErrorPage />;

  return customContent ? customContent : <DefaultErrorPage />;
};

// Used in error states to know what options to show the customer
const useIsVerifiedAuthenticated = initialAssumption => {
  const [isVerifiedAuthenticated, setIsVerifiedAuthenticated] =
    useState(initialAssumption);
  useEffect(() => {
    getCurrentAuthenticatedUser()
      .then(() => {
        setIsVerifiedAuthenticated(true);
      })
      .catch(() => {
        setIsVerifiedAuthenticated(false);
      });
  }, []);
  return isVerifiedAuthenticated;
};

const ErrorPage = ({
  errors = [],
  isAuthenticated,
  currentUser,
  isOutsideRouter = false,
  customContent,
  pageTitle = 'Uh oh, something went wrong',
  isStandalone,
}) => {
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const appLayoutRef = useRef();
  const { showHelpPanel, handlePanelClose } = useHelpPanelContext();
  const isVerifiedAuthenticated = useIsVerifiedAuthenticated(isAuthenticated);

  return (
    <>
      <SEO title={pageTitle} />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isVerifiedAuthenticated}
          isStandalone={isStandalone}
          isOutsideRouter={isOutsideRouter}
        />
      </HeaderWrapper>

      <AppLayout
        ref={appLayoutRef}
        content={<Main>{getErrorContent({ customContent, errors })}</Main>}
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        navigationHide={true}
        stickyNotifications={true}
        notifications={<AppNotifications />}
        toolsHide={!showHelpPanel}
        toolsOpen={showHelpPanel}
        onToolsChange={handlePanelClose}
        tools={!isOutsideRouter && <HelpPanel showTourLink={false} />}
        ariaLabels={APP_LAYOUT_LABELS}
      />

      <Footer
        isStandalone={isStandalone}
        isOutsideRouter={isOutsideRouter}
        appLayoutRef={appLayoutRef}
      />
    </>
  );
};

ErrorPage.propTypes = {
  errors: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  currentUser: PropTypes.string,
  isOutsideRouter: PropTypes.bool,
  customContent: PropTypes.node,
  pageTitle: PropTypes.string,
  isStandalone: PropTypes.bool,
};

export default ErrorPage;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  copied: {
    id: 'labSecrets.copied',
    defaultMessage: 'Copied',
  },
  copyLabel: {
    id: 'labSecrets.copyLabel',
    defaultMessage: 'Copy {secret}',
  },
  resources: {
    id: 'labSecrets.resources',
    defaultMessage: 'Resources',
  },

  // Secret Labels
  password: {
    id: 'labSecrets.password',
    defaultMessage: 'Password',
  },
  adminPassword: {
    id: 'labSecrets.adminPassword',
    defaultMessage: 'Administrator Password',
  },
  ec2KeyPairPEM: {
    id: 'labSecrets.ec2KeyPairPEM',
    defaultMessage: 'EC2 Key Pair PEM',
  },
  downloadPEM: {
    id: 'labSecrets.downloadPEM',
    defaultMessage: 'Download PEM',
  },
  ec2KeyPairPPK: {
    id: 'labSecrets.ec2KeyPairPPK',
    defaultMessage: 'EC2 Key Pair PPK',
  },
  downloadPPK: {
    id: 'labSecrets.downloadPPK',
    defaultMessage: 'Download PPK',
  },
});

export default messages;

export const DEFAULT_LOCALE = 'en-US';

/**
 * This constant is only used for:
 * -- browser language lookup in LocaleContextProvider
 * -- getting array of locales using Object.values
 * -- getting full locale code within test files
 * The short locale code in the key is not used anywhere else.
 */
export const LOCALE_CODES = {
  ['en-US']: 'en-US',
  ['ja-JP']: 'ja-JP',
  ['de-DE']: 'de-DE',
  ['es-ES']: 'es-ES',
  ['es-LA']: 'es-LA',
  ['fr-FR']: 'fr-FR',
  ['it-IT']: 'it-IT',
  ['pt-BR']: 'pt-BR',
  ['ko-KR']: 'ko-KR',
  ['zh-CN']: 'zh-CN',
  ['zh-TW']: 'zh-TW',
  ['id-ID']: 'id-ID',
  ['pl-PL']: 'pl-PL',
  ['th-TH']: 'th-TH',
  ['uk-UA']: 'uk-UA',
  ['vi-VN']: 'vi-VN',
};

/**
 * Used to provide a default when a locale shortcode has multiple choices.
 * @example
 * LOCALE_SHORT_CODES['es'] // returns "es-ES"
 */
export const LOCALE_SHORT_CODES = {
  en: 'en-US',
  ja: 'ja-JP',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-BR',
  ko: 'ko-KR',
  zh: 'zh-CN',
  id: 'id-ID',
  pl: 'pl-PL',
  th: 'th-TH',
  uk: 'uk-UA',
  vi: 'vi-VN',
};

/**
 * All available locales.
 * This also represents the list order represented in the UI.
 * It is ordered alphabetically by `label` for non-symbol languages,
 * then alphabetically by `description` for symbol languages.
 */
export const LOCALES = {
  [LOCALE_CODES['id-ID']]: {
    id: LOCALE_CODES['id-ID'],
    description: 'Indonesian',
    label: 'Bahasa Indonesia',
    inline: 'Bahasa Indonesia',
  },
  [LOCALE_CODES['de-DE']]: {
    id: LOCALE_CODES['de-DE'],
    description: 'German',
    label: 'Deutsch',
    inline: 'Deutsch',
  },
  [LOCALE_CODES['en-US']]: {
    id: LOCALE_CODES['en-US'],
    description: 'English',
    label: 'English',
    inline: 'English',
  },
  [LOCALE_CODES['es-ES']]: {
    id: LOCALE_CODES['es-ES'],
    description: 'Spanish',
    label: 'Español (España)',
    inline: 'Español (España)',
  },
  [LOCALE_CODES['es-LA']]: {
    id: LOCALE_CODES['es-LA'],
    description: 'Spanish',
    label: 'Español (Latinoamérica)',
    inline: 'Español (Latinoamérica)',
  },
  [LOCALE_CODES['fr-FR']]: {
    id: LOCALE_CODES['fr-FR'],
    description: 'French',
    label: 'Français',
    inline: 'français',
  },
  [LOCALE_CODES['it-IT']]: {
    id: LOCALE_CODES['it-IT'],
    description: 'Italian',
    label: 'Italiano',
    inline: 'Italiano',
  },
  [LOCALE_CODES['pt-BR']]: {
    id: LOCALE_CODES['pt-BR'],
    description: 'Portuguese',
    label: 'Português',
    inline: 'Português',
  },
  [LOCALE_CODES['zh-CN']]: {
    id: LOCALE_CODES['zh-CN'],
    description: 'Chinese (Simplified)',
    label: '中文（简体）',
    inline: '中文（简体）',
  },
  [LOCALE_CODES['zh-TW']]: {
    id: LOCALE_CODES['zh-TW'],
    description: 'Chinese (Traditional)',
    label: '中文（繁體）',
    inline: '中文（繁體）',
  },
  [LOCALE_CODES['ja-JP']]: {
    id: LOCALE_CODES['ja-JP'],
    description: 'Japanese',
    label: '日本語',
    inline: '日本語',
  },
  [LOCALE_CODES['ko-KR']]: {
    id: LOCALE_CODES['ko-KR'],
    description: 'Korean',
    label: '한국어',
    inline: '한국어',
  },
  [LOCALE_CODES['pl-PL']]: {
    id: LOCALE_CODES['pl-PL'],
    description: 'Polish',
    label: 'Polski',
    inline: 'Polski',
  },
  [LOCALE_CODES['th-TH']]: {
    id: LOCALE_CODES['th-TH'],
    description: 'Thai',
    label: 'ไทย',
    inline: 'ไทย',
  },
  [LOCALE_CODES['uk-UA']]: {
    id: LOCALE_CODES['uk-UA'],
    description: 'Ukrainian (Ukraine)',
    label: 'Yкраїнська',
    inline: 'Yкраїнська',
  },
  [LOCALE_CODES['vi-VN']]: {
    id: LOCALE_CODES['vi-VN'],
    description: 'Vietnamese',
    label: 'Tiếng Việt',
    inline: 'Tiếng Việt',
  },
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reload: {
    id: 'initialize.reload',
    defaultMessage: 'Reload',
  },
});

export default messages;

import { prop, propOr } from 'ramda';
import './typedef';
import { CALLOUT_TYPES, SLOTS } from '../components/CustomCallouts/constants';
import {
  parseDurationData,
  getDurationInMinutes,
  getDurationId,
} from '../utils/blueprintDurationUtils';

const decodeMarkdown = encodedMarkdown => {
  if (!encodedMarkdown) return undefined;
  return decodeURIComponent(encodedMarkdown);
};

class CalloutComponent {
  constructor(payload) {
    /** @type {string} */
    this.name = prop('name', payload);
    /** @type {string} */
    this.type = prop('type', payload);
    /** @type {Locale[]} */
    this.locales = (propOr([], 'locales', payload) || []).map(
      locale => new Locale(locale)
    );
  }
}

class Locale {
  constructor(payload) {
    /** @type {string} */
    this.locale = prop('locale', payload);
    /** @type {string} Markdown */
    this.content = decodeMarkdown(prop('content', payload));

    /** @type {Banner{}} */
    this.banner = new Banner(propOr({}, CALLOUT_TYPES.banner, payload));
    /** @type {Alert{}} */
    this.alert = prop(CALLOUT_TYPES.alert, payload)
      ? new Alert(prop(CALLOUT_TYPES.alert, payload))
      : new Alert();
    /** @type {Hero{}} */
    this.hero = new Hero(propOr({}, CALLOUT_TYPES.hero, payload));
    /** @type {Container{}} */
    this.container = new Container(
      propOr({}, CALLOUT_TYPES.container, payload)
    );
    /** @type {Carousel{}} */
    this.carousel = new Carousel(propOr({}, CALLOUT_TYPES.carousel, payload));
    /** @type {Topic{}} */
    this.topic = new Topic(propOr({}, CALLOUT_TYPES.topic, payload));
  }
}

class Banner {
  constructor(payload) {
    /** @type {string} */
    this.alt = prop('alt', payload);
    /** @type {string} */
    this.img = prop('img', payload);
    /** @type {string} */
    this.maxWidth = prop('maxWidth', payload);
  }
}

class Alert {
  constructor(payload) {
    /** @type {string} */
    this.type = prop('type', payload);
    /** @type {string} */
    this.header = prop('header', payload);
    /** @type {string} */
    this.content = prop('content', payload);
  }
}

class Hero {
  constructor(payload) {
    /** @type {string} */
    this.title = prop('title', payload);
    /** @type {string} */
    this.secondaryTitle = prop('secondaryTitle', payload);
    /** @type {string} Markdown */
    this.content = decodeMarkdown(prop('content', payload));
  }
}

class Container {
  constructor(payload) {
    /** @type {string} */
    this.title = prop('title', payload);
    /** @type {string} Markdown */
    this.content = decodeMarkdown(prop('content', payload));
    /** @type {string} */
    this.mediaSrc = prop('mediaSrc', payload);
    /** @type {string} */
    this.mediaAlt = prop('mediaAlt', payload);
    /** @type {string} */
    this.mediaPosition = prop('mediaPosition', payload);
    /** @type {string} */
    this.mediaSize = prop('mediaSize', payload);
    /** @type {string} */
    this.ctaTitle = prop('ctaTitle', payload);
    /** @type {string} */
    this.ctaHref = prop('ctaHref', payload);
    /** @type {boolean} */
    this.jumpToLink = prop('jumpToLink', payload);
  }
}

class Topic {
  constructor(payload) {
    /** @type {string} */
    this.title = prop('title', payload);
    /** @type {string} */
    this.description = prop('description', payload);
    /** @type {boolean} */
    this.expanded = prop('expanded', payload);
    /** @type {object[]} */
    this.labs = propOr([], 'labs', payload);
    /** @type {string} */
    this.labsDescription = prop('labsDescription', payload);
    /** @type {object[]} */
    this.jams = propOr([], 'jams', payload);
    /** @type {string} */
    this.jamsDescription = prop('jamsDescription', payload);
  }
}

class CarouselItem {
  constructor(payload) {
    /** @type {string} */
    this.title = prop('title', payload);
    /** @type {string} */
    this.description = prop('description', payload);
    /** @type {string} */
    this.url = prop('url', payload);
    /** @type {string} */
    this.duration = prop('duration', payload);
    /** @type {string} */
    this.level = prop('level', payload);
  }

  /**
   * The `dur` property is used for filtering.
   * @type {LabDurationId}
   */
  get dur() {
    return getDurationId(this.duration);
  }

  /**
   * Parsed duration object with hour and minutes.
   * @type {Object|undefined}
   */
  get durationData() {
    return parseDurationData(this.duration);
  }

  /**
   * Parses duration string and returns it in minutes
   * @returns {number} duration in minutes
   */
  getDurationInMinutes() {
    return getDurationInMinutes(this.durationData);
  }
}

class Carousel {
  constructor(payload) {
    /** @type {string} */
    this.id = prop('id', payload);
    /** @type {string} */
    this.headerTitle = prop('headerTitle', payload);
    /** @type {string} */
    this.headerDescription = prop('headerDescription', payload);
    /** @type {string} */
    this.viewAllUrl = prop('viewAllUrl', payload);
    /** @type {CarouselItem[]} */
    this.items = propOr([], 'items', payload);
  }
}

export const calloutTypes = {
  Banner,
  Alert,
  Hero,
  Container,
  Topic,
  Carousel,
  CarouselItem,
};

export class EHCustomCallouts {
  constructor(payload) {
    Object.keys(SLOTS).forEach(slot => {
      /** @type {CalloutComponent[]} */
      this[slot] = propOr([], slot, payload).map(
        callout => new CalloutComponent(callout)
      );
    });
  }
}

import { prop } from 'ramda';

import './typedef';

export class EHLabEvaluation {
  constructor(payload) {
    /** @type {string} */
    this.arn = prop('arn', payload);
    /** @type {string} */
    this.functionName = prop('functionName', payload);
    /** @type {string} */
    this.executionId = prop('executionId', payload);
    /** @type {LabFunctionExecutionStatus} */
    this.status = prop('status', payload);
    /** @type {string?} */
    this.startedOn = prop('startedOn', payload);
    /** @type {string?} */
    this.endedOn = prop('endedOn', payload);
    /** @type {LabEvaluationLocale[]?} */
    this.locales = prop('locales', payload);
    /** @type {LabEvaluationScore?} */
    this.score = prop('score', payload);
  }

  /**
   * Calculates a score percentage using the given and maximum score
   */
  get percentageScore() {
    const score = this.score || {};
    const given = parseFloat(score.scoreGiven);
    const maximum = parseFloat(score.scoreMaximum);
    if (isNaN(given) || isNaN(given)) return undefined;
    return Math.round((given / maximum) * 100);
  }
}

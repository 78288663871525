import { defineMessages } from 'react-intl';

const messages = defineMessages({
  signOut: {
    id: 'userMenu.signOut',
    defaultMessage: 'Sign out',
  },
  userIconAlt: {
    id: 'userMenu.userIconAlt',
    defaultMessage: 'User profile',
  },

  // THEME MENU
  themeHeader: {
    id: 'userMenu.themeHeader',
    defaultMessage: 'Theme',
  },
  light: {
    id: 'userMenu.light',
    defaultMessage: 'Light',
  },
  dark: {
    id: 'userMenu.dark',
    defaultMessage: 'Dark',
  },
});

export default messages;

import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { prop, propOr } from 'ramda';

import App from './App';
import RootProviders from './contexts/RootProviders';
import ErrorBoundary from './components/ErrorBoundary';
import { initiateAuth, catchRequiredAuthError } from './utils/authUtils';
import metrics, {
  publishCounterMetric,
  trackRumSessionAttribute,
} from './utils/metrics';
import configureAmplify from './utils/configureAmplify';
import { publishZeroMetrics } from './utils/feedbackService';
import { captureTimingMetrics } from './utils/performance';
import {
  catchForbiddenError,
  catchDefaultError,
  renderLastResortErrorPage,
  catchSignInError,
} from './utils/indexErrorHandlers';
import { checkForCookieConsent } from './utils/cookieManagement';
import { isBrowserSupported, renderFallbackPage } from './utils/browserSupport';
import configService from './utils/configService';
import { setEHLocationValue } from './utils/locationUtils';
import { URL_PATHS } from './constants/urlPaths';
import { EVENT_TYPES } from './constants/eventTypes';
import StandaloneSignedOutPage from './pages/StandaloneSignedOutPage';
import OfflinePage from './pages/OfflinePage';
import { interceptNetworkRequestsInDevMaybe } from './mocks/browserMockLoader';

import '@amzn/awsui-global-styles/polaris.css';
import './constants/globalStyles.scss';

// TODO: delete after shortbread dark mode is implemented
import './utils/cookieManagement.scss';
import { RUM_SESSION_ATTRIBUTES } from './constants/metrics';

const $root = document.getElementById('root');

const renderPage = component => {
  ReactDOM.createRoot($root).render(<RootProviders>{component}</RootProviders>);
};

/**
 * Renders the webapp and emits exceptions in case something unexpected happens
 * during initiation.
 * @returns {Promise<void>}
 */
async function render() {
  await interceptNetworkRequestsInDevMaybe();
  await metrics.initMetrics();
  captureTimingMetrics();
  publishZeroMetrics();
  checkForCookieConsent();
  setEHLocationValue();

  // Check if user is in a signed out end state. This is the case if the user
  // signs out in a standalone event.
  if (location.pathname === URL_PATHS.standaloneSignOut) {
    renderPage(<StandaloneSignedOutPage />);
    return;
  }

  const config = await configService.get();
  if (prop('eventType', config) === EVENT_TYPES.Offline) {
    const notifications = propOr([], 'notifications', config);
    renderPage(<OfflinePage notifications={notifications} />);
    return;
  }

  await configureAmplify();
  const { isAuthenticated, user } = await initiateAuth();
  publishCounterMetric('Index');

  trackRumSessionAttribute(
    RUM_SESSION_ATTRIBUTES.VIBE_ID,
    user?.signInUserSession?.idToken?.payload?.vibe_user_id || 'N/A'
  );

  ReactDOM.createRoot($root).render(
    <RootProviders
      isAuthenticated={isAuthenticated}
      user={user}
      config={config}
    >
      <ErrorBoundary isAuthenticated={isAuthenticated}>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </RootProviders>
  );
}

if (isBrowserSupported()) {
  render()
    .catch(error => catchRequiredAuthError(error))
    .catch(error => renderPage(catchSignInError(error)))
    .catch(error => renderPage(catchForbiddenError(error)))
    .catch(error => renderPage(catchDefaultError(error)))
    .catch(error => renderLastResortErrorPage(error, $root));
} else {
  renderFallbackPage($root);
}

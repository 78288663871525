import { Mode } from '@amzn/awsui-global-styles';

/**
 * Items in the user menu.
 * Obs. These ID's are also being used for capturing client side metrics.
 * Make sure to also update the dashboards when IDs are changed or updated.
 *
 * @constant {Object}
 */
export const USER_MENU = {
  signOut: 'signOut',
  lightTheme: Mode.Light,
  darkTheme: Mode.Dark,
};

import { defineMessages } from 'react-intl';

const labActionMessages = defineMessages({
  disabledTooltipOngoing: {
    id: 'labActions.disabledTooltipOngoing',
    defaultMessage: 'There is a another lab already running.',
  },
  disabledTooltipCredits: {
    id: 'labActions.disabledTooltipCredits',
    defaultMessage: 'Lab limit has been reached.',
  },
  labUnderMaintenanceTooltip: {
    id: 'labActions.labUnderMaintenanceTooltip',
    defaultMessage: 'The lab is currently under maintenance.',
  },
});

export default labActionMessages;

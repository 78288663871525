import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'offlinePage.title',
    defaultMessage: 'Offline',
  },
  heading: {
    id: 'offlinePage.heading',
    defaultMessage: 'This page will be back soon',
  },
  message: {
    id: 'offlinePage.message',
    defaultMessage:
      "We're undergoing maintenance and expect this page to be available again soon. Thanks for your patience.",
  },
});

export default messages;

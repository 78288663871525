import { defineMessages } from 'react-intl';

const messages = defineMessages({
  seoError: {
    id: 'labPage.seoError',
    defaultMessage: 'Uh oh, something went wrong',
  },
  localeNotAvailable: {
    id: 'labPage.localeNotAvailable',
    defaultMessage: 'This lab is not yet available in {language}.',
  },
  labUnderMaintenance: {
    id: 'labPage.labUnderMaintenance',
    defaultMessage:
      'The lab is currently under maintenance. Please try again later or select a different lab.',
  },
});

export default messages;

import { defineMessages } from 'react-intl';
import { IdPs } from '../../constants/idPs';

const messages = defineMessages({
  learnMoreLabel: {
    id: 'signin.learnMore',
    defaultMessage: 'Learn more',
    description: 'Learn more label',
  },
  signInButton: {
    id: 'signin.signinButton',
    defaultMessage: 'Sign in',
    description: 'Signin button label for Provider Button',
  },
});

const providerMessages = defineMessages({
  [IdPs.OTP]: {
    id: 'provider.OTP',
    defaultMessage: 'One-time email passcode',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSBuilderID]: {
    id: 'provider.AWSBuilderID',
    defaultMessage: 'AWS Builder ID',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSSignIn]: {
    id: 'provider.AWSSignIn',
    defaultMessage: 'AWS',
    description: 'Button label for the identity provider.',
  },
  [IdPs.LoginWithAmazon]: {
    id: 'provider.LoginWithAmazon',
    defaultMessage: 'Login with Amazon',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AmazonFederate]: {
    id: 'provider.AmazonFederate',
    defaultMessage: 'Amazon employee single sign-on',
    description: 'Button label for the identity provider.',
  },
});

const providerDescription = defineMessages({
  [IdPs.OTP]: {
    id: 'providerDescription.OTP',
    defaultMessage: 'Use a passcode sent to the email address you specify',
    description: 'Description for OTP provider',
  },
  [IdPs.AWSBuilderID]: {
    id: 'providerDescription.AWSBuilderID',
    defaultMessage: 'Use your AWS Builder ID or create one',
    description: 'Description for AWSBuilderID',
  },
  [IdPs.LoginWithAmazon]: {
    id: 'proiderDescription.LoginWithAmazon',
    defaultMessage: 'Use your Amazon retail account to sign in',
    description: 'Description for LoginWithAmazon',
  },
  [IdPs.AmazonFederate]: {
    id: 'providerDescription.AmazonFederate',
    defaultMessage: 'Use your Amazon work credentials',
    description: 'Description for AmazonFederate',
  },
  [IdPs.AWSSignIn]: {
    id: 'providerDescription.AWSSignIn',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription',
  },
});

const signoutFromIdp = defineMessages({
  [IdPs.AWSBuilderID]: {
    id: 'signoutFromIdp.AWSBuilderID',
    defaultMessage: 'Change signed in account',
    description:
      'Link text instructing customers how to log out from Builder ID.',
  },
});

export { messages, providerDescription, providerMessages, signoutFromIdp };

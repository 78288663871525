import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import SEO from '../components/SEO';
import Main from '../components/Main';
import { publishCounterMetric } from '../utils/metrics';
import messages from './DefaultErrorPage.messages';

const DefaultErrorPage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    publishCounterMetric('DefaultErrorPage');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main>
      <SEO title={formatMessage(messages.heading)} />
      <Container
        header={<Header variant="h1">{formatMessage(messages.heading)}</Header>}
      >
        <SpaceBetween size="s">
          <p>{formatMessage(messages.message)}</p>
          <Button onClick={() => window.location.reload()}>
            {formatMessage(messages.reload)}
          </Button>
        </SpaceBetween>
      </Container>
    </Main>
  );
};

export default DefaultErrorPage;

import { useState } from 'react';

export const defaultValues = {
  splitPanelSize: 350,
  splitPanelOpen: false,
  splitPanelPreferences: { position: 'side' },
};

/**
 * @returns {Object} Returns an object matching the AppLayout SplitPanel props
 */
const useSplitPanelProps = () => {
  const [splitPanelSize, setSplitPanelSize] = useState(
    defaultValues.splitPanelSize
  );
  const [splitPanelOpen, setSplitPanelOpen] = useState(
    defaultValues.splitPanelOpen
  );
  const [splitPanelPreferences, setSplitPanelPreferences] = useState(
    defaultValues.splitPanelPreferences
  );

  const onSplitPanelToggle = ({ detail: { open } }) => {
    setSplitPanelOpen(open);
  };

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  const onSplitPanelPreferencesChange = ({ detail }) => {
    setSplitPanelPreferences(detail);
  };

  return {
    setSplitPanelOpen,
    appLayoutSplitPanelProps: {
      splitPanelOpen,
      splitPanelSize,
      splitPanelPreferences,
      onSplitPanelToggle,
      onSplitPanelResize,
      onSplitPanelPreferencesChange,
    },
  };
};

export default useSplitPanelProps;

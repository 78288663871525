import React, {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import Tour from '../components/SiteTour/Tour';
import { tourReducer, actions, defaultState } from './tourState';
import { TOUR_STORAGE_TEXT } from '../constants/siteTour';
import { TOUR_STORAGE_ID } from '../constants/cookies';
import { localStorageAdapter } from '../utils/cookieManagement';
import { URL_PATHS } from '../constants/urlPaths';
import { HELP_BTN_SELECTOR } from '../constants/helpPanel';

export const TourContext = createContext();
export const useTourContext = () => useContext(TourContext);

const isOnLabPath = path =>
  path.includes(`${URL_PATHS.labPage}/`) ||
  path.includes(`${URL_PATHS.standaloneLabPage}/`);

const TourContextProvider = ({ children }) => {
  const [tourState, dispatchTourUpdate] = useReducer(tourReducer, defaultState);
  const { pathname: currentPath } = useLocation();
  const [isAutoRunTour, setIsAutoRunTour] = useState();
  const [tourEnabled, setTourEnabled] = useState(false);

  useEffect(() => {
    // automatically show tour if it has never been shown and user is on a lab page
    const tourStatus = localStorageAdapter.getItem(TOUR_STORAGE_ID);
    if (tourStatus === TOUR_STORAGE_TEXT) return;

    if (tourEnabled && tourState === defaultState && isOnLabPath(currentPath)) {
      setIsAutoRunTour(true);
      dispatchTourUpdate({ type: actions.START_TOUR });
    }
  }, [tourState, tourEnabled, dispatchTourUpdate, currentPath]);

  const startTour = useCallback(() => {
    dispatchTourUpdate({ type: actions.START_TOUR });
  }, [dispatchTourUpdate]);

  const continueTour = useCallback(
    ({ stepIndex, tourAction }) => {
      dispatchTourUpdate({
        type: actions.CONTINUE_TOUR,
        stepIndex,
        tourAction,
      });
    },
    [dispatchTourUpdate]
  );

  const endTour = useCallback(() => {
    dispatchTourUpdate({ type: actions.END_TOUR });

    if (isAutoRunTour) return setIsAutoRunTour(false);
    setTimeout(() => {
      const $helpOpenBtn = document.querySelector(HELP_BTN_SELECTOR);
      $helpOpenBtn && $helpOpenBtn.focus();
    });
  }, [dispatchTourUpdate, isAutoRunTour]);

  return (
    <TourContext.Provider
      value={{
        tourState,
        startTour,
        continueTour,
        endTour,
        tourEnabled,
        setTourEnabled,
      }}
    >
      {children}

      <Tour />
    </TourContext.Provider>
  );
};

TourContextProvider.propTypes = {
  children: PropTypes.node,
};

export default TourContextProvider;

const tenMinutesInMs = 1000 * 60 * 10;

/**
 * How often to force a refresh an ongoing lab session to get updated credentials.
 * Federation URLs are valid for 15 minutes. We refresh every 10 minutes to have a nice buffer.
 * https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_providers_enable-console-custom-url.html
 * @constant {Number}
 */
export const CONSOLE_URL_TTL = tenMinutesInMs;

export const SANDBOX_CONSOLE_WINDOW_NAME = 'SandboxConsole';

export const CONSOLE_SIGNOUT_URL =
  'https://signin.aws.amazon.com/oauth?Action=logout';

export const MD_CONTENT_CLASSNAME = 'MarkdownContent';

export const MD_WRAPPER_CLASSNAME = 'js-markdown-content-wrapper';

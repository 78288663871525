import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'helpPanel.title',
    defaultMessage: 'Help and Resources',
  },

  troubleshooting: {
    id: 'helpPanel.troubleshooting',
    defaultMessage: 'Troubleshooting',
  },
  faq: {
    id: 'helpPanel.faq',
    defaultMessage: 'FAQs',
  },
  resources: {
    id: 'helpPanel.resources',
    defaultMessage: 'Resources',
  },
  tour: {
    id: 'helpPanel.tour',
    defaultMessage: 'Guided tour',
  },

  // Resource Links
  awsBlogs: {
    id: 'helpPanel.awsBlogs',
    defaultMessage: 'AWS Blogs',
  },
  awsDocs: {
    id: 'helpPanel.awsDocs',
    defaultMessage: 'AWS Documentation',
  },
  awsForums: {
    id: 'helpPanel.awsForums',
    defaultMessage: 'AWS re:Post',
  },
  awsTCResources: {
    id: 'helpPanel.awsTCResources',
    defaultMessage: 'AWS Training Resources',
  },

  report: {
    id: 'helpPanel.report',
    defaultMessage: 'Contact support',
  },
});

export default messages;

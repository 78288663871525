import { defineMessages } from 'react-intl';

const messages = defineMessages({
  multipleOngoingLabsHeader: {
    id: 'ongoingLab.multipleOngoingLabsHeader',
    defaultMessage: 'Max number of labs reached',
  },
  multipleOngoingLabsContent: {
    id: 'ongoingLab.multipleOngoingLabsContent',
    defaultMessage: 'End one of these running labs to start another lab',
  },
  loadMultipleOngoingLabsFail: {
    id: 'ongoingLab.loadMultipleOngoingLabsFail',
    defaultMessage:
      'Uh oh, something went wrong.{br}Please reload the page and try again.',
  },
  reload: {
    id: 'ongoingLab.reload',
    defaultMessage: 'Reload page',
  },
  loading: {
    id: 'ongoingLab.loading',
    defaultMessage: 'Loading lab details...',
  },
});

export default messages;

import axios from 'axios';
import { retry } from './promiseUtils';

const cacheBuster = new Date().getTime();
const CONFIG_URI = `/config/config.json?v=${cacheBuster}`;
let cachedConfig = null;

const get = async () => {
  if (cachedConfig) return cachedConfig;

  try {
    const response = await retry(() => axios.get(CONFIG_URI));
    // eslint-disable-next-line require-atomic-updates
    return (cachedConfig = response.data);
  } catch (err) {
    throw new Error(`Failed to fetch config: ${err.message}`);
  }
};

export default {
  get,
};

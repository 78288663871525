import { defineMessages } from 'react-intl';

const messages = defineMessages({
  end: {
    id: 'tourTooltip.end',
    defaultMessage: 'End tour',
  },
  next: {
    id: 'tourTooltip.next',
    defaultMessage: 'Next',
  },
  back: {
    id: 'tourTooltip.back',
    defaultMessage: 'Back',
  },
});

export default messages;

import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import LabAssessmentContainer from './LabAssessmentContainer';
import useMutationObserver from '../../../hooks/useMutationObserver';
import { ASSESSMENT_CSS_SELECTOR } from '../../../constants/labAssessment';

const LabAssessmentPortal = ({ labId, parentNode }) => {
  const containers = useMutationObserver(parentNode, ASSESSMENT_CSS_SELECTOR);

  if (!containers.length) {
    return null;
  }

  return containers.map(container => {
    const { assessmentId } = container.dataset;
    return createPortal(
      <LabAssessmentContainer labId={labId} assessmentId={assessmentId} />,
      container
    );
  });
};

LabAssessmentPortal.propTypes = {
  labId: PropTypes.string,
  parentNode: PropTypes.object,
};

export default LabAssessmentPortal;

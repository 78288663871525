import React, { useRef } from 'react';
import {
  Box,
  StatusIndicator,
  Popover,
  Button,
  Input,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';

import messages from './LabSecrets.messages';
import copyToClipboard from '../MarkdownRenderer/utils/copy.utils';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const styledSecretTypes = {
  Password: 'Password',
  AdministratorPassword: 'AdministratorPassword',
  'Ec2KeyPair-PEM': 'Ec2KeyPair-PEM',
  'Ec2KeyPair-PPK': 'Ec2KeyPair-PPK',
};

const supportedKeyPairFormats = {
  'Ec2KeyPair-PEM': {
    downloadExtension: 'pem',
  },
  'Ec2KeyPair-PPK': {
    downloadExtension: 'ppk',
  },
};

const downloadFromText = (filename, content) => {
  saveAs(new Blob([content], { type: 'text/plain;charset=utf-8' }), filename, {
    autoBom: true,
  });
};

const getExtension = secret =>
  supportedKeyPairFormats[secret.name].downloadExtension;

const handleDownloadEvent = secret => e => {
  e.preventDefault();
  downloadFromText(`${secret.name}.${getExtension(secret)}`, secret.value);
};

const SecretLabel = ({ messageId }) => {
  const { formatMessage } = useIntl();
  return (
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {formatMessage(messages[messageId])}
    </Box>
  );
};

const SecretTextToCopy = ({ secretValue, secretLabel }) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef();
  const inputId = uuid();
  return (
    <Box margin={{ bottom: 's' }}>
      <Popover
        size="small"
        position="top"
        triggerType="custom"
        dismissButton={false}
        content={
          <StatusIndicator type="success">
            {formatMessage(messages.copied)}
          </StatusIndicator>
        }
      >
        <Button
          iconName="copy"
          variant="inline-icon"
          ariaLabel={formatMessage(messages.copyLabel, { secret: secretLabel })}
          onClick={() => copyToClipboard(secretValue, inputRef)}
        />
      </Popover>
      <div style={{ width: '80%', display: 'inline-block' }}>
        <Input
          id={inputId}
          readOnly={true}
          ref={inputRef}
          value={secretValue}
          ariaLabel={secretLabel}
          onFocus={() => {
            // Polaris v3 no longer returns target in event api
            const input = document
              .getElementById(inputId)
              .querySelector('input');
            input && input.select();
          }}
        />
      </div>
    </Box>
  );
};

const Secret = ({ secret }) => {
  const { formatMessage } = useIntl();

  switch (secret.name) {
    case styledSecretTypes.Password:
      return (
        <div>
          <SecretLabel messageId="password" />
          <SecretTextToCopy
            secretValue={secret.value}
            secretLabel={formatMessage(messages.password)}
          />
        </div>
      );
    case styledSecretTypes.AdministratorPassword:
      return (
        <div>
          <SecretLabel messageId="adminPassword" />
          <SecretTextToCopy
            secretValue={secret.value}
            secretLabel={formatMessage(messages.adminPassword)}
          />
        </div>
      );
    case styledSecretTypes['Ec2KeyPair-PEM']:
      return (
        <div>
          <SecretLabel messageId="ec2KeyPairPEM" />
          <Button
            variant="link"
            iconName="download"
            iconAlign="right"
            onClick={handleDownloadEvent(secret)}
          >
            {formatMessage(messages.downloadPEM)}
          </Button>
        </div>
      );
    case styledSecretTypes['Ec2KeyPair-PPK']:
      return (
        <div>
          <SecretLabel messageId="ec2KeyPairPPK" />
          <Button
            variant="link"
            iconName="download"
            iconAlign="right"
            onClick={handleDownloadEvent(secret)}
          >
            {formatMessage(messages.downloadPPK)}
          </Button>
        </div>
      );
    default:
      return (
        <div>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {secret.name}
          </Box>
          <SecretTextToCopy
            secretValue={secret.value}
            secretLabel={secret.name}
          />
        </div>
      );
  }
};

const LabSecrets = ({ secrets }) => {
  const { formatMessage } = useIntl();
  if (!secrets || !secrets.length) return null;

  const renderedSecrets = secrets.map(secret => (
    <Secret key={secret.name} secret={secret} />
  ));

  if (!renderedSecrets.length) return null;

  return (
    <>
      <Box margin={{ bottom: 'l' }}>
        <Box
          margin={{ bottom: 's' }}
          variant="h3"
          data-testid={dataTestId['lab-resources']}
        >
          {formatMessage(messages.resources)}
        </Box>
        {renderedSecrets}
      </Box>
      <hr />
    </>
  );
};

LabSecrets.propTypes = {
  secrets: PropTypes.array,
};

export default LabSecrets;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';

import messages from './EventInformation.messages';
import { LANDING_PAGE_VARIANTS } from '../../constants/landingPage';

const DefaultInfo = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Box variant="h1" color="inherit" fontSize="display-l">
        {formatMessage(messages.defaultTitle, {
          b: chunk => <b>{chunk}</b>,
        })}
      </Box>
      <Box variant="p" color="inherit">
        {formatMessage(messages.defaultMessage)}
      </Box>
    </>
  );
};

const EhAndJamInfo = () => {
  const { formatMessage } = useIntl();
  return (
    <SpaceBetween size="m">
      <Box variant="h1" color="inherit" fontSize="display-l">
        {formatMessage(messages.ehAndJam_title)}
      </Box>
      <Box variant="p" color="inherit">
        {formatMessage(messages.ehAndJam_message)}
      </Box>
      <div>
        <Box variant="h2" color="inherit">
          {formatMessage(messages.ehAndJam_labsTitle)}
        </Box>
        <Box variant="p" color="inherit">
          {formatMessage(messages.ehAndJam_labsDescription)}
        </Box>
      </div>
      <div>
        <Box variant="h2" color="inherit">
          {formatMessage(messages.ehAndJam_jamTitle)}
        </Box>
        <Box variant="p" color="inherit">
          {formatMessage(messages.ehAndJam_jamDescription)}
        </Box>
      </div>
    </SpaceBetween>
  );
};

const ReInventInfo = () => {
  const { formatMessage } = useIntl();
  return (
    <SpaceBetween size="m">
      <Box variant="h1" color="inherit" fontSize="display-l">
        {formatMessage(messages.reInvent_title)}
      </Box>
      <Box variant="p" color="inherit">
        {formatMessage(messages.reInvent_message)}
      </Box>
      <div>
        <Box variant="h2" color="inherit">
          {formatMessage(messages.reInvent_labsTitle)}
        </Box>
        <Box variant="p" color="inherit">
          {formatMessage(messages.reInvent_labsDescription)}
        </Box>
      </div>
      <div>
        <Box variant="h2" color="inherit">
          {formatMessage(messages.reInvent_simulearnTitle)}
        </Box>
        <Box variant="p" color="inherit">
          {formatMessage(messages.reInvent_simulearnDescription)}
        </Box>
      </div>
      <div>
        <Box variant="h2" color="inherit">
          {formatMessage(messages.reInvent_jamTitle)}
        </Box>
        <Box variant="p" color="inherit">
          {formatMessage(messages.reInvent_jamDescription)}
        </Box>
      </div>
    </SpaceBetween>
  );
};

const EventInformation = ({ variant }) => {
  let InfoComponent;

  switch (variant) {
    case LANDING_PAGE_VARIANTS.default:
      InfoComponent = DefaultInfo;
      break;
    case LANDING_PAGE_VARIANTS.ehAndJam:
      InfoComponent = EhAndJamInfo;
      break;
    case LANDING_PAGE_VARIANTS.reInvent:
      InfoComponent = ReInventInfo;
      break;
    default:
      InfoComponent = DefaultInfo;
      break;
  }

  return (
    <div
      style={{
        minHeight: '100%',
        backgroundImage:
          'linear-gradient(180deg, #09051b 0%, #091429 50%, #0a4b5d 100%)',
        backgroundSize: 'cover',
        color: 'white',
      }}
    >
      <Box padding="xxxl" display="block" color="inherit">
        <InfoComponent />
      </Box>
    </div>
  );
};

EventInformation.propTypes = {
  variant: PropTypes.oneOf([
    LANDING_PAGE_VARIANTS.default,
    LANDING_PAGE_VARIANTS.ehAndJam,
    LANDING_PAGE_VARIANTS.reInvent,
  ]).isRequired,
};

export default EventInformation;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Spinner } from '@amzn/awsui-components-react';
import appMessages from '../i18n/app.messages';

const Loader = ({ loading, children, size = 'large' }) => {
  const { formatMessage } = useIntl();
  return loading ? (
    <span aria-label={formatMessage(appMessages.loading)}>
      <Spinner size={size} />
    </span>
  ) : (
    <>{children}</>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.string,
  children: PropTypes.node,
};

export default Loader;

import { useEffect, useState } from 'react';

/**
 * @param {HTMLElement} node
 * @param {string} selector
 * @returns {HTMLElement[]}
 */
const useMutationObserver = (node, selector) => {
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    if (node) {
      const containers = node.querySelectorAll(selector);
      setContainers(Array.from(containers));

      const observer = new MutationObserver(() => {
        const containers = node.querySelectorAll(selector);
        setContainers(Array.from(containers));
      });

      observer.observe(node, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }
  }, [node, selector]);

  return containers;
};

export default useMutationObserver;

import { isEmpty, propOr } from 'ramda';
import { getBlueprintProp } from '../../utils/blueprintUtils';
import { DEFAULT_LOCALE } from '../../constants/locale';

const labArnRegex = /arn:aws:learningcontent:.+:.+:blueprintversion.+/;

// https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/translate-survey/#AvailableLanguageCodes
// Maps Event Horizon language codes to Qualtrics language codes
const qualitricsLanguageMap = {
  'en-US': 'EN',
  'id-ID': 'ID',
  'de-DE': 'DE',
  'es-LA': 'ES',
  'es-ES': 'ES-ES',
  'fr-FR': 'FR',
  'it-IT': 'IT',
  'pt-BR': 'PT-BR',
  'zh-CN': 'ZH-S',
  'zh-TW': 'ZH-T',
  'ja-JP': 'JA',
  'ko-KR': 'KO',
  'th-TH': 'TH',
  'uk-UA': 'UK',
  'vi-VN': 'VI',
};

const getQualtricsLanguageCode = localeCode =>
  propOr('EN', localeCode, qualitricsLanguageMap);

export const generateExternalSurveyUrl = (metadata, feedbackURLParams = {}) => {
  if (isEmpty(metadata) || isEmpty(metadata.baseUrl)) return '';
  const url = new URL(metadata.baseUrl);
  const searchParams = new URLSearchParams(url.search);

  for (const [key, value] of Object.entries(feedbackURLParams)) {
    searchParams.append(key, value);
  }
  url.search = searchParams.toString();

  return url.toString();
};

const getLabKey = labId => labId?.split('::')[1] || '';

export const createFeedbackURLParams = (
  vibeId,
  event,
  blueprint,
  labId,
  locale
) => {
  let fullBlueprintName, blueprintVersion;

  if (blueprint?.arn && blueprint.arn.match(labArnRegex)) {
    const blueprintArnContents = blueprint.arn.split('blueprintversion/')[1];
    if (blueprintArnContents && blueprintArnContents.length > 0) {
      [fullBlueprintName, blueprintVersion] = blueprintArnContents.split(':');
    }
  }

  return {
    VibeId: vibeId || '',
    EventId: event?.name || '',
    EventType: event?.isStandaloneLabEvent() ? 'standalone' : 'event',
    LabName: getBlueprintProp(DEFAULT_LOCALE, blueprint, 'title') || '',
    LabId: getLabKey(labId),
    BlueprintName: fullBlueprintName
      ? encodeURIComponent(fullBlueprintName)
      : '',
    BlueprintVersion: blueprintVersion || '',
    Q_Language: getQualtricsLanguageCode(locale),
  };
};

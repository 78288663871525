import { useQueryClient, useMutation } from 'react-query';

import {
  labAssessmentQueryKey,
  startLabAssessment,
} from '../utils/assessmentsService';
import { interceptRequestMetrics } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';

const metricsNamespace = 'StartLabAssessment';

/**
 * @typedef {import('../utils/assessmentsService').LabAssessment} LabAssessment
 */

/**
 * @typedef {Object} Variables
 * @property {string} labId
 * @property {string} assessmentId
 */

/**
 * @param {Object} props
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @returns {import('react-query').UseMutationResult<LabAssessment, any, Variables, void>}
 */
const useStartLabAssessment = ({ metricsPublisher } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ labId, assessmentId }) =>
      interceptRequestMetrics(
        metricsNamespace,
        startLabAssessment(labId, assessmentId),
        metricsPublisher
      ),
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [
            ERROR_TYPES.BadRequest,
            ERROR_TYPES.NotFound,
            ERROR_TYPES.Conflict,
          ],
        })
      ) {
        return false;
      }
      return failureCount < 2;
    },
    retryDelay: attemptIndex =>
      calculateRetryDelay(attemptIndex, { interval: 200 }),
    onSuccess: (data, variables) => {
      metricsPublisher?.publishCounter('AssessmentStarted', 1);
      queryClient.setQueryData(
        labAssessmentQueryKey(variables.labId, variables.assessmentId),
        data
      );
    },
  });
};

export default useStartLabAssessment;

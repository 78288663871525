import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Modal, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { ButtonOverride } from '../PolarisOverrides';
import FeedbackForm from './FeedbackForm';
import { IMPRESSIONS } from '../../constants/metrics';
import { TOUR_IDS } from '../../constants/siteTour';
import metrics, { publishCounterMetric } from '../../utils/metrics';
import {
  getFeedbackPublisher,
  initialFeedbackState,
  publishFeedbackState,
  feedbackMetricsList,
} from '../../utils/feedbackService';
import {
  notificationTypes,
  useNotificationContext,
  useAuthContext,
  useEventContext,
  useLabContext,
  useLocaleContext,
} from '../../contexts';
import messages from './Feedback.messages';
import appMessages from '../../i18n/app.messages';
import FEATURES from '../../constants/features';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import {
  generateExternalSurveyUrl,
  createFeedbackURLParams,
} from './feedbackUtils';

const Feedback = ({ blueprint, isInSidebar }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [feedbackState, setFeedbackState] = useState(initialFeedbackState);
  const feedbackBtnRef = useRef(null);
  const feedbackPublisher = useRef(getFeedbackPublisher());
  const timerMetric = useRef(null);
  const { appendNotification } = useNotificationContext();
  const [{ vibeId }] = useAuthContext();
  const { event } = useEventContext();
  const [locale] = useLocaleContext();
  const { getFirstLabInLabState } = useLabContext();
  const labState = getFirstLabInLabState();
  const openExternalLink = event.hasFeature(FEATURES.feedbackOpensExternalLink);
  const handleFeedbackChange = useCallback(
    ({ name, value }) => {
      setFeedbackState({
        ...feedbackState,
        [name]: value,
      });
    },
    [feedbackState]
  );

  useEffect(() => {
    if (!isOpen) return;
    feedbackPublisher.current.publishCounter(IMPRESSIONS, 1);
    timerMetric.current = metrics.createTimerStopWatch('timer').withMonitor();
  }, [isOpen]);

  const closeModal = () => {
    setFeedbackState(initialFeedbackState);
    setOpen(false);
    setTimeout(() => feedbackBtnRef?.current?.focus(), 0);
  };

  const submitFeedback = () => {
    feedbackPublisher.current.publishMetric(timerMetric.current);
    feedbackPublisher.current.publishString('PathName', location.pathname);
    feedbackPublisher.current.publishString('VibeId', vibeId);
    publishFeedbackState(feedbackPublisher.current, feedbackState);
    closeModal();
    appendNotification({
      contentId: messages.submitMessage,
      type: 'success',
      notificationType: notificationTypes.GLOBAL,
    });
  };

  const handleExternalButtonClicked = useCallback(({ name = 'Survey' }) => {
    publishCounterMetric(name, { counterName: 'SurveyClicked' });
  }, []);

  let externalURL;
  if (openExternalLink) {
    externalURL = generateExternalSurveyUrl(
      event.getMetadata(FEATURES.feedbackOpensExternalLink),
      createFeedbackURLParams(vibeId, event, blueprint, labState.labId, locale)
    );
  }

  const FeedbackButton = ({ openExternalLink, externalURL, isInSidebar }) => {
    if (openExternalLink && externalURL) {
      if (isInSidebar) {
        return (
          <ButtonOverride
            variant="inline-link"
            onClick={() =>
              handleExternalButtonClicked({ name: 'SurveySidebar' })
            }
            ref={buttonRef => (feedbackBtnRef.current = buttonRef)}
            href={externalURL}
            target="_blank"
            ariaLabel={`${formatMessage(messages.feedbackBtn)} ${formatMessage(
              appMessages.externalIconAriaLabel
            )}`}
          >
            <Icon name="contact" /> {formatMessage(messages.feedbackBtn)}
          </ButtonOverride>
        );
      }
      return (
        <ButtonOverride
          variant="transparent"
          onClick={handleExternalButtonClicked}
          ref={buttonRef => (feedbackBtnRef.current = buttonRef)}
          data-testid={dataTestId['feedback-open']}
          data-tourid={TOUR_IDS.feedbackBtn}
          href={externalURL}
          target="_blank"
          ariaLabel={`${formatMessage(messages.feedbackBtn)} ${formatMessage(
            appMessages.externalIconAriaLabel
          )}`}
        >
          {formatMessage(messages.feedbackBtn)}
        </ButtonOverride>
      );
    }

    if (isInSidebar) {
      return (
        <ButtonOverride
          variant="inline-link"
          onClick={() => setOpen(true)}
          ref={buttonRef => (feedbackBtnRef.current = buttonRef)}
        >
          <Icon name="contact" /> {formatMessage(messages.feedbackBtn)}
        </ButtonOverride>
      );
    }

    return (
      <ButtonOverride
        variant="transparent"
        onClick={() => setOpen(true)}
        ref={buttonRef => (feedbackBtnRef.current = buttonRef)}
        data-testid={dataTestId['feedback-open']}
        data-tourid={TOUR_IDS.feedbackBtn}
      >
        {formatMessage(messages.feedbackBtn)}
      </ButtonOverride>
    );
  };

  return (
    <>
      {isOpen && (
        <Modal
          header={formatMessage(messages.modalHeader)}
          visible={isOpen}
          onDismiss={closeModal}
          closeAriaLabel={formatMessage(messages.modalClose)}
          data-testid="feedback-dialog"
          footer={
            <Box variant="span" float="right">
              <Button
                variant="link"
                onClick={closeModal}
                data-testid={dataTestId['feedback-close']}
              >
                {formatMessage(messages.closeBtn)}
              </Button>
              <Button
                variant="primary"
                onClick={submitFeedback}
                data-testid={dataTestId['feedback-submit']}
              >
                {formatMessage(messages.submitBtn)}
              </Button>
            </Box>
          }
        >
          <FeedbackForm
            feedbackState={feedbackState}
            metricsList={feedbackMetricsList}
            onChange={handleFeedbackChange}
          />
        </Modal>
      )}
      <FeedbackButton
        openExternalLink={openExternalLink}
        externalURL={externalURL}
        isInSidebar={isInSidebar}
      />
    </>
  );
};

Feedback.propTypes = {};

export default Feedback;

import React from 'react';
import PropTypes from 'prop-types';
import { Mode } from '@amzn/awsui-global-styles';

import { useLocaleContext, useThemeContext } from '../contexts';
import MarkdownRenderer from './MarkdownRenderer';

/**
 * Applies the EH theme and the current locale automatically.
 * This is a wrapper around the MarkdownRenderer component.
 *
 * @param {string} content - Markdown content
 * @param {boolean} padding - Whether to apply padding
 */
const EasyMarkdown = ({ content, padding = false }) => {
  const [theme] = useThemeContext();
  const [userLocale] = useLocaleContext();
  if (!content) return null;

  return (
    <MarkdownRenderer
      theme={theme === Mode.Dark ? 'dark' : 'light'}
      locale={userLocale}
      markdown={content}
      padding={padding}
    />
  );
};

EasyMarkdown.propTypes = {
  content: PropTypes.string.isRequired,
  padding: PropTypes.bool,
};

export default EasyMarkdown;

import React from 'react';
import { Box, SegmentedControl } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { useIntl } from 'react-intl';

import FeedbackStarRating from './FeedbackStarRating';
import { useThemeContext } from '../../contexts';
import { CSAT_STAR } from '../../constants/feedback';
import messages from './Feedback.messages';

import './FeedbackForm.css';

const FeedbackMetric = ({ metric, onChange, value }) => {
  const { formatMessage } = useIntl();
  const [theme] = useThemeContext();

  return (
    <Box padding={{ bottom: 'l' }}>
      <Box variant="h3" id={`feedback-${metric.name}`}>
        {formatMessage(messages[metric.messageId])}
      </Box>
      {metric.csat === CSAT_STAR ? (
        <FeedbackStarRating
          csat={metric.csat}
          name={metric.name}
          onChange={onChange}
        />
      ) : (
        <SegmentedControl
          className={`FeedbackMetricControl--${theme}`}
          selectedId={value}
          ariaLabelledby={`feedback-${metric.name}`}
          options={metric.csat.map(item => ({
            id: item.value,
            text: !item.iconUrl && formatMessage(messages[item.messageId]),
            iconAlt: item.iconUrl && formatMessage(messages[item.messageId]),
            iconUrl: item.iconUrl,
          }))}
          onChange={event =>
            onChange({
              name: metric.name,
              value: path(['detail', 'selectedId'], event),
            })
          }
        ></SegmentedControl>
      )}
    </Box>
  );
};

const FeedbackForm = ({ onChange, feedbackState, metricsList }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Box variant="p" padding={{ bottom: 'l' }}>
        {formatMessage(messages.formHeader)}
      </Box>
      {metricsList.map((metric, i) => (
        <FeedbackMetric
          key={i}
          metric={metric}
          onChange={onChange}
          value={feedbackState[metric.name]}
        />
      ))}
    </>
  );
};

FeedbackForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  metricsList: PropTypes.array.isRequired,
  feedbackState: PropTypes.object.isRequired,
};

export default FeedbackForm;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'SEO.description',
    defaultMessage:
      'Self-Paced Labs gives you the opportunity to learn how to use AWS in a safe sandbox environment.',
  },
});

export default messages;

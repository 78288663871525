import thumbsUp from '@fortawesome/fontawesome-free/svgs/regular/thumbs-up.svg';
import thumbsDown from '@fortawesome/fontawesome-free/svgs/regular/thumbs-down.svg';

/**
 * PMET methodName for general feedback metrics.
 * @constant {String}
 */
export const METRIC_METHOD = 'Feedback';

/**
 * PMET metric values, combined with metric names.
 * @constant {Object}
 */
export const METRIC_VALUES = {
  NA: 'NA',
  v0: '0',
  v1: '1',
  v2: '2',
  v3: '3',
  v4: '4',
  v5: '5',
};

/**
 * The CSAT value is combined with the metric name in PMET.
 * @constant {Array[Object]}
 */
export const CSAT_BINARY = [
  // messageId must be the same as id in Feedback.messages.js
  { value: METRIC_VALUES.NA, messageId: 'noInput' },
  {
    value: METRIC_VALUES.v0,
    messageId: 'thumbsDown',
    iconUrl: thumbsDown,
  },
  {
    value: METRIC_VALUES.v1,
    messageId: 'thumbsUp',
    iconUrl: thumbsUp,
  },
];

/**
 * The CSAT value is combined with the metric name in PMET.
 * @constant {Array}
 */
export const CSAT_STAR = [
  METRIC_VALUES.NA,
  METRIC_VALUES.v1,
  METRIC_VALUES.v2,
  METRIC_VALUES.v3,
  METRIC_VALUES.v4,
  METRIC_VALUES.v5,
];

/**
 * Metric names used in PMET. Versioned to allow tracking changes if changes
 * are made to questions.
 * @constant {Object}
 */
export const METRICS_NAMES = {
  OverallSatisfaction: 'OverallSatisfaction-1.0',
  LabInstructions: 'LabInstructions-1.0',
  WebApplication: 'WebApplication-1.0',
  AWSConsole: 'AWSConsole-1.0',
};

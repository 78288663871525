import { path, propOr } from 'ramda';
import { GraphQLAuthError } from '@aws-amplify/api';

import logger from './logger';
import TimeoutError from '../errors/TimeoutError';
import ERROR_TYPES from '../constants/errorTypes';

/**
 * @typedef {Object} RetryOptions
 * @property {Number} [retries=3]
 * @property {Number} [interval=100]
 * @property {Boolean} [expBackoff=true] Adds exponential backoff between retries
 * @property {Boolean} [jitter=true] Adds jitter to retries
 * @property {Function<Boolean>} [retryPredicate] Fine tune when to retry, gets passed the caught error.
 */

/** @type {RetryOptions} */
const defaultOptions = {
  retries: 3,
  interval: 100,
  expBackoff: true,
  jitter: true,
};

/**
 * @param {Number} attempt
 * @param {RetryOptions} [options]
 */
export const calculateRetryDelay = (attempt, options = {}) => {
  const opts = { ...defaultOptions, ...options };
  // Avoid delays when running test suite.
  if (path(['env', 'NODE_ENV'], process) === 'test') return 0;

  const expBackoff = opts.expBackoff ? Math.pow(2, attempt) : 1;
  const jitter = opts.jitter ? Math.random() : 1;
  return Math.floor(opts.interval * jitter * expBackoff);
};

/**
 * Checks against a known list of errors there is no need to retry.
 *
 * @param {any} error An GraphQL error object, Error or primitive
 * @param {Object} [options]
 * @param {string[]} [options.messages] Additional non-retyable error messages
 * @param {string[]} [options.errorTypes] Additional non-retyable GraphQL error types
 * @returns {boolean}
 */
export const isNonRetryableError = (error, options) => {
  // Error messages we can expect to see from ex Amplify GraphQL
  const messages = [
    GraphQLAuthError.NO_CURRENT_USER,
    GraphQLAuthError.NO_CREDENTIALS,
    ...(options?.messages || []),
  ];

  // ErrorTypes we can expect to see from EH AppSync
  const errorTypes = [
    ERROR_TYPES.ClosedEvent,
    ERROR_TYPES.Unauthorized, // AppSync or EH ACL AuthZ
    ERROR_TYPES.Forbidden, // VIAS AuthZ
    ERROR_TYPES.NotAuthorizedInACL, // EH ACL
    ERROR_TYPES.EmailValidationRequired,
    ERROR_TYPES.NoActiveLab,
    ...(options?.errorTypes || []),
  ];

  // Perform checks
  const errors = propOr([], 'errors', error);
  if (error === 'No current user') return true;
  if (error?.message && messages.indexOf(error.message) > -1) return true;
  if (errors.find(e => errorTypes.indexOf(e?.errorType) > -1)) return true;

  return false;
};

const shouldRetry = (options, error) => {
  if (isNonRetryableError(error)) return false;
  return options.retryPredicate ? options.retryPredicate(error) : true;
};

/**
 * Wraps a function that returns a promise with retry mechanics.
 *
 * @param {Function} asyncFn Function that returns a promise
 * @param {RetryOptions} [options]
 * @returns {Promise}
 */
export const retry = (asyncFn, options = {}, attempt = 1) => {
  const opts = { ...defaultOptions, ...options };
  const promise = asyncFn();

  if (!isPromise(promise)) {
    logger.error('[retry] The function needs to return a Promise.');
    throw new Error('asyncFn did not resolve to a Promise');
  }

  return promise.catch(err => {
    logger.debug('[retry] Caught error.', err);

    if (!shouldRetry(opts, err)) {
      logger.debug('[retry] retryPredicate opted not to retry:', err);
      return Promise.reject(err);
    }

    if (opts.retries === 0) {
      logger.debug('[retry] No more retries, failing.');
      return Promise.reject(err);
    }

    return delay(calculateRetryDelay(attempt, opts)).then(() => {
      logger.debug(
        `[retry] Retrying async call, ${opts.retries} attempts left.`
      );
      return retry(
        asyncFn,
        {
          ...opts,
          retries: opts.retries - 1,
        },
        attempt + 1
      );
    });
  });
};

/**
 * Checks if the passed object is a Promise.
 *
 * @param {Object} object
 * @returns {Boolean}
 */
export const isPromise = object =>
  // There is currently no great way to assert this absolutely. Right now the
  // existance of a then-able function is the only consistent pattern to check.
  Boolean(object && object.then && typeof object.then === 'function');

/**
 * Returns a promise that resolves after `ms`.
 *
 * @param {Number} ms Timeout in ms
 * @returns {Promise}
 */
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Promise wrapper that rejects if the `promise` does not resolve before `ms`.
 *
 * @param {Number} ms
 * @param {Promise} promise
 * @returns {Promise}
 */
export const timeout = (ms, promise) => {
  let timeoutId;
  const timeoutPromise = new Promise((__, reject) => {
    timeoutId = setTimeout(() => {
      reject(new TimeoutError(`Promise timed out after ${ms}ms.`));
    }, ms);
  });

  return Promise.race([
    promise.finally(() => clearTimeout(timeoutId)),
    timeoutPromise,
  ]);
};

/**
 * Allows you tap into a Promise's `then` function without having to worry
 * about stopping values passed in the promise chain.
 *
 * @example
 * Promise.resolve('Hello')
 *   .then(tap(res => console.log(res))) // "Hello"
 *   .then(res => console.log(res))      // "Hello"
 *   .then(res => console.log(res));     // undefined
 *
 * @param {Function} tapper
 * @returns {Function}
 */
export const tap = tapper => results => {
  tapper(results);
  return Promise.resolve(results);
};

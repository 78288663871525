import { path } from 'ramda';
import { useMutation } from 'react-query';

import { graphql } from '../utils/graphQLService';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';

/**
 * Submit lab evaluation.
 *
 * @param {string} labId
 * @param {string} executionId
 * @returns {Promise}
 */
const submitLabEvaluation = async (labId, executionId) => {
  const fieldName = 'submitLabEvaluation';
  const submitLabEvaluationGql = `
    mutation SubmitLabEvaluation($labId: String!, $executionId: String) {
      ${fieldName}(labId: $labId, executionId: $executionId) {
        scoreGiven
        scoreMaximum
      }
    }
  `;
  return graphql(submitLabEvaluationGql, {
    labId,
    executionId,
  }).then(path(['data', fieldName]));
};

const metricsNamespace = 'SubmitLabEvaluation';

/**
 * @typedef {Object} Variables
 * @property {string} labId
 * @property {string} blueprintArn
 * @property {string} executionId
 */

/**
 * @param {Object} props
 * @param {(variables: Variables) => void} [props.onMutate]
 * @param {(data: any, variables: Variables) => void} [props.onSuccess]
 * @param {(error: any, variables: Variables) => void} [props.onError]
 * @returns {import('react-query').UseMutationResult<any, any, Variables, void>}
 */
const useSubmitLabEvaluation = ({ onMutate, onSuccess, onError } = {}) => {
  return useMutation({
    mutationFn: ({ labId, executionId }) =>
      interceptRequestMetrics(
        metricsNamespace,
        submitLabEvaluation(labId, executionId)
      ),
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [ERROR_TYPES.BadRequest, ERROR_TYPES.NotFound],
        })
      ) {
        return false;
      }
      return failureCount < 3;
    },
    retryDelay: failureCount =>
      calculateRetryDelay(failureCount, { interval: 200 }),
    onMutate: variables => {
      if (onMutate) onMutate(variables);
    },
    onSuccess: (data, variables) => {
      if (onSuccess) onSuccess(data, variables);
    },
    onError: (error, variables) => {
      publishFatal(metricsNamespace, error, { ...variables });
      if (onError) onError(error, variables);
    },
  });
};

export default useSubmitLabEvaluation;

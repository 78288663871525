import { defineMessages } from 'react-intl';

const labEvaluationMessages = defineMessages({
  // evaluationFailed
  evaluationFailedMessage: {
    id: 'LabEvaluation.evaluationFailedMessage',
    defaultMessage: 'We were unable to check your work. Please try again.',
  },
  // noScoreYet
  noScoreYetHeader: {
    id: 'LabEvaluation.noScoreYetHeader',
    defaultMessage: 'No score yet',
  },
  noScoreYetMessage: {
    id: 'LabEvaluation.noScoreYetMessage',
    defaultMessage:
      'Each time you check your work, we’ll look for the expected resources in your AWS console and let you know how you’re doing. This process may take up to a minute. You can review the result here.',
  },
  noScoreYetIconLabel: {
    id: 'LabEvaluation.noScoreYetIconLabel',
    defaultMessage: 'Information',
  },
  // evaluation
  evaluateLabInfo: {
    id: 'LabEvaluation.evaluateLabInfo',
    defaultMessage:
      'Check your work as often as you want. Submit it when you’re done.',
  },
  evaluateLabInfoNoSubmission: {
    id: 'LabEvaluation.evaluateLabInfoNoSubmission',
    defaultMessage: 'Check your work as often as you want.',
  },
  evaluateLabButton: {
    id: 'LabEvaluation.evaluateLabButton',
    defaultMessage: 'Check Work',
  },
  evaluateScoreHeading: {
    id: 'LabEvaluation.evaluateScoreHeading',
    defaultMessage: 'Result',
  },
  submitScoreButton: {
    id: 'LabEvaluation.submitScoreButton',
    defaultMessage: 'Submit Result',
  },
  submitScoreSuccessfull: {
    id: 'LabEvaluation.submitScoreSuccessfull',
    defaultMessage: 'Submitted successfully',
  },
  submitScoreFailedMessage: {
    id: 'LabEvaluation.submitScoreFailedMessage',
    defaultMessage: 'We were unable to submit this result. Please try again.',
  },
  supportInfoMessage: {
    id: 'LabEvaluation.supportInfoMessage',
    defaultMessage:
      'If the problem persists, please provide the following information when reaching out to support.',
  },
  submitScorePopoverInfo: {
    id: 'LabEvaluation.submitScorePopoverInfo',
    defaultMessage: 'When you submit, we send this result to your class.',
  },
  evaluateResultsDetailsHeader: {
    id: 'LabEvaluation.evaluateResultsDetailsHeader',
    defaultMessage: 'Details',
  },
  lastEvaluationTimestamp: {
    id: 'LabEvaluation.lastEvaluationTimestamp',
    defaultMessage: 'Checked {timestamp}',
  },
  // splitPanel
  splitPanelHeader: {
    id: 'LabEvaluation.splitPanelHeader',
    defaultMessage: 'Lab validation',
  },
  preferencesTitle: {
    id: 'LabEvaluation.preferencesTitle',
    defaultMessage: 'Lab validation panel preferences',
  },
  preferencesPositionLabel: {
    id: 'LabEvaluation.preferencesPositionLabel',
    defaultMessage: 'Lab validation panel position',
  },
  preferencesPositionDescription: {
    id: 'LabEvaluation.preferencesPositionDescription',
    defaultMessage:
      'Choose where the lab validation panel will shown in the lab UI.',
  },
  preferencesPositionSide: {
    id: 'LabEvaluation.preferencesPositionSide',
    defaultMessage: 'Side',
  },
  preferencesPositionBottom: {
    id: 'LabEvaluation.preferencesPositionBottom',
    defaultMessage: 'Bottom',
  },
  preferencesConfirm: {
    id: 'LabEvaluation.preferencesConfirm',
    defaultMessage: 'Confirm',
  },
  preferencesCancel: {
    id: 'LabEvaluation.preferencesCancel',
    defaultMessage: 'Cancel',
  },
  closeButtonAriaLabel: {
    id: 'LabEvaluation.closeButtonAriaLabel',
    defaultMessage: 'Close lab validation panel',
  },
  openButtonAriaLabel: {
    id: 'LabEvaluation.openButtonAriaLabel',
    defaultMessage: 'Open lab validation panel',
  },
  resizeHandleAriaLabel: {
    id: 'LabEvaluation.resizeHandleAriaLabel',
    defaultMessage: 'Resize lab validation panel',
  },
  noCheckedScoreEndLabModalHeader: {
    id: 'LabEvaluation.noCheckedScoreEndLabModalHeader',
    defaultMessage: 'End lab without a result?',
  },
  noCheckedScoreEndLabModalContent: {
    id: 'LabEvaluation.noCheckedScoreEndLabModalContent',
    defaultMessage:
      "You haven't submitted a result yet. To submit a result, click cancel to return to the lab. Check your work to receive a result. If you're satisfied with the result submit it before ending the lab.",
  },
  noCheckedScoreEndLabModalButton: {
    id: 'LabEvaluation.noCheckedScoreEndLabModalButton',
    defaultMessage: 'End Lab without Result',
  },
  noSubmittedScoreEndLabModalHeader: {
    id: 'LabEvaluation.noSubmittedScoreEndLabModalHeader',
    defaultMessage: 'End lab without a result?',
  },
  noSubmittedScoreEndLabModalContent: {
    id: 'LabEvaluation.noSubmittedScoreEndLabModalContent',
    defaultMessage:
      "You haven't submitted a result yet. To submit a result, click cancel to return to the lab and submit your result. If you've attempted any tasks since you last checked your work, check your work again before you submit.",
  },
  noSubmittedScoreEndLabModalButton: {
    id: 'LabEvaluation.noSubmittedScoreEndLabModalButton',
    defaultMessage: 'End Lab without Result',
  },
  submittedScoreEndLabModalHeader: {
    id: 'LabEvaluation.submittedScoreEndLabModalHeader',
    defaultMessage: 'Confirm end lab',
  },
  submittedScoreEndLabModalContent: {
    id: 'LabEvaluation.submittedScoreEndLabModalContent',
    defaultMessage:
      "When you end lab, this environment will be reset and you won't have access to your prior work. If you've attempted any tasks since you last submitted your result, select the cancel button to return to the lab. Check your work and submit the result again before you end lab.",
  },
  submittedScoreEndLabModalButton: {
    id: 'LabEvaluation.submittedScoreEndLabModalButton',
    defaultMessage: 'End Lab',
  },
});

export default labEvaluationMessages;

import { path, prop } from 'ramda';

import metrics from './metrics';
import logger from './logger';

const capturePerformanceObserverMetrics = publishMetric => {
  // The First Contentful Paint (FCP) metric measures the time from when the
  // page starts loading to when any part of the page's content is rendered on
  // the screen.
  // https://web.dev/fcp/
  const handleFcpEvent = (list, observer) => {
    for (const entry of list.getEntriesByName('first-contentful-paint')) {
      publishMetric('FirstContentfulPaint', entry.startTime);
      observer.disconnect();
    }
  };

  // First Input Delay (FID) measures the time from when a user first interacts
  // with a page (i.e. when they click a link, tap on a button, or use a
  // custom, JavaScript-powered control) to the time when the browser is
  // actually able to respond to that interaction.
  // https://web.dev/fid/
  const handleFidEvent = (list, observer) => {
    for (const entry of list.getEntries()) {
      const firstInputDelay = entry.processingStart - entry.startTime;
      publishMetric('FirstInputDelay', firstInputDelay);
      observer.disconnect();
    }
  };

  try {
    const fcpObserver = new PerformanceObserver(handleFcpEvent);
    fcpObserver.observe({
      type: 'paint',
      buffered: true,
    });

    const fidObserver = new PerformanceObserver(handleFidEvent);
    fidObserver.observe({
      type: 'first-input',
      buffered: true,
    });
  } catch (e) {
    // Do nothing if the browser doesn't support the API.
    logger.debug('Not possible to set up PerformanceObserver', e);
  }
};

/**
 * Time To First Byte, how long it takes for the server to send the first byte.
 * @param metrics PerformanceNavigationTiming
 */
const getTTFB = metrics => metrics.responseStart - metrics.requestStart;

/**
 * Time it takes to lookup DNS.
 * @param metrics PerformanceNavigationTiming
 */
const getDNS = metrics => metrics.domainLookupEnd - metrics.domainLookupStart;

/**
 * The time from when the user agent starts to fetch the resource until the
 * user agent receives the last byte of the response.
 * @param metrics PerformanceNavigationTiming
 */
const getLatency = metrics => metrics.responseEnd - metrics.fetchStart;

/**
 * Calculates the time taken for the user interface (UI) to process the data,
 * download resources, execute scripts, render DOMs, apply stylesheets, etc
 * when loading the page.
 * @param metrics PerformanceNavigationTiming
 */
const getUiTime = metrics => metrics.loadEventStart - metrics.responseStart;

const captureOnLoadMetrics = publishMetric => {
  const metrics = getTimingMetrics();

  // Early exit if timing metrics are unavailable.
  if (!metrics) return;

  publishMetric('DomInteractive', metrics.domInteractive);
  publishMetric(
    'DomContentLoadedEventStart',
    metrics.domContentLoadedEventStart
  );
  publishMetric('DomComplete', metrics.domComplete);
  publishMetric('LoadEventStart', metrics.loadEventStart);
  publishMetric('FetchStart', metrics.fetchStart);
  publishMetric('TTFB', getTTFB(metrics));
  publishMetric('DNS', getDNS(metrics));
  publishMetric('Latency', getLatency(metrics));
  publishMetric('UiTime', getUiTime(metrics));
};

const getTimingMetrics = () => {
  if (!path(['performance', 'getEntriesByType'], window)) {
    // Do nothing if the browser doesn't support this API.
    logger.debug('window.performance.getEntriesByType is unavailable.');
    return;
  }
  const entries = window.performance.getEntriesByType('navigation');
  if (!entries || entries.length === 0) return;
  return entries[0];
};

const hasLoadEventFired = () => {
  const metrics = getTimingMetrics();
  return Boolean(prop('loadEventEnd', metrics));
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Resource_Timing_API/Using_the_Resource_Timing_API
 */
export const captureTimingMetrics = () => {
  const publisher = metrics.createPublisher('Performance');
  const publishMetric = (name, value) => {
    if (value >= 0) publisher.publishTimer(name, value);
    else logger.debug(`Ignoring invalid metric, name:${name} value:${value}`);
  };

  capturePerformanceObserverMetrics(publishMetric);

  if (hasLoadEventFired()) {
    captureOnLoadMetrics(publishMetric);
  } else {
    window.addEventListener('load', () => {
      // Defer to after the load event has finished.
      setTimeout(() => captureOnLoadMetrics(publishMetric));
    });
  }
};

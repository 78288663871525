import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'recommendationsPage.title',
    defaultMessage: 'Home',
  },
  recommendationsHeader: {
    id: 'recommendationsPage.recommendationsHeader',
    defaultMessage: 'Recommendations',
  },
  catalogBtn: {
    id: 'recommendationsPage.catalogBtn',
    defaultMessage: 'View Entire Catalog',
  },
});

export default messages;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BORKMarkdown } from '@amzn/bork-markdown';

import useMarkdownStyleOverrides from '../hooks/useMarkdownStyleOverrides';
import { MD_CONTENT_CLASSNAME } from '../../../constants/lab';

import '@amzn/bork-markdown/dist/index.css';

const MarkdownRenderer = ({
  setToc = () => null,
  markdown,
  locale = 'en-US',
  padding = true,
  theme = 'light',
}) => {
  const cleanedMarkdown = useMarkdownStyleOverrides(markdown);

  return (
    <div className={MD_CONTENT_CLASSNAME} style={{ overflowX: 'auto' }}>
      <BORKMarkdown
        markdown={cleanedMarkdown}
        locale={locale}
        onTocUpdate={setToc}
        padding={padding}
        theme={theme}
      />
    </div>
  );
};

MarkdownRenderer.propTypes = {
  markdown: PropTypes.string,
  locale: PropTypes.string,
  setToc: PropTypes.func,
  padding: PropTypes.bool,
  theme: PropTypes.string,
};

// Prevent the component from re-rendering if the props have not changed.
export default memo(MarkdownRenderer);

import React, { useEffect } from 'react';
import { Container, Header, Link } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import SEO from '../components/SEO';
import Main from '../components/Main';
import { publishCounterMetric } from '../utils/metrics';
import messages from './ClosedEventPage.messages';
import appMessages from '../i18n/app.messages';

const ClosedEventPage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    publishCounterMetric('ClosedEvent');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main>
      <SEO title={formatMessage(messages.primaryMessage)} />
      <Container
        header={
          <Header variant="h1">{formatMessage(messages.primaryMessage)}</Header>
        }
      >
        {formatMessage(messages.secondaryMessage, {
          a: chunk => (
            <Link
              href="https://aws.amazon.com/events/"
              external
              externalIconAriaLabel={formatMessage(
                appMessages.externalIconAriaLabel
              )}
            >
              {chunk}
            </Link>
          ),
        })}
      </Container>
    </Main>
  );
};

export default ClosedEventPage;

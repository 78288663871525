import React from 'react';
import { FormattedRelativeTime } from 'react-intl';

const isValidDate = date => {
  return date instanceof Date && isFinite(date.getTime());
};

/**
 * @example
 * <TimestampAgo timestamp={'2022-12-15T14:31:56Z'} />
 *
 * @param {Object} props
 * @param {String} props.timestamp Expected format:  yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
 * @returns {String|null} Returns null if invalid date string
 */
const TimestampAgo = ({ timestamp }) => {
  if (!timestamp) return null;

  const previousDate = new Date(timestamp);
  if (!isValidDate(previousDate)) return null;

  const previous = previousDate.getTime();
  const now = new Date().getTime();

  return (
    // Will automatically use current locale from IntlProvider
    <span role="timer">
      <FormattedRelativeTime
        value={(previous - now) / 1000}
        unit="second"
        updateIntervalInSeconds={1}
      />
    </span>
  );
};

export default TimestampAgo;

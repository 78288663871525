import FEATURES from '../../constants/features';

const hasChatBotEnabled = event => event.hasFeature(FEATURES.blueprintAIWidget);

export const showChatBotPromoBanner = ({ eventContext, blueprintName }) => {
  if (!showChatBotOnLabPage({ eventContext, blueprintName })) {
    return false;
  }

  const { showPromoBanner } = eventContext.getMetadata(
    FEATURES.blueprintAIWidget
  );
  return showPromoBanner;
};

export const showChatBotOnLabPage = ({ eventContext, blueprintName }) => {
  // check if feature is enabled for event
  if (!hasChatBotEnabled(eventContext)) return false;

  // check if allowlist metadata has blueprint entries
  const { allowlist } = eventContext.getMetadata(FEATURES.blueprintAIWidget);
  const allowListArr = !allowlist ? [] : allowlist.split(',');

  // if allowlist is empty, show chatbot on all lab pages
  if (allowListArr.length === 0) return true;

  // check if allowlist contains blueprint name
  return allowListArr
    .map(item => item.toLowerCase())
    .includes(blueprintName.toLowerCase());
};

import { prop, path, has, propOr } from 'ramda';
import logger from '../utils/logger';

function stringifyError(error) {
  try {
    return JSON.stringify(error);
  } catch (_) {
    return `(WrappedError failed stringify)  ${error.toString()}`;
  }
}

/**
 * Creates a opinionated error with a specific error message. The given error
 * is added to the `error.cause` if possible.
 *
 * @param {string} context String without spaces
 * @param {any} errorMaybe
 * @returns {Error}
 */
export function createWrappedError(context, errorMaybe) {
  let message;
  let cause;

  // If the error is a normal error, just update the message with context and return as is
  if (errorMaybe instanceof Error) {
    errorMaybe.message = `${context} - ${errorMaybe.message}`;
    return errorMaybe;
  }

  if (errorMaybe instanceof ErrorEvent) {
    message = `${context} - ${prop('message', errorMaybe)} - ${path(
      ['error', 'message'],
      errorMaybe
    )}`;
    cause = errorMaybe;
  } else if (typeof errorMaybe === 'string') {
    message = `${context} - ${errorMaybe}`;
    cause = errorMaybe;
  } else if (has('errors', errorMaybe)) {
    // GraphQL error responses contains a list of errors. We only care about the first.
    const errorObj = propOr('unknown', 0, prop('errors', errorMaybe));
    const errorType = propOr('', 'errorType', errorObj);
    message = `${context}:${errorType} - ${stringifyError(errorObj)}`;
    cause = errorMaybe;
  } else {
    message = `${context} - ${stringifyError(errorMaybe)}`;
  }

  try {
    return new WrappedError(message, { cause });
  } catch (err) {
    logger.debug('Unable to add cause to WrappedError', err);
    return new WrappedError(message);
  }
}

class WrappedError extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'WrappedError';
    this.date = new Date();

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WrappedError);
    }
  }
}

export default WrappedError;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  slideCarouselLabel: {
    id: 'carousel.slideCarouselLabel',
    defaultMessage: 'Slide carousel {direction}',
  },
  loading: {
    id: 'carousel.loading',
    defaultMessage: 'Loading lab information ...',
  },
  left: {
    id: 'carousel.left',
    defaultMessage: 'left',
  },
  right: {
    id: 'carousel.right',
    defaultMessage: 'right',
  },
  labTags: {
    id: 'carousel.labTags',
    defaultMessage: 'Lab tags',
  },
});

export default messages;

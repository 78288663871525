import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'catalog.title',
    defaultMessage: 'Catalog',
  },
  reload: {
    id: 'catalog.reload',
    defaultMessage: 'Reload page',
  },
  labHeader: {
    id: 'catalog.labHeader',
    defaultMessage: 'Lab',
  },
  emptyViewTitle: {
    id: 'catalog.emptyViewTitle',
    defaultMessage: 'No Labs',
  },
  emptyViewMessage: {
    id: 'catalog.emptyViewMessage',
    defaultMessage: 'No labs to display.',
  },
  nextPageLabel: {
    id: 'catalog.nextPageLabel',
    defaultMessage: 'Next',
  },
  previousPageLabel: {
    id: 'catalog.previousPageLabel',
    defaultMessage: 'Previous',
  },
  pageLabel: {
    id: 'catalog.pageLabel',
    defaultMessage: 'Page {number}',
  },
  filteringPlaceholder: {
    id: 'catalog.filteringPlaceholder',
    defaultMessage: 'Search catalog',
  },
  filteringLabel: {
    id: 'catalog.filteringLabel',
    defaultMessage: 'Search catalog',
  },
  filteringClearAriaLabel: {
    id: 'catalog.filteringClearAriaLabel',
    defaultMessage: 'Clear field',
  },
  filteringCountSingular: {
    id: 'catalog.filteringCountSingular',
    defaultMessage: 'match',
  },
  filteringCountPlural: {
    id: 'catalog.filteringCountPlural',
    defaultMessage: 'matches',
  },
  filteringNoMatchesLineOne: {
    id: 'catalog.filteringNoMatchesLineOne',
    defaultMessage: "We can't find a match",
  },
  filteringNoMatchesLineTwo: {
    id: 'catalog.filteringNoMatchesLineTwo',
    defaultMessage: 'Change your search and try again',
  },
  headerCountSeparator: {
    id: 'catalog.headerCountSeparator',
    defaultMessage: 'of',
  },
});

export default messages;

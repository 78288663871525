import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Container,
  Box,
  SpaceBetween,
  Button,
  Link,
} from '@amzn/awsui-components-react';

import appMessages from '../../i18n/app.messages';
import EasyMarkdown from '../EasyMarkdown';
import jamGangImage from '../../images/callouts/aws-jam-gang.png';
import awsJamImage from '../../images/callouts/aws-jam.png';
import builderLabsImage from '../../images/callouts/aws-builder-labs.png';
import simulearnImage from '../../images/callouts/aws-simulearn.png';
import { publishCounterMetric } from '../../utils/metrics';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { CALLOUT_MEDIA, CALLOUT_TYPES } from './constants';
import { calloutTypes } from '../../models/EHCustomCallouts';

/**
 * Shorthand media sources which are available if needed by providing a CALLOUT_MEDIA key.
 * Workaround until we have proper and convenient asset management.
 */
const existingMediaSources = {
  [CALLOUT_MEDIA.awsJamGang]: jamGangImage,
  [CALLOUT_MEDIA.awsJamImage]: awsJamImage,
  [CALLOUT_MEDIA.builderLabsImage]: builderLabsImage,
  [CALLOUT_MEDIA.simulearnImage]: simulearnImage,
};

/**
 * Prepares a media object used in the Cloudscape container component.
 * Returns a empty object if the required media attributes are not provided.
 */
const getMedia = ({ mediaSrc, mediaAlt, mediaPosition, mediaSize }) => {
  if (!mediaSrc || !mediaPosition || !mediaSize) return {};
  return {
    content: (
      <img
        src={existingMediaSources[mediaSrc] || mediaSrc}
        alt={mediaAlt || ''}
      />
    ),
    position: mediaPosition,
    width: mediaSize,
    height: mediaSize,
  };
};

/**
 * Renders a Cloudscape container component.
 * Supports:
 * - Title and BORK markdown content
 * - (Optional) Media shown to the side or on top of the container
 * - (Optional) CTA button link
 */
const CalloutContainer = ({ params }) => {
  const { formatMessage } = useIntl();
  useEmitCalloutImpression(CALLOUT_TYPES.container);
  const {
    title,
    content,
    mediaSrc,
    mediaAlt,
    mediaPosition,
    mediaSize,
    ctaTitle,
    ctaHref,
    jumpToLink,
  } = params.container || {};

  // Do not render if there's no title or content
  if (!content && !title) return null;

  const Title = () => {
    if (!title) return null;

    return (
      <SpaceBetween direction="vertical" size="xxs">
        {jumpToLink ? (
          <Box variant="h3" fontWeight="normal">
            <Link href={ctaHref} variant="primary" fontSize="inherit">
              {title}
            </Link>
          </Box>
        ) : (
          <Box variant="h3">{title}</Box>
        )}
      </SpaceBetween>
    );
  };

  const media = getMedia({ mediaSrc, mediaAlt, mediaPosition, mediaSize });
  return (
    <Container
      media={media}
      data-testid={`callout-${CALLOUT_TYPES.container}`}
      fitHeight
    >
      <SpaceBetween direction="vertical" size="s">
        <Title />
        {content ? (
          <div style={{ maxWidth: '900px' }}>
            <EasyMarkdown content={content} />
          </div>
        ) : null}
        {ctaTitle && ctaHref && !jumpToLink ? (
          <Button
            href={ctaHref}
            iconName="external"
            iconAlign="right"
            external
            target="_blank"
            data-testid={dataTestId['calloutContainer-cta']}
            ariaLabel={formatMessage(appMessages.externalIconAriaLabel)}
            onClick={() => {
              publishCounterMetric('CalloutContainer', {
                counterName: 'CtaClick',
                additionalMetrics: {
                  href: ctaHref,
                  title: ctaTitle,
                },
              });
            }}
          >
            {ctaTitle}
          </Button>
        ) : null}
      </SpaceBetween>
    </Container>
  );
};

CalloutContainer.propTypes = {
  params: PropTypes.shape({
    container: PropTypes.instanceOf(calloutTypes.Container).isRequired,
  }),
};

export default CalloutContainer;

import React from 'react';
import { Box, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { Mode } from '@amzn/awsui-global-styles';

import { useLocaleContext, useThemeContext } from '../../../contexts';
import { getLocaleOrAvailable } from '../../../utils/localeUtils';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import MarkdownRenderer from '../../../components/MarkdownRenderer';

const ContentPlaceholders = () => (
  <Box margin={{ top: 'xxs' }}>
    <SpaceBetween size="s">
      <LoadingSkeleton width={40} height={22} />
      <LoadingSkeleton width={90} height={20} />
      <LoadingSkeleton width={90} height={20} />
      <LoadingSkeleton width={90} height={20} />
      <LoadingSkeleton width={90} height={20} />
      <LoadingSkeleton width={70} height={20} />
    </SpaceBetween>
  </Box>
);

/**
 * Renders the evalation result markdown
 */
const EvaluationContent = ({ locales, isLoading }) => {
  const [locale] = useLocaleContext();
  const [theme] = useThemeContext();

  // Show previous results while reloading if it's available
  if (isLoading) return <ContentPlaceholders />;
  if (!locales?.length > 0) return null;

  const { content } = getLocaleOrAvailable(locale, { locales });

  return (
    <TextContent>
      {/* BORKMarkdown has a bug when rendering react components, using our wrapper in the meantime, https://sim.amazon.com/issues/EventHorizon-2979 */}
      <MarkdownRenderer
        locale={locale}
        markdown={content}
        padding={false}
        theme={theme === Mode.Dark ? 'dark' : 'light'}
      />
    </TextContent>
  );
};

export default EvaluationContent;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'defaultErrorPage.heading',
    defaultMessage: 'Uh oh, something went wrong',
  },
  message: {
    id: 'defaultErrorPage.message',
    defaultMessage: 'Please reload the page and try again.',
  },
  reload: {
    id: 'defaultErrorPage.reload',
    defaultMessage: 'Reload',
  },
});

export default messages;

import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { initialize } from '../utils/initializeUtils';
import {
  interceptRequestMetrics,
  publishCounterMetric,
} from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import { useNotificationContext, notificationTypes } from '../contexts';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { dataTestId } from '../constants/dataTestIdSelectors';

import logger from '../utils/logger';
import messages from './useInitialize.messages';
import ERROR_MESSAGES from '../i18n/errors.messages';
import { useIntl } from 'react-intl';
const metricsNamespace = 'Initialize';

const useInitialize = ({ isAuthenticated, enableSharedAuthZ }) => {
  let retryCount = 0;
  const { formatMessage } = useIntl();
  const { appendNotification } = useNotificationContext();

  const mutation = useMutation({
    mutationFn: () => interceptRequestMetrics(metricsNamespace, initialize()),
    retry: (failureCount, error) => {
      retryCount = failureCount;
      if (isNonRetryableError(error)) return false;
      return failureCount < 2;
    },
    retryDelay: failureCount =>
      calculateRetryDelay(failureCount, { interval: 200 }),
    onMutate: () => {
      retryCount = 0;
    },
    onSuccess: () => {
      publishCounterMetric(metricsNamespace, {
        counterName: 'InitializationSuccess',
        counterValue: 1,
        additionalMetrics: {
          retryCount,
        },
      });
    },
    onError: error => {
      logger.error('Error in initialize', error);
      publishCounterMetric(metricsNamespace, {
        counterName: 'InitializationFailure',
        counterValue: 1,
        additionalMetrics: {
          retryCount,
        },
      });
      if (retryCount >= 2) {
        appendNotification({
          contentText: (
            <SpaceBetween size="s">
              <span>{formatMessage(ERROR_MESSAGES.initializationFailure)}</span>
              <Button
                data-testid={dataTestId['sharedAuthZ-failReloadBtn']}
                onClick={() => window.location.reload()}
              >
                {formatMessage(messages.reload)}
              </Button>
            </SpaceBetween>
          ),
          type: 'info',
          dismissible: false,
          notificationType: notificationTypes.GLOBAL,
        });
      }
    },
  });

  useEffect(() => {
    if (isAuthenticated && enableSharedAuthZ) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, enableSharedAuthZ]);

  return mutation;
};

export default useInitialize;

import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  HELP_BTN_SELECTOR,
  TAGS,
  CLASSROOMS_EVENT_NAME,
  CLASSROOMS_GAMMA_EVENT_NAME,
} from '../constants/helpPanel';
import {
  troubleshooting,
  faq,
  troubleshootingClassrooms,
  faqClassrooms,
} from '../components/HelpPanel';
import { isDevelopment } from '../utils/envUtil';
import { useConfigContext } from '../contexts/ConfigContextProvider';

export const HelpPanelContext = createContext();
export const useHelpPanelContext = () => useContext(HelpPanelContext);

const headerRegex = /(.*)Header$/;
const contentRegex = /(.*)Content$/;

const isValidFaqPair = (headerId, contentId) => {
  try {
    if (!headerId || !contentId)
      throw new Error(`Missing header or content, ${headerId} ${contentId}`);

    const [, headerPrefix] = headerId.match(headerRegex);
    const [, contentPrefix] = contentId.match(contentRegex);
    if (headerPrefix !== contentPrefix)
      throw new Error(`FAQ ID mismatch, ${headerId} ${contentId}`);

    return true;
  } catch (error) {
    // Hey developer. If block throws, verify the FAQ pairs exist in the
    // X.messages.js file and that they are named as expected, see `headerRegex`
    // and `contentRegex`.

    // Re-throwing this error in local dev to not silently hide the content issue
    // when content is being updated.
    if (isDevelopment) throw error;
  }
  return false;
};

/**
 * Parses a given react-intl messages object and combines the entries into a
 * list of FAQ items.
 *
 * Obs:
 * This function expects the messages content to be entered as pairs where
 * the FAQ items are required to start with the same prefix, `{prefix}Header`
 * and `{prefix}Content`. See an example below.
 *
 * @example
 * messagesObject = {
 *   ...
 *   troubleshooting10Header: {
 *     id: 'helpPanelContent.troubleshooting10Header',
 *     defaultMessage: 'How do I add help panel items?',
 *   },
 *   troubleshooting10Content: {
 *     id: 'helpPanelContent.troubleshooting10Content',
 *     defaultMessage: 'You add them as pairs.',
 *   },
 *   ...
 * }
 *
 * @todo This will get refactored when the help panel content is fetched async. https://sim.amazon.com/issues/EventHorizon-1287
 *
 * @param {Object} messagesObject A react-intl messages object.
 * @param {String} tag
 */
const generateQuestionListFromIntlMessages = (messagesObject, tag) => {
  const contentKeys = Object.keys(messagesObject);
  let questionsList = [];

  for (
    let messagesIndex = 0;
    messagesIndex < contentKeys.length;
    messagesIndex = messagesIndex + 2 // Advance to each FAQ pair
  ) {
    const headerId = contentKeys[messagesIndex];
    const contentId = contentKeys[messagesIndex + 1];
    if (!isValidFaqPair(headerId, contentId)) continue;

    questionsList.push({
      tag,
      headerId,
      headerMessage: messagesObject[headerId],
      contentId,
      contentMessage: messagesObject[contentId],
      expanded: false,
    });
  }
  return questionsList;
};

const HelpPanelContextProvider = ({ children }) => {
  const [showHelpPanel, setShowHelpPanel] = useState(false);
  const config = useConfigContext();
  const isClassroomsEvent =
    config?.eventName === CLASSROOMS_EVENT_NAME ||
    config?.eventName === CLASSROOMS_GAMMA_EVENT_NAME;

  const troubleshootingContents = isClassroomsEvent
    ? troubleshootingClassrooms
    : troubleshooting;
  const faqContents = isClassroomsEvent ? faqClassrooms : faq;

  const [questions, setQuestions] = useState({
    troubleshooting: generateQuestionListFromIntlMessages(
      troubleshootingContents,
      TAGS.troubleshooting
    ),
    faq: generateQuestionListFromIntlMessages(faqContents, TAGS.faq),
  });

  const handlePanelClose = useCallback(() => {
    setShowHelpPanel(false);
    document.querySelector(HELP_BTN_SELECTOR).focus();
  }, [setShowHelpPanel]);

  return (
    <HelpPanelContext.Provider
      value={{
        questions,
        setQuestions,
        showHelpPanel,
        setShowHelpPanel,
        handlePanelClose,
      }}
    >
      {children}
    </HelpPanelContext.Provider>
  );
};
HelpPanelContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HelpPanelContextProvider;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  help: {
    id: 'helpButton.help',
    defaultMessage: 'Help',
  },
});

export default messages;

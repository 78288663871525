import { useState, useEffect } from 'react';
import { polarisButtonStyle } from '../utils/polarisButtonStyle';
import { matchPatternType } from '../utils/matchPatternType';

/**
 * Removes the T&C logo at the top of the provided markdown.
 * Replaces inline styles from lab service markdown with polaris button styles.
 * @param {String} markdown string of markdown to be updated with style overrides
 * @returns {String} cleanedMarkdown
 */
const useCleanMarkdown = markdown => {
  const [cleanedMarkdown, setCleanedMarkdown] = useState(null);

  useEffect(() => {
    if (markdown) {
      const cleanedMD = markdown
        .replace(/^.*aws-logo.png.*$/gm, '')
        .replace(
          new RegExp(matchPatternType('startLab'), 'gim'),
          polarisButtonStyle('#000716', '#f90', '#f90')
        )
        .replace(
          new RegExp(matchPatternType('openConsole'), 'gim'),
          polarisButtonStyle('#000716', '#f90', '#f90')
        )
        .replace(
          new RegExp(matchPatternType('endLab'), 'gim'),
          polarisButtonStyle('#0972d3', '#fff', '#0972d3')
        );

      setCleanedMarkdown(cleanedMD);
    }
  }, [markdown]);

  return cleanedMarkdown;
};

export default useCleanMarkdown;

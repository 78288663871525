import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Header,
  Popover,
  Spinner,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import LiveSupportButton from './LiveSupportButton';
import {
  currentlyInOpenHours,
  getAvailableAgents,
  hasChatHours,
  initChatConnection,
} from './utils/connectUtils';
import metrics from '../../utils/metrics';
import {
  useAuthContext,
  useHelpPanelContext,
  useEventContext,
  useConnectChatContext,
} from '../../contexts';
import FEATURES from '../../constants/features';
import { CHATBOX_ID, SUPPORT } from '../../constants/support';
import appLayoutMessages from '../../i18n/appLayout.messages';
import messages from './LiveSupport.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const ConnectChat = ({ contentOnly = false }) => {
  const connectPublisher = useRef(metrics.createPublisher('Connect'));

  const { formatMessage } = useIntl();
  const [{ currentUser }] = useAuthContext();
  const { event } = useEventContext();
  const chatMetadata = event.getMetadata(FEATURES.connectChat);
  const constants = SUPPORT.awsConnectLiveChat;
  const { setShowHelpPanel } = useHelpPanelContext();
  const [chatIsAvailable, setChatIsAvailable] = useState(false);
  const {
    chatState,
    startChat,
    dispatchChatFailed,
    dispatchChatSucceeded,
    toggleChatLoading,
    toggleChatWindow,
    setChatSession,
  } = useConnectChatContext();

  const initConnection = () => {
    startChat();
    let options = {
      displayName: currentUser,
      userAttributes: '',
      region: constants.connectRegion,
      contactFlowId: '',
      instanceId: '',
    };
    initChatConnection({
      id: CHATBOX_ID,
      options,
      setChatSession,
      successHandler: () => {
        dispatchChatSucceeded();
        connectPublisher.current.publishCounter(constants.metricNameSuccess, 1);
      },
      failureHandler: () => {
        dispatchChatFailed();
        connectPublisher.current.publishCounter(constants.metricNameFailure, 1);
      },
    });
  };

  const handleChatButton = async (withInit = true) => {
    if (!chatState.chatSessionActive) {
      toggleChatLoading();
      const numAgents = await getAvailableAgents();
      if (
        numAgents > 0 ||
        (hasChatHours(chatMetadata) && currentlyInOpenHours(chatMetadata))
      ) {
        setChatIsAvailable(true);
        if (withInit) initConnection();
      } else {
        toggleChatLoading();
        connectPublisher.current.publishCounter(
          constants.metricNameNoAgents,
          1
        );
      }
    } else {
      !contentOnly && toggleChatWindow();
    }
  };

  const chatHeader = (
    <Header
      variant="h3"
      headingTagOverride="h2"
      data-testid={dataTestId['liveSupport-chatHeader']}
    >
      {formatMessage(messages.chatHeader)}
    </Header>
  );

  const popoverContent = (
    <>
      {chatHeader}

      {chatState.chatLoading ? (
        <Box variant="p" color="text-body-secondary">
          <Spinner />
          {formatMessage(messages.chatLoading)}
        </Box>
      ) : (
        formatMessage(messages.chatUnavailable)
      )}

      {!chatState.chatLoading && (
        <Box variant="p">
          <button
            className="awsui-button-link"
            onClick={() => {
              setShowHelpPanel(true);
              // Must first close live support popover
              // before focusing on help panel
              document
                .querySelector(
                  `[aria-label="${formatMessage(messages.closeLabel)}"]`
                )
                ?.click();
              setTimeout(() =>
                document
                  .querySelector(
                    `[aria-label="${formatMessage(
                      appLayoutMessages.toolsClose
                    )}"]`
                  )
                  ?.focus()
              );
            }}
          >
            {formatMessage(messages.helpLink)}
          </button>
        </Box>
      )}
    </>
  );

  const chatButton = (
    <LiveSupportButton
      disabled={chatState.chatButtonDisabled}
      ariaLabel={
        chatState.chatSessionActive ? formatMessage(messages.toggleLabel) : null
      }
      onClick={handleChatButton}
    />
  );

  useEffect(() => {
    if (contentOnly) handleChatButton(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (contentOnly) {
    return chatIsAvailable ? (
      <>
        {chatHeader}
        <Box variant="p">{formatMessage(messages.chatAvailable)}</Box>
        <Box margin={{ top: 's', bottom: 's' }}>
          <Button
            disabled={chatState.chatStarting}
            loading={chatState.chatStarting}
            onClick={initConnection}
          >
            {formatMessage(messages.startChatBtn)}
          </Button>
        </Box>
      </>
    ) : (
      popoverContent
    );
  }

  return chatState.chatSessionActive ? (
    chatButton
  ) : (
    <Popover
      position="top"
      triggerType="custom"
      size="medium"
      content={<Box color="text-body-secondary">{popoverContent}</Box>}
      dismissAriaLabel={formatMessage(messages.closeLabel)}
    >
      {chatButton}
    </Popover>
  );
};

export default ConnectChat;

import { useEffect, useRef } from 'react';
import { prop } from 'ramda';
import {
  useEventContext,
  useLocaleContext,
  useNotificationContext,
  notificationTypes,
} from '../contexts';
import { getLanguageCode, getLocaleOrAvailable } from '../utils/localeUtils';

/**
 * @param {Object} announcementData
 * @returns {Boolean} True if text and locale properties exists in announcement
 */
const hasAnnouncementProp = announcementData =>
  announcementData &&
  Boolean(prop('text', announcementData)) &&
  Boolean(prop('locale', announcementData));

const useEventAnnouncements = () => {
  const { event } = useEventContext();
  const [locale] = useLocaleContext();
  const { appendNotification } = useNotificationContext();
  const shownEventAnnouncements = useRef({});

  useEffect(() => {
    if (!event || !event.announcements) return;
    const hasSeenEventAnnouncement = announcement => {
      if (shownEventAnnouncements.current[announcement.text]) return true;
      shownEventAnnouncements.current = {
        ...shownEventAnnouncements.current,
        [announcement.text]: true,
      };
      return false;
    };

    event.announcements.forEach(a => {
      const announcement = getLocaleOrAvailable(locale, a);
      if (!hasAnnouncementProp(announcement)) return;
      if (hasSeenEventAnnouncement(announcement)) return;
      appendNotification({
        contentText: announcement.text,
        lang: getLanguageCode(announcement.locale),
        type: 'info',
        notificationType: notificationTypes.GLOBAL,
        dismissible: true,
      });
    });
  }, [appendNotification, event, locale]);
};

export default useEventAnnouncements;

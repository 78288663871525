import { getArnWithoutVersion } from './blueprintUtils';

/**
 * Adds labId to URL if blueprint arn matches current ongoing lab.
 * Assumes there can only be one ongoing lab at a time.
 *
 * @param {String} blueprintArn Blueprint arn for the catalog item
 * @param {String} locale Locale of the catalog item
 * @param {String} ongoingBlueprintArn Blueprint arn for ongoing lab
 * @param {String} ongoingLabId Lab id for ongoing lab
 * @returns {String} URI for catalog item
 */
export const getUriForCatalogItem = (
  blueprintArn,
  locale,
  ongoingBlueprintArn,
  ongoingLabId
) => {
  const defaultString = `/lab/${encodeURIComponent(blueprintArn)}/${locale}`;

  // no ongoing lab
  if (!ongoingBlueprintArn) return defaultString;

  // both arns include the same versions
  if (blueprintArn === ongoingBlueprintArn)
    return `/lab/${encodeURIComponent(blueprintArn)}/${locale}/${ongoingLabId}`;

  // blueprints match but not version (ex. spotlightArn does not have version id)
  // return ongoing lab with specified version id
  if (
    getArnWithoutVersion(blueprintArn) ===
    getArnWithoutVersion(ongoingBlueprintArn)
  )
    return `/lab/${encodeURIComponent(
      ongoingBlueprintArn
    )}/${locale}/${ongoingLabId}`;

  // default case
  return defaultString;
};

import React from 'react';

import logger from './logger';
import ErrorPage from '../pages/ErrorPage';
import metrics, { publishCounterMetric } from './metrics';
import ForbiddenAccess from '../errors/ForbiddenAccess';
import SignInError from '../errors/SignInError';
import { DOGS_OF_AMAZON_LINK } from '../constants/notFound';
import ForbiddenAccessErrorPage from '../pages/ForbiddenAccessErrorPage';
import SignInErrorPage from '../pages/SignInErrorPage';

export const catchForbiddenError = error => {
  if (error instanceof ForbiddenAccess) return handleForbiddenError(error);
  throw error;
};

export const catchSignInError = error => {
  if (error instanceof SignInError) return handleSignInError(error);
  throw error;
};

export const handleForbiddenError = error => {
  metrics.recordRumError('ForbiddenAccess', error);
  return (
    <ErrorPage
      isAuthenticated={false}
      isOutsideRouter={true}
      pageTitle="Unauthorized"
      customContent={<ForbiddenAccessErrorPage />}
    />
  );
};

export const handleSignInError = error => {
  metrics.recordRumError('SignInError', error);
  return (
    <ErrorPage
      isAuthenticated={false}
      isOutsideRouter={true}
      pageTitle="Sign in failed"
      customContent={<SignInErrorPage errorMessage={error.message} />}
    />
  );
};

export const catchDefaultError = err => {
  logger.error(err);
  metrics.recordRumError('InitAppError', err);
  publishCounterMetric('InitAppError', {
    additionalMetrics: {
      Message: err.toString(),
      UserAgent: navigator.userAgent,
    },
  });
  return <ErrorPage isAuthenticated={false} isOutsideRouter={true} />;
};

/**
 * Rendered when all else has failed. Cannot assume that the app will render
 * React components at this point so we fallback to updating the DOM manually.
 *
 * @param {Error} error
 * @param {element} element
 */
export const renderLastResortErrorPage = (error, element) => {
  element.innerHTML = `
    <div style="max-width: 80rem;margin: 2rem auto 0 auto; padding: 2rem;">
      <h1>Uh oh, something went wrong.</h1>
      <p>Please <a href="/">reload the page</a> and try again.</p>
      <a href="${DOGS_OF_AMAZON_LINK}" target="_blank" rel="noopener noreferrer">
        <img style="max-width: 100%" alt="Meet the dogs of Amazon." src="/waffles.jpg"/>
      </a>
    </div>`;
  document.body.appendChild(element);

  try {
    // Try publishing metrics
    metrics.recordRumError('AppLastResortError', error);
    publishCounterMetric('AppLastResortError', {
      additionalMetrics: {
        Message: error.toString(),
        UserAgent: navigator.userAgent,
      },
    });
  } catch (_) {
    // We tried
  }
};

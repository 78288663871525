import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'durationItem.label',
    defaultMessage: 'Duration:',
  },
  unknown: {
    id: 'durationItem.unknown',
    defaultMessage: 'Unknown',
  },
  hour: {
    id: 'durationItem.hour',
    defaultMessage: 'hour',
  },
  hours: {
    id: 'durationItem.hours',
    defaultMessage: 'hours',
  },
  minute: {
    id: 'durationItem.minute',
    defaultMessage: 'minute',
  },
  minutes: {
    id: 'durationItem.minutes',
    defaultMessage: 'minutes',
  },
});

export default messages;

import React from 'react';
import { useIntl } from 'react-intl';
import { prop } from 'ramda';

import { ButtonOverride } from '../PolarisOverrides';
import {
  useEventContext,
  useLocaleContext,
  useAuthContext,
} from '../../contexts';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import { publishButtonClickMetric } from '../../utils/metrics';
import DEFAULT_BUTTON_TEXT from './FooterEventLink.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import appMessages from '../../i18n/app.messages';

const FooterEventLink = () => {
  const { formatMessage } = useIntl();
  const [locale] = useLocaleContext();
  const [{ vibeId }] = useAuthContext();
  const {
    event: { eventLink },
  } = useEventContext();
  const eventLinkLocale = getLocaleOrAvailable(locale, eventLink);

  if (!prop('url', eventLink)) return null;

  const buttonText =
    prop('text', eventLinkLocale) || formatMessage(DEFAULT_BUTTON_TEXT);

  return (
    <ButtonOverride
      variant="transparent"
      data-testid={dataTestId['footer-eventLink']}
      href={eventLink.url}
      target="_blank"
      onClick={() =>
        publishButtonClickMetric('EventLink', {
          currentPath: window.location.pathname,
          destinationUrl: eventLink.url,
          eventLinkVideId: vibeId,
        })
      }
      ariaLabel={`${buttonText} ${formatMessage(
        appMessages.externalIconAriaLabel
      )}`}
    >
      {buttonText}
    </ButtonOverride>
  );
};

FooterEventLink.propTypes = {};

export default FooterEventLink;

import metrics, { publishCounterMetric } from './metrics';

/**
 * Checks if a browser meets our least common denominator in terms of browser features.
 * This check would preferably be done as a inline script to ensure all cases are covered,
 * but we lack that capability right now because of CSP limitations.
 *
 * @param {window} [windowContext] Window or window-like object.
 * @param {Object} [objectContext] Object or Object-like object.
 */
export const isBrowserSupported = (
  windowContext = window,
  objectContext = Object
) => {
  return (
    'localStorage' in windowContext &&
    'addEventListener' in windowContext &&
    'Promise' in windowContext &&
    'URLSearchParams' in windowContext &&
    'fetch' in windowContext &&
    'entries' in objectContext &&
    'ResizeObserver' in windowContext &&
    'matchMedia' in windowContext
  );
};

/**
 * Renders a fallback page for non-supported browsers. Not using React for rendering
 * as it might not be supported.
 *
 * @param {element} $root Element to render into.
 */
export const renderFallbackPage = async $root => {
  $root.innerHTML = `
      <div style="max-width: 80rem;margin: 2rem auto 0 auto; padding: 2rem;">
        <h1>Upgrade your browser for a better experience</h1>
        <p>It looks like you may be using a web browser that we don't support. Make sure that you 
        are using the latest version of your browser, or try using one of these supported browsers.</p>
        <ul>
          <li><a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">Google Chrome</a></li>
          <li><a href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener noreferrer" target="_blank">Firefox</a></li>
        </ul>
      </div>
    `;
  document.body.appendChild($root);
  document.title = 'Unsupported browser';

  // Attempt to send metrics
  try {
    await metrics.initMetrics();
    publishCounterMetric('NonSupportedBrowser', {
      additionalMetrics: {
        UserAgent: navigator.userAgent,
      },
    });
  } catch (_) {
    // We tried
  }
};

import React, { useState, useEffect } from 'react';
import { Flashbar } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { useNotificationContext } from '../contexts';
import messages from './AppNotifications.messages';
import { flashbarI18nStrings } from '../i18n/flashbarI18nStrings.messages';

const getFormattedText = (
  text,
  lang,
  messageId,
  formatMessage,
  values = {}
) => {
  if (text) {
    if (lang) {
      return <span lang={lang}>{text}</span>;
    }
    return text;
  }
  if (messageId) return formatMessage(messageId, values);
  return null;
};

const AppNotifications = () => {
  const { notifications, removeNotification } = useNotificationContext();
  const [items, setItems] = useState([]);
  const { formatMessage } = useIntl();

  const i18nStrings = {
    errorIconAriaLabel: formatMessage(flashbarI18nStrings.error),
    inProgressIconAriaLabel: formatMessage(flashbarI18nStrings.inProgress),
    infoIconAriaLabel: formatMessage(flashbarI18nStrings.info),
    successIconAriaLabel: formatMessage(flashbarI18nStrings.success),
    warningIconAriaLabel: formatMessage(flashbarI18nStrings.warning),
  };

  useEffect(
    () =>
      setItems(
        notifications.map(notification => ({
          header: getFormattedText(
            notification.headerText,
            notification.lang,
            notification.headerId,
            formatMessage,
            notification.messageValues
          ),
          content: getFormattedText(
            notification.contentText,
            notification.lang,
            notification.contentId,
            formatMessage,
            notification.messageValues
          ),
          type: notification.type,
          dismissible: !(notification.dismissible === false),
          onDismiss: () => removeNotification(notification.id),
          dismissLabel: formatMessage(messages.dismissLabel),
        }))
      ),
    [notifications, removeNotification, formatMessage]
  );

  return (
    <div aria-live="polite">
      {notifications ? (
        <Flashbar items={items} i18nStrings={i18nStrings}></Flashbar>
      ) : null}
    </div>
  );
};

AppNotifications.propTypes = {};

export default AppNotifications;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  liveSupportBtn: {
    id: 'liveSupport.liveSupportBtn',
    defaultMessage: 'Live support',
  },
  inPersonHeader: {
    id: 'liveSupport.inPersonHeader',
    defaultMessage: 'In person',
  },
  chatHeader: {
    id: 'liveSupport.chatHeader',
    defaultMessage: 'Chat',
  },
  closeLabel: {
    id: 'liveSupport.closeLabel',
    defaultMessage: 'Close live support',
  },
  startChatBtn: {
    id: 'liveSupport.startChatBtn',
    defaultMessage: 'Start chat',
  },
  toggleLabel: {
    id: 'liveSupport.toggleLabel',
    defaultMessage: 'Toggle live chat window',
  },
  chatUnavailable: {
    id: 'liveSupport.chatUnavailable',
    defaultMessage: 'Chat support is currently unavailable.',
  },
  chatAvailable: {
    id: 'liveSupport.chatAvailable',
    defaultMessage: 'Chat support is available.',
  },
  chatLoading: {
    id: 'liveSupport.chatLoading',
    defaultMessage: 'Checking for available agents',
  },
  helpLink: {
    id: 'liveSupport.helpLink',
    defaultMessage: 'Go to help content',
  },
});

export default messages;

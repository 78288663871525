import React, { forwardRef } from 'react';
import { Button } from '@amzn/awsui-components-react';
import './ButtonOverride.scss';

const ButtonOverride = ({ variant, active, style, ...props }, ref) => {
  if (variant === 'transparent')
    return (
      <span
        className={`ButtonOverride--transparent${
          active ? ' ButtonOverride--active' : '' // active prop only used for transparent variant
        }`}
      >
        <Button ref={ref} {...props} />
      </span>
    );

  if (variant === 'carousel')
    return (
      // style prop currently only used for carousel variant
      <span style={style} className="ButtonOverride--carousel">
        <Button ref={ref} {...props} />
      </span>
    );

  return <Button variant={variant} ref={ref} {...props} />;
};

export default forwardRef(ButtonOverride);

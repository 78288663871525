import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import useEmitCalloutImpression from './useCalloutImpressionCounter';
import { CALLOUT_TYPES } from './constants';
import { calloutTypes } from '../../models/EHCustomCallouts';

const CalloutAlert = ({ params }) => {
  const { header, content, type = 'info' } = params?.alert || {};
  useEmitCalloutImpression(CALLOUT_TYPES.alert);

  return (
    <Alert
      header={header}
      type={type}
      data-testid={`callout-${CALLOUT_TYPES.alert}`}
    >
      {content}
    </Alert>
  );
};

CalloutAlert.propTypes = {
  params: PropTypes.shape({
    alert: PropTypes.instanceOf(calloutTypes.Alert).isRequired,
  }),
};

export default CalloutAlert;

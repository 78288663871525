import { prop, map, pathOr, propSatisfies, length } from 'ramda';
import { EHBlueprint } from './EHBlueprint';
import './typedef';

const hasValidLocales = propSatisfies(length, 'locales');

export class EHCollection {
  constructor(payload) {
    /** @type {string} */
    this.arn = prop('arn', payload);
    /** @type {string} */
    this.availability = prop('availability', payload);
    /** @type {Locale[]} */
    this.locales = prop('locales', payload);
    /** @type {string[]} */
    this.blueprintArns = prop('blueprintArns', payload);
    /** @type {EHBlueprint[]} */
    this.blueprints = map(
      data => new EHBlueprint(prop('blueprint', data)),
      pathOr([], ['blueprints', 'blueprints'], payload)
    ).filter(hasValidLocales);
    /** @type {string} */
    this.createdAt = prop('createdAt', payload);
  }
}

import { useMutation } from 'react-query';

import { stopLab } from '../utils/labsService';
import { labStatuses } from '../contexts';
import {
  interceptRequestMetrics,
  publishCounterMetric,
} from '../utils/metrics';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';
import ERROR_TYPES from '../constants/errorTypes';

const metricsNamespace = 'StopLab';

/**
 * @typedef {Object} Variables
 * @property {string} labId
 * @property {string} blueprintArn
 * @property {string} status
 */

/**
 * @param {Object} props
 * @param {import('@amzn/katal-metrics').Publisher} [props.metricsPublisher] - (optional) The metrics publisher to use
 * @param {(variables: Variables) => void} [props.onMutate] - (optional) Callback on stopLab call
 * @param {(data: any, variables: Variables) => void} [props.onSuccess] - (optional) Callback on stopLab success
 * @param {(error: any, variables: Variables) => void} [props.onError] - (optional) Callback on stopLab error
 * @returns {import('react-query').UseMutationResult<any, any, Variables, void>}
 */
const useStopLabMutation = ({
  metricsPublisher,
  onMutate,
  onSuccess,
  onError,
} = {}) => {
  return useMutation({
    mutationFn: ({ labId }) =>
      interceptRequestMetrics(
        metricsNamespace,
        stopLab(labId),
        metricsPublisher
      ),
    retry: (failureCount, error) => {
      if (
        isNonRetryableError(error, {
          errorTypes: [ERROR_TYPES.BadRequest, ERROR_TYPES.Conflict],
        })
      ) {
        return false;
      }
      return failureCount < 2;
    },
    retryDelay: failureCount =>
      calculateRetryDelay(failureCount, { interval: 200 }),
    onMutate: variables => {
      if (variables.status === labStatuses.PROVISIONING) {
        publishCounterMetric('ProvisioningLabEnded', {
          additionalMetrics: {
            blueprintArn: variables.blueprintArn,
          },
        });
      }
      if (onMutate) onMutate(variables);
    },
    onSuccess: (data, variables) => {
      if (onSuccess) onSuccess(data, variables);
    },
    onError: (error, variables) => {
      if (onError) onError(error, variables);
    },
  });
};

export default useStopLabMutation;
